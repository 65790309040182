import {ThemeProvider} from "@material-ui/core/styles";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import {theme} from "constants/config";
import {ConfirmProvider} from "material-ui-confirm";
import React from "react";
import ReactDOM from "react-dom";
import {I18nextProvider} from "react-i18next";
import {Provider} from "react-redux";
import {BrowserRouter, Router} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import {store} from "./app/store";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import i18n from "./translation/i18n";
import DateFnsUtils from "@date-io/date-fns";

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <ConfirmProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <App />
            </MuiPickersUtilsProvider>
          </ConfirmProvider>
        </BrowserRouter>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </ThemeProvider>
    </I18nextProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
