import { PayloadAction } from "@reduxjs/toolkit";
import managerUserApi from "api/managerUser";
import { InfoUser } from "models/auth";
import { ListParams } from "models/common";
import { ListResponseUser } from "models/managerUser";
import { call, debounce, put, takeLatest } from "redux-saga/effects";
import {
  fetchUserList,
  fetchUserListFailed,
  fetchUserListSuccess,
  setFilterSearchDebounce,
  setFilterUserList,
} from "./managerAdminSlice";

function* fetchDataUserList(action: PayloadAction<ListParams>) {
  try {
    const res: ListResponseUser<InfoUser> = yield call(managerUserApi.getUsers, action.payload);
    if (res.responseCode === "00") {
      yield put(fetchUserListSuccess(res));
    } else {
      yield put(fetchUserListFailed());
    }
  } catch (error) { }
}

function* handleSearchDebounce(action: PayloadAction<ListParams>) {
  yield put(setFilterUserList(action.payload));
}

export default function* managerAdminSaga() {
  yield takeLatest(fetchUserList, fetchDataUserList);

  yield debounce(500, setFilterSearchDebounce, handleSearchDebounce);
}
