import {Box, Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import managerLessonApi from "api/managerLesson";
import {useAppDispatch, useAppSelector} from "app/hooks";
import InputField from "components/FormFields/InputField";
import {ResponseStatus} from "models/common";
import {Lesson} from "models/managerLesson";
import * as React from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {
  fetchLessonList,
  selectFilterLessonListDraft,
  selectFilterLessonListFuture,
} from "../../managerLessonSlice";

export interface EditCalendarProps {
  lesson: Lesson | undefined;
  setOpenModal: (e: any) => void;
  type?: String;
}

export default function EditCalendar({lesson, setOpenModal, type}: EditCalendarProps) {
  const useStyles = makeStyles(() => ({
    btn_edit: {
      borderRadius: 20,
      background: "linear-gradient(0deg, #10BCA7 0%, rgba(255, 239, 156, 0.9) 165.52%)",
      color: "#fff",
      padding: "5px 15px",
    },
  }));
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const filterListFuture = useAppSelector(selectFilterLessonListFuture);
  const filterListDraft = useAppSelector(selectFilterLessonListDraft);

  const initialValues = {
    publishDate: `${String(lesson?.publishDate).slice(0, 4)}-${String(lesson?.publishDate).slice(
      4,
      6
    )}-${String(lesson?.publishDate).slice(-2)}`,
    publishTime:
      lesson?.publishTime && lesson?.publishTime >= 1000
        ? `${String(lesson?.publishTime).slice(0, 2)}:${String(lesson?.publishTime).slice(2, 4)}`
        : `0${String(lesson?.publishTime).slice(0, 1)}:${String(lesson?.publishTime).slice(1, 3)}`,
  };
  const {control, handleSubmit} = useForm({
    defaultValues: initialValues,
  });
  const handleSubmitForm = async (value: any) => {
    const newValue = {
      ...value,
      publishDate: value.publishDate.split("-").join(""),
      publishTime: value.publishTime.split(":").join(""),
      lessionId: String(lesson?.id),
    };
    const formData = new FormData();
    formData.append("publishDate", newValue.publishDate);
    formData.append("publishTime", newValue.publishTime);
    formData.append("lessionId", newValue.lessionId);
    try {
      const res: ResponseStatus = await managerLessonApi.updateLesson(formData);
      if (res.responseCode === "00") {
        toast.success(`${t("managerLesson.capnhatlenlichthanhcong")}`);
        setOpenModal(false);
        type === "FUTURE"
          ? dispatch(fetchLessonList({...filterListFuture, type: "FUTURE"}))
          : dispatch(fetchLessonList({...filterListDraft, type: "DRAFT"}));
      } else {
        toast.error(`${t("managerLesson.capnhatlenlichthatbai")}`);
        throw new Error("Update lesson is failed");
      }
    } catch (error) {}
  };

  const classes = useStyles();
  return (
    <Box padding="60px 20px" pt={2}>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <InputField type="date" control={control} label="" name="publishDate" />
        <InputField type="time" control={control} label="" name="publishTime" />

        <Box textAlign="center" mt={2}>
          <Button className={classes.btn_edit} type="submit">
            {t("managerLesson.thaydoi")}
          </Button>
        </Box>
      </form>
    </Box>
  );
}
