import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "app/store";
import {Category, ListResponseCategory} from "models/managerCategory";

export interface ManagerCategoryState {
  categoryList: Category[];
  loading: Boolean;
}

const initialState: ManagerCategoryState = {
  categoryList: [],
  loading: false,
};

const managerCategory = createSlice({
  initialState,
  name: "manager-category-slice",
  reducers: {
    fetchCategoryList(state) {
      state.loading = true;
    },
    fetchCategoryListSuccess(state, action: PayloadAction<ListResponseCategory<Category>>) {
      state.categoryList = action.payload.list;

      state.loading = false;
    },
    fetchCategoryListFailed(state) {
      state.loading = false;
    },
  },
});

export const {fetchCategoryList, fetchCategoryListFailed, fetchCategoryListSuccess} =
  managerCategory.actions;

export const selectCategoryList = (state: RootState) => state.managerCategoryReducer.categoryList;

const managerCategoryReducer = managerCategory.reducer;
export default managerCategoryReducer;
