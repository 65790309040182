import {
    Box,
    Button,
    IconButton,
    makeStyles,
    Menu,
    TextField,
    Theme,
    Card,
    Typography,
    CardHeader,
  } from "@material-ui/core";
import MAGAZINE_IMG from "assets/img/magazine_community.png"
import { useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { url } from "inspector";
import { useEffect, useState } from "react";
import { ResponseMagazine,MagazineList } from "models/managerMagazine";
import {useTranslation} from "react-i18next";
import managerMagazineApi from "api/managerMagazine";
export interface MagazineCardProps{}
export default function MagazineCard (props:MagazineCardProps ){
    const useStyles = makeStyles((theme: Theme) => ({
        magazine_img_box: {
            height:"352px",
            width:"100%"
        },
        body:{
            marginLeft:'40px',
        },
        text:{
            color:'#004673',
            fontSize:'30px',
            textAlign:'center',
            backgroundColor:'white',
            padding:20,
            width:"100%"
        },
        bg_color:{
            backgroundColor:'white'
        },
        title:{
            fontSize:'30px',
            color:'#004673',
            margin:0
        },
        action:{
            color:'#0D76B9',
            fontSize:'20px',
            marginRight:'10px',
            cursor:'pointer'
        },
        magazine_card:{
            marginTop:'30px'
        },
        text_magazine:{
            color:'#797979',
            fontSize:'24px',
            margin:'0 0 0 20px'
        },
        title_carousel:{
            fontSize:'24px',
            color:'#1B1B1B',
            margin:'0 0 40px 0'
        },
        image_carousel:{
            padding:'20px'
        }
    }));
    const navigate=useNavigate();
    const {t} = useTranslation();
    const openMagazine=(item:any)=>{
        navigate("/admin/magazine-news",{
            state:{
                magazine:item,
                openModal:true
            }
        });
    }
    const classes = useStyles();
    const [magazineList,setMagazineList]=useState<any>();
    const getListMagazine = async()=>{
        const params={
            page_index: 0,
            page_size: 5
        }
        try{
            const res:ResponseMagazine<MagazineList> = await managerMagazineApi.getMagazineList(params) ;
            if(res.responseCode=== '00'){
               setMagazineList(res.magazineList);
            }
          }
          catch (error) {}
    }
    useEffect(()=>{
        getListMagazine()
    },[])
    return(
        <Box className={classes.body}>
            <Box className={classes.bg_color}>
                <Box className={classes.magazine_img_box} style={{backgroundImage:`url(${MAGAZINE_IMG})`, backgroundPosition:'center', backgroundRepeat:'no-repeat', backgroundSize:'contain'}}></Box>
                <Typography className={classes.text}>{t("community.magazine.gioithieu")}</Typography>
            </Box>
            <Box className={classes.magazine_card}>
                <Card>
                    <CardHeader
                    action={
                        <p onClick={()=>navigate('/admin/magazine-news')} className={classes.action}>{t("community.magazine.xemthem")}</p>
                    }
                    title={<p className={classes.title}>{t("community.magazine.tapchi")}</p>}>

                    </CardHeader>
                    <Typography className={classes.text_magazine}>{t("community.magazine.thamkhao")}</Typography>
                    <Carousel showThumbs={false} showStatus={false} showArrows={false} autoPlay={true} infiniteLoop={true}>
                        {
                            magazineList?magazineList.map((item:MagazineList,index:number)=>(
                                <div key={index} onClick={()=>openMagazine(item)} style={{cursor:"pointer"}}>
                                    <img className={classes.image_carousel} src="https://media.ex-cdn.com/EXP/media.nongnghiep.vn/files/dungct/2021/04/25/gia-heo-hoi-hom-nay_nongnghiep-075806_609.jpg" alt="" />
                                    <p className={classes.title_carousel}>{item.title}</p>
                                </div>
                            )):null
                        }
                    </Carousel>
                </Card>
            </Box>
        </Box>
    )
}