import {Box, Button, CircularProgress, Typography} from "@material-ui/core";
import authApi from "api/authApi";
import BACK_GROUND from "assets/img/bg_forgotpassword.jpg";
import LOGO from "assets/img/logo.png";
import InputField from "components/FormFields/InputField";
import {ResponseStatus} from "models/common";
import React, {Fragment, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import useStyles from "./style";
export default function ForgotPassword() {
  const classes = useStyles({
    background: BACK_GROUND,
  });
  const initialValues = {
    username: "",
  };

  const {control, handleSubmit} = useForm({
    defaultValues: initialValues,
  });
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isSuccess, setIsSuccess] = useState(false);
  const [notifySuccess, setNotifySuccess] = useState("");
  const handleSubmitForm = async (value: any) => {
    if (!value) return;
    setLoading(true);
    const rqUser = value.request.includes("@gmail.com")
      ? {email: value.request}
      : {staff_id: value.request};
    try {
      const res: ResponseStatus = await authApi.forgotPassword(rqUser);
      if (res.responseCode === "00") {
        setNotifySuccess(
          `${t("profile.yeucaulaylaimatkhau")} ${value.request} ${t("profile.daduocguitoiadmin")}`
        );
        setIsSuccess(true);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(`${t("profile.manhanvienhoacemailkhongtontai")}`);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <Box className={classes.background}>
      {loading && (
        <Box className={classes.loading}>
          <Box>
            <CircularProgress style={{width: 50, height: 50}} />
          </Box>
        </Box>
      )}
      <Box className={classes.content}>
        <img src={LOGO} alt="logo" className={classes.logo} />
        {isSuccess ? (
          <Box width="70%" margin="0 auto">
            <Typography variant="body2" className={classes.notify_success}>
              {notifySuccess}
            </Typography>

            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/login")}
              className={classes.btn_submit}
            >
              {t("button.goback")}
            </Button>
          </Box>
        ) : (
          <Fragment>
            <Typography variant="subtitle2" className={classes.content_title}>
              {t("auth.staffId-or-email")}
            </Typography>
            <form onSubmit={handleSubmit(handleSubmitForm)}>
              <Box className={classes.form__input}>
                <InputField
                  name="request"
                  placeholder={t("staffId-or-email")}
                  control={control}
                  borderRadius="10px"
                />
              </Box>
              <Box display="flex" justifyContent="center" mt={3}>
                <Button
                  variant="contained"
                  className={classes.btn_cancel}
                  onClick={() => navigate(-1)}
                >
                  {t("button.cancel")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.btn_submit}
                  type="submit"
                >
                  {" "}
                  {t("button.next")}
                </Button>
              </Box>
            </form>
          </Fragment>
        )}
      </Box>
    </Box>
  );
}
