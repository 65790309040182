import {PayloadAction} from "@reduxjs/toolkit";
import managerLessonApi from "api/managerLesson";
import {ListParams} from "models/common";
import {LessonByUser, ListResponseLesson} from "models/managerLesson";
import {call, put, takeLatest} from "redux-saga/effects";
import {
  fetchListLessonByUser,
  fetchListLessonByUserFailed,
  fetchListLessonByUserSuccess,
} from "./listLessonSlice";

function* fetchDataListLessonByUser(action: PayloadAction<ListParams>) {
  try {
    const res: ListResponseLesson<LessonByUser> = yield call(
      managerLessonApi.getLessonByUser,
      action.payload
    );
    if (res.responseCode === "00") {
      yield put(fetchListLessonByUserSuccess(res));
    } else {
      yield put(fetchListLessonByUserFailed);
      throw new Error(res.responseDescription);
    }
  } catch (error) {}
}

export default function* ListLessonByUserSaga() {
  yield takeLatest(fetchListLessonByUser, fetchDataListLessonByUser);
}
