import {Box, Button, Container, Grid, Tooltip, Typography} from "@material-ui/core";
import {Pagination} from "@material-ui/lab";
import {useAppDispatch, useAppSelector} from "app/hooks";
import ICON_COMMENT from "assets/img/icon_comment.png";
import ICON_DISLIKE from "assets/img/icon_dislike.png";
import ICON_LIKE from "assets/img/icon_like.png";
import ICON_NO_WATCHED from "assets/img/icon_noWatched.png";
import ICON_WATCHED from "assets/img/icon_watched.png";
import LOADING from "assets/img/loading.gif";
import VECTOR_CATEGORY from "assets/img/vector_category.png";
import clsx from "clsx";
import queryString from "query-string";
import React, {Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {
  fetchListLessonByUser,
  selectFilterLessonListByUser,
  selectLessonListByUser,
  selectLoadingLessonListByUser,
  setFilterListLessonByUser,
} from "./listLessonSlice";
import useStyles from "./style";

export default function ListLesson() {
  const location = useLocation();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const params: any = queryString.parse(location.search);
  const dispatch = useAppDispatch();
  const filter = useAppSelector(selectFilterLessonListByUser);
  const listLesson = useAppSelector(selectLessonListByUser);
  const loading = useAppSelector(selectLoadingLessonListByUser);
  useEffect(() => {
    dispatch(fetchListLessonByUser({...filter, month: params.month, year: params.year}));
  }, [dispatch, filter]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleOpenMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangePage = (page: number) => {
    dispatch(
      setFilterListLessonByUser({
        ...filter,
        page: page - 1,
      })
    );
  };
  const classes = useStyles();
  {
    /* <Box className={classes.filter}>
        <Box className={classes.filter__title}>
          <Typography variant="subtitle2">TẤT CẢ</Typography>
        </Box>
        <Box className={clsx(classes.filter__title, classes.filter__title__viewed)}>
          <Typography variant="subtitle2">ĐÃ XEM</Typography>
        </Box>
        <Box className={clsx(classes.filter__title, classes.filter__title__notsee)}>
          <Typography variant="subtitle2">CHƯA XEM</Typography>
        </Box>
        <Box className={clsx(classes.filter__title, classes.filter__title__topic)}>
          <div onClick={handleOpenMenu}>
            <Typography variant="subtitle2">CHỦ ĐỀ</Typography>
            <Typography variant="subtitle2">
              <ExpandMore />
            </Typography>
          </div>
        </Box>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          className={classes.menu}
        >
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
            }}
          >
            GGP
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
            }}
          >
            AI CENTER
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
            }}
          >
            LAB
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
            }}
          >
            SẢN XUẤT
          </MenuItem>
        </Menu>
      </Box> */
  }

  return (
    <Fragment>
      {loading ? (
        <Box className={classes.loading}>
          <img src={LOADING} alt="LOADING" />
        </Box>
      ) : (
        <>
          {listLesson?.content?.length > 0 ? (
            <Box className={classes.container}>
              <Box mt={4}>
                {listLesson?.content?.map((lesson) => {
                  return (
                    <Box mt={3} key={lesson.id}>
                      <Grid container>
                        <Grid item xs={4} sm={3}>
                          <img
                            src={
                              lesson.lession.attachment
                                ? lesson?.lession?.attachment.urlThumbnail
                                : lesson?.lession?.powerPointFileList[0]?.powerPointFile
                                    ?.urlThumbnail
                            }
                            alt="LESSON"
                            className={classes.image}
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              navigate({
                                pathname: `/lesson/${lesson.lessionId}`,
                                search: `year=${params.year}&month=${params.month}&attachmentID=${lesson.lession.attachmentID}`,
                              })
                            }
                          />
                        </Grid>

                        <Grid item xs={8} sm={9}>
                          <Box
                            className={classes.wrapper}
                            style={{
                              backgroundColor: "#FFF",
                            }}
                          >
                            <Box className={classes.content}>
                              <Box className={classes.root}>
                                <Typography variant="body1" className={classes.content__date}>
                                  {String(lesson?.lession?.publishDate).slice(-2)}/
                                  {String(lesson?.lession?.publishDate).slice(4, 6)}/
                                  {String(lesson?.lession?.publishDate).slice(0, 4)}
                                </Typography>

                                <Box className={clsx(classes.root, classes.content__category)}>
                                  <Typography variant="subtitle2">
                                    {lesson?.lession?.lessionCategory?.name}
                                  </Typography>

                                  <img src={VECTOR_CATEGORY} alt="background" />
                                </Box>
                              </Box>

                              <Box className={classes.root}>
                                <Typography
                                  variant="h5"
                                  className={classes.content__lesson__name}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    navigate({
                                      pathname: `/lesson/${lesson.lessionId}`,
                                      search: `year=${params.year}&month=${params.month}&attachmentID=${lesson.lession.attachmentID}`,
                                    })
                                  }
                                >
                                  {lesson?.lession?.lessionName}
                                </Typography>
                                <Box
                                  className={clsx(
                                    classes.root,
                                    classes.content__lesson__status__icon
                                  )}
                                >
                                  {lesson?.watched ? (
                                    <Fragment>
                                      <img src={ICON_WATCHED} alt="ICON_WATCHED" />
                                      <Typography variant="subtitle2">Đã xem</Typography>
                                    </Fragment>
                                  ) : (
                                    <Fragment>
                                      <img src={ICON_NO_WATCHED} alt="ICON_NO_WATCHED" />
                                      <Typography variant="subtitle2">Chưa xem</Typography>
                                    </Fragment>
                                  )}
                                </Box>
                              </Box>
                              <Typography variant="subtitle2" className={classes.description}>
                                Mô tả :{" "}
                                {lesson?.lession?.lessionDescription.length > 100
                                  ? lesson?.lession?.lessionDescription.slice(0, 100) + "..."
                                  : lesson?.lession?.lessionDescription}
                              </Typography>

                              <Box className={clsx(classes.root, classes.statistic)}>
                                {/* <Box textAlign="center">
                                    <img src={ICON_LIKE} alt="ICON_LIKE" />
                                    <Typography variant="subtitle2">
                                      {lesson?.lession?.noUserLiked} thích
                                    </Typography>
                                  </Box>
                                  <Box textAlign="center">
                                    <img src={ICON_DISLIKE} alt="ICON_DISLIKE" />
                                    <Typography variant="subtitle2">
                                      {lesson?.lession?.noUserDislike} không thích
                                    </Typography>
                                  </Box> */}

                                <Box textAlign="right">
                                  <img src={ICON_COMMENT} alt="ICON_COMMENT" />
                                  <Typography variant="subtitle2">
                                    {" "}
                                    {lesson?.lession?.noComments} {t("home.detaillesson.binhluan")}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })}
                <Box mt={3} display="flex" justifyContent="flex-end">
                  <Pagination
                    count={Math.ceil(listLesson.totalElements / listLesson.size)}
                    variant="outlined"
                    page={listLesson.number + 1}
                    color="primary"
                    shape="rounded"
                    onChange={(e, page) => handleChangePage(page)}
                  />
                </Box>
              </Box>
            </Box>
          ) : (
            <Container maxWidth="xl">
              <Box mt={2}>
                <Typography variant="subtitle2" style={{fontSize: 16, fontWeight: 600}}>
                  {t("home.listlesson.chuacobaihoc")}
                </Typography>
                <Button onClick={() => navigate(-1)} className={classes.btn_goback}>
                  {t("button.goback")}
                </Button>
              </Box>
            </Container>
          )}
        </>
      )}
    </Fragment>
  );
}
