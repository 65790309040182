import {Box, Button} from "@material-ui/core";
import managerCategoryApi from "api/managerCategory";
import {useAppDispatch} from "app/hooks";
import InputField from "components/FormFields/InputField";
import {ResponseStatus} from "models/common";
import {Category} from "models/managerCategory";
import React, {useMemo} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {fetchCategoryList} from "../../managerCategorySlice";
import useStyles from "./style";

interface EditCategoryProps {
  valueCategory: Category;
  setOpenModal: (openModal: any) => void;
}

export default function EditCategory({valueCategory, setOpenModal}: EditCategoryProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  const initialValues = {
    lessionCategoryName: valueCategory.name,
  };

  const {control, handleSubmit} = useForm({
    defaultValues: useMemo(() => initialValues, [initialValues]),
  });

  const handleSubmitForm = async (value: any) => {
    try {
      const res: ResponseStatus = await managerCategoryApi.updateCategory(value, valueCategory?.id);
      if (res.responseCode === "00") {
        toast.success("Cập nhật chủ đề thành công");
        dispatch(fetchCategoryList());
        setOpenModal(false);
      } else {
        toast.error(`${t("managerCategory.capnhatthatbai")}`);
        setOpenModal(false);
        throw new Error(`${t("managerCategory.capnhatthatbai")}`);
      }
    } catch (error) {}
  };
  return (
    <Box className={classes.content}>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <InputField name="lessionCategoryName" control={control} label="Tên chủ đề" />

        <Box textAlign="center">
          <Button className={classes.btn_submit} type="submit">
            {t("button.update")}
          </Button>
        </Box>
      </form>
    </Box>
  );
}
