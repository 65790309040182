import {Box} from "@material-ui/core";
import MagazineNews from "features/Admin/pages/ManagerMagazine/components/MagazineNews";
import * as React from "react";

export interface MagazineProps {}

export default function Magazine(props: MagazineProps) {
  return (
    <Box mt={3}>
      <MagazineNews />
    </Box>
  );
}
