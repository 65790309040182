import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import {FormHelperText, makeStyles} from "@material-ui/core";
import * as React from "react";
import {Control, useController} from "react-hook-form";

export interface RadioOption {
  label?: string;
  value: number | string | undefined;
}

export interface RadioGroupFieldProps {
  name: any;
  control: Control<any>;
  label?: string;
  disabled?: boolean;
  options: RadioOption[];
}

export function RadioGroupField({name, control, label, disabled, options}: RadioGroupFieldProps) {
  const {
    field: {value, onChange, onBlur},
    fieldState: {invalid, error},
  } = useController({
    name,
    control,
  });
  const useStyles = makeStyles(() => ({
    radioGroup: {
      "& .MuiRadio-colorPrimary.Mui-disabled": {
        color: "#3f51b5",
      },
      "& .MuiFormControlLabel-label.Mui-disabled": {
        color: "rgba(0, 0, 0, 0.87)",
      },
    },
  }));
  const classes = useStyles();

  return (
    <FormControl disabled={disabled} margin="normal" error={invalid}>
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        color="primary"
        className={classes.radioGroup}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio color="primary" size="small" />}
            label={option.label}
          />
        ))}
      </RadioGroup>

      <FormHelperText>{error?.message}</FormHelperText>
    </FormControl>
  );
}
