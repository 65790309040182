import {
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  MenuList,
  Modal,
  Popover,
  Slide,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import CloseIcon from "@material-ui/icons/Close";
import managerCommnunityApi from "api/managerCommunity";
import {useAppDispatch, useAppSelector} from "app/hooks";
import EMOJI from "assets/img/emoji.png";
import ICON__ADD__PDF from "assets/img/icon_add_pdf.png";
import ICON_FILTER from "assets/img/icon_filter.png";
import ICON_PDF from "assets/img/icon_pdf.png";
import ICON_PIG from "assets/img/icon_pig_pink.png";
import ICON_PIG_NOBG from "assets/img/icon_pig_pink_nobg.png";
import ICON_VIDEO from "assets/img/icon_video.png";
import ICON__ADD__IMAGE from "assets/img/icon__add__image.png";
import NO_AVATAR from "assets/img/no_avatar.png";
import VECTOR_CATEGORY_1 from "assets/img/vector_category_1.png";
import axios, {AxiosResponse} from "axios";
import CardPosts from "components/cardPosts";
import {BASE_URL, TOKEN} from "constants/config";
import Picker, {SKIN_TONE_MEDIUM_DARK} from "emoji-picker-react";
import {
  fetchCommunityPostList,
  handleFilterParams,
  loadingComunnityPost,
  selectCommunityPostList,
  selectParamsCommunityPostList,
} from "features/Admin/pages/Community/communityPostSlice";
import {
  fetchCommunityCategoryList,
  selectCommunityCategoryList,
  setCategoryCheckList,
  setCheckList,
} from "features/Admin/pages/Community/comunitySlice";
import {selectInfoLogin} from "features/Auth/authSlice";
import {
  CommnunityPostList,
  CommunityCommentList,
  CreatePostInterface,
  ListResponseCommunityPost,
  ListResponseReact,
  ResponseHandleComment,
} from "models/managerCommunityPost";
import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import Dropzone from "react-dropzone";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {BsSearch} from "react-icons/bs";
import ReactPlayer from "react-player";
import {toast} from "react-toastify";
import Comment from "./Comment";
import CreatePost from "./CreatePost";
import InputComment from "./InputComment";
import MagazineCard from "./MagazineCard";
import ModalCategory from "./ModalCategory";
import useStyles from "./style";
export interface NewsProps {
  showFilterCategory: boolean;
  listIDSearchCategory?: any;
}
export default function News({showFilterCategory, listIDSearchCategory}: NewsProps) {
  const {t} = useTranslation();
  const loadingPostList = useAppSelector(loadingComunnityPost);
  const [loadingMoreData, setLoadingMoreData] = useState(false);
  const infoLogin = useAppSelector(selectInfoLogin);
  const classes = useStyles();
  const [valueSearch, setValueSearch] = useState<any>();
  const [comment, setComment] = useState<any>([]);
  const [openEmoji, setOpenEmoji] = useState<null | HTMLElement>(null);
  const [openModal, setOpenModal] = useState(false);
  const [openModalCategory, setOpenModalCategory] = useState(false);
  const [showDropzone, setShowDropzone] = useState(false);
  const [showDropzonePDF, setShowDropzonePDF] = useState(false);
  const [content, setContent] = useState("");
  const [fileReaderList, setFileReaderList] = useState<any>([]);
  const [file, setFile] = useState<any>("");
  const [listFile, setListFile] = useState<any>([]);
  const [openMenuCategory, setOpenMenuCategory] = useState<HTMLButtonElement | null>(null);
  const [openMenuFilter, setOpenMenuFilter] = useState<HTMLButtonElement | null>(null);
  const dispatch = useAppDispatch();
  const listCategory = useAppSelector(selectCommunityCategoryList);
  const listChecked = useAppSelector(setCategoryCheckList);
  const [countTotalVideo, setCountTotalVideo] = useState<number>();
  const [loading, setLoading] = useState(false);
  const params = useAppSelector(selectParamsCommunityPostList);
  const [postList, setPostList] = useState<CommnunityPostList[]>([]);
  const selectPostList = useAppSelector(selectCommunityPostList);
  const [listCategoryChecked, setListCategoryChecked] = useState<number[]>([]);

  const [checkedFilterListPost, setCheckedFilterListPost] = useState("new_post");
  const [checkedFilterUser, setCheckedFilterUser] = useState("everyone");
  const ref: any = useRef();

  useEffect(() => {
    if (selectPostList.length > 0) {
      setPostList(selectPostList);
    }
  }, [selectPostList]);

  useEffect(() => {
    if (listIDSearchCategory && listIDSearchCategory.length > 0) {
      const category_id_list = listIDSearchCategory.join(",");
      dispatch(fetchCommunityPostList({...params, category_id_list}));
      dispatch(handleFilterParams({...params, category_id_list}));
    } else if (listCategoryChecked.length > 0) {
      dispatch(
        fetchCommunityPostList({
          ...params,
          page_index: 0,
          category_id_list: listCategoryChecked.join(","),
        })
      );
      dispatch(
        handleFilterParams({
          ...params,
          page_index: 0,
          category_id_list: listCategoryChecked.join(","),
        })
      );
    } else {
      dispatch(
        fetchCommunityPostList({
          ...params,
          page_index: 0,
          category_id_list: "",
        })
      );
      dispatch(
        handleFilterParams({
          ...params,
          page_index: 0,
          category_id_list: "",
        })
      );
    }
  }, [listIDSearchCategory, dispatch, listCategoryChecked]);
  useEffect(() => {
    if (valueSearch === "") {
      dispatch(
        handleFilterParams({
          ...params,
          page_index: 0,
          content: "",
        })
      );
      dispatch(
        fetchCommunityPostList({
          ...params,
          page_index: 0,
          content: "",
        })
      );
    }
  }, [valueSearch]);
  useEffect(() => {
    dispatch(fetchCommunityCategoryList());
  }, []);

  const initialValues: CreatePostInterface = {
    category_id_list: "",
    content: "",
    attachments: "",
  };
  const {handleSubmit} = useForm({
    defaultValues: initialValues,
  });
  const token = localStorage.getItem(TOKEN);
  const handleSubmitForm = async (value: CreatePostInterface) => {
    setLoading(true);
    let newListChecked = listChecked.join(",");
    const newValue = {
      ...value,
      content: content,
      attachments: file,
      category_id_list: newListChecked,
    };
    const formData = new FormData();
    formData.append("content", newValue.content);
    listFile.forEach((item: any) => {
      formData.append("attachments", item);
    });
    formData.append("category_id_list", newValue.category_id_list);
    try {
      const res: AxiosResponse = await axios({
        method: "POST",
        url: `${BASE_URL}/community/createPost`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });
      if (res.data.responseCode === "00") {
        setLoading(false);
        if (infoLogin.role === "STUDENT") {
          toast.success(`${t("community.duyetbaidadang")}`);
        } else {
          toast.success(`${t("community.thembaivietthanhcong")}`);
          dispatch(fetchCommunityPostList(params));
        }
        handleCloseModalCreate();
        setContent("");
        setFileReaderList([]);
        setShowDropzone(false);
        dispatch(setCheckList([]));
      } else {
        setLoading(false);
        toast.error(`${t("community.taobaivietthatbai")}`);
        throw new Error("Create lesson is failed");
      }
    } catch (error) {}
  };
  const handleShowMenuCategory = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenMenuCategory(event.currentTarget);
  };
  const handleCloseMenuCategory = () => {
    setOpenMenuCategory(null);
  };
  const handleShowMenuFilter = (event: any) => {
    setOpenMenuFilter(event.currentTarget);
  };
  const handleCloseMenuFilter = () => {
    setOpenMenuFilter(null);
  };
  const handleOpenEmoji = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenEmoji(event.currentTarget);
  };
  const onEmojiClick = (event: any, emojiObject: any) => {
    setContent(content + emojiObject.emoji);
  };
  const openModalPDF = () => {
    setOpenModal(true);
    setShowDropzonePDF(true);
  };
  const openModalIMAGE = () => {
    setOpenModal(true);
    setShowDropzone(true);
  };
  const handleCloseModalCreate = () => {
    setOpenModal(false);
    setContent("");
    setFileReaderList([]);
    setShowDropzone(false);
    setShowDropzonePDF(false);
    dispatch(setCheckList([]));
    setListFile([]);
  };
  const handleCloseModalCategory = () => {
    setOpenModalCategory(false);
    setOpenModal(true);
  };
  const handleChangeFile = (acceptedFiles: any) => {
    if (
      acceptedFiles[0].type !== "video/mp4" &&
      acceptedFiles[0].type !== "image/jpeg" &&
      acceptedFiles[0].type !== "image/png" &&
      acceptedFiles[0].type !== "image/gif"
    ) {
      toast.error(`${t("community.saidinhdangfile")}`);
      return;
    }
    if (acceptedFiles[0].size > 500e7) {
      toast.error(`${t("community.dungluongtoida")}`);
      return;
    }
    const newListFile = [...listFile];
    newListFile.push(acceptedFiles[0]);
    setListFile(newListFile);
    setFile(acceptedFiles[0]);
    let reader = new FileReader();
    reader.readAsDataURL(acceptedFiles[0]);

    reader.onload = (e: any) => {
      const newFileReaderList = [...fileReaderList];
      newFileReaderList.push(URL.createObjectURL(acceptedFiles[0]));
      setFileReaderList(newFileReaderList);
    };
  };
  const handleChangeFilePDF = (acceptedFiles: any) => {
    if (acceptedFiles[0].type !== "application/pdf") {
      toast.error(`${t("community.saidinhdangfile")}`);
      return;
    }
    if (acceptedFiles[0].size > 500e7) {
      toast.error(`${t("community.dungluongtoida")}`);
      return;
    }
    const newListFile = [...listFile];
    newListFile.push(acceptedFiles[0]);
    setListFile(newListFile);
    setFile(acceptedFiles[0]);
    let reader = new FileReader();
    reader.readAsDataURL(acceptedFiles[0]);

    reader.onload = (e: any) => {
      const newFileReaderList = [...fileReaderList];
      newFileReaderList.push(URL.createObjectURL(acceptedFiles[0]));
      setFileReaderList(newFileReaderList);
    };
  };

  const openDropZone = () => {
    setShowDropzone(true);
    setShowDropzonePDF(false);
  };
  const openDropZonePDF = () => {
    setShowDropzone(false);
    setShowDropzonePDF(true);
  };
  const enterKeyPressed = async (e: any, postId: string, value: string, setValue: any) => {
    if (e.key == "Enter") {
      const formData = new FormData();
      formData.append("content", value);
      formData.append("parent_post_id", postId);
      try {
        const res: ResponseHandleComment = await managerCommnunityApi.createComment(formData);
        if (res.responseCode === "00") {
          dispatch(fetchCommunityPostList({...params, loading: true}));
          setValue("");
        } else {
          toast.error("Lỗi bình luận");
          throw new Error("Create lesson is failed");
        }
      } catch (error) {}
    }
  };

  const handleLikePost = async (item: CommnunityPostList) => {
    try {
      const value = {
        post_id: item.id,
        action: item.isLikedByRequester ? "Unlike" : "Like",
      };
      const res: ListResponseReact = await managerCommnunityApi.ReactPost(value);
      if (res.responseCode == "00") {
        dispatch(fetchCommunityPostList({...params, loading: true}));
      }
    } catch (error) {}
  };

  const fetchMoreData = async () => {
    setLoadingMoreData(true);
    try {
      const res: ListResponseCommunityPost<CommnunityPostList> =
        await managerCommnunityApi.getListPost({...params, page_index: params.page_index + 1});
      setLoadingMoreData(false);
      if (res.responseCode === "00") {
        if (res.postList.length === 0) {
          return;
        } else {
          dispatch(handleFilterParams({...params, page_index: params.page_index + 1}));
          setPostList([...postList, ...res.postList]);
        }
      }
    } catch (error) {
      setLoadingMoreData(false);
      throw new Error("error");
    }
  };

  const getMoreParentComment = async (postId: string, size: number) => {
    try {
      const value = {
        parent_post_id: postId,
        page_size: size + 5,
        page_index: 0,
      };
      const res: ListResponseCommunityPost<CommunityCommentList> =
        await managerCommnunityApi.getListComment(value);
      if (res.responseCode === "00") {
        const newPostList = postList.map((x: any) => {
          if (x.id === postId) {
            return {
              ...x,
              commentList: res.communityCommentList,
              shouldLoadMoreComment: res.shouldLoadMore,
            };
          } else {
            return x;
          }
        });
        setPostList(newPostList);
      }
    } catch (error) {}
  };

  const handleFilterCategoryPostList = (id: number) => {
    const listCategoryCheck: number[] = [...listCategoryChecked];
    const foundItem = listCategoryCheck.findIndex((x) => x === id);
    if (foundItem === -1) {
      listCategoryCheck.push(id);
    } else {
      listCategoryCheck.splice(foundItem, 1);
    }
    setListCategoryChecked(listCategoryCheck);
  };

  const enterKeyPressedSearch = (e: any) => {
    const value = e.target.value;
    if (value.trim()) {
      if (e.key === "Enter") {
        dispatch(fetchCommunityPostList({...params, content: value}));
        dispatch(
          handleFilterParams({
            ...params,
            page_index: 0,
            content: valueSearch,
          })
        );
      }
    }
  };

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints?.down("sm"));
  const handleChangeComment = (e: any, index: number) => {
    const value = e.target.value;
    const tempArr: any[] = [...comment];
    tempArr[index] = value;
    setComment(tempArr);
  };
  useEffect(() => {
    if (loadingMoreData) return;
    const handleFetchData = () => {
      if (ref.current.clientHeight + ref.current.offsetTop <= window.innerHeight + window.scrollY) {
        fetchMoreData();
      }
    };
    window.addEventListener("scroll", handleFetchData);
    return () => {
      window.removeEventListener("scroll", handleFetchData);
    };
  }, [loadingMoreData, fetchMoreData]);

  const handleClickChecked = (value: string) => {
    if (value === "owner" || value === "everyone") {
      dispatch(
        fetchCommunityPostList({...params, owner_id: value === "owner" ? infoLogin.id : ""})
      );
      dispatch(handleFilterParams({...params, owner_id: value === "owner" ? infoLogin.id : ""}));
      setCheckedFilterUser(value);
    } else {
      const sort_type = value === "most_comments" ? 2 : value === "new_post" ? 1 : 0;
      dispatch(fetchCommunityPostList({...params, sort_type}));
      dispatch(handleFilterParams({...params, sort_type}));
      setCheckedFilterListPost(value);
    }
  };
  return (
    <Box px={infoLogin.role === "ADMIN" ? 5 : 0} id="3">
      {/* Search bar */}
      <Box className={classes.searchBar}>
        <TextField
          name="title_post"
          placeholder={t("community.nhapnoidungtimkiem")}
          fullWidth
          variant="outlined"
          type="search"
          size="small"
          onKeyPress={(e) => enterKeyPressedSearch(e)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <BsSearch className={classes.searchIcon} />
              </InputAdornment>
            ),
          }}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setValueSearch(event.target.value)}
          className={classes.inputSearch}
        />
        {showFilterCategory && (
          <Box mx={1} minWidth={isTablet ? 90 : 120}>
            <Button
              aria-describedby="simple-popover"
              className={classes.btn_menu_category}
              onClick={handleShowMenuCategory}
              endIcon={<ArrowDropDownIcon />}
              disableRipple
            >
              {t("community.chude")}
            </Button>
            <Popover
              id="simple-popover"
              open={Boolean(openMenuCategory)}
              anchorEl={openMenuCategory}
              onClose={handleCloseMenuCategory}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              className={classes.popover_category}
            >
              <MenuList className={classes.menu__category__list}>
                {listCategory?.length > 0 ? (
                  listCategory.map((item, index) => (
                    <MenuItem onClick={() => handleFilterCategoryPostList(item.id)} key={index}>
                      <Box display="flex" alignItems="center">
                        <img
                          src={"/img/img-category/vector_category_6.png"}
                          alt="VECTOR_CATEGORY"
                          className={classes.menu__category__icon}
                        />
                        <Typography
                          variant="subtitle2"
                          style={{
                            color: listCategoryChecked.includes(item.id) ? "#0d76b8" : "initial",
                          }}
                        >
                          {item.name}
                        </Typography>
                      </Box>
                    </MenuItem>
                  ))
                ) : (
                  <p style={{paddingLeft: "20px"}}>{t("community.chuacochude")}</p>
                )}
              </MenuList>
            </Popover>
          </Box>
        )}
        <Box className={classes.icon_filter} ml={showFilterCategory ? 0 : 2}>
          <img src={ICON_FILTER} alt="icon_filter" onClick={handleShowMenuFilter} />
          <Popover
            id="simple-popover"
            open={Boolean(openMenuFilter)}
            anchorEl={openMenuFilter}
            onClose={handleCloseMenuFilter}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            className={classes.popover_filter_list}
          >
            <Box className={classes.menu__filter__list}>
              <Box>
                <Typography variant="subtitle2" className={classes.menu__filter__title}>
                  1. {t("community.doituongbaiviet")}
                </Typography>
                <Box className={classes.menu__filter__viewer}>
                  <Box>
                    <Typography variant="subtitle2">{t("community.canhan")}</Typography>
                  </Box>
                  <img
                    src={checkedFilterUser === "owner" ? ICON_PIG : ICON_PIG_NOBG}
                    alt="ICON_PIG_NOBG"
                    onClick={() => handleClickChecked("owner")}
                  />
                </Box>
                <Box className={classes.menu__filter__viewer}>
                  <Box>
                    <Typography variant="subtitle2">{t("community.moinguoi")}</Typography>
                  </Box>
                  <img
                    src={checkedFilterUser === "everyone" ? ICON_PIG : ICON_PIG_NOBG}
                    alt="ICON_PIG_NOBG"
                    onClick={() => handleClickChecked("everyone")}
                  />
                </Box>
              </Box>

              <Box>
                <Typography variant="subtitle2" className={classes.menu__filter__title}>
                  2. {t("community.thutubaiviet")}
                </Typography>
                <Box className={classes.menu__filter__viewer}>
                  <Box>
                    <Typography variant="subtitle2">{t("community.hoatdongmoinhat")} </Typography>
                    <Typography variant="body2">
                      {t("community.baivietcobinhluangannhat")}{" "}
                    </Typography>
                  </Box>
                  <img
                    src={checkedFilterListPost === "new_comment" ? ICON_PIG : ICON_PIG_NOBG}
                    alt="ICON_PIG_NOBG"
                    onClick={() => handleClickChecked("new_comment")}
                  />
                </Box>
                <Box className={classes.menu__filter__viewer}>
                  <Box>
                    <Typography variant="subtitle2">{t("community.baivietganday")}</Typography>
                    <Typography variant="body2">
                      {t("community.baivietduocdanglengannhat")}
                    </Typography>
                  </Box>
                  <img
                    src={checkedFilterListPost === "new_post" ? ICON_PIG : ICON_PIG_NOBG}
                    alt="ICON_PIG_NOBG"
                    onClick={() => handleClickChecked("new_post")}
                  />
                </Box>
                <Box className={classes.menu__filter__viewer}>
                  <Box>
                    <Typography variant="subtitle2">{t("community.binhluannhieunhat")}</Typography>
                    <Typography variant="body2">
                      {t("community.baivietcobinhluannhieunhat")}
                    </Typography>
                  </Box>
                  <img
                    src={checkedFilterListPost === "most_comments" ? ICON_PIG : ICON_PIG_NOBG}
                    alt="ICON_PIG_NOBG"
                    onClick={() => handleClickChecked("most_comments")}
                  />
                </Box>
              </Box>
            </Box>
          </Popover>
        </Box>
      </Box>
      <Box my={isTablet ? 1 : 2}>
        <hr />
      </Box>

      <Box>
        {/* Card Posts */}
        <div ref={ref}>
          {loadingPostList ? (
            <Box className={classes.loading}>
              <Box>
                <CircularProgress style={{width: 50, height: 50}} />
              </Box>
            </Box>
          ) : postList?.length > 0 ? (
            <Box
              display={
                infoLogin.role === "ADMIN" || infoLogin.role === "SUPER_ADMIN" ? "flex" : "block"
              }
            >
              <Box>
                {/* Create posts */}
                <CreatePost
                  openModalIMAGE={openModalIMAGE}
                  openModalPDF={openModalPDF}
                  setOpenModalCategory={setOpenModalCategory}
                  setOpenModal={setOpenModal}
                />
                {postList.map((item: CommnunityPostList, index: number) => (
                  <Card className={classes.card} key={index}>
                    <CardPosts
                      showBtnDeleted={true}
                      content={item}
                      info={infoLogin}
                      allPost={postList}
                      setAllPost={setPostList}
                    />
                    <Box px={2}>
                      <Box className={classes.card__wrapper}>
                        <Box className={classes.card__actions}>
                          <Box onClick={() => handleLikePost(item)}>
                            <img
                              src={item.isLikedByRequester ? ICON_PIG : ICON_PIG_NOBG}
                              alt="ICON_PIG_CHECK"
                              className={classes.card__actions__icon}
                            />
                            <Typography variant="subtitle2" style={{cursor: "pointer"}}>
                              {t("community.thich")}
                            </Typography>
                          </Box>
                          <Box width="50%">
                            <ChatBubbleOutlineIcon
                              className={classes.card__actions__icon}
                              style={{color: "#c0b7b7"}}
                            />
                            <Typography variant="subtitle2" style={{cursor: "pointer"}}>
                              {t("community.binhluan")}
                            </Typography>
                          </Box>
                        </Box>

                        {item.commentList.map((comment) => (
                          <Comment comment={comment} />
                        ))}
                        {item.shouldLoadMoreComment && (
                          <p
                            style={{fontWeight: "bold", cursor: "pointer"}}
                            onClick={(e) => getMoreParentComment(item.id, item.commentList.length)}
                          >
                            {t("community.xemthembinhluan")}
                          </p>
                        )}

                        <InputComment
                          infoLogin={infoLogin}
                          parent={item}
                          enterKeyPressed={enterKeyPressed}
                        ></InputComment>
                      </Box>
                    </Box>
                  </Card>
                ))}
                {loadingMoreData && (
                  <Box display="flex">
                    <CircularProgress style={{width: 20, height: 20, marginRight: 15}} />
                    <Typography variant="subtitle2">Loading...</Typography>
                  </Box>
                )}
              </Box>
              {(infoLogin?.role === "ADMIN" || infoLogin?.role === "SUPER_ADMIN") && (
                <Box width="30%">
                  <MagazineCard />
                </Box>
              )}
            </Box>
          ) : (
            <Box pt={3}>{t("community.khongcobaiviet")}</Box>
          )}
        </div>
      </Box>

      {/* Modal Create post */}
      <Modal
        open={openModal}
        onClose={handleCloseModalCreate}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        closeAfterTransition
        className={classes.root_modal}
      >
        <Slide direction="up" in={openModal}>
          <Box position="relative">
            <Box className={classes.modal__container}>
              <form onSubmit={handleSubmit(handleSubmitForm)}>
                <Box className={classes.modal__header}>
                  <div></div>
                  <Typography variant="subtitle2">{t("community.taobaiviet")}</Typography>
                  <IconButton onClick={handleCloseModalCreate}>
                    <CloseIcon />
                  </IconButton>
                </Box>
                <Box className={classes.modal__content}>
                  {loading && (
                    <Box className={classes.loading}>
                      <Box>
                        <CircularProgress style={{width: 50, height: 50}} />
                      </Box>
                    </Box>
                  )}
                  <Box className={classes.modal__content__info}>
                    {infoLogin.profileImageUrl ? (
                      <img
                        src={infoLogin.profileImageUrl}
                        alt="avatar"
                        className={classes.modal__avatar}
                      />
                    ) : (
                      <img src={NO_AVATAR} alt="avatar" className={classes.modal__avatar} />
                    )}
                    <Box>
                      <Typography variant="subtitle2" className={classes.modal__username}>
                        {infoLogin.name}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        className={classes.modal__category}
                        style={{display: "flex", flexWrap: "wrap"}}
                      >
                        {listCategory
                          ? listCategory.map((item, index) => {
                              if (listChecked?.length > 3) {
                                let newArray = listChecked.slice(0, 3);
                                if (newArray.includes(item.id)) {
                                  return (
                                    <p
                                      key={index}
                                      style={{margin: "0px 10px 0 0", fontSize: "14px"}}
                                    >{`${item.name}`}</p>
                                  );
                                }
                              } else {
                                let newArray = listChecked;
                                if (newArray.includes(item.id)) {
                                  return (
                                    <span
                                      key={index}
                                      style={{margin: "0px 10px 0 0", fontSize: "14px"}}
                                    >{`${item.name}`}</span>
                                  );
                                }
                              }
                            })
                          : null}
                        {listChecked?.length > 3 ? (
                          <span style={{margin: "0px 10px 0 0", fontSize: "14px"}}>...</span>
                        ) : null}
                      </Typography>
                    </Box>
                  </Box>
                  <Box my={2}>
                    <TextField
                      style={{width: "100%"}}
                      onChange={(e) => setContent(e.target.value)}
                      value={content}
                      placeholder={t("community.taobaivietcongkhai")}
                      // control={control}
                      name="content"
                      multiline
                      rows={3}
                      className={classes.modal__input__post}
                    />

                    {showDropzone && (
                      <Dropzone onDrop={handleChangeFile}>
                        {({getRootProps, getInputProps}) => (
                          <>
                            <Box className={classes.modal__dropzone}>
                              <Box
                                onClick={() => {
                                  setFile("");
                                  setListFile([]);
                                  setFileReaderList([]);
                                  setShowDropzone(false);
                                }}
                                className={classes.modal__dropzone__video__iconClose}
                              >
                                <CloseIcon />
                              </Box>
                              <div
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  flexDirection: "column",
                                }}
                              >
                                <Box
                                  {...getRootProps({
                                    className: classes.modal__dropzone__video__wrapper,
                                  })}
                                >
                                  <input {...getInputProps()} accept="image/*,video/*" />

                                  <Box className={classes.modal__dropzone__video__content}>
                                    <img src={ICON_VIDEO} alt="ICON_VIDEO" height="90px" />
                                    <Typography variant="subtitle1">
                                      {t("community.themanh/video")}
                                    </Typography>
                                    <Typography variant="subtitle1" component="span">
                                      {t("community.hoackeotha")}
                                    </Typography>
                                  </Box>
                                </Box>
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    marginTop: "20px",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  {countTotalVideo && countTotalVideo <= 4
                                    ? fileReaderList.map((item: any, index: number) =>
                                        listFile ? (
                                          listFile[index]?.path.slice(-3) === "mp4" ? (
                                            <ReactPlayer
                                              key={index}
                                              url={item}
                                              controls={true}
                                              width="250px"
                                              height="140px"
                                              config={{
                                                file: {
                                                  attributes: {
                                                    controlsList: "nodownload",
                                                  },
                                                },
                                              }}
                                              style={{
                                                padding: "10px",
                                                border: "1px solid black",
                                                marginBottom: "20px",
                                              }}
                                              // Disable right click
                                              onContextMenu={(e: any) => e.preventDefault()}
                                            />
                                          ) : listFile ? (
                                            listFile[index]?.path.slice(-3) === "png" ||
                                            listFile[index]?.path.slice(-3) === "gif" ||
                                            listFile[index]?.path.slice(-3) === "jpg" ||
                                            listFile[index]?.path.slice(-3) === "fif" ? (
                                              <img
                                                key={index}
                                                style={{
                                                  padding: "10px",
                                                  border: "1px solid black",
                                                  marginBottom: "20px",
                                                }}
                                                src={item}
                                                alt="imageFile"
                                                className={classes.imageReader}
                                              />
                                            ) : null
                                          ) : null
                                        ) : null
                                      )
                                    : fileReaderList.map((item: any, index: number) =>
                                        index < 3 ? (
                                          listFile ? (
                                            listFile[index]?.path.slice(-3) === "mp4" ? (
                                              <ReactPlayer
                                                key={index}
                                                url={item}
                                                controls={true}
                                                width="250px"
                                                height="140px"
                                                style={{
                                                  padding: "10px",
                                                  border: "1px solid black",
                                                  marginBottom: "20px",
                                                }}
                                                config={{
                                                  file: {
                                                    attributes: {
                                                      controlsList: "nodownload",
                                                    },
                                                  },
                                                }}
                                                // Disable right click
                                                onContextMenu={(e: any) => e.preventDefault()}
                                              />
                                            ) : listFile ? (
                                              listFile[index]?.path.slice(-3) === "png" ||
                                              listFile[index]?.path.slice(-3) === "gif" ||
                                              listFile[index]?.path.slice(-3) === "jpg" ||
                                              listFile[index]?.path.slice(-3) === "fif" ? (
                                                <img
                                                  key={index}
                                                  style={{
                                                    padding: "10px",
                                                    border: "1px solid black",
                                                    marginBottom: "20px",
                                                  }}
                                                  src={item}
                                                  alt="imageFile"
                                                  className={classes.imageReader}
                                                />
                                              ) : null
                                            ) : null
                                          ) : null
                                        ) : index === 3 ? (
                                          listFile ? (
                                            listFile[index]?.path.slice(-3) === "mp4" ? (
                                              <div className={classes.imageReaderOver}>
                                                <ReactPlayer
                                                  key={index}
                                                  url={item}
                                                  controls={true}
                                                  width="250px"
                                                  height="140px"
                                                  config={{
                                                    file: {
                                                      attributes: {
                                                        controlsList: "nodownload",
                                                      },
                                                    },
                                                  }}
                                                  style={{
                                                    padding: "10px",
                                                    border: "1px solid black",
                                                    marginBottom: "20px",
                                                  }}
                                                  // Disable right click
                                                  onContextMenu={(e: any) => e.preventDefault()}
                                                />
                                                <p className={classes.count}>
                                                  {countTotalVideo
                                                    ? `+${countTotalVideo - 4}`
                                                    : null}
                                                </p>
                                              </div>
                                            ) : listFile ? (
                                              listFile[index]?.path.slice(-3) === "png" ||
                                              listFile[index]?.path.slice(-3) === "gif" ||
                                              listFile[index]?.path.slice(-3) === "jpg" ||
                                              listFile[index]?.path.slice(-3) === "fif" ? (
                                                <div className={classes.imageReaderOver}>
                                                  <img
                                                    key={index}
                                                    style={{
                                                      padding: "10px",
                                                      border: "1px solid black",
                                                      marginBottom: "20px",
                                                    }}
                                                    src={item}
                                                    alt="imageFile"
                                                    className={classes.imageReader}
                                                  />
                                                  <p className={classes.count}>
                                                    {countTotalVideo
                                                      ? `+${countTotalVideo - 4}`
                                                      : null}
                                                  </p>
                                                </div>
                                              ) : null
                                            ) : null
                                          ) : null
                                        ) : null
                                      )}
                                </div>
                              </div>
                            </Box>
                          </>
                        )}
                      </Dropzone>
                    )}
                    {showDropzonePDF && (
                      <Dropzone onDrop={handleChangeFilePDF}>
                        {({getRootProps, getInputProps}) => (
                          <>
                            <Box className={classes.modal__dropzone}>
                              <Box
                                onClick={() => {
                                  setFile("");
                                  setListFile([]);
                                  setFileReaderList([]);
                                  setShowDropzonePDF(false);
                                }}
                                className={classes.modal__dropzone__video__iconClose}
                              >
                                <CloseIcon />
                              </Box>
                              <div
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  flexDirection: "column",
                                }}
                              >
                                <Box
                                  {...getRootProps({
                                    className: classes.modal__dropzone__video__wrapper,
                                  })}
                                >
                                  <input {...getInputProps()} accept=".pdf" />

                                  <Box className={classes.modal__dropzone__video__content}>
                                    <img src={ICON_VIDEO} alt="ICON_VIDEO" height="90px" />
                                    <Typography variant="subtitle1">
                                      {t("community.themfile")}
                                    </Typography>
                                    <Typography variant="subtitle1" component="span">
                                      {t("community.hoackeotha")}
                                    </Typography>
                                  </Box>
                                </Box>
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    marginTop: "20px",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  {fileReaderList.map((item: any, index: number) =>
                                    listFile ? (
                                      listFile[index]?.path.slice(-3) === "pdf" ? (
                                        <div className={classes.center}>
                                          <img className={classes.icon_pdf} src={ICON_PDF} alt="" />
                                          <p style={{color: "#4C555D"}}>{listFile[index]?.path}</p>
                                        </div>
                                      ) : null
                                    ) : null
                                  )}
                                </div>
                              </div>
                            </Box>
                          </>
                        )}
                      </Dropzone>
                    )}
                  </Box>
                  <Box textAlign="right" mr={2}>
                    <IconButton onClick={handleOpenEmoji}>
                      <img src={EMOJI} alt="EMOJI" className={classes.icon_emoji} />
                    </IconButton>
                  </Box>
                  {openEmoji && (
                    <Menu
                      id="simple-menu"
                      anchorEl={openEmoji}
                      keepMounted
                      open={Boolean(openEmoji)}
                      onClose={() => setOpenEmoji(null)}
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <Picker
                        onEmojiClick={onEmojiClick}
                        disableAutoFocus={true}
                        skinTone={SKIN_TONE_MEDIUM_DARK}
                        groupNames={{smileys_people: "PEOPLE"}}
                        native
                      />
                    </Menu>
                  )}
                  <Box className={classes.modal__content__actions}>
                    <Typography
                      variant="subtitle2"
                      onClick={() => {
                        setOpenModalCategory(true);
                        setOpenModal(false);
                      }}
                    >
                      {t("community.themvaobaidang")}
                    </Typography>
                    <Box className={classes.modal__content__actions__icon}>
                      {showDropzonePDF ? null : (
                        <Tooltip title={t("community.themanh/video") || ""}>
                          <img
                            src={ICON__ADD__IMAGE}
                            alt="ICON__ADD__IMAGE"
                            onClick={() => openDropZone()}
                          />
                        </Tooltip>
                      )}
                      {showDropzone ? null : (
                        <Tooltip title={t("community.themfile") || ""}>
                          <img
                            style={{width: "23px"}}
                            src={ICON__ADD__PDF}
                            alt="ICON__ADD__PDF"
                            onClick={() => openDropZonePDF()}
                          />
                        </Tooltip>
                      )}
                      <Tooltip title={t("community.chonchude") || ""}>
                        <img
                          src={VECTOR_CATEGORY_1}
                          alt="icon_category"
                          onClick={() => {
                            setOpenModalCategory(true);
                            setOpenModal(false);
                          }}
                        />
                      </Tooltip>
                    </Box>
                  </Box>
                  <Box textAlign="center" mt={2}>
                    <Button className={classes.modal__content__btn} type="submit">
                      {t("community.dangbai")}
                    </Button>
                  </Box>
                </Box>
              </form>
            </Box>
          </Box>
        </Slide>
      </Modal>
      {/* Modal Category */}
      <Modal
        open={openModalCategory}
        onClose={handleCloseModalCategory}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        closeAfterTransition
        className={classes.root_modal}
      >
        <Slide direction="left" in={openModalCategory}>
          <Box position="relative">
            <ModalCategory
              handleClose={handleCloseModalCategory}
              showBtnDelete={
                infoLogin.role === "ADMIN" || infoLogin.role === "SUPER_ADMIN" ? true : false
              }
            />
          </Box>
        </Slide>
      </Modal>
    </Box>
  );
}
