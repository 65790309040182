import {
  Avatar,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import LOGO from "assets/img/logo.png";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuIcon from "@material-ui/icons/Menu";
import MenuOutlinedIcon from "@material-ui/icons/MenuOutlined";
import {useAppDispatch, useAppSelector} from "app/hooks";
import clsx from "clsx";
import {TOKEN} from "constants/config";
import {fetchInfoLogin, selectInfoLogin} from "features/Auth/authSlice";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {NavLink, useMatch, useNavigate} from "react-router-dom";
import useStyles from "./style";

export default function Header() {
  const {t} = useTranslation();
  const token = localStorage.getItem(TOKEN);
  const match = useMatch({
    path: "/exam/take-exam/:id",
  });

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (token) {
      dispatch(fetchInfoLogin());
    }
  }, [token, dispatch]);
  const navigate = useNavigate();
  const [active, setActive] = useState("/home");
  const classes = useStyles();
  const theme = useTheme();
  const infoLogin = useAppSelector(selectInfoLogin);
  const isTablet = useMediaQuery(theme.breakpoints?.down("sm"));
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem(TOKEN);
    navigate("/");
  };
  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };
  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Box>
          <img src={LOGO} alt="logo" className={classes.logo} onClick={() => navigate("/home")} />
        </Box>
        {match?.pattern?.path === "/exam/take-exam/:id" ? (
          <Box className={classes.exit} onClick={() => navigate("/exam-upcoming")}>
            <ExitToAppIcon className={classes.icon_exit} onClick={() => navigate("/home")} />
            <Typography variant="subtitle2">{t("header.thoat")}</Typography>
          </Box>
        ) : (
          <>
            {isTablet ? (
              <div>
                <IconButton edge="end" onClick={handleDrawerOpen} color="inherit" disableRipple>
                  <MenuIcon />
                </IconButton>
              </div>
            ) : (
              <>
                <Box flexGrow={1}>
                  <ul className={classes.list}>
                    <li>
                      <span
                        className={classes.listItems}
                        style={{color: active.includes("/home") ? "#28A5F4" : "#000"}}
                        onClick={() => {
                          navigate("/home");
                          setActive("/home");
                        }}
                      >
                        {t("header.trangchu")}
                      </span>
                    </li>
                    {/* <li className={classes.listItems}>
                      <span
                        style={{color: active.includes("/community") ? "#28A5F4" : "#000"}}
                        className={classes.listItems__text}
                        onClick={() => {
                          navigate("/community");
                          setActive("/community");
                        }}
                      >
                        {t("header.congdong")}
                      </span>
                    </li>
                    <li className={classes.listItems}>
                      <span
                        style={{color: active.includes("/magazine") ? "#28A5F4" : "#000"}}
                        className={classes.listItems__text}
                        onClick={() => {
                          navigate("/magazine");
                          setActive("/magazine");
                        }}
                      >
                        {t("header.tapchi")}
                      </span>
                    </li> */}
                    <li className={classes.listItems}>
                      <span
                        style={{color: active.includes("/lesson-month") ? "#28A5F4" : "#000"}}
                        className={classes.listItems__text}
                        onClick={() => {
                          navigate("/lesson-month");
                          setActive("/lesson-month");
                        }}
                      >
                        {t("header.baihoc")}
                      </span>
                    </li>
                    <li className={classes.listItems}>
                      <span
                        style={{color: active.includes("/academic-record") ? "#28A5F4" : "#000"}}
                        className={classes.listItems__text}
                        onClick={() => {
                          navigate("/academic-record");
                          setActive("/academic-record");
                        }}
                      >
                        {t("header.hocba")}
                      </span>
                    </li>
                    <li className={classes.listItems}>
                      <Box>
                        <Box
                          style={{
                            color:
                              active.includes("/test-history") || active.includes("/exam-upcoming")
                                ? "#28A5F4"
                                : "#000",
                          }}
                          className={classes.listItems__text}
                        >
                          {t("header.kiemtra")} <ExpandMoreIcon />
                        </Box>
                        <Box className={clsx(classes.listItems__content, classes.listItems__test)}>
                          <ul>
                            <li className={classes.listItems__content__title}>
                              <span
                                onClick={() => {
                                  navigate("/test-history");
                                  setActive("/test-history");
                                }}
                              >
                                {t("header.danhsachbaithicu")}
                              </span>
                            </li>
                            <li className={classes.listItems__content__title}>
                              <span
                                onClick={() => {
                                  navigate("/exam-upcoming");
                                  setActive("/exam-upcoming");
                                }}
                              >
                                {t("header.lichkiemtrasaptoi")}
                              </span>
                            </li>
                          </ul>
                        </Box>
                      </Box>
                    </li>
                  </ul>
                </Box>

                <Box>
                  <Button
                    className={classes.btn_profile}
                    aria-controls={anchorEl ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={handleOpenMenu}
                    disableRipple
                  >
                    <MenuOutlinedIcon />
                    <img
                      src={infoLogin.profileImageUrl || LOGO}
                      alt="avatar"
                      className={classes.avatar}
                    />
                  </Button>
                </Box>

                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  className={classes.menu}
                >
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      navigate("/profile");
                    }}
                  >
                    {t("header.hosocanhan")}
                  </MenuItem>

                  <MenuItem onClick={handleLogout}>{t("header.dangxuat")}</MenuItem>
                </Menu>
              </>
            )}
          </>
        )}
      </Box>
      <Drawer
        open={openDrawer}
        onClose={handleDrawerClose}
        anchor="right"
        transitionDuration={400}
        className={classes.drawer_container}
      >
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Avatar
              alt="avatar"
              src={infoLogin.profileImageUrl || LOGO}
              className={classes.avatar_mobile}
              onClick={() => {
                navigate("/profile");
                handleDrawerClose();
              }}
            />
            <Typography
              variant="subtitle2"
              onClick={() => {
                navigate("/profile");
                handleDrawerClose();
              }}
              className={classes.username}
            >
              {infoLogin.name}
            </Typography>
          </Box>

          <Box>
            <IconButton onClick={handleDrawerClose}>
              <MenuIcon />
            </IconButton>
          </Box>
        </Box>

        <List disablePadding style={{marginTop: 10}}>
          <ListItem className={classes.list__item}>
            <NavLink to="/home" onClick={handleDrawerClose}>
              {t("header.trangchu")}
            </NavLink>
          </ListItem>
          <ListItem className={classes.list__item}>
            <NavLink to="/profile" onClick={handleDrawerClose}>
              {t("header.hosocanhan")}
            </NavLink>
          </ListItem>
          <ListItem className={classes.list__item}>
            <NavLink to="/community" onClick={handleDrawerClose}>
              {t("header.congdong")}
            </NavLink>
          </ListItem>

          <ListItem className={classes.list__item}>
            <NavLink to="/magazine" onClick={handleDrawerClose}>
              {t("header.tapchi")}
            </NavLink>
          </ListItem>

          <ListItem className={classes.list__item}>
            <NavLink to="/lesson-month" onClick={handleDrawerClose}>
              {t("header.baihoc")}
            </NavLink>
          </ListItem>

          <ListItem className={classes.list__item}>
            <NavLink to="/test-history" onClick={handleDrawerClose}>
              {t("header.danhsachbaithicu")}
            </NavLink>
          </ListItem>
          <ListItem className={classes.list__item}>
            <NavLink to="/exam-upcoming" onClick={handleDrawerClose}>
              {t("header.lichkiemtrasaptoi")}
            </NavLink>
          </ListItem>
          <ListItem className={classes.list__item}>
            <NavLink to="/home" onClick={handleDrawerClose}>
              {t("header.hocba")}
            </NavLink>
          </ListItem>

          <ListItem className={classes.list__item}>
            <NavLink to="/home" onClick={handleLogout}>
              {t("header.dangxuat")}
            </NavLink>
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
}
