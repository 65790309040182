import {
  Box,
  Button,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import managerTestApi from "api/managerTest";
import { useAppSelector } from "app/hooks";
import clsx from "clsx";
import { selectInfoLogin } from "features/Auth/authSlice";
import {
  DetailTestByUser,
  StudentAnswers
} from "models/managerTest";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { scroller } from "react-scroll";
import { secondsToHms } from "utils/timeConvert";

export default function DetailResultTest() {
  const useStyles = makeStyles((theme: Theme) => ({
    title: {
      color: "#004673",
      fontWeight: 700,
      fontSize: 20,
      padding: "10px 0",
    },
    header: {
      backgroundColor: "#137BBD",
      width: "100%",
      marginTop: 20,
    },

    header__title: {
      padding: "10px 40px",
      borderBottom: "1px solid #FFFFFF",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints?.down("md")]: {
        padding: "10px 20px",
      },
      [theme.breakpoints?.down("xs")]: {
        padding: "10px ",
      },
      "& > h6": {
        fontSize: 20,
        fontWeight: 600,
        color: "#FFFFFF",
        [theme.breakpoints?.down("md")]: {
          fontSize: 16,
        },
        [theme.breakpoints?.down("xs")]: {
          fontSize: 13,
          width: 250,
        },
      },
    },
    header__bg: {
      color: "#DB2139",
      backgroundColor: "#FFFFFF",
      padding: "2px 30px",
      fontSize: 25,
      fontWeight: 700,
      borderRadius: 8,
      [theme.breakpoints?.down("md")]: {
        fontSize: 16,
        padding: "2px 20px",
      },
      [theme.breakpoints?.down("xs")]: {
        fontSize: 13,
        padding: "2px 10px",
      },
    },
    header__ranking: {
      marginLeft: 40,
      "& > span": {
        color: "#004673",
        fontSize: 20,
        [theme.breakpoints?.down("md")]: {
          fontSize: 16,
        },
        [theme.breakpoints?.down("xs")]: {
          fontSize: 13,
        },
      },
    },
    exam: {
      backgroundColor: "#FFFFFF",
      boxShadow: " 0px 1px 4px rgba(168, 168, 168, 0.4)",
      borderRadius: 6,
      padding: "10px 15px",
      marginBottom: 15,
    },
    question__content: {
      color: "#0065A6",
      fontSize: 18,
      "&> p": {
        fontSize: 15,
        marginLeft: 10,
        color: "#000000",
      },
    },
    btn_submit: {
      background: "linear-gradient(0deg, #137BBD 0%, rgba(105, 197, 255, 0.8) 145.33%)",
      borderRadius: 10,
      padding: "10px 20px",
      color: "#FFFFFF",
    },
    icon_scroll: {
      position: "fixed",
      right: 30,
      bottom: 25,
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints?.down("xs")]: {
        right: 20,
        bottom: 15,
      },
      "& > svg": {
        fontSize: 30,
        color: "#0065A6",
        cursor: "pointer",
        marginTop: 10,
        [theme.breakpoints?.down("xs")]: {
          fontSize: 20,
        },
      },
    },
    btn_goBack: {
      background: "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)",
      color: "#fff",
      borderRadius: 10,
      padding: "8px 15px",
      alignSelf: "center",
      fontSize: 13,
      [theme.breakpoints?.down("xs")]: {
        padding: "4px 10px",
        fontSize: 11,
        borderRadius: 5,
      },
    },
    userChecked: {
      position: "absolute",
      width: 11,
      height: 11,
      borderRadius: "50%",
      backgroundColor: "#565656",
      top: 15.5,
      left: 4,
    },
    checkIconRight: {
      color: "#1FDA53",
      marginRight: 40,
      fontSize: 25,
      [theme.breakpoints?.down("md")]: {
        fontSize: 20,
        marginRight: 20,
      },
      [theme.breakpoints?.down("xs")]: {
        fontSize: 15,
        marginRight: 10,
      },
    },
    question_number: {
      color: "#0065A6",
      fontSize: 16,
      [theme.breakpoints?.down("md")]: {
        fontSize: 14,
      },
    },
    question_content: {
      fontSize: 15,
      [theme.breakpoints?.down("md")]: {
        fontSize: 13,
      },
    },
    answer_content: {
      fontSize: 13,
      [theme.breakpoints?.down("md")]: {
        fontSize: 11,
      },
    },
  }));
  const {t} = useTranslation();
  const classes = useStyles();
  const params = useParams();
  const location: any = useLocation();
  const navigate = useNavigate();
  const infoUser = useAppSelector(selectInfoLogin);
  const [detailTestByUser, setDetailTestByUser] = useState<DetailTestByUser>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints?.down("xs"));

  useEffect(() => {
    (async () => {
      const res: DetailTestByUser = await managerTestApi.getDetailTestByUser(params?.id);
      setDetailTestByUser(res);
    })();
  }, []);

  const handleScrollToTop = () => {
    scroller.scrollTo("header", {
      duration: 800,
      smooth: "easeInOutQuart",
      offset: -80,
    });
  };
  const handleScrollToBottom = () => {
    scroller.scrollTo("footer", {
      duration: 800,
      smooth: "easeInOutQuart",
      offset: -80,
    });
  };


  return (
    <Box px={isMobile ? 1 : 3}>
      <Box className={classes.header} id="header">
        <Box className={classes.header__title}>
          <Typography variant="subtitle2">{infoUser.name}</Typography>
          <Box display="flex" justifyContent="space-between">
            <Box className={clsx(classes.header__ranking, classes.header__bg)}>
              {detailTestByUser?.submissionDetails?.point || 0}
              <Typography variant="subtitle2" component="span">
                {t("test.diem")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={classes.header__title}>
          <Typography variant="subtitle2">
            {detailTestByUser?.submissionDetails?.exam?.examTitle}
          </Typography>
          <Box className={classes.header__bg}>{secondsToHms(location?.state?.duration)}</Box>
        </Box>
      </Box>

      {detailTestByUser?.submissionDetails?.studentAnswers?.map(
        (answer: StudentAnswers, index: number) => (
          <Box className={classes.exam} key={answer.id}>
            <Box key={answer.id}>
              <Box mb={2}>
                <Typography
                  variant="subtitle2"
                  className={classes.question_number}
                  component="span"
                >
                  {t("test.cau")} {index + 1} :{" "}
                  <Typography variant="h6" component="span" className={classes.question_content}>
                    {answer.question.content}
                  </Typography>
                </Typography>
              </Box>
              {answer.question.imageFileList.length > 0 && (
                <Box
                  display="grid"
                  gridTemplateColumns={
                    answer?.question?.imageFileList?.length === 1 ? "1fr" : "1fr 1fr"
                  }
                  gridGap={10}
                  my="10px"
                >
                  {answer.question.imageFileList.map((x: any) => {
                    return (
                      <img
                        key={x.id}
                        style={{
                          width: "100%",
                          height: "fit-content",

                          objectFit: "contain",
                          maxHeight: "450px",
                        }}
                        src={x.imageFile.url}
                        alt={x}
                      />
                    );
                  })}
                </Box>
              )}
              <RadioGroup name="choice">
                {answer.question.answers.map((item) => (
                  <Box
                    key={item.id}
                    position="relative"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box
                      className={classes.userChecked}
                      style={{
                        display: answer.choice === item.id ? "block" : "none",
                      }}
                    ></Box>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={answer.choice === item.id}
                          style={{
                            color: answer.choice === item.id ? "#1FDA53" :'#8d87878a',
                          }}
                          disabled={answer.choice === item.id}
                        />
                      }
                      label={
                        <Typography
                          variant="subtitle2"
                          className={classes.answer_content}
                        >
                          {item?.content}
                        </Typography>
                      }
                    />
                    {item.isRightAnswer && answer.choice === item.id && answer.isCorrect && (
                      <Box className={classes.checkIconRight}>
                        <CheckIcon />
                      </Box>
                    )}
                    {!answer.isCorrect && answer.choice === item.id && (
                      <Box className={classes.checkIconRight} style={{color: "red"}}>
                        <CloseIcon />
                      </Box>
                    )}
                  </Box>
                ))}
              </RadioGroup>
            </Box>
          </Box>
        )
      )}

      <Box mt={2} id="footer">
        <Button
          variant="contained"
          color="primary"
          className={classes.btn_goBack}
          onClick={() => navigate(-1)}
        >
          {t("button.goback")}
        </Button>
      </Box>
      <Box className={classes.icon_scroll}>
        <Tooltip title={t("test.arrow.dichuyenlendautrang") || ""}>
          <ExpandLessIcon onClick={handleScrollToTop} />
        </Tooltip>
        <Tooltip title={t("test.arrow.dichuyenxuongcuoitrang") || ""}>
          <ExpandMoreIcon onClick={handleScrollToBottom} />
        </Tooltip>
      </Box>
    </Box>
  );
}
