import {Box, Grid, Typography, useMediaQuery} from "@material-ui/core";
import {useTheme} from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import managerLessonApi from "api/managerLesson";
import {useAppDispatch, useAppSelector} from "app/hooks";
import BG_LESSON_STUDENT from "assets/img/bg_detail_lesson_student.png";
import LOADING from "assets/img/loading.gif";

import ICON_NO_WATCHED from "assets/img/icon_noWatched.png";
import ICON_WATCHED from "assets/img/icon_watched.png";
import clsx from "clsx";
import ControlVideo from "components/ControlVideo";
import {BASE_URL} from "constants/config";
import {ResponseStatus} from "models/common";
import {Comment, LessonByUser, ListResponseComments} from "models/managerLesson";
import queryString from "query-string";
import {Fragment, useCallback, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import ReactPlayer from "react-player";
import {useLocation, useParams} from "react-router-dom";
import {fetchListLessonByUser, selectFilterLessonListByUser} from "../ListLesson/listLessonSlice";
import CreateComment from "./components/CreateComment";
import ListComment from "./components/ListComment";
import ListVideoRecommend from "./components/ListVideoRecommend";
import ListCommentMobile from "./components/Mobile/ListCommentMobile";
import ListVideoRecommandMobile from "./components/Mobile/ListVideoRecommendMobile";
import {
  fetchDetailLesson,
  selectDetailLesson,
  selectLoadingDetailLesson,
} from "./detailLessonSlice";
import useStyles from "./style";

let count = 0;

export default function DetailLesson() {
  const {t} = useTranslation();
  const [openDrawer, setOpenDrawer] = useState(true);
  const detailLesson = useAppSelector(selectDetailLesson);
  const loading = useAppSelector(selectLoadingDetailLesson);
  const params = useParams();
  const location = useLocation();
  const querySearch: any = queryString.parse(location.search);
  const dispatch = useAppDispatch();
  const filterListLesson = useAppSelector(selectFilterLessonListByUser);

  const [listComment, setListComment] = useState<ListResponseComments<Comment> | undefined>();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [filter, setFilter] = useState({
    page: 0,
    size: 3,
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [detailLesson?.lessionUsers?.lession?.attachmentID]);
  const playerWrapperRef = useRef<any>(null);
  const playerRef = useRef<any>(null);
  const controlsRef = useRef<any>(null);
  const handleFetchListComment = useCallback(async () => {
    try {
      const res: ListResponseComments<Comment> = await managerLessonApi.getComments({
        page: 0,
        size: 3,
        lessionId: Number(params.id),
      });
      setListComment(res);
    } catch (error) {}
  }, [params]);

  useEffect(() => {
    dispatch(
      fetchListLessonByUser({
        ...filterListLesson,
        size: 20,
        month: querySearch.month,
        year: querySearch.year,
      })
    );
  }, [detailLesson]);

  useEffect(() => {
    handleFetchListComment();
    setFilter({page: 0, size: 3});
  }, [params]);
  useEffect(() => {
    dispatch(
      fetchDetailLesson({
        id: Number(params?.id),
        loading: true,
      })
    );
  }, [params]);
  const classes = useStyles();

  const handleShowMore = async () => {
    setFilter({...filter, page: filter.page + 1});
    try {
      const res: ListResponseComments<Comment> = await managerLessonApi.getComments({
        ...filter,
        lessionId: Number(params.id),
        page: filter.page + 1,
      });
      const dataListCommentNext = res.comments.content;
      const currentListComment = {...res};
      const newListComment = listComment?.comments?.content?.concat(dataListCommentNext);
      currentListComment.comments.content = newListComment;
      setListComment(currentListComment);
    } catch (error) {}
  };

  // const handlePostLikeStatus = async (detail: LessonByUser | undefined) => {
  //   try {
  //     const res: ResponseStatus = await managerLessonApi.postLikeStatus({
  //       lessionId: detail?.lessionUsers?.lessionId,
  //       likeStatus: detail?.lessionUsers?.likeStatus === "LIKED" ? "NONE" : "LIKED",
  //     });
  //     if (res.responseCode === "00") {
  //       dispatch(fetchDetailLesson(Number(params?.id)));
  //     } else {
  //       throw new Error("Like video is failed");
  //     }
  //   } catch (error) {}
  // };

  // const handlePostDislikeStatus = async (detail: LessonByUser | undefined) => {
  //   try {
  //     const res: ResponseStatus = await managerLessonApi.postLikeStatus({
  //       lessionId: detail?.lessionUsers?.lessionId,
  //       likeStatus: detail?.lessionUsers?.likeStatus === "DISLIKE" ? "NONE" : "DISLIKE",
  //     });
  //     if (res.responseCode === "00") {
  //       dispatch(fetchDetailLesson(Number(params?.id)));
  //     } else {
  //       throw new Error("Like video is failed");
  //     }
  //   } catch (error) {}
  // };

  //  Control Video
  const [showControl, setShowControl] = useState(false);
  const [control, setControl] = useState({
    played: 0,
    muted: false,
    volume: 1,
    playbackRate: 1.0,
    playing: true,
    start: false,
  });

  useEffect(() => {
    setControl({
      played: 0,
      muted: false,
      volume: 1,
      playbackRate: 1.0,
      playing: true,
      start: false,
    });
    setShowControl(false);
    if (detailLesson?.lessionUsers?.lastSeenTime > 0) {
      playerRef.current.seekTo(detailLesson?.lessionUsers?.lastSeenTime, "seconds");
    }
  }, [detailLesson]);
  const currentTime = playerRef && playerRef.current ? playerRef.current.getCurrentTime() : "00:00";
  const duration = playerRef && playerRef.current ? playerRef.current.getDuration() : "00:00";

  useEffect(() => {
    if (detailLesson?.lessionUsers?.watched) return;
    const handleWatched = async (detail: LessonByUser | undefined) => {
      try {
        const res: ResponseStatus = await managerLessonApi.postWatchedStatus({
          lessionId: detail?.lessionUsers?.lessionId,
          isWatched: !detail?.lessionUsers?.watched,
        });
        if (res.responseCode === "00") {
          dispatch(
            fetchDetailLesson({
              id: Number(params?.id),
              loading: false,
            })
          );
        } else {
          throw new Error(res.responseDescription);
        }
      } catch (error) {}
    };
    if (currentTime / duration > 0.98) {
      handleWatched(detailLesson);
    }
    return;
  }, [currentTime, detailLesson]);
  const handleToggleScreenfull = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else if (playerWrapperRef.current.requestFullscreen) {
      playerWrapperRef.current.requestFullscreen();
    } else if (playerWrapperRef.current.webkitRequestFullscreen) {
      /* Safari */
      playerWrapperRef.current.webkitRequestFullscreen();
    } else if (playerWrapperRef.current.msRequestFullscreen) {
      /* IE11 */
      playerWrapperRef.current.msRequestFullscreen();
    } else {
      playerWrapperRef.current.requestFullscreen();
    }
  };
  const handleMouseMove = () => {
    if (!controlsRef.current) return;
    controlsRef.current.style.visibility = "visible";
    count = 0;
  };

  const hanldeMouseLeave = () => {
    if (!controlsRef.current) return;
    controlsRef.current.style.visibility = "hidden";
    count = 0;
  };

  const handleProgress = (changeState: any) => {
    if (!controlsRef.current) return;
    if (count > 2) {
      controlsRef.current.style.visibility = "hidden";
      count = 0;
    }
    if (controlsRef.current.style.visibility == "visible") {
      count += 1;
    }
    setControl({...control, played: changeState.played});
  };
  useEffect(() => {
    if (control.start) {
      const timer = setInterval(async () => {
        await managerLessonApi.trackLessonLastSeen({
          lessonUserID: detailLesson.lessionUsers.id,
          lastSeenTime: playerRef.current.getCurrentTime(),
        });
      }, 5000);
      return () => clearInterval(timer);
    }
    return;
  }, [control.start]);
  return (
    <Box className={classes.container}>
      <Grid container>
        <Grid item xs={12} lg={openDrawer ? 8 : 10}>
          {loading ? (
            <Box className={classes.loading}>
              <img src={LOADING} alt="LOADING" />
            </Box>
          ) : (
            <>
              {detailLesson?.lessionUsers?.lession?.attachment ? (
                <div
                  className={classes.player_wrapper}
                  ref={playerWrapperRef}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={hanldeMouseLeave}
                  onClick={() => setShowControl(true)}
                >
                  <ReactPlayer
                    onEnded={() => setControl({...control, playing: false})}
                    playsinline
                    playing={control.playing}
                    className={classes.react_player}
                    url={
                      isMobile
                        ? detailLesson?.lessionUsers?.lession?.attachment.url
                        : `${BASE_URL}/common/video/${detailLesson?.lessionUsers?.lession?.attachmentID}`
                    }
                    controls={false}
                    width="100%"
                    height="100%"
                    light={detailLesson?.lessionUsers?.lession?.attachment?.urlThumbnail}
                    playIcon={
                      <PlayArrowIcon
                        className={classes.icon_play}
                        onClick={() => {
                          setShowControl(true);
                        }}
                      />
                    }
                    ref={playerRef}
                    config={{file: {attributes: {controlsList: "nodownload"}}}}
                    onContextMenu={(e: any) => e.preventDefault()}
                    muted={control.muted}
                    volume={control.volume}
                    playbackRate={control.playbackRate}
                    onProgress={handleProgress}
                    onStart={() => {
                      setControl({...control, start: !control.start});
                      const timer = detailLesson?.lessionUsers?.lastSeenTime;
                      if (timer) {
                        playerRef.current.seekTo(timer, "seconds");
                      }
                    }}
                  />
                  {showControl && (
                    <ControlVideo
                      isWatched={detailLesson?.lessionUsers?.watched}
                      playerRef={playerRef}
                      handleToggleScreenfull={handleToggleScreenfull}
                      ref={controlsRef}
                      setControl={setControl}
                      control={control}
                      currentTime={currentTime}
                      duration={duration}
                    />
                  )}
                </div>
              ) : (
                <iframe
                  src={`https://view.officeapps.live.com/op/embed.aspx?src=${detailLesson?.lessionUsers?.lession?.powerPointFileList[0]?.powerPointFile?.url}`}
                  width="100%"
                  height="400px"
                ></iframe>
              )}
            </>
          )}

          <Box className={classes.content}>
            <Box className={classes.root}>
              <Typography variant="subtitle2" className={classes.lesson__category}>
                {detailLesson?.lessionUsers?.lession?.lessionCategory?.name}
              </Typography>
              {detailLesson?.lessionUsers?.watched ? (
                <Box className={classes.lesson__isWatched}>
                  <img src={ICON_WATCHED} alt="ICON_WATCHED" />
                  <Typography variant="subtitle2">{t("home.detaillesson.daxem")}</Typography>
                </Box>
              ) : (
                <Box className={classes.lesson__isWatched}>
                  <img src={ICON_NO_WATCHED} alt="ICON_NO_WATCHED" />
                  <Typography variant="subtitle2">{t("home.detaillesson.chuaxem")}</Typography>
                </Box>
              )}
            </Box>
            <Box className={clsx(classes.root, classes.rootMobile)}>
              <Typography variant="subtitle2" className={classes.lesson__title}>
                {detailLesson?.lessionUsers?.lession?.lessionName}
              </Typography>
              {/* {!isMobile && (
                <Box className={clsx(classes.root, classes.content__status)}>
                  <Tooltip
                    title={
                      detailLesson?.lessionUsers?.likeStatus === "LIKED"
                        ? `${t("home.detaillesson.bothich")}`
                        : `${t("home.detaillesson.thich")}`
                    }
                    style={{cursor: "pointer"}}
                    onClick={() => handlePostLikeStatus(detailLesson)}
                  >
                    <Box display="flex" alignItems="center">
                      {detailLesson?.lessionUsers?.likeStatus === "LIKED" ? (
                        <ThumbUpIcon className={clsx(classes.icon, classes.icon_checked)} />
                      ) : (
                        <img src={ICON_LIKE} alt="ICON_LIKE" className={classes.icon} />
                      )}
                      <Typography
                        variant="subtitle2"
                        component="span"
                        className={classes.number_status}
                      >
                        {detailLesson?.lessionUsers?.lession?.noUserLiked}{" "}
                        {t("home.detaillesson.like")}
                      </Typography>
                    </Box>
                  </Tooltip>
                  <Tooltip
                    title={`${t("home.detaillesson.khongthich")}`}
                    style={{cursor: "pointer"}}
                    onClick={() => handlePostDislikeStatus(detailLesson)}
                  >
                    <Box display="flex" alignItems="center">
                      {detailLesson?.lessionUsers?.likeStatus === "DISLIKE" ? (
                        <ThumbDownIcon className={clsx(classes.icon, classes.icon_checked)} />
                      ) : (
                        <img src={ICON_DISLIKE} alt="ICON_DISLIKE" className={classes.icon} />
                      )}
                      <Typography
                        variant="subtitle2"
                        component="span"
                        className={classes.number_status}
                      >
                        {detailLesson?.lessionUsers?.lession?.noUserDislike}{" "}
                        {t("home.detaillesson.like")}
                      </Typography>
                    </Box>
                  </Tooltip>
                </Box>
              )} */}
            </Box>
            <Typography variant="subtitle2" className={classes.lesson__date}>
              {String(detailLesson?.lessionUsers?.lession?.publishDate).slice(-2)}/
              {String(detailLesson?.lessionUsers?.lession?.publishDate).slice(4, 6)}/
              {String(detailLesson?.lessionUsers?.lession?.publishDate).slice(0, 4)}
            </Typography>

            <Typography variant="subtitle2" className={classes.lesson__description}>
              {t("home.detaillesson.mota")} :{" "}
              <Typography variant="subtitle2" component="span">
                {detailLesson?.lessionUsers?.lession?.lessionDescription}
              </Typography>
            </Typography>
            {/* {isMobile && (
              <Box className={clsx(classes.root, classes.content__status)}>
                <Tooltip
                  title={
                    detailLesson?.lessionUsers?.likeStatus === "LIKED"
                      ? `${t("home.detaillesson.bothich")}`
                      : `${t("home.detaillesson.thich")}`
                  }
                  style={{cursor: "pointer"}}
                  onClick={() => handlePostLikeStatus(detailLesson)}
                >
                  <Box display="flex" alignItems="center">
                    {detailLesson?.lessionUsers?.likeStatus === "LIKED" ? (
                      <ThumbUpIcon className={clsx(classes.icon, classes.icon_checked)} />
                    ) : (
                      <img src={ICON_LIKE} alt="ICON_LIKE" className={classes.icon} />
                    )}
                    <Typography
                      variant="subtitle2"
                      component="span"
                      className={classes.number_status}
                    >
                      {detailLesson?.lessionUsers?.lession?.noUserLiked}{" "}
                      {t("home.detaillesson.like")}
                    </Typography>
                  </Box>
                </Tooltip>
                <Tooltip
                  title={t("home.detaillesson.khongthich") || ""}
                  style={{cursor: "pointer"}}
                  onClick={() => handlePostDislikeStatus(detailLesson)}
                >
                  <Box display="flex" alignItems="center">
                    {detailLesson?.lessionUsers?.likeStatus === "DISLIKE" ? (
                      <ThumbDownIcon className={clsx(classes.icon, classes.icon_checked)} />
                    ) : (
                      <img src={ICON_DISLIKE} alt="ICON_DISLIKE" className={classes.icon} />
                    )}
                    <Typography
                      variant="subtitle2"
                      component="span"
                      className={classes.number_status}
                    >
                      {detailLesson?.lessionUsers?.lession?.noUserLiked}{" "}
                      {t("home.detaillesson.unlike")}
                    </Typography>
                  </Box>
                </Tooltip>
              </Box>
            )} */}
            {/*  Comment */}
            {isTablet ? (
              <ListCommentMobile />
            ) : (
              <>
                <Typography variant="h5" style={{margin: "10px 0"}}>
                  {listComment?.comments.totalElements} {t("home.detaillesson.binhluan")}
                </Typography>
                <CreateComment handleFetchListComment={handleFetchListComment} />

                {listComment?.comments?.content?.map((comment: Comment, index: number) => (
                  <Fragment key={index}>
                    <ListComment
                      userComment={comment}
                      handleFetchListComment={handleFetchListComment}
                    />
                  </Fragment>
                ))}
                {listComment?.comments?.number + 1 !== listComment?.comments?.totalPages &&
                listComment?.comments?.content &&
                listComment?.comments?.content?.length > 0 ? (
                  <Box className={classes.showMore} onClick={handleShowMore} mt={3}>
                    <ArrowDropDownIcon />
                    <Typography variant="subtitle2">
                      {t("home.detaillesson.hienthithembinhluan")}
                    </Typography>
                  </Box>
                ) : null}
              </>
            )}
            {/* List comment */}
          </Box>
        </Grid>

        {/* List video recommend */}

        <Grid item xs={12} lg={openDrawer ? 4 : 2}>
          {isTablet ? (
            <ListVideoRecommandMobile />
          ) : (
            <>
              <ListVideoRecommend openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
              <Box className={classes.bg_lesson}>
                <img src={BG_LESSON_STUDENT} alt="BG_LESSON_STUDENT" />
              </Box>
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
