import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { ListResponseCommunityCategory, CommnunityCategoryList } from "models/managerCommunity";

export interface ManagerCommunityCategoryListState {
    communityCategoryList: CommnunityCategoryList[];
    loading: Boolean;
    listCheck: number[];
}
const initialState: ManagerCommunityCategoryListState = {
    communityCategoryList: [],
    loading: false,
    listCheck: [],
};
const managerCommunityCategory = createSlice({
    initialState,
    name: "manager-community-slice",
    reducers: {
        fetchCommunityCategoryList(state) {
            state.loading = true;
        },
        fetchCommunityCategoryListSuccess(state, action: PayloadAction<ListResponseCommunityCategory<CommnunityCategoryList>>) {
            state.communityCategoryList = action.payload.categoryList;
            state.loading = false;
        },
        fetchCommunityCategoryListFailed(state) {
            state.loading = false;
        },
        setCheckList(state, action: PayloadAction<number[]>) {
            state.listCheck = action.payload;
        },

    }
})

export const { fetchCommunityCategoryList, fetchCommunityCategoryListFailed, fetchCommunityCategoryListSuccess, setCheckList } = managerCommunityCategory.actions;

export const selectCommunityCategoryList = (state: RootState) => state.managerCommunityCategoryReducer.communityCategoryList;
export const setCategoryCheckList = (state: RootState) => state.managerCommunityCategoryReducer.listCheck;
const managerCommunityCategoryReducer = managerCommunityCategory.reducer;
export default managerCommunityCategoryReducer;
