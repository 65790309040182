import {Box, Button, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {ExamNoQuestion} from "models/managerTest";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

export interface TimeoutProps {
  exam: ExamNoQuestion;
  isMobile: boolean;
}

export default function Timeout({exam, isMobile}: TimeoutProps) {
  const navigate = useNavigate();
  const useStyles = makeStyles((theme: Theme) => ({
    btn_detail: {
      background: "linear-gradient(0deg, #10BCA7 0%, rgba(255, 239, 156, 0.9) 165.52%)",
      borderRadius: 10,
      color: "#fff",
      padding: "5px 15px",
      [theme.breakpoints?.down("xs")]: {
        padding: 0,
        fontSize: 8,
        borderRadius: 5,
        minWidth: 45,
      },
    },
  }));
  const classes = useStyles();

  const [difference, setDifference] = useState<any>();
  const [difference__timeout, setDifferenceTimeOut] = useState<any>();

  useEffect(() => {
    const interval = setInterval(() => {
      const currentDay = new Date().getTime();
      const examDay = new Date(exam.startDate).getTime();
      const difference = moment.duration(moment(examDay).diff(moment(currentDay)));
      const timeExam = new Date().setMinutes(new Date().getMinutes() - Number(exam?.examTime));
      const difference__timeout = moment.duration(moment(examDay).diff(moment(timeExam)));
      setDifferenceTimeOut(difference__timeout);
      setDifference(difference);
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  const handleDateTime = () => {
    if (difference?.days() > 0) {
      return (
        <Typography variant="subtitle2" style={{color: "#137BBD", fontSize: isMobile ? 8 : 14}}>
          Còn {difference?.days()} ngày
        </Typography>
      );
    } else if (difference?.days() === 0 && difference?.hours() > 0) {
      return (
        <Typography variant="subtitle2" style={{color: "#137BBD", fontSize: isMobile ? 8 : 14}}>
          Còn {difference?.hours()} giờ
        </Typography>
      );
    } else if (
      difference?.days() === 0 &&
      difference?.hours() === 0 &&
      difference?.minutes() > 15
    ) {
      return (
        <Typography variant="subtitle2" style={{color: "#137BBD", fontSize: isMobile ? 8 : 14}}>
          Còn {difference?.minutes()} phút
        </Typography>
      );
    } else if (
      difference?.days() === 0 &&
      difference?.hours() === 0 &&
      difference?.minutes() <= 15 &&
      difference__timeout?.milliseconds() > 0
    ) {
      return (
        <Button
          size="small"
          className={classes.btn_detail}
          onClick={() =>
            navigate("/exam/", {
              state: {
                id: exam?.id,
              },
            })
          }
        >
          Vào thi
        </Button>
      );
    }
  };
  return <>{handleDateTime()}</>;
}
