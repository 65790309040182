import {
  Box,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {useAppSelector} from "app/hooks";
import ICON_CHECK_SEARCH from "assets/img/icon_pig_pink_checked.png";
import ICON_PIG from "assets/img/icon_pig_pink_nobg.png";
import VECTOR_CATEGORY_TITLE from "assets/img/vector_category_white.png";
import News from "components/News";
import {selectCommunityCategoryList} from "features/Admin/pages/Community/comunitySlice";
import {CommnunityCategoryList} from "models/managerCommunity";
import {ChangeEvent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {BsSearch} from "react-icons/bs";
export interface CommunityProps {}

export default function Community(props: CommunityProps) {
  const useStyles = makeStyles((theme: Theme) => ({
    container: {
      padding: "35px 25px",
      [theme.breakpoints?.down("md")]: {
        padding: "25px 20px",
      },
      [theme.breakpoints?.down("xs")]: {
        padding: "0px",
      },
    },
    content__category: {
      height: "100%",
      position: "fixed",
      minWidth: 500,
      display: "inline-block",
      [theme.breakpoints?.down("lg")]: {
        minWidth: 400,
      },
      [theme.breakpoints?.down("md")]: {
        minWidth: 300,
      },
      [theme.breakpoints?.down("sm")]: {
        minWidth: 250,
      },
    },
    title__category: {
      backgroundColor: "#E58A25",
      color: "#fff",
      padding: 15,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&> h6": {
        fontSize: 16,
        color: "#fff",
      },
    },
    list__category: {
      maxHeight: 300,
      overflowY: "auto",
      padding: "10px 20px",
      backgroundColor: "#Fff",
    },
    list__item: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: "#fff",
      paddingBottom: 5,
      "&:hover": {
        backgroundColor: "#F5F5F5",
        transition: "backgroundColor 0.3s linear",
      },
    },
    icon__category: {
      width: 35,
      maxHeight: 20,
      marginRight: 8,
    },

    icon_pig: {
      height: 30,
      width: 30,
      marginRight: 8,
      cursor: "pointer",
    },

    input__search__category: {
      height: "100%",
      "& .MuiInputBase-root": {
        height: "100%",
        borderRadius: 0,
        backgroundColor: "#fff",
        "& .MuiOutlinedInput-input": {
          padding: 12,
        },
      },
    },
    searchIcon: {
      fontSize: 20,
      color: "#797979",
    },
  }));
  const {t} = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints?.down("sm"));
  const list = useAppSelector(selectCommunityCategoryList);
  const [listCategory, setListCategory] = useState<CommnunityCategoryList[]>([]);
  useEffect(() => {
    if (list.length > 0) {
      setListCategory(list);
    }
  }, [list]);
  const [listSearchCategory, setListSearchCategory] = useState<any>([]);
  const findIndex = (array: number[], i: number) => {
    var result = -1;
    array.forEach((item, index) => {
      if (item === i) {
        return (result = index);
      }
    });
    return result;
  };
  const handleCheckSearch = (id: number) => {
    if (listSearchCategory.includes(id)) {
      const index = findIndex(listSearchCategory, id);
      if (index !== -1) {
        const newListSearch = [...listSearchCategory];
        newListSearch.splice(index, 1);
        setListSearchCategory(newListSearch);
      }
    } else {
      const newListSearch = [...listSearchCategory];
      newListSearch.push(id);
      setListSearchCategory(newListSearch);
    }
  };

  const handleSearch = (value: string) => {
    const newListSearch = list.filter((x) => x.name.toUpperCase().includes(value.toUpperCase()));
    setListCategory(newListSearch);
  };
  return (
    <Box className={classes.container}>
      <Grid container>
        <Grid item xs={12} md={8}>
          <Box pr={isTablet ? 0 : 3}>
            <News
              showFilterCategory={isTablet ? true : false}
              listIDSearchCategory={listSearchCategory}
            />
          </Box>
        </Grid>
        {!isTablet && (
          <Grid item xs={12} md={4} style={{position: "relative"}}>
            <Box className={classes.content__category}>
              <Box className={classes.title__category}>
                <img
                  src={VECTOR_CATEGORY_TITLE}
                  alt="VECTOR_CATEGORY"
                  className={classes.icon__category}
                />
                <Typography variant="subtitle2">{t("community.chude")}</Typography>
              </Box>

              <Box>
                <TextField
                  name="title_post"
                  placeholder={t("community.nhapchudetimkiem")}
                  fullWidth
                  variant="outlined"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <BsSearch className={classes.searchIcon} />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleSearch(event.target.value)
                  }
                  className={classes.input__search__category}
                />
              </Box>
              <Box className={classes.list__category}>
                {listCategory?.map((item, index) => (
                  <Box
                    key={item.id}
                    className={classes.list__item}
                    onClick={() => handleCheckSearch(item.id)}
                  >
                    <Box display="flex" alignItems="center">
                      <img
                        src={"/img/img-category/vector_category_6.png"}
                        alt="VECTOR_CATEGORY"
                        className={classes.icon__category}
                      />
                      <Typography variant="subtitle2">{item.name}</Typography>
                    </Box>
                    <Box display="flex" alignItems="center">
                      {listSearchCategory.includes(item.id) ? (
                        <img src={ICON_CHECK_SEARCH} alt="ICON_PIG" className={classes.icon_pig} />
                      ) : (
                        <img src={ICON_PIG} alt="ICON_PIG" className={classes.icon_pig} />
                      )}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
