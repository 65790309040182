import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "app/store";
import {GroupList, ListResponseHiarachy} from "models/managerGroup";

export interface ManagerGroupState {
  groupList: GroupList[];
  loading: Boolean;
}

const initialState: ManagerGroupState = {
  groupList: [],
  loading: false,
};

const managerGroupSlice = createSlice({
  initialState,
  name: "manager-group-slice",
  reducers: {
    fetchGroupList(state) {
      state.loading = true;
    },
    fetchGroupListSuccess(state, action: PayloadAction<ListResponseHiarachy<GroupList>>) {
      state.groupList = action.payload.list;
      state.loading = false;
    },
    fetchGroupListFailed(state) {
      state.loading = false;
    },
  },
});

export const {fetchGroupList, fetchGroupListSuccess, fetchGroupListFailed} =
  managerGroupSlice.actions;

export const selectGroupList = (state: RootState) => state.manaagerGroupReducer.groupList;

const manaagerGroupReducer = managerGroupSlice.reducer;

export default manaagerGroupReducer;
