import { PayloadAction } from "@reduxjs/toolkit";
import managerLessonApi from "api/managerLesson";
import { LessonByUser } from "models/managerLesson";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchDetailLesson,
  fetchDetailLessonFailed,
  fetchDetailLessonSuccess,
} from "./detailLessonSlice";

function* fetchDataDetailLesson(action: PayloadAction<any | undefined>) {
  try {
    const res: LessonByUser = yield call(managerLessonApi.getDetailLessonByUser, action.payload.id);
    if (res.responseCode === "00") {
      yield put(fetchDetailLessonSuccess(res));
    } else {
      yield put(fetchDetailLessonFailed());
    }
  } catch (error) { }
}

export default function* detailLessonSaga() {
  yield takeLatest(fetchDetailLesson, fetchDataDetailLesson);
}
