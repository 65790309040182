import {PayloadAction} from "@reduxjs/toolkit";
import managerTestApi from "api/managerTest";
import {ListParams} from "models/common";
import {
  ExamNoQuestion,
  ListExamNoQuestionResponse,
  ListTest,
  ListTestByCodeExamResponse,
} from "models/managerTest";
import {call, put, takeLatest} from "redux-saga/effects";
import {
  fetchListExamStudent,
  fetchListExamStudentFailed,
  fetchListExamStudentSuccess,
  fetchListTestOldStudent,
  fetchListTestOldStudentFailed,
  fetchListTestOldStudentSuccess,
} from "./listExamStudentSlice";

function* handleFetchDataListExamStudent(action: PayloadAction<ListParams>) {
  try {
    const res: ListExamNoQuestionResponse<ExamNoQuestion> = yield call(
      managerTestApi.getListExamNoQuestions,
      action.payload
    );
    if (res.responseCode === "00") {
      yield put(fetchListExamStudentSuccess(res));
    } else {
      yield put(fetchListExamStudentFailed());
    }
  } catch (error) {}
}

function* handleFetchDataListTestOldStudent(action: PayloadAction<ListParams>) {
  try {
    const res: ListTestByCodeExamResponse<ListTest> = yield call(
      managerTestApi.getListTestOld,
      action.payload
    );
    if (res.responseCode === "00") {
      yield put(fetchListTestOldStudentSuccess(res));
    } else {
      yield put(fetchListTestOldStudentFailed());
    }
  } catch (error) {}
}

export default function* listExamStudentSaga() {
  yield takeLatest(fetchListExamStudent, handleFetchDataListExamStudent);
  yield takeLatest(fetchListTestOldStudent, handleFetchDataListTestOldStudent);
}
