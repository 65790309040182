import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "app/store";
import {ListParams} from "models/common";
import {LessonByUser, ListResponseLesson} from "models/managerLesson";

export interface ListLessonState {
  listLesson: {
    content: LessonByUser[];
    [key: string]: any;
  };
  loading: boolean;
  filter: ListParams;
}

const initialState: ListLessonState = {
  filter: {
    page: 0,
    size: 10,
  },
  loading: false,
  listLesson: {
    content: [],
  },
};

const listLessonByUserSlice = createSlice({
  initialState,
  name: "list-lesson-by-user",
  reducers: {
    fetchListLessonByUser(state, action: PayloadAction<ListParams>) {
      state.loading = true;
    },
    fetchListLessonByUserSuccess(state, action: PayloadAction<ListResponseLesson<LessonByUser>>) {
      state.listLesson = action.payload.lessions;
      state.loading = false;
    },
    fetchListLessonByUserFailed(state) {
      state.loading = false;
    },
    setFilterListLessonByUser(state, action: PayloadAction<ListParams>) {
      state.filter = action.payload;
    },
  },
});

export const {
  fetchListLessonByUser,
  fetchListLessonByUserFailed,
  fetchListLessonByUserSuccess,
  setFilterListLessonByUser,
} = listLessonByUserSlice.actions;

export const selectLessonListByUser = (state: RootState) =>
  state.listLessonByuserReducer.listLesson;

export const selectFilterLessonListByUser = (state: RootState) =>
  state.listLessonByuserReducer.filter;

export const selectLoadingLessonListByUser = (state: RootState) =>
  state.listLessonByuserReducer.loading;

const listLessonByuserReducer = listLessonByUserSlice.reducer;
export default listLessonByuserReducer;
