import {Box, Theme, Typography, IconButton, Modal, Slide} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {useAppSelector} from "app/hooks";
import React, {Fragment, useCallback, useEffect, useState} from "react";
import {selectDetailLesson} from "../../detailLessonSlice";
import CreateComment from "../CreateComment";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import CloseIcon from "@material-ui/icons/Close";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import ListComment from "../ListComment";
import {Comment, ListResponseComments} from "models/managerLesson";
import managerLessonApi from "api/managerLesson";

export interface ListCommentMobileProps {}

export default function ListCommentMobile(props: ListCommentMobileProps) {
  const useStyles = makeStyles((theme: Theme) => ({
    container: {
      borderTop: "1px solid rgba(0,0,0,0.1)",
      borderBottom: "1px solid rgba(0,0,0,0.1)",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& > h6": {
        padding: "15px 0",
      },
    },
    icon_show: {
      color: "#8c8c8c",
      fontSize: 20,
      cursor: "pointer",
    },
    root_modal: {
      width: "100%",
      height: "100%",
      "& >div:first-child": {
        backgroundColor: "#fff",
        zIndex: -1,
      },
    },
    modal__container: {
      width: "100%",
      borderRadius: 12,
      backgroundColor: "#fff",
      zIndex: 9,
      display: "flex",
      flexDirection: "column",
      margin: "30px 0",
      height: "70%",
      marginTop: "63%",
    },
    modal__header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0 24px",
      minHeight: 65,
      borderBottom: "1px solid rgb(235, 235, 235)",
      "& >h6": {
        fontWeight: 700,
        fontSize: 18,
        marginRight: 50,
      },
    },
    content: {
      padding: 15,
      overflowY: "scroll",
    },
    showMore: {
      backgroundColor: "#FFFFFF",
      boxShadow: "0px 0px 4px rgba(168, 168, 168, 0.4)",
      marginTop: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: 8,
      cursor: "pointer",
      "& >h6": {
        color: "#4F4F4F",
        fontSize: 15,
      },
    },
  }));
  const classes = useStyles();
  const detailLesson = useAppSelector(selectDetailLesson);
  const [listComment, setListComment] = useState<ListResponseComments<Comment> | undefined>();
  const [openModal, setOpenModal] = useState(false);
  const [filter, setFilter] = useState({
    page: 0,
    size: 5,
  });
  useEffect(() => {
    handleFetchListComment();
    setFilter({page: 0, size: 5});
  }, [detailLesson]);
  const handleClose = () => {
    setOpenModal(false);
  };
  const handleFetchListComment = useCallback(async () => {
    try {
      const res: ListResponseComments<Comment> = await managerLessonApi.getComments({
        page: 0,
        size: 5,
        lessionId: Number(detailLesson?.lessionUsers?.lessionId),
      });

      setListComment(res);
    } catch (error) {}
  }, [detailLesson]);
  const handleShowMore = async () => {
    setFilter({...filter, page: filter.page + 1});
    try {
      const res: ListResponseComments<Comment> = await managerLessonApi.getComments({
        ...filter,
        lessionId: Number(detailLesson?.lessionUsers?.lessionId),
        page: filter.page + 1,
      });
      const dataListCommentNext = res.comments.content;
      const currentListComment = {...res};
      const newListComment = listComment?.comments?.content?.concat(dataListCommentNext);
      currentListComment.comments.content = newListComment;
      setListComment(currentListComment);
    } catch (error) {}
  };
  return (
    <Fragment>
      <Box className={classes.container} onClick={() => setOpenModal(true)}>
        <Typography variant="subtitle2">
          Bình luận : {detailLesson?.lessionUsers?.lession?.noComments}
        </Typography>
        <UnfoldMoreIcon className={classes.icon_show} />
      </Box>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        closeAfterTransition
        className={classes.root_modal}
      >
        <Slide direction="up" in={openModal}>
          <Box className={classes.modal__container}>
            <Box className={classes.modal__header}>
              <Typography variant="subtitle2">Bình luận</Typography>
              <div></div>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box className={classes.content}>
              <CreateComment handleFetchListComment={handleFetchListComment} />
              <Box>
                {listComment?.comments?.content?.map((comment: Comment, index: number) => (
                  <Fragment key={index}>
                    <ListComment
                      userComment={comment}
                      handleFetchListComment={handleFetchListComment}
                    />
                  </Fragment>
                ))}
                {listComment?.comments?.number + 1 !== listComment?.comments?.totalPages &&
                listComment?.comments?.content &&
                listComment?.comments?.content?.length > 0 ? (
                  <Box className={classes.showMore} onClick={handleShowMore}>
                    <ArrowDropDownIcon />
                    <Typography variant="subtitle2">Hiển thị thêm nhiều bình luận</Typography>
                  </Box>
                ) : null}
              </Box>
            </Box>
          </Box>
        </Slide>
      </Modal>
    </Fragment>
  );
}
