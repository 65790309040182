import managerCommnunityApi from "api/managerCommunity";
import { ListResponseCommunityCategory,CommnunityCategoryList } from "models/managerCommunity";
import {call, put, takeLatest} from "redux-saga/effects";
import{
    fetchCommunityCategoryList,
    fetchCommunityCategoryListSuccess,
    fetchCommunityCategoryListFailed,
} from "./comunitySlice";
function* fetchDataCommunityCategoryList() {
    try {
      const res: ListResponseCommunityCategory<CommnunityCategoryList> = yield call(managerCommnunityApi.getListCategory);
      if (res.responseCode === "00") {
        yield put(fetchCommunityCategoryListSuccess(res));
      } else {
        yield put(fetchCommunityCategoryListFailed());
      }
    } catch (error) {}
  }
  
  export default function* managerCommunityCategorySaga() { 
    yield takeLatest(fetchCommunityCategoryList, fetchDataCommunityCategoryList);
  }
  
