import { InfoUser } from "models/auth";
import { ListParams, NewListParams, ResponseStatus } from "models/common";
import { AccessLogList, ActivityHistory, CreateAdmin, ListResponseAccessLog, ListResponseUser, ResponseActivityHistory, ResponseCreateAdmin } from "models/managerUser";
import axiosClient from "./axiosClient";

const managerUserApi = {
  getUsers(params: ListParams): Promise<ListResponseUser<InfoUser>> {
    const url = `/users`;
    return axiosClient.get(url, { params });
  },
  deleteUsers(id: number | undefined): Promise<ResponseStatus> {
    const url = `/user/${id}`;
    return axiosClient.delete(url);
  },
  detailUser(id: string | undefined): Promise<InfoUser> {
    const url = `/user/${id}`;
    return axiosClient.get(url);
  },
  approvedUser(params: any): Promise<ResponseStatus> {
    const url = `reviewUserList`;
    return axiosClient.post(url, params);
  },
  createAdmin(data: CreateAdmin): Promise<ResponseCreateAdmin> {
    const url = "/user/create";
    return axiosClient.post(url, data);
  },
  getAccessLog(params: ListParams): Promise<ListResponseAccessLog<AccessLogList>> {
    const url = `/getAccessLog?page_index=${params.page}&page_size=${params.size}`;
    return axiosClient.get(url);
  },
  getActivityHistory(params: NewListParams): Promise<ResponseActivityHistory<ActivityHistory>> {
    const url = '/getActivityHistory';
    return axiosClient.get(url, { params })
  },
  resetPassword(data: any): Promise<ResponseStatus> {
    const url = "/admin/reset-password";
    return axiosClient.post(url, data);
  },
};

export default managerUserApi;
