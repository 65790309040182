import {
  Box,
  Button,
  Container,
  createStyles,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Slide,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {Pagination} from "@material-ui/lab";
import {withStyles} from "@material-ui/styles";
import {useAppDispatch, useAppSelector} from "app/hooks";
import BACKGROUND from "assets/img/bg_manager_student.png";
import NO_AVATAR from "assets/img/no_avatar.png";
import SortByAlphaIcon from "@material-ui/icons/SortByAlpha";
import SortIcon from "@material-ui/icons/Sort";
import {
  fetchDataOptions,
  fetchListProvince,
  fetchListWorkTypes,
  selectListArea,
  selectListDepartment,
  selectListProvince,
  selectListWorkTypes,
} from "features/Auth/options/optionsSlice";
import {useNavigate} from "react-router-dom";

import {ListParams, ResponseStatus} from "models/common";
import React, {useEffect, useState} from "react";
import InputSearch from "../../../../components/InputSearch";
import {
  fetchUserList,
  selectFilterUserList,
  selectUserList,
  setFilterSearchDebounce,
  setFilterUserList,
} from "../ManagerAdmin/managerAdminSlice";
import useStyles from "./style";
import {ListOptions, ListResponseOptions} from "models/managerGroup";
import CloseIcon from "@material-ui/icons/Close";
import managerGroupApi from "api/managerGroupApi";
import managerUserApi from "api/managerUser";
import {toast} from "react-toastify";
import {useConfirm} from "material-ui-confirm";
import {InfoUser} from "models/auth";
import {useTranslation} from "react-i18next";
import EmptyComponent from "components/Empty";
import {useForm} from "react-hook-form";
import InputField from "components/FormFields/InputField";
import { BASE_URL, TOKEN } from "constants/config";
import axios, { AxiosResponse } from "axios";

export default function ListStudent() {
  const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
      head: {
        padding: "16px 5px",
        // backgroundColor: "#F3F3F3CC",
        color: "#1B1B1B",
        fontSize: 11,
        fontWeight: 700,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        borderBottom: "1px solid #777777",
        [theme.breakpoints?.up("xl")]: {
          fontSize: 13,
        },
      },
      body: {
        padding: "16px 5px",
        fontSize: 11,
        borderRight: "1px solid rgba(224, 224, 224, 0.8)",
        [theme.breakpoints?.up("xl")]: {
          fontSize: 13,
        },
      },
    })
  )(TableCell);
  const classes = useStyles({
    background: BACKGROUND,
  });
  const {t} = useTranslation();
  const filter = useAppSelector(selectFilterUserList);
  const [openModal, setOpenModal] = useState(false);
  const [staffIdUser, setStaffIdUser] = useState("");
  const handleClose = () => {
    setOpenModal(false);
  };

  const userList = useAppSelector(selectUserList);
  const [sortType, setSortType] = useState(2);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const {control, handleSubmit} = useForm();
  useEffect(() => {
    dispatch(
      fetchUserList({
        ...filter,
        role: "STUDENT",
      })
    );
  }, [dispatch, filter]);

  const handleChangePage = (page: number) => {
    dispatch(
      setFilterUserList({
        ...filter,
        page: page - 1,
      })
    );
  };

  const handleSearchChange = (newFilter: ListParams) => {
    dispatch(setFilterSearchDebounce(newFilter));
  };

  const handleDeleteUser = async (user: InfoUser) => {
    confirm({
      title: (
        <Typography variant="h5" color="secondary">
          {t("delete.chuy")}
        </Typography>
      ),
      description: (
        <Typography variant="subtitle2">
          {t("delete.khiban")}{" "}
          <Typography variant="subtitle2" component="span" color="secondary">
            {t("delete.dongy")}
          </Typography>{" "}
          {t("delete.xoa")}
          <Typography variant="subtitle2" component="span" style={{color: "#004673"}}>
            {" "}
            {user.name}
          </Typography>{" "}
          {t("delete.sexoatatcadulieu")}
        </Typography>
      ),
      confirmationText: <Button className={classes.btn_delete}>{t("delete.dongy")}</Button>,
      cancellationText: (
        <Button className={classes.btn_edit} style={{margin: 0}}>
          {t("delete.huybo")}
        </Button>
      ),
    })
      .then(async () => {
        const res: ResponseStatus = await managerUserApi.deleteUsers(user?.id);
        if (res.responseCode === "00") {
          toast.success(`${t("managerStudent.xoahocvienthanhcong")}`);
          dispatch(fetchUserList(filter));
        } else {
          toast.error(`${t("managerStudent.xoahocvienthatbai")}`);
          throw new Error("Delete user is failed");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSortType = (type: any) => {
    setSortType(type);
    dispatch(
      setFilterUserList({
        ...filter,
        sortType: type,
      })
    );
  };

  const handleResetPassword = (id: any) => {
    setOpenModal(true);
    setStaffIdUser(id);
  };

  const handleSubmitForm = async (value: any) => {
    try {
      const res = await managerUserApi.resetPassword({
        username: staffIdUser,
        newPassword: value.newPassword,
      });
      if (res.responseCode === "00") {
        toast.success("Cập nhật mật khẩu thành công");
        setOpenModal(false);
      } else {
        toast.error(`Cập nhật mật khẩu thất bại`);
      }
    } catch (error) {}
  };

  const handleUpload = async (event: any) => {
    const token = localStorage.getItem(TOKEN);
    const fileExcel = event?.target?.files[0];
    const formData = new FormData();
    formData.append("attachments", fileExcel);
    try {
      const res: AxiosResponse = await axios({
        method: "POST",
        url: `${BASE_URL}/user/importUserList`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });
      if (res.data.responseCode === "00") {
        toast.success(`${t("managerTest.nhapdulieuthanhcong")}`);
        dispatch(fetchUserList(filter));
      } else {
        toast.error(`${t("managerTest.nhapdulieuthatbai")}`);
      }
    } catch (error) {}
  };

  return (
    <Box>
      <Box className={classes.background}>
        <Box className={classes.header}>
          <Box className={classes.header_title}>
            <Typography variant="subtitle2"> {t("managerStudent.danhsachhocvien")}</Typography>
            <Box>
              {sortType === 2 ? (
                <Tooltip title="Sort by name">
                  <SortByAlphaIcon onClick={() => handleSortType(1)} />
                </Tooltip>
              ) : (
                <Tooltip title="Sort by date">
                  <SortIcon onClick={() => handleSortType(2)} />
                </Tooltip>
              )}
            </Box>
          </Box>
          <label htmlFor="upload_file" className={classes.btn_upload}>
            {t("button.uploadfile")}
          </label>
          <input
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8ent.spreadsheetml.sheet"
            id="upload_file"
            multiple
            type="file"
            name="file"
            onChange={handleUpload}
            style={{display: "none"}}
          />

        </Box>
      </Box>

      <Box margin="20px 0">
        <InputSearch
          filter={filter}
          onSearchChange={handleSearchChange}
          label={t("managerStudent.timkiemhocvientheoten")}
        />
      </Box>

      {/* <Box display="flex" justifyContent="space-between" mb={3}>
        <FormControl variant="outlined" style={{width: "20%"}}>
          <InputLabel id="demo-simple-select-filled-label"> {t("auth.area")}</InputLabel>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={valueArea}
            onChange={handleFilterArea}
            label={t("auth.area")}
            className={classes.selectFilter}
          >
            {listArea.map((area) => (
              <MenuItem value={area.id} onClick={() => handleClickListArea(area.id)} key={area.id}>
                {area.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant="outlined" style={{width: "20%"}}>
          <InputLabel id="demo-simple-select-filled-label">{t("admin.tinh/thanhpho")}</InputLabel>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            label={t("admin.tinh/thanhpho")}
            value={valueProvince}
            style={{backgroundColor: disabledProvince ? "#f8f4f4" : "#fff"}}
            onChange={handleFilterProvince}
            className={classes.selectFilter}
            disabled={listProvince.length === 0 || disabledProvince}
          >
            {listProvince.map((province) => (
              <MenuItem key={province.id} value={province.id}>
                {province.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" style={{width: "20%"}}>
          <InputLabel id="demo-simple-select-filled-label">{t("admin.bophan")}</InputLabel>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            label={t("admin.bophan")}
            value={valueDepartment}
            onChange={handleFilterDepartment}
            className={classes.selectFilter}
          >
            {listDepartment.map((department) => (
              <MenuItem
                key={department.id}
                value={department.id}
                onClick={() =>
                  department.title === "Sản Xuất"
                    ? handleGetListWorkType(areaId, department.title)
                    : handleDisabledWorkType()
                }
              >
                {department.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant="outlined" style={{width: "20%"}}>
          <InputLabel id="demo-simple-select-filled-label">{t("admin.phongban")}</InputLabel>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={valueWorkType}
            label={t("admin.phongban")}
            onChange={handleFilterWorkType}
            className={classes.selectFilter}
            style={{backgroundColor: disableWorkType ? "#f8f4f4" : "#fff"}}
            disabled={listWorkType.length === 0 ? true : disableWorkType}
          >
            {listWorkType.map((workType) => (
              <MenuItem
                value={workType?.workTypeId}
                // onClick={() => handleGetListProvince(workType)}
                key={workType.id}
              >
                {workType?.workType?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          onClick={() => {
            setValueWorkType("");
            setValueArea("");
            setValueProvince("");
            setValueDepartment("");
            setDisabledProvince(true);
            setDisableWorkType(true);
          }}
          size="small"
          className={classes.btn_delete_fitler}
          style={{padding: "10px 15px"}}
        >
          {t("managerStudent.xoaboloc")}
        </Button>
      </Box> */}
      {userList.content.length > 0 ? (
        <Box>
          <TableContainer component={Paper} className={classes.container}>
            <Table
              stickyHeader
              // style={{minWidth: 1400}}
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center" width="70px">
                    {t("stt")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width="250px">
                    {t("admin.tenhocvien")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width="130px">
                    {t("admin.manhanvien")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width="150px">
                    {t("admin.class")}
                  </StyledTableCell>
                  {/* <StyledTableCell align="center" width="130px">
                    {t("auth.area")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width="130px">
                    {t("auth.department")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width="140px">
                    {t("auth.workType")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width="230px">
                    {t("admin.tinh/thanhpho")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width="150px">
                    {t("auth.farm")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width="130px">
                    {t("auth.position")}
                  </StyledTableCell> */}
                  <StyledTableCell align="center" width="350px">
                    <Typography variant="subtitle1">{t("thaotac")}</Typography>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userList.content.map((user, index) => (
                  <TableRow key={user.id}>
                    <StyledTableCell align="center" component="th" scope="user">
                      {userList.size * userList.number + index + 1}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                        margin="0 auto"
                      >
                        <Box width="30%">
                          <img
                            src={user.profileImageUrl || NO_AVATAR}
                            alt="avatar"
                            className={classes.avatar}
                          />
                        </Box>
                        <Typography variant="subtitle2" className={classes.name}>
                          {user.name}
                        </Typography>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell align="center" component="th" scope="user">
                      {user.staffId}
                    </StyledTableCell>
                    <StyledTableCell align="center" component="th" scope="user">
                      {user?.class?.name}
                    </StyledTableCell>
                    {/* <StyledTableCell
                      align="center"
                      style={{
                        color:
                          user?.area?.name === "Miền Bắc"
                            ? "#137BBD"
                            : user?.area?.name === "Miền Nam"
                            ? "#DC2138"
                            : "#E58A25",
                      }}
                    >
                      {user?.area?.name}
                    </StyledTableCell>
                    <StyledTableCell align="center">{user?.department?.title}</StyledTableCell>
                    <StyledTableCell align="center">{user?.workType?.name}</StyledTableCell>
                    <StyledTableCell align="center">{user?.newProvince?.name}</StyledTableCell>
                    <StyledTableCell align="center">{user?.farm?.name}</StyledTableCell>
                    <StyledTableCell align="center">{user?.position}</StyledTableCell> */}
                    <StyledTableCell align="center">
                      <Button className={classes.btn_delete} onClick={() => handleDeleteUser(user)}>
                        {t("button.delete")}
                      </Button>
                      <Button
                        className={classes.btn_edit}
                        onClick={() =>
                          navigate(`/admin/manager-student/edit/${user?.id}`, {
                            state: {
                              infoUser: user,
                            },
                          })
                        }
                      >
                        {t("button.edit")}
                      </Button>
                      <Button
                        className={classes.btn_detail}
                        onClick={() => navigate(`/admin/manager-student/detail/${user?.id}`)}
                      >
                        {t("button.detail")}
                      </Button>
                      <Button
                        className={classes.btn_edit}
                        onClick={() => handleResetPassword(user.staffId)}
                      >
                        Đặt lại mật khẩu
                      </Button>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {userList.totalPages > 1 && (
            <Box mt={3} display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle2" style={{fontWeight: 600}}>
                {t("managerStudent.tonghocvien")}: {userList.totalElements}
              </Typography>
              <Pagination
                count={Math.ceil(userList.totalElements / userList.size)}
                page={userList.number + 1}
                variant="outlined"
                color="primary"
                shape="rounded"
                onChange={(e, page) => handleChangePage(page)}
              />
            </Box>
          )}
        </Box>
      ) : (
        <EmptyComponent />
      )}

      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        closeAfterTransition
        className={classes.root_modal}
      >
        <Slide direction="up" in={openModal}>
          <Box className={classes.modal__container}>
            <Box className={classes.modal__header}>
              <div></div>
              <Typography variant="subtitle2">{"Đặt mật khẩu mới"}</Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <form onSubmit={handleSubmit(handleSubmitForm)} style={{padding: "10px 20px"}}>
              <InputField
                name="newPassword"
                label={"Nhập mật khẩu mới"}
                control={control}
                borderRadius="10px"
              />

              <Box textAlign="center">
                <Button type="submit" className={classes.form__button}>
                  {t("button.update")}
                </Button>
              </Box>
            </form>
          </Box>
        </Slide>
      </Modal>
    </Box>
  );
}
