import { ListParams, NewListParams, ResponseStatus } from "models/common";
import { DetailUserSkip } from "models/managerLesson";
import {
  DetailTestByUser,
  ExamNoQuestion,
  ListExam,
  ListExamNoQuestionResponse,
  ListExamResponse,
  ListQuestionByExamIdResponse,
  ListResponseExam,
  ListTest,
  ListTestByCodeExamResponse,
  QuestionByExamId,
  ResponseListSkipExam,
  ResponseSubmissionExam,
} from "models/managerTest";
import axiosClient from "./axiosClient";

const managerTestApi = {
  createExam(value: any): Promise<ResponseStatus> {
    const url = "/create-exam";
    return axiosClient.post(url, value);
  },

  updateExam(value: any, examId: string | undefined): Promise<ResponseStatus> {
    const url = `/exam/${examId}`;
    return axiosClient.put(url, value);
  },

  getUsersByExamID(examId: string) {
    const url = `/exam/${examId}/users`;
    return axiosClient.get(url);
  },

  addUserToExam(value: any) {
    const url = "/exam-users";
    return axiosClient.post(url, value);
  },


  deleteUserFromExam(value: any) {
    const url = "/exam-users";
    return axiosClient.delete(url, {
      data: value,
    });
  },

  submitTest(value: any): Promise<ResponseSubmissionExam> {
    const url = "/exam-submit";
    return axiosClient.post(url, value);
  },

  getListExam(params: ListParams): Promise<ListExamResponse<ListExam>> {
    const url = "/exams";
    return axiosClient.get(url, { params });
  },
  getListExamNoQuestions(params: ListParams): Promise<ListExamNoQuestionResponse<ExamNoQuestion>> {
    const url = "exams-no-questions";
    return axiosClient.get(url, { params });
  },

  getListTestOld(params: ListParams): Promise<ListTestByCodeExamResponse<ListTest>> {
    const url = `/submissions`;
    return axiosClient.get(url, { params });
  },
  getListTest(
    id: number | undefined,
    params: ListParams
  ): Promise<ListTestByCodeExamResponse<ListTest>> {
    const url = `/submissions/${id}`;
    return axiosClient.get(url, { params });
  },

  getDetailExam(id: number, isShuffle?: boolean): Promise<ListExam> {
    const url = `/exam/${id}?isShuffleQNA=${isShuffle || false}`;
    return axiosClient.get(url);
  },

  getDetailExamHasAnswers(id: number): Promise<ListExam> {
    const url = `/questions/${id}`;
    return axiosClient.get(url);
  },

  getDetailTestByUser(id: string | undefined): Promise<DetailTestByUser> {
    const url = `submission-details/${id}`;
    return axiosClient.get(url);
  },

  getQuestionByMutipleExamId(
    examId: string | undefined
  ): Promise<ListQuestionByExamIdResponse<QuestionByExamId>> {
    const url = `questions?examIds=${examId}`;
    return axiosClient.get(url);
  },

  getListSkipExam(params: NewListParams): Promise<ResponseListSkipExam<DetailUserSkip>> {
    const url = "/statistic/statisticDropExam";
    return axiosClient.get(url, { params });
  },
  deleteExam(exam_id: number): Promise<ListResponseExam<ListExam>> {
    const url = `/deleteExam`;
    return axiosClient.post(url, { exam_id });
  },

  updateExamScore(data: any): Promise<ResponseStatus> {
    const url = "/updateExamScore";
    return axiosClient.post(url, data);
  },
};

export default managerTestApi;
