import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, {useEffect, useRef, useState} from "react";
import Slider from "react-slick";
import useStyles from "./style";
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {ListSlideMonth, SlideMonth} from "models/managerLesson";
import managerLessonApi from "api/managerLesson";
import BACKGROUND_1234 from "assets/img/slide_month_1234.png";
import BACKGROUND_5678 from "assets/img/slide_month_5678.png";
import BACKGROUND_9101112 from "assets/img/slide_month_9101112.png";
import LOGO from "assets/img/logo.png";
import {useNavigate} from "react-router-dom";
import CarouselMonthMobile from "./components/CarouselMonthMobile";
import {useTranslation} from "react-i18next";

export default function CarouselMonth() {
  const classes = useStyles();
  function SampleNextArrow(props: any) {
    const {className, style, onClick} = props;
    return (
      <ArrowForwardIosIcon
        className={className}
        style={{...style, color: "#E95869", display: "block", fontSize: 35}}
        onClick={onClick}
      />
    );
  }
  function SamplePrevArrow(props: any) {
    const {className, style, onClick} = props;
    return (
      <ArrowBackIosOutlinedIcon
        className={className}
        style={{...style, color: "#E95869", display: "block", fontSize: 35}}
        onClick={onClick}
      />
    );
  }

  const settings = {
    infinite: true,
    slidesToShow: 4,
    dots: false,
    slidesToScroll: 4,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };

  const currentMonth = new Date().getMonth() + 1;
  const {t} = useTranslation();
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [listSlideMonth, setListSlideMonth] = useState<SlideMonth[]>([]);
  const navigate = useNavigate();
  const sliderRef = useRef<any>();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints?.down("sm"));
  const handleChangeYear = (event: React.ChangeEvent<{value: unknown}>) => {
    setYear(Number(event.target.value));
  };

  useEffect(() => {
    (async () => {
      try {
        const res: ListSlideMonth<SlideMonth> = await managerLessonApi.getSlideMonth(year);
        setListSlideMonth(res.lessionOverview);
      } catch (error) {}
    })();
  }, [year]);

  useEffect(() => {
    sliderRef?.current?.slickGoTo(currentMonth < 5 ? 0 : currentMonth < 9 ? 4 : 8);
  }, []);
  return isTablet ? (
    <CarouselMonthMobile />
  ) : (
    <Box className={classes.container}>
      <Box mb={2}>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            native
            value={year}
            onChange={handleChangeYear}
            defaultValue={new Date().getFullYear()}
          >
            {Array.from(new Array(10)).map((id, index) => (
              <option value={index + 2020} key={id}>
                {index + 2020}
              </option>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Slider {...settings} ref={sliderRef}>
        {listSlideMonth?.map((item) => (
          <Box key={item.month}>
            <Card
              className={classes.card}
              style={{
                borderBottom:
                  item.month < 5
                    ? "10px solid #E95869"
                    : item.month < 9
                    ? "10px solid #FCAB51"
                    : "10px solid #2495DC",
              }}
            >
              <CardMedia
                className={classes.contentMedia}
                image={
                  item.month < 5
                    ? BACKGROUND_1234
                    : item.month < 9
                    ? BACKGROUND_5678
                    : BACKGROUND_9101112
                }
              >
                <Box className={classes.currentMonth}>
                  {item.month === currentMonth && <img src={LOGO} alt="LOGO" />}
                </Box>
                <Box className={classes.month}>
                  <Typography variant="subtitle2">
                    {item.month < 10 ? `0${item.month}` : item.month}
                  </Typography>
                </Box>
              </CardMedia>

              <CardContent>
                <Box className={classes.card__content}>
                  <Typography variant="subtitle2">
                    {t("home.carousel.sobaihocdahoanthanh")}
                  </Typography>
                  <Box className={classes.totalWatched}>
                    <Typography variant="subtitle2" component="span">
                      {" "}
                      {item.noWatchedLession}
                    </Typography>
                    <Typography variant="subtitle2" component="i">
                      / {item.noTotalLession}
                    </Typography>
                  </Box>
                </Box>

                <Box textAlign="center">
                  <Button
                    className={classes.btn_detail}
                    style={{
                      backgroundColor:
                        item.month < 5 ? " #E95869" : item.month < 9 ? " #FCAB51" : " #2495DC",
                    }}
                    onClick={() =>
                      navigate({
                        pathname: "/list-lesson",
                        search: `year=${year}&month=${
                          item.month < 10 ? `0${item.month}` : item.month
                        }`,
                      })
                    }
                  >
                    {t("home.xemthem")}
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Box>
        ))}
      </Slider>
    </Box>
  );
}
