import { PayloadAction } from "@reduxjs/toolkit";
import managerLessonApi from "api/managerLesson";
import { ListParams } from "models/common";
import { Lesson, ListResponseLesson } from "models/managerLesson";
import { call, debounce, put, takeLatest } from "redux-saga/effects";
import {
  fetchLessonList,
  fetchLessonListFailed,
  fetchLessonListSuccess,
  setFilterLessonListDraft,
  setFilterLessonListFuture,
  setFilterLessonListPosted,
  setFilterLessonListHidden,
  setFilterSearchDebounceLessonListDraft,
  setFilterSearchDebounceLessonListFuture,
  setFilterSearchDebounceLessonListHidden,
  setFilterSearchDebounceLessonListPosted,
} from "./managerLessonSlice";

function* fetchDataLessonList(action: PayloadAction<ListParams>) {
  try {
    const res: ListResponseLesson<Lesson> = yield call(managerLessonApi.getLessons, action.payload);
    if (res.responseCode === "00") {
      yield put(fetchLessonListSuccess(res));
    } else {
      yield put(fetchLessonListFailed);
      throw new Error("Fetch lesson list is failed");
    }
  } catch (error) { }
}

function* handleSearchDebounceListPosted(action: PayloadAction<ListParams>) {
  yield put(setFilterLessonListPosted(action.payload));
}

function* handleSearchDebounceListFuture(action: PayloadAction<ListParams>) {
  yield put(setFilterLessonListFuture(action.payload));
}
function* handleSearchDebounceListHidden(action: PayloadAction<ListParams>) {
  yield put(setFilterLessonListHidden(action.payload));
}
function* handleSearchDebounceListDraft(action: PayloadAction<ListParams>) {
  yield put(setFilterLessonListDraft(action.payload));
}

export default function* managerLessonSaga() {
  yield takeLatest(fetchLessonList, fetchDataLessonList);
  yield debounce(500, setFilterSearchDebounceLessonListHidden, handleSearchDebounceListHidden);
  yield debounce(500, setFilterSearchDebounceLessonListPosted, handleSearchDebounceListPosted);
  yield debounce(500, setFilterSearchDebounceLessonListFuture, handleSearchDebounceListFuture);
  yield debounce(500, setFilterSearchDebounceLessonListDraft, handleSearchDebounceListDraft);
}
