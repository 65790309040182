import {Box, Container, makeStyles, Theme, Typography} from "@material-ui/core";
import {useAppDispatch} from "app/hooks";
import BACKGROUND from "assets/img/bg_manager_category.png";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import AddClass from "./components/AddClass";
import TableClass from "./components/TableClass";
import {fetchClassList, selectFilterClassList} from "./managerClassSlice";
import {useSelector} from "react-redux";

export interface ManagerClassProps {}

export default function ManagerClass(props: ManagerClassProps) {
  const useStyles = makeStyles((theme: Theme) => ({
    background: {
      backgroundImage: `url(${BACKGROUND})`,
      backgroundSize: "cover",
      backgroundPositionX: "right",
      backgroundRepeat: "no-repeat",
      height: 150,
      width: "100%",
      position: "relative",
      "& >h6": {
        position: "absolute",
        top: 50,
        left: 0,
        color: "#004673",
        fontSize: 20,
        fontWeight: 700,
      },
    },
  }));
  const {t} = useTranslation();
  const classes = useStyles();

  return (
    <Box>
      {/* Add new */}
      <AddClass />

      <Box className={classes.background}>
        <Typography variant="subtitle2">{t("managerClass.danhsachclass")}</Typography>
      </Box>

      {/* Table */}
      <TableClass />
    </Box>
  );
}
