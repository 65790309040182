import {Box, Button} from "@material-ui/core";
import managerClassApi from "api/managerClass";
import {useAppDispatch} from "app/hooks";
import InputField from "components/FormFields/InputField";
import {ResponseStatus} from "models/common";
import {IClass} from "models/managerClass";
import React, {useMemo} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {fetchClassList, selectFilterClassList} from "../../managerClassSlice";
import useStyles from "./style";
import {useSelector} from "react-redux";

interface EditClassProps {
  valueClass: IClass;
  setOpenModal: (openModal: any) => void;
}

export default function EditClass({valueClass, setOpenModal}: EditClassProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const filterClassList = useSelector(selectFilterClassList);

  const initialValues = {
    name: valueClass.name,
    description: valueClass.description,
  };

  const {control, handleSubmit} = useForm({
    defaultValues: useMemo(() => initialValues, [initialValues]),
  });

  const handleSubmitForm = async (value: any) => {
    try {
      const res: ResponseStatus = await managerClassApi.updateClass(value, valueClass?.id);
      if (res.responseCode === "00") {
        toast.success("Cập nhật lớp học thành công");
        dispatch(fetchClassList(filterClassList));
        setOpenModal(false);
      } else {
        toast.error(`${t("managerClass.capnhatthatbai")}`);
        setOpenModal(false);
        throw new Error(`${t("managerClass.capnhatthatbai")}`);
      }
    } catch (error) {}
  };
  return (
    <Box className={classes.content}>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <InputField name="name" control={control} label="Tên lớp học" />
        <InputField
          multiline
          rows={3}
          name="description"
          control={control}
          label="Thông tin lớp học"
        />

        <Box textAlign="center">
          <Button className={classes.btn_submit} type="submit">
            {t("button.update")}
          </Button>
        </Box>
      </form>
    </Box>
  );
}
