import {
  Box,
  Button,
  createStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import {Pagination} from "@material-ui/lab";
import {makeStyles, withStyles} from "@material-ui/styles";
import managerLessonApi from "api/managerLesson";
import {useAppDispatch, useAppSelector} from "app/hooks";
import BACKGROUND from "assets/img/bg_manager_lesson.png";
import LOADING from "assets/img/loading.gif";
import {useConfirm} from "material-ui-confirm";
import {ResponseStatus} from "models/common";
import {Lesson} from "models/managerLesson";
import React from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {
  fetchLessonList,
  selectFilterLessonListDraft,
  selectLessonList,
  setFilterLessonListDraft,
} from "../../managerLessonSlice";

export interface ListLessonDraftProps {}

export default function ListLessonDraft(props: ListLessonDraftProps) {
  const useStyles = makeStyles((theme: Theme) => ({
    background: {
      backgroundImage: `url(${BACKGROUND})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      height: 100,
      width: "100%",
      [theme.breakpoints?.up("xl")]: {
        width: "90%",
        height: 150,
      },
    },

    thumbnail_video: {
      width: 40,
      height: 40,
      objectFit: "cover",
    },

    percentHide: {
      width: "100%",
      position: "relative",
      backgroundColor: "#EFEFEF",
      height: 8,
      borderRadius: 8,
      marginTop: 5,
    },
    percent: {
      height: 8,
      borderRadius: 8,
      position: "absolute",
      top: 0,
      left: 0,
    },
    percentView: {
      backgroundColor: "#F1A34D",
    },
    percentLike: {
      backgroundColor: "  #0499C8",
    },
    percentComment: {
      backgroundColor: "  #04C8BC",
    },
    btn_delete: {
      background: "linear-gradient(0deg, #FD5468 0%, rgba(255, 193, 200, 0.9) 169.32%)",
      borderRadius: 10,
      fontSize: 12,
      color: "#fff",
      padding: "4px 10px",
    },
    btn_changeCalendar: {
      background: "linear-gradient(0deg, #10BCA7 0%, rgba(255, 239, 156, 0.9) 165.52%)",
      borderRadius: 10,
      fontSize: 12,
      color: "#fff",
      padding: "4px 10px",
      marginRight: 10,
    },

    btn_edit: {
      background: "linear-gradient(360deg, #019ABB 0%, rgba(167, 255, 255, 0.9) 168.18%)",
      borderRadius: 10,
      fontSize: 12,
      color: "#fff",
      padding: "4px 10px",
      marginRight: 10,
    },
    root_modal: {
      width: "100%",
      height: "70%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    modal__container: {
      width: "100%",
      maxWidth: 560,
      borderRadius: 12,
      backgroundColor: "#fff",
      zIndex: 9,
      display: "flex",
      flexDirection: "column",
    },
    modal__header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0 24px",
      minHeight: 65,
      borderBottom: "1px solid rgb(235, 235, 235)",
      "& >h6": {
        fontWeight: 700,
        fontSize: 18,
        marginLeft: 50,
      },
    },
    container: {
      maxHeight: 500,
    },
  }));
  const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
      head: {
        padding: "16px 5px",
        // backgroundColor: "#F3F3F3CC",
        color: "#1B1B1B",
        fontSize: 11,
        fontWeight: 700,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        borderBottom: "1px solid #777777",
        [theme.breakpoints?.up("xl")]: {
          fontSize: 13,
        },
      },
      body: {
        padding: "16px 5px",
        fontSize: 11,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        [theme.breakpoints?.up("xl")]: {
          fontSize: 13,
        },
      },
    })
  )(TableCell);
  const {t} = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const listLesson = useAppSelector(selectLessonList);
  const filter = useAppSelector(selectFilterLessonListDraft);
  const filterListDraft = useAppSelector(selectFilterLessonListDraft);

  const handleChangePage = (page: number) => {
    dispatch(
      setFilterLessonListDraft({
        ...filter,
        page: page - 1,
      })
    );
  };
  const handleDeleteLesson = async (lesson: Lesson) => {
    confirm({
      title: (
        <Typography variant="h5" color="secondary">
          {t("delete.chuy")}
        </Typography>
      ),
      description: (
        <Typography variant="subtitle2">
          {t("delete.khiban")}{" "}
          <Typography variant="subtitle2" component="span" color="secondary">
            {t("delete.dongy")}
          </Typography>{" "}
          {t("delete.xoa")}
          <Typography variant="subtitle2" component="span" style={{color: "#004673"}}>
            {" "}
            {lesson.lessionName}
          </Typography>{" "}
          {t("delete.sexoatatcadulieu")}
        </Typography>
      ),
      confirmationText: <Button className={classes.btn_delete}> {t("delete.dongy")}</Button>,
      cancellationText: (
        <Button className={classes.btn_edit} style={{marginRight: 0}}>
          {t("delete.huybo")}
        </Button>
      ),
    })
      .then(async () => {
        const res: ResponseStatus = await managerLessonApi.deleteLesson(lesson.id);
        if (res.responseCode === "00") {
          toast.success(`${t("managerLesson.xoabaihocthanhcong")}`);
          dispatch(fetchLessonList({...filter, type: "DRAFT"}));
        } else {
          toast.error(`${t("managerLesson.xoabaihocthatbai")}`);
          throw new Error(res.responseDescription);
        }
      })
      .catch((error) => console.log(error));
  };

  const handleChangeDraft = (id: number) => {
    const formData = new FormData();
    formData.append("isDraft", "N");
    formData.append("lessionId", String(id));
    confirm({
      title: (
        <Typography variant="h5" color="secondary">
          {t("delete.chuy")}
        </Typography>
      ),
      description: <Typography variant="subtitle2">{t("delete.khibamdongy")}</Typography>,
      confirmationText: <Button className={classes.btn_delete}> {t("delete.dongy")}</Button>,
      cancellationText: <Button className={classes.btn_edit}> {t("delete.huybo")}</Button>,
    })
      .then(async () => {
        const res: ResponseStatus = await managerLessonApi.updateLesson(formData);
        if (res.responseCode === "00") {
          toast.success("Lên lịch thành công");
          dispatch(fetchLessonList({...filterListDraft, type: "DRAFT"}));
        }
      })
      .catch((error) => console.log(error));
  };
  return (
    <>
      <TableContainer component={Paper} className={classes.container}>
        <Table stickyHeader style={{minWidth: "1600px"}}>
          <TableHead>
            <TableRow>
              <StyledTableCell align="center" width="100px">
                {t("managerLesson.video")}
              </StyledTableCell>
              <StyledTableCell align="center" width="200px">
                {t("managerLesson.tenbaihoc")}
              </StyledTableCell>
              <StyledTableCell align="center" width="120px">
                {t("managerLesson.ngaycapnhat")}
              </StyledTableCell>
              <StyledTableCell align="center" width="120px">
                {t("managerLesson.ngayan")}
              </StyledTableCell>
              <StyledTableCell align="center" width="120px">
                {t("managerLesson.gioan")}
              </StyledTableCell>
              <StyledTableCell align="center" width="150px">
                {t("managerLesson.nguoilenlich")}
              </StyledTableCell>
              {/* <StyledTableCell align="center" width="120px">
                {t("managerLesson.khuvuc")}
              </StyledTableCell>
              <StyledTableCell align="center" width="120px">
                {t("managerLesson.bophan")}
              </StyledTableCell>
              <StyledTableCell align="center" width="120px">
                {t("managerLesson.phongban")}
              </StyledTableCell> */}
              <StyledTableCell align="center" width="350px">
                {t("thaotac")}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listLesson?.content.map((lesson) => (
              <TableRow key={lesson.id}>
                <StyledTableCell align="center">
                  {lesson.attachment ? (
                    <img
                      src={lesson.attachment?.urlThumbnail}
                      alt="thumbnal_video"
                      className={classes.thumbnail_video}
                    />
                  ) : lesson.powerPointFileList ? (
                    <img
                      src={lesson.powerPointFileList[0].powerPointFile.urlThumbnail}
                      alt="thumbnal_video"
                      className={classes.thumbnail_video}
                    />
                  ) : (
                    <img src={LOADING} alt="thumbnal_video" className={classes.thumbnail_video} />
                  )}
                </StyledTableCell>
                <StyledTableCell align="center">{lesson.lessionName}</StyledTableCell>
                <StyledTableCell align="center">
                  {String(lesson?.publishDate).slice(-2)}/{String(lesson?.publishDate).slice(4, 6)}/
                  {String(lesson?.publishDate).slice(0, 4)}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {String(lesson?.expiryDateTimeAsString).slice(8)}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {String(lesson?.expiryDateTimeAsString).slice(0, 5)}
                </StyledTableCell>
                <StyledTableCell align="center">{lesson?.user?.name}</StyledTableCell>
                {/* <StyledTableCell align="center">{lesson?.area?.name}</StyledTableCell>
                <StyledTableCell align="center">{lesson?.department?.title}</StyledTableCell>
                <StyledTableCell align="center">{lesson?.workType?.name}</StyledTableCell> */}
                <StyledTableCell align="center">
                  <Button
                    onClick={() =>
                      navigate(`/admin/manager-lesson/edit/draft/${lesson.id}`, {
                        state: {
                          detailLesson: lesson,
                        },
                      })
                    }
                    className={classes.btn_edit}
                    size="small"
                  >
                    {t("button.edit")}
                  </Button>
                  <Button
                    className={classes.btn_changeCalendar}
                    size="small"
                    onClick={() => handleChangeDraft(lesson.id)}
                  >
                    {t("managerLesson.lenlich")}
                  </Button>
                  <Button
                    onClick={() => handleDeleteLesson(lesson)}
                    className={classes.btn_delete}
                    size="small"
                  >
                    {t("managerLesson.xoabai")}
                  </Button>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {listLesson?.totalPages > 1 && (
        <Box mt={3} display="flex" justifyContent="space-between">
          <Typography variant="subtitle2" style={{fontWeight: 600}}>
            {t("managerLesson.tongbaihoc")}: {listLesson.totalElements}
          </Typography>
          <Pagination
            count={Math.ceil(listLesson?.totalElements / listLesson?.size)}
            page={listLesson?.number + 1}
            variant="outlined"
            color="primary"
            shape="rounded"
            onChange={(e, page) => handleChangePage(page)}
          />
        </Box>
      )}
    </>
  );
}
