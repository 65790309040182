import {
  Box,
  Button,
  createStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {Pagination} from "@material-ui/lab";
import {makeStyles, withStyles} from "@material-ui/styles";
import {useAppDispatch, useAppSelector} from "app/hooks";
import BACKGROUND from "assets/img/bg_exam_upcoming.png";
import {selectInfoLogin} from "features/Auth/authSlice";
import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {dateTimeToFormatYYYY} from "utils/dateTimeFormat";
import {
  fetchListTestOldStudent,
  SelectFilterListExamStudent,
  SelectListTestOldStudent,
  selectLoadingListExamStudent,
  setFilterListExam,
} from "../../listExamStudentSlice";
import {useTranslation} from "react-i18next";
import LOADING from "assets/img/loading.gif";
import EmptyComponent from "components/Empty";
import moment from "moment";

export default function ListExamHistory() {
  const useStyles = makeStyles((theme: Theme) => ({
    background: {
      backgroundImage: `url(${BACKGROUND})`,
      backgroundSize: "cover",
      backgroundPositionX: "right",
      backgroundRepeat: "no-repeat",
      height: 150,
      width: "100%",
      position: "relative",
      [theme.breakpoints?.down("sm")]: {
        height: 100,
      },
      [theme.breakpoints?.down("xs")]: {
        height: 50,
      },
      "& >h6": {
        position: "absolute",
        bottom: 30,
        left: 0,
        color: "#004673",
        fontSize: 20,
        fontWeight: 700,
        [theme.breakpoints?.down("sm")]: {
          fontSize: 14,
          bottom: 10,
        },
        [theme.breakpoints?.down("xs")]: {
          fontSize: 12,
          bottom: 10,
        },
      },
    },
    loading: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      "& >img": {
        width: 100,
        height: 100,
      },
    },
    btn_detail: {
      background: "linear-gradient(0deg, #10BCA7 0%, rgba(255, 239, 156, 0.9) 165.52%)",
      borderRadius: 10,
      color: "#fff",
      padding: "5px 15px",
      [theme.breakpoints?.down("sm")]: {
        fontSize: 10,
      },
      [theme.breakpoints?.down("xs")]: {
        padding: 0,
        fontSize: 8,
        minWidth: 45,
        borderRadius: 2,
      },
    },
  }));

  const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
      head: {
        padding: "10px 2px",
        background: "linear-gradient(360deg, #137BBD 0%, rgba(68, 177, 238, 0.8) 120.47%)",
        color: "#FFFFFF",
        fontSize: 7,
        fontWeight: 700,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        [theme.breakpoints?.up("sm")]: {
          fontSize: 13,
          padding: "16px 5px",
        },
        [theme.breakpoints?.up("xl")]: {
          fontSize: 15,
        },
      },
      body: {
        padding: "10px 3px",
        fontSize: 7,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        fontWeight: 500,
        [theme.breakpoints?.up("sm")]: {
          fontSize: 13,
          padding: "16px 5px",
        },
        [theme.breakpoints?.up("xl")]: {
          fontSize: 15,
        },
      },
    })
  )(TableCell);

  const {t} = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const filter = useAppSelector(SelectFilterListExamStudent);
  const listTest = useAppSelector(SelectListTestOldStudent);
  const loading = useAppSelector(selectLoadingListExamStudent);
  const navigate = useNavigate();
  const infoLogin = useAppSelector(selectInfoLogin);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints?.down("xs"));

  useEffect(() => {
    dispatch(fetchListTestOldStudent({...filter, studentId: infoLogin?.id}));
  }, [dispatch, filter, infoLogin]);

  const handleChangePage = (page: number) => {
    dispatch(
      setFilterListExam({
        ...filter,
        page: page - 1,
      })
    );
  };
  return loading ? (
    <Box className={classes.loading}>
      <img src={LOADING} alt="LOADING" />
    </Box>
  ) : (
    <Box px={isMobile ? 1 : 3} mt={2}>
      <Box className={classes.background}>
        <Typography variant="subtitle2">{t("listexam.danhsachbaithicu")}</Typography>
      </Box>
      <Box>
        {listTest?.submissionDetails?.content?.length > 0 ? (
          <TableContainer component={Paper} style={{overflowX: "initial"}}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center" width={isMobile ? "50px" : "70px"}>
                    {t("listexam.stt")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width={isMobile ? "100px" : "150px"}>
                    {t("listexam.ngaythi")}
                  </StyledTableCell>

                  <StyledTableCell align="center" width={isMobile ? "300px" : "300px"}>
                    {t("listexam.tieudebaithi")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width={isMobile ? "150px" : "250px"}>
                    {t("listexam.phanthuong")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width={isMobile ? "70px" : "100px"}>
                    {t("listexam.diem")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width={isMobile ? "150px" : "120px"}>
                    {t("listexam.xeploai")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width={isMobile ? "100px" : "200px"}>
                    {t("listexam.thaotac")}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listTest?.submissionDetails?.content?.map((test: any, index) => {
                  return (
                    <TableRow key={test.id}>
                      <StyledTableCell align="center" component="th" scope="test">
                        {listTest?.submissionDetails?.size * listTest?.submissionDetails?.number +
                          index +
                          1}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        {dateTimeToFormatYYYY(test?.startTime)}
                      </StyledTableCell>

                      <StyledTableCell align="center">{test?.exam?.examTitle}</StyledTableCell>
                      <StyledTableCell align="center">{test?.exam?.prize?.name}</StyledTableCell>
                      <StyledTableCell align="center">{test.point.toFixed(2)}</StyledTableCell>
                      <StyledTableCell align="center">
                        {(test.point / test.maxPoint) * 100 < 50
                          ? `${t("point.yeu")}`
                          : (test.point / test.maxPoint) * 100 < 65
                          ? `${t("point.trungbinh")}`
                          : (test.point / test.maxPoint) * 100 < 80
                          ? `${t("point.kha")}`
                          : (test.point / test.maxPoint) * 100 < 100
                          ? `${t("point.gioi")}`
                          : (test.point / test.maxPoint) * 100 === 100
                          ? `${t("point.thukhoa")}`
                          : ""}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        {moment.utc(test.exam.publishAnswerDate).unix() -
                          moment.utc(new Date()).unix() <
                        0 ? (
                          <Button
                            className={classes.btn_detail}
                            onClick={() =>
                              navigate(`/test/detail/${test?.id}`, {
                                state: {
                                  examId: test?.examId,
                                  duration: test?.duration,
                                },
                              })
                            }
                          >
                            {t("button.detail")}
                          </Button>
                        ) : (
                          <div>-</div>
                        )}
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <EmptyComponent isBorder={false} description={t("listexam.chuacobaithicu")} />
        )}
        {listTest?.submissionDetails?.totalPages > 0 && (
          <Box mt={isMobile ? 2 : 3} display="flex" justifyContent="flex-end" mb={3}>
            <Pagination
              count={Math.ceil(
                listTest?.submissionDetails?.totalElements / listTest?.submissionDetails?.size
              )}
              page={listTest?.submissionDetails?.number + 1}
              variant="outlined"
              color="primary"
              shape="rounded"
              onChange={(e, page) => handleChangePage(page)}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
  // );
}
