import {makeStyles, Theme} from "@material-ui/core";

type Props = {
  comment: string;
};
const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  loading: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    "& >img": {
      width: 100,
      height: 100,
    },
  },
  container: {
    position: "relative",
  },
  btn_goBack: {
    position: "absolute",
    top: -15,
    left: -20,
  },
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  icon: {
    width: 20,
    height: 20,
    objectFit: "cover",
    marginRight: 8,
    cursor: "pointer",
  },
  icon_checked: {
    color: "#137bbd",
  },
  number_status: {
    fontWeight: 500,
    fontSize: 13,
    paddingTop: 5,
  },
  lesson__category: {
    fontWeight: 700,
    fontSize: 22,
    marginTop: 15,
    color: "#CE6C00",
  },
  lesson__title: {
    fontWeight: 700,
    fontSize: 20,
    marginTop: 10,
  },
  lesson__date: {
    color: "#4F4F4F",
    fontSize: 15,
  },
  lesson__description: {
    paddingTop: 5,
    fontSize: 15,
    fontWeight: 700,
    "&>span": {
      fontSize: 13,
    },
  },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: "50%",
    objectFit: "cover",
    marginRight: 15,
  },
  avatar__reply: {
    width: 30,
    height: 30,
  },
  username: {
    fontWeight: 600,
    fontSize: 15,
    "&>span": {
      fontSize: 13,
      marginLeft: 10,
      color: "#A8A8A8",
    },
  },
  reply: {
    cursor: "pointer",
    color: "#4F4F4F",
    fontSize: 13,
  },
  btn__comment: {
    background: (props) =>
      props.comment.length > 0
        ? "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)"
        : "transparent",
    color: "#fff",
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },

  player_wrapper: {
    position: "relative",
    paddingTop: " 56.25% ",
  },
  react_player: {
    position: "absolute",
    top: 0,
    left: 0,
  },

  showMore: {
    backgroundColor: "#FFFFFF",
    width: "100%",
    boxShadow: "0px 0px 4px rgba(168, 168, 168, 0.4)",
    marginTop: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 8,
    cursor: "pointer",
    "& >h6": {
      color: "#4F4F4F",
      fontSize: 15,
    },
  },
  icon_emoji: {
    position: "absolute",
    bottom: 7,
    right: 10,
    "& >span >img": {
      width: 25,
      height: 25,
    },
  },
  icon_play: {
    padding: 5,
    backgroundColor: "#fff",
    width: 50,
    height: 50,
    borderRadius: "50%",
    color: "#f9ab00",
    transition: "ease-in 0.5s",
    border: "8px solid #999",
    "&:hover": {
      backgroundColor: "#f9ab00",
      color: "#fff",
    },
  },
  bg_lesson: {
    padding: 15,
    paddingRight: 0,
    "& >img": {
      width: "100%",
      height: "100%",
    },
  },
  btn_delete: {
    borderRadius: 10,
    background: "linear-gradient(0deg, #FD5468 0%, rgba(255, 193, 200, 0.9) 169.32%)",
    color: "#fff",
    padding: "5px 10px",
    marginBottom: 5,
  },
}));

export default useStyles;
