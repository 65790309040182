import DateFnsUtils from "@date-io/date-fns";
import Box from "@material-ui/core/Box";
import {makeStyles, Theme, useTheme} from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import {useAppDispatch, useAppSelector} from "app/hooks";
import BACKGROUND from "assets/img/bg_manager_lesson1.png";
import InputSearch from "components/InputSearch";
import {ListParams} from "models/common";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {locale} from "utils/config";
import ListLessonDraft from "./components/ListDraft";
import ListLessonFuture from "./components/ListFuture";
import ListLessonPosted from "./components/ListPosted";
import ListVideoHidden from "./components/ListVideoHidden";
import {
  clearFilterLessonListDraft,
  clearFilterLessonListFuture,
  clearFilterLessonListPosted,
  clearFilterLessonListHidden,
  fetchLessonList,
  selectFilterLessonListDraft,
  selectFilterLessonListHidden,
  selectFilterLessonListFuture,
  selectFilterLessonListPosted,
  selectLessonList,
  setFilterSearchDebounceLessonListDraft,
  setFilterSearchDebounceLessonListHidden,
  setFilterSearchDebounceLessonListFuture,
  setFilterSearchDebounceLessonListPosted,
} from "./managerLessonSlice";
import EmptyComponent from "components/Empty";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  background: {
    backgroundImage: `url(${BACKGROUND})`,
    backgroundSize: "cover",
    backgroundPositionX: "right",
    backgroundRepeat: "no-repeat",
    height: 150,
    width: "100%",
    position: "relative",
    "& >h6": {
      position: "absolute",
      bottom: 30,
      left: 0,
      color: "#004673",
      fontSize: 20,
      fontWeight: 700,
    },
  },
  datePicker: {
    marginLeft: 15,
    "& .MuiOutlinedInput-root": {
      borderRadius: 12,
    },
  },
  btn_listTest: {
    background: "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)",
    color: "#fff",
    borderRadius: 20,
    "&:hover": {
      backgroundColor: "#E38825",
    },
  },
  tabs: {
    width: 800,
    padding: "10px 24px",
    backgroundColor: "#FFFFFF",
    paddingBottom: 15,
    "& .MuiTab-textColorPrimary": {
      color: "#000000",
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: "#3f51b5",
      backgroundColor: "#E1F3FF",
    },
    "& .PrivateTabIndicator-colorPrimary-46": {
      backgroundColor: "initial",
    },
  },
  tabLabel: {
    fontWeight: 600,
    fontSize: 16,
  },
  backgroundContent: {
    backgroundColor: "#FFFFFF",
    padding: 0,
  },
}));

export default function ManagerListLesson() {
  const classes = useStyles();
  const params = useParams();
  const page = params.page ? params.page : "listPosted";
  const theme = useTheme();
  const tabNameToIndex: any = {
    0: "listPosted",
    1: "listFuture",
    2: "listDraft",
    3: "listVideoHidden",
  };
  const indexToTabName: any = {
    listPosted: 0,
    listFuture: 1,
    listDraft: 2,
    listVideoHidden: 3,
  };
  const [selectedTab, setSelectedTab] = useState(indexToTabName[page]);
  const {t} = useTranslation();
  const [valueSelect, setValueSelect] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [selectedFromDateListPosted, setSelectedFromDateListPosted] = useState<Date | null>(null);
  const [selectedToDateListPosted, setSelectedToDateListPosted] = useState<Date | null>(null);

  const [selectedFromDateListFuture, setSelectedFromDateListFuture] = useState<Date | null>(null);
  const [selectedToDateListFuture, setSelectedToDateListFuture] = useState<Date | null>(null);
  const [selectedFromDateListHidden, setSelectedFromDateListHidden] = useState<Date | null>(null);
  const [selectedToDateListHidden, setSelectedToDateListHidden] = useState<Date | null>(null);
  const handleFromDateChangeListPosted = (date: Date | null) => {
    setSelectedFromDateListPosted(date);
    dispatch(
      setFilterSearchDebounceLessonListPosted({
        ...filterListPosted,
        searchFromDate: moment(date).format("YYYY/MM/DD").split("/").join(""),
      })
    );
  };
  const handleToDateChangeListPosted = (date: Date | null) => {
    setSelectedToDateListPosted(date);
    dispatch(
      setFilterSearchDebounceLessonListPosted({
        ...filterListPosted,
        searchToDate: moment(date).format("YYYY/MM/DD").split("/").join(""),
      })
    );
  };
  const handleFromDateChangeListFuture = (date: Date | null) => {
    setSelectedFromDateListFuture(date);

    dispatch(
      setFilterSearchDebounceLessonListFuture({
        ...filterListFuture,
        searchFromDate: moment(date).format("YYYY/MM/DD").split("/").join(""),
      })
    );
  };

  const handleToDateChangeListFuture = (date: Date | null) => {
    setSelectedToDateListFuture(date);
    dispatch(
      setFilterSearchDebounceLessonListFuture({
        ...filterListFuture,
        searchToDate: moment(date).format("YYYY/MM/DD").split("/").join(""),
      })
    );
  };
  const handleFromDateChangeListHidden = (date: Date | null) => {
    setSelectedFromDateListHidden(date);

    dispatch(
      setFilterSearchDebounceLessonListHidden({
        ...filterListHidden,
        searchFromDate: moment(date).format("YYYY/MM/DD").split("/").join(""),
      })
    );
  };

  const handleToDateChangeListHidden = (date: Date | null) => {
    setSelectedToDateListHidden(date);
    dispatch(
      setFilterSearchDebounceLessonListHidden({
        ...filterListHidden,
        searchToDate: moment(date).format("YYYY/MM/DD").split("/").join(""),
      })
    );
  };

  const filterListPosted = useAppSelector(selectFilterLessonListPosted);
  const filterListFuture = useAppSelector(selectFilterLessonListFuture);
  const filterListDraft = useAppSelector(selectFilterLessonListDraft);
  const filterListHidden = useAppSelector(selectFilterLessonListHidden);
  const listLesson = useAppSelector(selectLessonList);
  useEffect(() => {
    if (selectedTab === 0) {
      dispatch(fetchLessonList(filterListPosted));
      // clear filter
      dispatch(clearFilterLessonListFuture());
      dispatch(clearFilterLessonListDraft());
      dispatch(clearFilterLessonListHidden());
    } else if (selectedTab === 1) {
      dispatch(fetchLessonList(filterListFuture));

      // clear filter
      dispatch(clearFilterLessonListPosted());
      dispatch(clearFilterLessonListDraft());
      dispatch(clearFilterLessonListHidden());
    } else if (selectedTab === 2) {
      dispatch(fetchLessonList(filterListDraft));

      // clear filter
      dispatch(clearFilterLessonListFuture());
      dispatch(clearFilterLessonListHidden());
      dispatch(clearFilterLessonListPosted());
    } else {
      dispatch(fetchLessonList(filterListHidden));
      //clear filter
      dispatch(clearFilterLessonListFuture());
      dispatch(clearFilterLessonListDraft());
      dispatch(clearFilterLessonListPosted());
    }
  }, [
    dispatch,
    filterListPosted,
    filterListFuture,
    filterListDraft,
    filterListHidden,
    selectedTab,
  ]);
  useEffect(() => {
    setSelectedTab(indexToTabName[page]);
  }, [params]);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    navigate(`/admin/manager-lesson/${tabNameToIndex[newValue]}`);
    setSelectedTab(newValue);
  };
  const handleSearchListPosted = (newFilter: ListParams) => {
    const {name, ...rest} = newFilter;
    dispatch(setFilterSearchDebounceLessonListPosted({...rest, searchName: name}));
  };
  const handleSearchListHidden = (newFilter: ListParams) => {
    const {name, ...rest} = newFilter;
    dispatch(setFilterSearchDebounceLessonListHidden({...rest, searchName: name}));
  };
  const handleSearchListFuture = (newFilter: ListParams) => {
    const {name, ...rest} = newFilter;
    dispatch(setFilterSearchDebounceLessonListFuture({...rest, searchName: name}));
  };
  const handleSearchListDraft = (newFilter: ListParams) => {
    const {name, ...rest} = newFilter;
    dispatch(setFilterSearchDebounceLessonListDraft({...rest, searchName: name}));
  };

  const handleChangeSelect = (event: React.ChangeEvent<{value: any}>) => {
    setValueSelect(event.target.value);
  };
  return (
    <Box>
      <Box className={classes.background}>
        <Typography variant="subtitle2">
          {t("managerLesson.danhsachbaihoc").toUpperCase()}
        </Typography>
      </Box>
      <Box className={classes.backgroundContent}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
          className={classes.tabs}
        >
          <Tab
            label={
              <Typography variant="subtitle2" className={classes.tabLabel}>
                {t("managerLesson.dadang")}
              </Typography>
            }
          />
          <Tab
            label={
              <Typography variant="subtitle2" className={classes.tabLabel}>
                {t("managerLesson.lenlich")}
              </Typography>
            }
          />
          <Tab
            label={
              <Typography variant="subtitle2" className={classes.tabLabel}>
                {t("managerLesson.bannhap")}
              </Typography>
            }
          />
          <Tab
            label={
              <Typography variant="subtitle2" className={classes.tabLabel}>
                {t("managerLesson.videodaan")}
              </Typography>
            }
          />
        </Tabs>

        <div
          style={{
            backgroundColor: "#CDCDCD",
            height: 1,
            width: "95%",
            marginTop: 10,
            margin: "0 auto",
          }}
        ></div>
      </Box>

      <Box>
        {selectedTab === 0 && (
          <Box className={classes.backgroundContent}>
            <TabPanel value={selectedTab} index={0} dir={theme.direction}>
              <Box className={classes.root} mb={2}>
                <Box width="50%">
                  <InputSearch
                    label={t("managerLesson.timtheotenbaihoc")}
                    filter={filterListPosted}
                    onSearchChange={handleSearchListPosted}
                  />
                </Box>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                  <KeyboardDateTimePicker
                    className={classes.datePicker}
                    strictCompareDates={true}
                    views={["year", "month", "date"]}
                    autoOk
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    id="from-date"
                    label={t("managerLesson.tungay")}
                    value={selectedFromDateListPosted}
                    onChange={handleFromDateChangeListPosted}
                    inputVariant="outlined"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                  <KeyboardDatePicker
                    views={["year", "month", "date"]}
                    autoOk
                    strictCompareDates={true}
                    className={classes.datePicker}
                    disableToolbar
                    variant="inline"
                    id="to-date"
                    label={t("managerLesson.denngay")}
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    value={selectedToDateListPosted}
                    onChange={handleToDateChangeListPosted}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Box>
              <ListLessonPosted />
            </TabPanel>
          </Box>
        )}
        {selectedTab === 1 && (
          <Box className={classes.backgroundContent}>
            <TabPanel value={selectedTab} index={1} dir={theme.direction}>
              <Box className={classes.root} mb={2}>
                <Box width="50%">
                  <InputSearch
                    label={t("managerLesson.timtheotenbaihoc")}
                    filter={filterListFuture}
                    onSearchChange={handleSearchListFuture}
                  />
                </Box>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disablePast
                    views={["year", "month", "date"]}
                    autoOk
                    className={classes.datePicker}
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    id="from-date"
                    label={t("managerLesson.tungay")}
                    value={selectedFromDateListFuture}
                    onChange={handleFromDateChangeListFuture}
                    inputVariant="outlined"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                  <KeyboardDatePicker
                    disablePast
                    views={["year", "month", "date"]}
                    autoOk
                    className={classes.datePicker}
                    disableToolbar
                    variant="inline"
                    id="to-date"
                    label={t("managerLesson.denngay")}
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    value={selectedToDateListFuture}
                    onChange={handleToDateChangeListFuture}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Box>
              {listLesson?.content?.length > 0 ? (
                <ListLessonFuture />
              ) : (
                <Box p={2}>
                  <EmptyComponent isBorder={false} />
                </Box>
              )}
            </TabPanel>
          </Box>
        )}
        {selectedTab === 2 && (
          <Box className={classes.backgroundContent}>
            <TabPanel value={selectedTab} index={2} dir={theme.direction}>
              <Box className={classes.root} mb={2}>
                <Box mr={3} width="100%">
                  <InputSearch
                    label={t("managerLesson.timtheotenbaihoc")}
                    filter={filterListFuture}
                    onSearchChange={handleSearchListDraft}
                  />
                </Box>
                {/* <Box mb={2}>
                  <FormControl variant="outlined">
                    <InputLabel id="demo-simple-select-outlined-label">Người lên lịch</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={valueSelect}
                      onChange={handleChangeSelect}
                      label="Người lên lịch"
                      style={{width: "400px", margin: 0}}
                    >
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                  </FormControl>
                </Box> */}
              </Box>
              {listLesson?.content?.length > 0 ? (
                <ListLessonDraft />
              ) : (
                <Box p={2}>
                  <EmptyComponent isBorder={false} />
                </Box>
              )}
            </TabPanel>
          </Box>
        )}
        {selectedTab === 3 && (
          <Box className={classes.backgroundContent}>
            <TabPanel value={selectedTab} index={3} dir={theme.direction}>
              <Box className={classes.root} mb={2}>
                <Box width="50%">
                  <InputSearch
                    label={t("managerLesson.timtheotenbaihoc")}
                    filter={filterListHidden}
                    onSearchChange={handleSearchListHidden}
                  />
                </Box>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                  <KeyboardDateTimePicker
                    className={classes.datePicker}
                    strictCompareDates={true}
                    views={["year", "month", "date"]}
                    autoOk
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    id="from-date"
                    label={t("managerLesson.tungay")}
                    value={selectedFromDateListHidden}
                    onChange={handleFromDateChangeListHidden}
                    inputVariant="outlined"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                  <KeyboardDatePicker
                    views={["year", "month", "date"]}
                    autoOk
                    strictCompareDates={true}
                    className={classes.datePicker}
                    disableToolbar
                    variant="inline"
                    id="to-date"
                    label={t("managerLesson.denngay")}
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    value={selectedToDateListHidden}
                    onChange={handleToDateChangeListHidden}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Box>
              <ListVideoHidden></ListVideoHidden>
            </TabPanel>
          </Box>
        )}
      </Box>
    </Box>
  );
}
