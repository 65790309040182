import {makeStyles, Theme} from "@material-ui/core";
const useStyles = makeStyles((theme: Theme) => ({
  background: {
    backgroundColor: "#F0F0F0",
    marginTop: 2,
    paddingBottom: 30,
  },
  content: {
    backgroundColor: "#ffffff",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    marginTop: 30,
    borderRadius: 8,
  },
  avatar: {
    position: "relative",
    textAlign: "center",
    borderBottom: "1px solid rgba(153, 153, 153, 0.5)",
    padding: "15px 0",
    "& >label >img": {
      width: 100,
      height: 100,
      color: "#9C9C9C",
      cursor: "pointer",
      borderRadius: "50%",
      objectFit: "cover",
    },
  },
  upload__avatar: {
    position: "absolute",

    top: "75%",
    left: "54%",
    transform: "translate(-50%,-60%)",
    width: 35,
    height: 35,
    backgroundColor: "#e4e6eb",
    borderRadius: "50%",
  },
  upload__avatar__camera: {
    color: "#1d1f23",
    width: 25,
    height: 25,
    cursor: "pointer",
    alignSelf: "center",
    paddingTop: 5,
  },
  content__info: {
    width: "70%",
    margin: "0 auto",
    padding: "30px 0",
    [theme.breakpoints?.down("xs")]: {
      width: "90%",
      padding: "15px 0",
    },
  },
  content__info__title: {
    color: "#E38825",
    textAlign: "center",
    padding: "20px 0",
    fontWeight: 600,
  },
  form: {
    "& .MuiGrid-item": {
      padding: "10px 0px",
      alignSelf: "center",
      [theme.breakpoints?.down("xs")]: {
        padding: "5px 0",
      },
    },
  },

  form__input: {
    backgroundColor: "#F3F3F3",
    textAlign: "center",
    color: "#E38825",
    // marginTop: 8,
    padding: 16,
    // marginBottom: 4,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    fontSize: 14,
    [theme.breakpoints?.down("md")]: {
      fontSize: 10,
      padding: 19,
    },
    [theme.breakpoints?.down("xs")]: {
      fontSize: 8,
      padding: 12,
    },
  },
  btn_submit: {
    backgroundColor: "#157BBC",
    boxShadow: " 0px 0px 4px rgba(121, 121, 121, 0.8)",
    borderRadius: 32,
    color: "#fff",
    padding: "10px 15px",
    [theme.breakpoints?.down("sm")]: {
      borderRadius: 16,
    },
  },
  staffId: {
    padding: 14,
    borderRadius: 4,
    backgroundColor: "#f8f4f4",
    border: "1px solid #cbcbcb",
    // marginTop: 8,
    // marginBottom: 4,
    [theme.breakpoints?.down("md")]: {
      fontSize: 10,
      padding: 19,
    },
    [theme.breakpoints?.down("xs")]: {
      fontSize: 8,
      padding: 12,
    },
  },
  forgotPassword: {
    color: "#157BBC",
    cursor: "pointer",
  },

  btn_add: {
    background: "linear-gradient(360deg, #019ABB 0%, rgba(167, 255, 255, 0.9) 168.18%)",
    boxShadow: "0px 2px 4px rgba(168, 168, 168, 0.25), 0px 0px 4px rgba(168, 168, 168, 0.25)",
    borderRadius: 6,
    color: "#fff",
    fontSize: 12,
  },
  btn_delete: {
    background: "linear-gradient(0deg, #FD5468 0%, rgba(255, 193, 200, 0.9) 169.32%)",
    fontSize: 12,
    color: "#fff",
    borderRadius: 6,
    border: "1px solid #E38825",
    marginRight: 10,
  },
  loading: {
    zIndex: 99,
    backgroundColor: "rgba(255,255,255,0.8)",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    "& >div": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      "& .MuiCircularProgress-root": {
        display: "flex",
        margin: "0 auto",
      },
    },
  },
}));

export default useStyles;
