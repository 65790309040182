import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@material-ui/core";
import {Search} from "@material-ui/icons";
import {makeStyles} from "@material-ui/styles";
import {ListParams, NewListParams} from "models/common";
import React, {ChangeEvent} from "react";

export interface InputSearchProps {
  onSearchChange?: (newFilter: ListParams) => void;
  filter: ListParams;
  label: string;
}

export default function InputSearch({onSearchChange, filter, label}: InputSearchProps) {
  const useStyles = makeStyles(() => ({
    input: {
      "& .MuiOutlinedInput-root": {
        backgroundColor: "#FFFFFF",
        borderRadius: 12,
      },
    },
  }));

  const classes = useStyles();
  const searchRef = React.useRef<HTMLInputElement>();
  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!onSearchChange) return;
    const newFilter: ListParams = {
      ...filter,
      name: e.target.value,
      page: 0,
    };
    onSearchChange(newFilter);
  };
  return (
    <Box className={classes.input}>
      <FormControl fullWidth variant="outlined" size="small">
        <TextField
          autoComplete="off"
          id="searchByName"
          label={label}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <Search style={{marginLeft: 5}} />
              </InputAdornment>
            ),
          }}
          defaultValue={filter.search}
          onChange={handleSearchChange}
          inputRef={searchRef}
          type="search"
        />
      </FormControl>
    </Box>
  );
}
