import {makeStyles, Theme} from "@material-ui/core";

type Props = {
  background: string;
};
const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  background: {
    backgroundImage: (props: Props) => `url(${props.background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: "100vh",
    width: "100%",
    position: "relative",
  },
  content: {
    width: "50%",
    margin: "0 auto",
    height: "100vh",
    textAlign: "center",
    backgroundColor: "rgba(255,255,255,0.5)",
    boxShadow: "0px 5px 15px 0px #A8A8A8",
    [theme.breakpoints?.down("xs")]: {
      width: "100%",
    },
  },
  loading: {
    zIndex: 99,
    backgroundColor: "rgba(255,255,255,0.8)",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    "& >div": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      "& .MuiCircularProgress-root": {
        display: "flex",
        margin: "0 auto",
      },
    },
  },
  logo: {
    height: 100,
    marginTop: 30,
    objectFit: "cover",
  },
  content_title: {
    color: "#157BBC",
    fontSize: 22,
    paddingTop: 20,
    [theme.breakpoints?.down("xs")]: {
      fontSize: 19,
    },
  },
  form__input: {
    width: "60%",
    margin: "0 auto",
    padding: "20px 0",
    [theme.breakpoints?.down("xs")]: {
      padding: "10px 0",
    },
  },
  btn_cancel: {
    borderRadius: 8,
    padding: "10px 25px",
    backgroundColor: "#E5E6EB",
    "&:hover": {
      backgroundColor: "#F7F7F7",
    },
  },

  btn_submit: {
    marginLeft: 20,
    borderRadius: 8,
    backgroundColor: "#157BBC",
    "&:hover": {
      backgroundColor: "#02669E",
    },
  },
  notify_success: {
    color: "#157BBC",
    fontSize: 20,
    margin: "20px 0px",
    [theme.breakpoints?.down("xs")]: {
      fontSize: 16,
    },
  },
}));

export default useStyles;
