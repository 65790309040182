import { Action, combineReducers, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { connectRouter, routerMiddleware } from "connected-react-router";
import managerAdminReducer from "features/Admin/pages/ManagerAdmin/managerAdminSlice";
import managerCategoryReducer from "features/Admin/pages/ManagerCategory/managerCategorySlice";
import manaagerGroupReducer from "features/Admin/pages/ManagerGroup/managerGroupSlice";
import managerLessonReducer from "features/Admin/pages/ManagerLesson/managerLessonSlice";
import managerPrizeReducer from "features/Admin/pages/ManagerPrize/managerPrizeSlice";
import managerListExamReducer from "features/Admin/pages/ManagerTest/managerExamListSlice";
import managerCommunityCategoryReducer from "features/Admin/pages/Community/comunitySlice";
import managerCommunityCommentReducer from "features/Admin/pages/Community/communityCommentSlice";
import managerCommunityPostReducer from "features/Admin/pages/Community/communityPostSlice";
import authReducer from "features/Auth/authSlice";
import optionsReducer from "features/Auth/options/optionsSlice";
import detailLessonReducer from "features/Home/pages/DetailLesson/detailLessonSlice";
import listExamStudentNoQuestionReducer from "features/Home/pages/ListExamStudent/listExamStudentSlice";
import listLessonByuserReducer from "features/Home/pages/ListLesson/listLessonSlice";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./rootSaga";
import managerChartReducer from "features/Admin/pages/Charts/managerChartSlice";
import managerMagazineReducer from "features/Admin/pages/ManagerMagazine/managerMagazineSlice";
import managerClassReducer from "features/Admin/pages/ManagerClass/managerClassSlice";

const history = createBrowserHistory();

const rootReducer = combineReducers({
  router: connectRouter(history),
  authReducer,
  optionsReducer,
  managerAdminReducer,
  managerPrizeReducer,
  manaagerGroupReducer,
  managerListExamReducer,
  managerCategoryReducer,
  managerCommunityPostReducer,
  managerCommunityCategoryReducer,
  managerLessonReducer,
  listLessonByuserReducer,
  detailLessonReducer,
  listExamStudentNoQuestionReducer,
  managerCommunityCommentReducer,
  managerChartReducer,
  managerMagazineReducer,
  managerClassReducer
});

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware, routerMiddleware(history)),
});

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
