import managerChartApi from "api/managerChart";
import {ProvinceForStatistic, ReponseGetAllProvinceForStatistic} from "models/managerChart";
import {call, put, takeLatest} from "redux-saga/effects";
import {
  fetchProvincesForStatistic,
  fetchProvincesForStatisticFailed,
  fetchProvincesForStatisticSuccess,
} from "./managerChartSlice";

function* fetchDataProvincesForStatistic() {
  try {
    const res: ReponseGetAllProvinceForStatistic<ProvinceForStatistic> = yield call(
      managerChartApi.getAllProvinceForStatistic
    );
    if (res.responseCode === "00") {
      yield put(fetchProvincesForStatisticSuccess(res));
    } else {
      yield put(fetchProvincesForStatisticFailed());
    }
  } catch (error) {}
}

export default function* managerChartSaga() {
  yield takeLatest(fetchProvincesForStatistic, fetchDataProvincesForStatistic);
}
