import managerClassApi from "api/managerClass";
import { PayloadAction } from "@reduxjs/toolkit";
import { ListParams } from "models/common";
import { IClass, ListResponseClass } from "models/managerClass";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchClassList,
  fetchClassListFailed,
  fetchClassListSuccess,
  setPageInfo
} from "./managerClassSlice";

function* fetchDataClassList(action: PayloadAction<ListParams>) {
  try {
    const res: ListResponseClass<IClass> = yield call(managerClassApi.getClasses, action.payload);
    if (res.responseCode === "00") {
      yield put(fetchClassListSuccess(res));
      yield put(setPageInfo(res.pageInfo))
    } else {
      yield put(fetchClassListFailed());
    }
  } catch (error) { }
}

export default function* managerClassSaga() {
  yield takeLatest(fetchClassList, fetchDataClassList);
}
