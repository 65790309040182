import {makeStyles, Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "30px 24px",
    [theme.breakpoints?.down("lg")]: {
      paddingTop: 20,
      padding: "0 8px",
    },
    [theme.breakpoints?.down("md")]: {
      paddingTop: 10,
    },
  },
  loading: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    "& >img": {
      width: 100,
      height: 100,
    },
  },
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  filter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 40,
    borderBottom: "1px solid #858585",
  },
  filter__title: {
    padding: "10px 20px",
    border: "2px solid #FFC700",
    borderRadius: 15,
    width: 150,
    textAlign: "center",
    cursor: "pointer",
  },
  filter__title__viewed: {
    border: "2px solid #FF9D33",
  },
  filter__title__notsee: {
    border: "2px solid #FF5269",
  },
  filter__title__topic: {
    border: "2px solid #28A5F4",
    "&>div": {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      cursor: "pointer",
    },
    "& .MuiSvgIcon-root": {
      cursor: "pointer",
      color: "#7A7A7A",
      fontSize: 20,
    },
  },
  menu: {
    marginTop: 17,
    "& .MuiMenu-paper": {
      borderRadius: 10,
      boxShadow: "none",
      border: "1px solid #28A5F4",
    },
  },
  image: {
    width: "100%",
    height: 250,
    objectFit: "cover",
    [theme.breakpoints?.down("md")]: {
      height: 200,
    },
    [theme.breakpoints?.down("sm")]: {
      height: 150,
    },
  },

  wrapper: {
    boxShadow: "0px 2px 6px rgba(168, 168, 168, 0.4)",
    width: "100%",
    height: "100%",
    paddingBottom: 0,
  },
  content: {
    padding: "25px 10px 20px 50px",
    paddingBottom: 0,
    [theme.breakpoints?.down("md")]: {
      paddingLeft: 25,
      paddingTop: 18,
    },

    [theme.breakpoints?.down("sm")]: {
      paddingLeft: 10,
      paddingTop: 10,
    },
  },

  content__date: {
    fontSize: 14,
    color: "#4F4F4F",
    [theme.breakpoints?.down("md")]: {
      fontSize: 12,
    },
    [theme.breakpoints?.down("sm")]: {
      fontSize: 10,
    },
  },
  content__lesson__name: {
    fontSize: 23,
    fontWeight: 700,
    padding: "10px 0",
    cursor: "pointer",
    "&:hover": {
      color: "#28A5F4",
      transition: "color 0.3s linear",
    },
    [theme.breakpoints?.down("md")]: {
      fontSize: 15,
      maxHeight: "0.7rem",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      paddingTop: 5,
    },
    [theme.breakpoints?.down("xs")]: {
      fontSize: 11,
      maxWidth: 150,
    },
  },
  non__content__lesson__name: {
    fontSize: 23,
    fontWeight: 700,
    padding: "10px 0",
    cursor: "no-drop",
    [theme.breakpoints?.down("md")]: {
      fontSize: 15,
      maxHeight: "0.7rem",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      paddingTop: 5,
    },
    [theme.breakpoints?.down("xs")]: {
      fontSize: 11,
      maxWidth: 150,
    },
  },
  description: {
    maxHeight: "2.3rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    padding: "10px 0",

    [theme.breakpoints?.down("sm")]: {
      padding: "5px 0",
      fontSize: 9,
    },
    [theme.breakpoints?.down("md")]: {
      fontSize: 12,
    },
  },
  content__lesson__status__icon: {
    marginRight: 20,
    "& >img": {
      width: 20,
      height: 20,
      marginRight: 10,
      [theme.breakpoints?.down("md")]: {
        width: 15,
        height: 15,
      },
      [theme.breakpoints?.down("sm")]: {
        width: 10,
        height: 10,
        marginRight: 5,
      },
    },
    "& >h6": {
      fontSize: 14,
      [theme.breakpoints?.down("md")]: {
        fontSize: 12,
      },
      [theme.breakpoints?.down("sm")]: {
        fontSize: 8,
      },
    },
    [theme.breakpoints?.down("md")]: {
      marginRight: 0,
    },
  },
  content__category: {
    "& >h6": {
      fontWeight: 700,
      fontSize: 17,
      marginRight: 5,

      [theme.breakpoints?.down("md")]: {
        fontSize: 12,
      },
      [theme.breakpoints?.down("sm")]: {
        fontSize: 9,
      },
    },
    "& >img": {
      width: 30,
      height: 20,
      [theme.breakpoints?.down("md")]: {
        width: 25,
        height: 15,
      },
      [theme.breakpoints?.down("sm")]: {
        width: 20,
        height: 12,
      },
    },
  },
  statistic: {
    padding: 24,
    paddingBottom: 10,
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints?.down("sm")]: {
      padding: 10,
    },

    "&> div": {
      "& >h6": {
        fontSize: 13,
        [theme.breakpoints?.down("sm")]: {
          fontSize: 10,
        },
      },
      "& >img": {
        width: 30,
        height: 30,
        [theme.breakpoints?.down("md")]: {
          width: 20,
          height: 20,
        },
      },
    },
  },
  btn_goback: {
    background: "linear-gradient(0deg, #10BCA7 0%, rgba(255, 239, 156, 0.9) 165.52%)",
    marginTop: 10,
    color: "#FFF",
    padding: "5px 20px",
  },
}));

export default useStyles;
