import {Box, FormControl, InputAdornment, makeStyles, TextField, Theme} from "@material-ui/core";
import {NewListParams} from "models/common";
import React from "react";
import {useTranslation} from "react-i18next";
import {BsSearch} from "react-icons/bs";

export interface InputSearchProps {
  handleSearchChange: (e: any) => void;
  filter: NewListParams;
}

export default function InputSearch({filter, handleSearchChange}: InputSearchProps) {
  const useStyles = makeStyles((theme: Theme) => ({
    inputSearch: {
      "& .MuiOutlinedInput-root": {
        backgroundColor: "#FFFFFF",
        borderRadius: 12,
      },
    },
  }));
  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <Box className={classes.inputSearch}>
      <FormControl fullWidth variant="outlined" size="small">
        <TextField
          autoComplete="off"
          placeholder={t("managerMagazine.timkiem")}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <BsSearch style={{marginLeft: 5, fontSize: 23}} />
              </InputAdornment>
            ),
          }}
          defaultValue={filter.title}
          onChange={handleSearchChange}
          type="search"
        />
      </FormControl>
    </Box>
  );
}
