import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { IClass, ListResponseClass } from "models/managerClass";
import { ListParams } from "models/common";

export interface ManagerClassState {
  classList: any;
  loading: Boolean;
  filter: ListParams;
  pageInfo: any;
}

const initialState: ManagerClassState = {
  classList: [],
  loading: false,
  pageInfo: {},
  filter: {
    page: 0,
    size: 10,
  },
};

const managerClass = createSlice({
  initialState,
  name: "manager-class-slice",
  reducers: {
    fetchClassList(state, action: PayloadAction<ListParams>) {
      state.loading = true;
    },
    setPageInfo(state, action: PayloadAction<any>) {
      state.pageInfo = action.payload
    },
    fetchClassListSuccess(state, action: PayloadAction<ListResponseClass<IClass>>) {
      state.classList = action.payload.classList;
      state.loading = false;
    },
    fetchClassListFailed(state) {
      state.loading = false;
    },

    setFilterClass(state, action: PayloadAction<ListParams>) {
      state.filter = action.payload;
    },
  },
});

export const { setPageInfo, setFilterClass, fetchClassList, fetchClassListFailed, fetchClassListSuccess } =
  managerClass.actions;

export const selectClassList = (state: RootState) => state.managerClassReducer.classList;
export const selectFilterClassList = (state: RootState) => state.managerClassReducer.filter;
export const selectPageInfoClass = (state: RootState) => state.managerClassReducer.pageInfo;

const managerClassReducer = managerClass.reducer;
export default managerClassReducer;
