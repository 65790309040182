import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "app/store";
import {ListParams} from "models/common";
import {Lesson, ListResponseLesson} from "models/managerLesson";

export interface ManagerLessonState {
  lessonList: {
    content: Lesson[];
    [key: string]: any;
  };
  loading: Boolean;
  filterListPosted: ListParams;
  filterListFuture: ListParams;
  filterListDraft: ListParams;
  filterListHidden:ListParams;
}
const initialState: ManagerLessonState = {
  lessonList: {
    content: [],
  },
  loading: false,
  filterListPosted: {
    page: 0,
    size: 10,
    type: "POSTED",
    searchName: "",
  },
  filterListFuture: {
    page: 0,
    size: 10,
    type: "FUTURE",
    searchName: "",
  },
  filterListDraft: {
    page: 0,
    size: 10,
    type: "DRAFT",
    searchName: "",
  },
  filterListHidden: {
    page: 0,
    size: 10, 
    type: "HIDDEN",
    searchName: "",
  },
};

const managerLessonSlice = createSlice({
  initialState,
  name: "manager-lesson-slice",
  reducers: { 
    fetchLessonList(state, action: PayloadAction<ListParams>) {
      state.loading = true;
    },
    fetchLessonListSuccess(state, action: PayloadAction<ListResponseLesson<Lesson>>) {
      state.lessonList = action.payload.lessions;
      state.loading = false;
    },
    fetchLessonListFailed(state) {
      state.loading = false;
    },
    setFilterLessonListPosted(state, action: PayloadAction<ListParams>) {
      state.filterListPosted = action.payload;
    },
    clearFilterLessonListPosted(state) {
      state.filterListPosted.searchName = "";
      state.filterListPosted.page = 0;
    },

    setFilterLessonListFuture(state, action: PayloadAction<ListParams>) {
      state.filterListFuture = action.payload;
    },
    clearFilterLessonListFuture(state) {
      state.filterListFuture.searchName = "";
      state.filterListFuture.page = 0;
    },

    setFilterLessonListDraft(state, action: PayloadAction<ListParams>) {
      state.filterListDraft = action.payload;
    },

    clearFilterLessonListDraft(state) {
      state.filterListDraft.searchName = "";
      state.filterListDraft.page = 0;
    },
    setFilterLessonListHidden(state, action: PayloadAction<ListParams>) {
      state.filterListHidden = action.payload;
    },

    clearFilterLessonListHidden(state) {
      state.filterListHidden.searchName = "";
      state.filterListHidden.page = 0;
    },
    setFilterSearchDebounceLessonListPosted(state, action: PayloadAction<ListParams>) {},
    setFilterSearchDebounceLessonListFuture(state, action: PayloadAction<ListParams>) {},
    setFilterSearchDebounceLessonListDraft(state, action: PayloadAction<ListParams>) {},
    setFilterSearchDebounceLessonListHidden(state, action: PayloadAction<ListParams>) {},
  },
});

export const {
  fetchLessonList,
  fetchLessonListFailed,
  fetchLessonListSuccess,
  setFilterLessonListPosted,
  setFilterSearchDebounceLessonListPosted,
  setFilterSearchDebounceLessonListFuture,
  setFilterSearchDebounceLessonListDraft,
  setFilterSearchDebounceLessonListHidden,
  setFilterLessonListFuture,
  setFilterLessonListDraft,
  setFilterLessonListHidden,
  clearFilterLessonListPosted,
  clearFilterLessonListFuture,
  clearFilterLessonListDraft,
  clearFilterLessonListHidden,
} = managerLessonSlice.actions;

export const selectLessonList = (state: RootState) => state.managerLessonReducer.lessonList;
export const selectFilterLessonListPosted = (state: RootState) =>
  state.managerLessonReducer.filterListPosted;
export const selectFilterLessonListFuture = (state: RootState) =>
  state.managerLessonReducer.filterListFuture;
export const selectFilterLessonListDraft = (state: RootState) =>
  state.managerLessonReducer.filterListDraft;
export const selectFilterLessonListHidden =(state:RootState)=>
  state.managerLessonReducer.filterListHidden;
export const selectLoadingLessonList = (state: RootState) => state.managerLessonReducer.loading;

const managerLessonReducer = managerLessonSlice.reducer;
export default managerLessonReducer;
