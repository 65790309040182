import { makeStyles, Theme } from "@material-ui/core";

type Props = {
  background: string;
  bg_mobile: string;
};

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  background: {
    backgroundImage: (props: Props) => `url(${props.background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: "100vh",
    width: "100%",
    position: "relative",
  },
  content: {
    position: "absolute",
    right: 0,
    top: 0,
    height: "100%",
    width: "40%",
    backgroundColor: "rgba(255,255,255,0.9)",
    boxShadow: "2px 5px 15px 5px #b5a9a9",

    [theme.breakpoints?.down("md")]: {
      width: "60%",
    },
    [theme.breakpoints?.down("xs")]: {
      width: "100%",
    },
  },
  selectLanguage: {
    position: "absolute",
    top: 30,
    right: 40,
    minWidth: 120,
    margin: "8px !important",
    "& .MuiSelect-selectMenu": {
      display: "flex",
      alignItems: "center",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: 'transparent',
    },
    [theme.breakpoints?.down("xs")]: {
      top: 15,
      right: 15,
    },
  },

  icon__flag: {
    width: 15,
    height: 15,
    marginRight: 8,
    objectFit: "cover",
  },

  form: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    textAlign: "center",
    width: "60%",
  },
  form__title: {
    color: "#31343a",
    fontWeight: 600,
    paddingBottom: 25,
    fontSize: 30,
    [theme.breakpoints?.down("md")]: {
      fontSize: 25,
      paddingBottom: 15,
    },
    [theme.breakpoints?.down("xs")]: {
      fontSize: 25,
      paddingBottom: 10,
    },
  },

  checkbox__rememberMe: {
    color: "#000000",
    [theme.breakpoints?.down("xs")]: {
      fontSize: 11,
    },
  },
  forgotPassword: {
    color: "#000000",
    cursor: "pointer",
    [theme.breakpoints?.down("xs")]: {
      fontSize: 12,
    },
  },
  btn_submit: {
    backgroundColor: "#DB2139",
    borderRadius: 40,
    padding: "10px 35px",
    fontSize: 20,
    marginTop: 15,
    "&:hover": {
      backgroundColor: "#157BBC",
    },
    [theme.breakpoints?.down("md")]: {
      fontSize: 15,
    },
    [theme.breakpoints?.down("xs")]: {
      fontSize: 12,
    },
  },
  textRegister: {
    paddingTop: 25,
    fontSize: 20,
    [theme.breakpoints?.down("md")]: {
      fontSize: 15,
    },
    [theme.breakpoints?.down("xs")]: {
      fontSize: 12,
    },
    "& > span": {
      color: "#157BBC",
      cursor: "pointer",
      fontSize: 20,
      [theme.breakpoints?.down("md")]: {
        fontSize: 15,
      },
      [theme.breakpoints?.down("xs")]: {
        fontSize: 12,
      },
    },
  },
  background_mobile: {
    backgroundImage: (props: Props) => `url(${props.bg_mobile})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: "45vh",
    width: "100%",
    position: "relative",
  },
  form_login_mobile: {
    backgroundColor: "#fff",
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    width: "100%",
    height: "55vh",
    marginTop: -20,
    zIndex: 9,
  },
  form__mobile__content: {
    margin: "0 auto",
    width: "70%",
    paddingTop: 40,
    textAlign: "center",
  },
}));

export default useStyles;
