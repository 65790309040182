import {
  Box,
  IconButton,
  Theme,
  Tooltip,
  Typography,
  createStyles,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import managerClassApi from "api/managerClass";
import BACKGROUND from "assets/img/bg_manager_category.png";
import {InfoUser} from "models/auth";
import {ListResponseDetailClass} from "models/managerClass";
import {Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

export interface DetailClassProps {}

export default function DetailClass(props: DetailClassProps) {
  const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
      head: {
        padding: "16px 5px",
        // backgroundColor: "#F3F3F3CC",
        color: "#1B1B1B",
        fontSize: 11,
        fontWeight: 700,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        borderBottom: "1px solid #777777",
        [theme.breakpoints?.up("xl")]: {
          fontSize: 13,
        },
      },
      body: {
        padding: "16px 5px",
        fontSize: 11,
        borderRight: "1px solid rgba(224, 224, 224, 0.8)",
        [theme.breakpoints?.up("xl")]: {
          fontSize: 13,
        },
      },
    })
  )(TableCell);
  const useStyles = makeStyles((theme: Theme) => ({
    background: {
      backgroundImage: `url(${BACKGROUND})`,
      backgroundSize: "cover",
      backgroundPositionX: "right",
      backgroundRepeat: "no-repeat",
      height: 150,
      width: "100%",
      position: "relative",
      "& >h6": {
        position: "absolute",
        top: 50,
        left: 0,
        color: "#004673",
        fontSize: 20,
        fontWeight: 700,
      },
    },
    container: {
      maxHeight: 500,
    },
    btn_goBack: {
      position: "absolute",
      top: -15,
      left: -20,
      zIndex: 9999,
    },
  }));
  const {t} = useTranslation();
  const classes = useStyles();
  const {id} = useParams();
  const [detailClass, setDetailClass] = useState<InfoUser[]>([]);
  const [className, setClassName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      (async () => {
        try {
          const res: ListResponseDetailClass = await managerClassApi.getDetailClass(id);
          setDetailClass(res.class.userList);
          setClassName(res.class.name);
        } catch (error) {}
      })();
    }
  }, [id]);
  return (
    <Box style={{position: "relative"}}>
      <Box className={classes.btn_goBack}>
        <Tooltip title="Quay lại">
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIosIcon style={{marginLeft: 7}} />
          </IconButton>
        </Tooltip>
      </Box>
      <Box className={classes.background}>
        <Typography variant="subtitle2">
          {t("managerClass.danhsachhocvien")} lớp {className}
        </Typography>
      </Box>
      <TableContainer component={Paper} className={classes.container}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell align="center" width="130px">
                {t("stt")}
              </StyledTableCell>
              <StyledTableCell align="center" width="250px">
                {t("admin.tenhocvien")}
              </StyledTableCell>
              <StyledTableCell align="center" width="150px">
                {t("admin.email")}
              </StyledTableCell>
              <StyledTableCell align="center" width="130px">
                {t("admin.manhanvien")}
              </StyledTableCell>
              <StyledTableCell align="center" width="130px">
                {t("admin.sodienthoai")}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {detailClass?.map((item: InfoUser, index: number) => (
              <TableRow key={item.id}>
                <StyledTableCell align="center" component="th" scope="row">
                  {index + 1}
                </StyledTableCell>
                <StyledTableCell align="center">{item?.name}</StyledTableCell>
                <StyledTableCell align="center">{item?.email}</StyledTableCell>
                <StyledTableCell align="center">{item?.staffId}</StyledTableCell>
                <StyledTableCell align="center">{item?.phone}</StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
