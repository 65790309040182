import {makeStyles, Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "35px 25px",
    [theme.breakpoints?.down("md")]: {
      padding: "25px 20px",
    },
    [theme.breakpoints?.down("xs")]: {
      padding: "20px",
    },
  },
  formControl: {
    minWidth: 120,
    backgroundColor: "#FFFFFF",
    marginLeft: 15,
    [theme.breakpoints?.down("md")]: {
      marginLeft: 10,
    },
    [theme.breakpoints?.down("xs")]: {
      marginLeft: 8,
    },
    "& .MuiSelect-root": {
      padding: 10,
    },
  },
  background: {
    padding: 20,
    "& >img": {
      width: "100%",
      height: "100%",
    },
  },
  card: {
    backgroundColor: "transparent",
    margin: "0 15px",
    [theme.breakpoints?.down("md")]: {
      margin: "0 12px",
      "& .MuiCardContent-root": {
        padding: 14,
      },
    },
    [theme.breakpoints?.down("sm")]: {
      margin: "0 8px",
      "& .MuiCardContent-root": {
        padding: 10,
      },
    },
    [theme.breakpoints?.down("xs")]: {
      margin: "0 5px",
      "& .MuiCardContent-root": {
        padding: 6,
      },
    },
  },
  contentMedia: {
    height: 250,
    borderRadius: 10,
    position: "relative",
    [theme.breakpoints?.down("xs")]: {
      height: 160,
    },
  },
  currentMonth: {
    position: "absolute",
    top: "5%",
    left: "50%",
    transform: "translate(-50%,-5%)",
    "&> img": {
      height: "auto",
      objectFit: "cover",
      [theme.breakpoints?.down("xs")]: {
        height: "auto",
      },
    },
  },
  month: {
    position: "absolute",
    top: "65%",
    left: "50%",
    transform: "translate(-50%,-65%)",

    "& >h6": {
      fontSize: "5rem",
      color: "#FFFFFF",
      fontWeight: 700,
      [theme.breakpoints?.down("md")]: {
        fontSize: "4rem",
      },
      [theme.breakpoints?.down("xs")]: {
        fontSize: "2.5em",
      },
    },
  },
  card__content: {
    padding: 20,
    textAlign: "center",
    [theme.breakpoints?.down("md")]: {
      padding: "12px 0",
    },
    "& >h6": {
      fontSize: 25,
      color: "#8D8D8D",
      [theme.breakpoints?.down("md")]: {
        fontSize: 18,
      },
      [theme.breakpoints?.down("xs")]: {
        fontSize: 12,
      },
    },
  },
  totalWatched: {
    paddingTop: 10,
    "& >span": {
      color: "#e66474",
      fontSize: 40,
      fontWeight: 700,
      paddingRight: 5,
      [theme.breakpoints?.down("xs")]: {
        fontSize: 30,
      },
    },
    "& > i ": {
      fontSize: 25,
      fontWeight: 700,
      color: "#a6a6a6",
      [theme.breakpoints?.down("xs")]: {
        fontSize: 18,
      },
    },
    [theme.breakpoints?.down("xs")]: {
      paddingTop: 5,
    },
  },
  btn_detail: {
    color: "#FFFFFF",
    padding: "10px 20px",
    borderRadius: 8,
    [theme.breakpoints?.down("md")]: {
      padding: "8px 15px",
    },
    [theme.breakpoints?.down("xs")]: {
      padding: "5px 10px",
      "& .MuiButton-label": {
        fontSize: 10,
      },
    },
  },
}));

export default useStyles;
