import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "app/store";
import {ListParams} from "models/common";
import {ListRespoinsePrizes, Prize} from "models/managerPrize";

export interface ManagerPrizeState {
  prizeList: {
    content: Prize[];
    [key: string]: any;
  };
  loading: Boolean;
  filter: ListParams;
}

const initialState: ManagerPrizeState = {
  prizeList: {
    content: [],
  },
  loading: false,
  filter: {
    page: 0,
    size: 10,
  },
};

const managerPrizeSlice = createSlice({
  initialState,
  name: "manager-prize-slice",
  reducers: {
    fetchPrizeList(state, action: PayloadAction<ListParams>) {
      state.loading = true;
    },
    fetchPrizeListSuccess(state, action: PayloadAction<ListRespoinsePrizes<Prize>>) { 
      state.prizeList = action.payload.prizes;
      state.loading = false;
    },
    fetchPrizeListFailed(state) {
      state.loading = false;
    },

    setFilterPrizeList(state, action: PayloadAction<ListParams>) {
      state.filter = action.payload;
    },

    setFilterSearchDebounce(state, action: PayloadAction<ListParams>) {},
  },
});

export const {
  fetchPrizeList,
  fetchPrizeListFailed,
  fetchPrizeListSuccess,
  setFilterPrizeList,
  setFilterSearchDebounce,
} = managerPrizeSlice.actions;

export const selectPrizeList = (state: RootState) => state.managerPrizeReducer.prizeList;
export const selectFilterPrizeList = (state: RootState) => state.managerPrizeReducer.filter;

const managerPrizeReducer = managerPrizeSlice.reducer;
export default managerPrizeReducer;
