import {Liquid} from "@ant-design/plots";
import {Box, Button, Grid, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import authApi from "api/authApi";
import {useAppSelector} from "app/hooks";
import NO_AVATAR from "assets/img/logo.png";
import MEDAL_GIOI from "assets/img/medal_gioi.png";
import MEDAL_KHA from "assets/img/medal_kha.png";
import MEDAL_THUKHOA from "assets/img/medal_thukhoa.png";
import MEDAL_TB from "assets/img/medal_tb.png";
import POPUP_MESSAGE from "assets/img/popup_message.png";
import RadialSeparators from "components/RadialSeparators";
import {selectInfoLogin} from "features/Auth/authSlice";
import {StatisticHomePage} from "models/auth";
import React, {useEffect, useState} from "react";
import {buildStyles, CircularProgressbarWithChildren} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {useNavigate} from "react-router-dom";
import ChartRanking from "./components/chartRanking";
import Statistics from "./components/statistics";
import TableCalendar from "./components/tableCalendar";
import useStyles from "./style";
import {useTranslation} from "react-i18next";

export default function HomePage() {
  const {t} = useTranslation();
  const classes = useStyles();
  const infoLogin = useAppSelector(selectInfoLogin);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints?.down("xs"));
  const [statistic, setStatistic] = useState<StatisticHomePage>();

  useEffect(() => {
    (async () => {
      try {
        const res: StatisticHomePage = await authApi.getStatisticHomePage();
        if (res.responseCode === "00") {
          setStatistic(res);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const helloText = () => {
    const timer = new Date().getHours();
    if (timer > 18) {
      return `${t("home.chaobuoitoi")}`;
    } else if (timer <= 18 && timer > 15) {
      return `${t("home.chaobuoichieu")}`;
    } else if (timer <= 15 && timer > 11) {
      return `${t("home.chaobuoitrua")}`;
    } else {
      return `${t("home.chaobuoisang")}`;
    }
  };

  const config = {
    percent: (statistic && statistic?.noLessionLearnedInYear / statistic?.noLessionInYear) || 0,
    outline: {
      border: 4,
      distance: 8,
    },
    wave: {
      length: 80,
    },
    height: 150,
    color: "#3e98c7",
  };

  return (
    <Box className={classes.container}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} lg={5}>
          <Box className={classes.content_info}>
            <Grid container>
              <Grid item xs={4} className={classes.content__info__left}>
                <Typography variant="h4" className={classes.content__info__left__title}>
                  {t("home.mungbanden")}
                  <br />
                  GPET EDU
                </Typography>

                <Box
                  className={
                    infoLogin?.profileImageUrl
                      ? classes.content__info__avatar
                      : classes.content__info__no__avatar
                  }
                >
                  {infoLogin?.profileImageUrl ? (
                    <img src={infoLogin?.profileImageUrl} alt="NO_AVATAR" />
                  ) : (
                    <div>
                      <img src={NO_AVATAR} alt="NO_AVATAR" />
                    </div>
                  )}
                </Box>
              </Grid>

              <Grid item xs={8}>
                <Box display="flex" alignItems="end" justifyContent="space-between" m={1}>
                  <Box className={classes.hello_text}>
                    <img src={POPUP_MESSAGE} alt="POPUP_MESSAGE" />
                    <Typography variant="h5">{helloText()}</Typography>
                  </Box>
                  <Box minHeight={isMobile ? "60px" : "120px"}>
                    {statistic && statistic?.averageMark > 0.5 && (
                      <img
                        src={
                          statistic.averageMark < 0.65
                            ? MEDAL_TB
                            : statistic.averageMark < 0.8
                            ? MEDAL_KHA
                            : statistic.averageMark < 1
                            ? MEDAL_GIOI
                            : MEDAL_THUKHOA
                        }
                        alt="MEDAL"
                        className={classes.medal}
                      />
                    )}
                  </Box>
                </Box>

                <Box className={classes.content__info__right}>
                  <Box className={classes.content__info__right__name}>
                    <Typography variant="h5">{infoLogin.name}</Typography>
                    <Button
                      endIcon={<ArrowRightAltIcon />}
                      className={classes.content__info__right__button}
                      onClick={() => navigate("/profile")}
                    >
                      {t("home.xemthem")}
                    </Button>
                  </Box>
                  <Typography variant="subtitle1">{infoLogin.staffId}</Typography>
                  <Typography variant="subtitle1">{infoLogin.email}</Typography>
                  {/* <Typography variant="subtitle1">{infoLogin.position}</Typography> */}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          {/* lesson of month */}
          <Box className={classes.content__lessonOfMonth}>
            <Typography variant="h5">{t("home.sobaidahoctrongthang")}</Typography>
            <Box display="flex" flexDirection="column" justifyContent="center" height="80%">
              <Box className={classes.content__lessonOfMonth__wrapper}>
                <Box>
                  <Typography variant="subtitle2" className={classes.content__lessonOfMonth__point}>
                    {statistic?.noLessionLearnedInMonth}
                    <Typography variant="subtitle2" component="span">
                      /{statistic?.noLessionInMonth}
                    </Typography>
                  </Typography>
                  <Button
                    endIcon={<ArrowRightAltIcon />}
                    className={classes.content__lessonOfMonth__button}
                    onClick={() => navigate("/lesson-month")}
                  >
                    {t("home.vaohoc")}
                  </Button>
                </Box>

                <Box width="25%">
                  <CircularProgressbarWithChildren
                    value={
                      (statistic &&
                        (statistic.noLessionLearnedInMonth / statistic.noLessionInMonth) * 100) ||
                      0
                    }
                    text={`${
                      statistic &&
                      (
                        (statistic.noLessionLearnedInMonth / statistic.noLessionInMonth) * 100 || 0
                      ).toFixed(2)
                    } %`}
                    strokeWidth={15}
                    styles={buildStyles({
                      strokeLinecap: "butt",
                      textColor: "#E9A051",
                      textSize: "15px",
                    })}
                  >
                    <RadialSeparators
                      count={12}
                      style={{
                        background: "#fff",
                        width: "1px",
                        height: `${15}%`,
                      }}
                    />
                  </CircularProgressbarWithChildren>
                </Box>
              </Box>
              <Box>
                <Typography
                  variant="subtitle2"
                  align="center"
                  className={classes.content__lessonOfMonth__text}
                >
                  {t("home.note.month")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        {/* statistic  */}
        {!isMobile && (
          <Grid item xs={12} sm={6} lg={3}>
            <Statistics statistic={statistic} />
          </Grid>
        )}

        <Grid item xs={12} sm={6} lg={5}>
          <Box className={classes.content__lessonOfMonth} style={{paddingTop: 0}}>
            <Typography variant="h5">{t("home.sobaidahoctrongnam")}</Typography>
            <Box className={classes.content__lessonOfMonth__wrapper}>
              <Box>
                <Typography variant="subtitle2" className={classes.content__lessonOfMonth__point}>
                  {statistic?.noLessionLearnedInYear}
                  <Typography variant="subtitle2" component="span">
                    /{statistic?.noLessionInYear}
                  </Typography>
                </Typography>
                <Button
                  endIcon={<ArrowRightAltIcon />}
                  className={classes.content__lessonOfMonth__button}
                >
                  {t("button.detail")}
                </Button>
              </Box>

              <Box width="30%">
                <Liquid {...config} className={classes.liquid} />
              </Box>
            </Box>
            <Box>
              <Typography variant="subtitle2" className={classes.content__lessonOfMonth__text}>
                {t("home.note.year")}
              </Typography>
            </Box>
          </Box>
        </Grid>

        {isMobile && <Statistics statistic={statistic} />}
        {/* Ranking  */}
        <Grid item xs={12} lg={7}>
          <Grid container className={classes.ranking}>
            <Grid item xs={12} sm={5} xl={4}>
              <Box className={classes.ranking__content}>
                <Typography variant="h5">{t("home.thanhtichcanhan")}</Typography>

                <Box className={classes.ranking__content__detail}>
                  <Typography variant="h5">
                    {statistic?.currentRank === null ? "-" : statistic?.currentRank}
                    <Typography variant="h5" component="span">
                      /{statistic?.totalStudents}
                    </Typography>
                  </Typography>
                  {/* <Typography
                    variant="subtitle2"
                    className={classes.ranking__content__detail__increase}
                  >
                    03 <ArrowUpwardIcon />
                    <Typography variant="subtitle2" component="span">
                      Tăng 3 hạng so với tháng trước
                    </Typography>
                  </Typography> */}
                  <Typography variant="body2">{t("home.cogangphathuy")}</Typography>
                </Box>
              </Box>
            </Grid>

            {/* Chart */}

            <Grid item xs={12} sm={7} xl={8}>
              <ChartRanking ranking={statistic?.ranks} />
            </Grid>
          </Grid>
        </Grid>

        {/* Table calendar */}
      </Grid>
      <Box mt={2} mb={2}>
        <TableCalendar />
      </Box>
    </Box>
  );
}
