import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "app/store";
import {InfoUser} from "models/auth";
import {ListParams} from "models/common";
import {ProvinceForStatistic, ReponseGetAllProvinceForStatistic} from "models/managerChart";
import {ListResponseUser} from "models/managerUser";

export interface ManagerChartState {
  provincesForStatistic: ProvinceForStatistic[];
  loading: Boolean;
}
const initialState: ManagerChartState = {
  provincesForStatistic: [],
  loading: false,
};

const managerChartSlice = createSlice({
  initialState,
  name: "manager-chart-slice",
  reducers: {
    fetchProvincesForStatistic(state) {
      state.loading = true;
    },
    fetchProvincesForStatisticSuccess(
      state,
      action: PayloadAction<ReponseGetAllProvinceForStatistic<ProvinceForStatistic>>
    ) {
      state.provincesForStatistic = action.payload.provinces.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      state.loading = false;
    },
    fetchProvincesForStatisticFailed(state) {
      state.loading = false;
    },
  },
});

export const {
  fetchProvincesForStatistic,
  fetchProvincesForStatisticSuccess,
  fetchProvincesForStatisticFailed,
} = managerChartSlice.actions;
export const selectProvincesForStatistic = (state: RootState) =>
  state.managerChartReducer.provincesForStatistic;

const managerChartReducer = managerChartSlice.reducer;
export default managerChartReducer;
