import {yupResolver} from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import managerTestApi from "api/managerTest";
import {useAppDispatch, useAppSelector} from "app/hooks";
import ICON_SHUFFLE from "assets/img/icon_shuffle.png";
import ICON_SHUFFLE_NOCHECKED from "assets/img/icon_shuffle_nochecked.png";
import CheckboxField from "components/FormFields/CheckboxField";
import InputField from "components/FormFields/InputField";
import {SelectField} from "components/FormFields/SelectFiled";
import {fetchDataOptions, selectListWorkTypes} from "features/Auth/options/optionsSlice";
import {ListQuestionByExamIdResponse, QuestionByExamId} from "models/managerTest";
import React, {ChangeEvent, Fragment, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {Question} from "models/managerTest";

import {toast} from "react-toastify";
import * as yup from "yup";
import DetailExam from "../../AddExam/components/DetailExam";
import {useTranslation} from "react-i18next";

export interface CreateExamProps {
  setShowCreateExam: (e: any) => void;
  arrIdQuestion: number[];
  type: string;
}

export default function CreateExam({setShowCreateExam, arrIdQuestion, type}: CreateExamProps) {
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      display: "flex",
      alignItems: "center",
      marginBottom: 15,
      width: "100%",
      "& .MuiFormGroup-root": {
        flexDirection: "row",
      },
    },

    title: {
      fontSize: 13,
      fontWeight: 600,
      width: "18%",
      [theme.breakpoints?.up("xl")]: {
        fontSize: 15,
        width: "14%",
      },
    },

    question: {
      backgroundColor: "#fff",
      boxShadow: "0px 2px 4px rgba(168, 168, 168, 0.25), 0px 0px 4px rgba(168, 168, 168, 0.25)",
      borderRadius: 6,
      padding: "10px 20px",
      marginTop: 10,
    },
    question__content: {
      color: "#0065A6",
      fontSize: 15,
    },
    btn_next: {
      borderRadius: 20,
      background: "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)",
      color: "#fff",
      padding: "10px 15px",
    },

    btn_goBack: {
      borderRadius: 20,
      background: "  linear-gradient(0deg, #FD5468 0%, rgba(255, 193, 200, 0.9) 169.32%)",
      color: "#fff",
      padding: "10px 15px",
      marginRight: 15,
    },
  }));

  const {t} = useTranslation();

  const schema = yup.object().shape({
    startDate: yup.string().required(`${t("managerTest.vuilongchonngaykiemtra")}`),
    time: yup.string().required(`${t("managerTest.vuilongchongiokiemtra")}`),
    examTime: yup.string().required(`${t("managerTest.vuilongnhapthoigianthi")}`),
    workTypeId: yup.string().when("allWorkType", {
      is: false,
      then: yup.string().required(`${t("managerTest.vuilongchonbophan")}`),
    }),
  });
  const dispatch = useAppDispatch();
  const [disableWorkType, setDisableWorkType] = useState(false);
  const [valueRadio, setValueRadio] = React.useState("");
  const [showDetailExamAgain, setShowDetailExamAgain] = useState(false);
  const [listQuestionHandwork, setListQuestionHandwork] = useState<QuestionByExamId[]>([]);
  const [arrQuestion, setArrQuestion] = useState<any[]>([]);
  const [valueForm, setValueForm] = useState<Question>();
  useEffect(() => {
    if (valueRadio === "handwork") {
      (async () => {
        try {
          const res: ListQuestionByExamIdResponse<QuestionByExamId> =
            await managerTestApi.getQuestionByMutipleExamId(arrIdQuestion?.join(","));
          if (res.responseCode === "00") {
            setListQuestionHandwork(res.questions);
          }
        } catch (error) {}
      })();
    }
  }, [valueRadio]);

  const handleChangeValueRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueRadio((event.target as HTMLInputElement).value);
  };

  const classes = useStyles();
  const workTypes = useAppSelector(selectListWorkTypes);
  useEffect(() => {
    dispatch(fetchDataOptions());
  }, [dispatch]);

  const initialValue = {
    workTypeId: "",
    startDate: "",
    examTime: "",
    time: "",
    totalPoint: "",
    allWorkType: false,
  };
  const {control, handleSubmit} = useForm({
    defaultValues: initialValue,
    resolver: yupResolver(schema),
  });

  const handleSubmitForm = (value: any) => {
    if (value && value?.totalPoint > 0) {
      const pointQuestion = arrQuestion.reduce((x, item) => (x += Number(item.point)), 0);
      if (pointQuestion > value?.totalPoint) {
        toast.error(`${t("managerTest.tongsodiemlonhondiemtongdanhap")}`);
        return;
      } else if (
        pointQuestion === value?.totalPoint &&
        arrQuestion.some((item) => item.point === "")
      ) {
        toast.error(`${t("managerTest.tongsodiemlonhondiemtongdanhap")}`);
        return;
      } else if (
        pointQuestion < value?.totalPoint &&
        arrQuestion.every((item) => item.point !== "")
      ) {
        toast.error(`${t("managerTest.tongsodiemlonhondiemtongdanhap")}`);
        return;
      }
    }
    const convertExamTime =
      (Math.floor(Number(value?.examTime) / 60) < 10
        ? "0" + Math.floor(Number(value?.examTime) / 60)
        : Math.floor(Number(value?.examTime) / 60)) +
      ":" +
      (Number(value?.examTime) % 60 > 0
        ? Number(value?.examTime) % 60
        : "0" + (Number(value?.examTime) % 60));

    const endDate =
      Number(value?.time?.split(":")[1]) + Number(convertExamTime?.split(":")[1]) > 59
        ? Number(value?.time?.split(":")[0]) +
          Number(convertExamTime?.split(":")[0]) +
          1 +
          ":" +
          (Number(value?.time?.split(":")[1]) + Number(convertExamTime?.split(":")[1]) - 60 < 10
            ? "0" +
              (Number(value?.time?.split(":")[1]) + Number(convertExamTime?.split(":")[1]) - 60)
            : Number(value?.time?.split(":")[1]) + Number(convertExamTime?.split(":")[1]) - 60)
        : Number(value?.time?.split(":")[0]) +
          Number(convertExamTime?.split(":")[0]) +
          ":" +
          (Number(value?.time?.split(":")[1]) + Number(convertExamTime?.split(":")[1]));
    setShowDetailExamAgain(true);
    setValueForm({
      ...value,
      type,
      startDate: value.startDate + "T" + value.time,
      totalPoint: Number(value.totalPoint) || "",
      workTypeId: value.allWorkType ? workTypes.map((x) => x.id) : [Number(value.workTypeId)],
      endDate: value.startDate + "T" + endDate,
    });
  };

  const handleChangePoint = (event: ChangeEvent<HTMLInputElement>, id: number) => {
    const newArrQuestion = [...arrQuestion];
    const foundIdx = newArrQuestion.findIndex((x: any) => x?.id === id);
    if (foundIdx > -1) {
      newArrQuestion[foundIdx].point = event.target.value;
      setArrQuestion(newArrQuestion);
    }
  };

  const handleShuffle = (id: number) => {
    const newArrQuestion = [...arrQuestion];
    const foundIdx = newArrQuestion.findIndex((x: any) => x?.id === id);
    if (foundIdx > -1) {
      if (newArrQuestion[foundIdx].shuffle === true) {
        newArrQuestion[foundIdx].shuffle = false;
        setArrQuestion(newArrQuestion);
      } else {
        newArrQuestion[foundIdx].shuffle = true;
        setArrQuestion(newArrQuestion);
      }
    }
  };
  return (
    <Fragment>
      {showDetailExamAgain ? (
        <DetailExam
          listQuestion={arrQuestion}
          valueForm={valueForm}
          setShowDetailExam={setShowDetailExamAgain}
        />
      ) : (
        <>
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            <InputField
              label={t("managerTest.nhaptieudebaithilai").toUpperCase()}
              control={control}
              name="title"
              rows={2}
              multiline
            />
            {/* <Box className={classes.root}>
              <Typography variant="subtitle2" className={classes.title}>
                {t("managerTest.bophan")}
              </Typography>
              <Box width="50%" mr={5}>
                <SelectField
                  options={workTypes}
                  name="workTypeId"
                  control={control}
                  label={t("managerTest.chonbophan")}
                  workType="createExam"
                  disabled={disableWorkType}
                />
              </Box>
              <CheckboxField
                name="allWorkType"
                label={t("managerTest.tatca")}
                control={control}
                handleChange={setDisableWorkType}
              />
            </Box> */}

            <Box className={classes.root}>
              <Typography variant="subtitle2" className={classes.title}>
                {t("managerTest.ngaygiokiemtra")}
              </Typography>
              <Box display="flex" width="80%">
                <InputField control={control} type="date" label="" name="startDate" />
                <Box ml={2} width="100%">
                  <InputField control={control} type="time" label="" name="time" />
                </Box>
              </Box>
            </Box>

            <Box className={classes.root}>
              <Typography variant="subtitle2" className={classes.title}>
                {t("managerTest.thoigianlambai")}
              </Typography>
              <Box width="30%">
                <InputField
                  control={control}
                  name="examTime"
                  label={t("managerTest.nhapthoigianthi")}
                />
                <Box ml={3}>
                  <Typography variant="subtitle2" component="i" style={{color: "#999999"}}>
                    ({t("managerTest.vidu")}: 100 = 01:40:00)
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box className={classes.root}>
              <Typography variant="subtitle2" className={classes.title}>
                {t("managerTest.tongdiem")}
              </Typography>
              <Box width="30%">
                <InputField
                  control={control}
                  name="totalPoint"
                  label={t("managerTest.nhaptongdiem")}
                />
              </Box>
            </Box>
            <Box>
              <Typography variant="subtitle2" className={classes.title} style={{width: "100%"}}>
                {t("managerTest.chonphuongthuctaocauhoi")}:
              </Typography>
              <FormControl component="fieldset" style={{width: "50%"}}>
                <RadioGroup
                  aria-label="method"
                  name="method"
                  value={valueRadio}
                  onChange={handleChangeValueRadio}
                >
                  <FormControlLabel
                    value="multipleChoice"
                    control={<Radio color="primary" />}
                    label={t("managerTest.choncauhoingaunhientunhungdethidachon")}
                  />
                  {valueRadio === "multipleChoice" && (
                    <Box display="flex" width="100%" alignItems="center">
                      <Typography variant="subtitle2" style={{marginRight: 10, fontSize: 16}}>
                        {t("managerTest.soluongcauhoi")} :
                      </Typography>
                      <Box>
                        <InputField
                          name="total_question"
                          label={t("managerTest.nhapsoluongcauhoi")}
                          control={control}
                        />
                      </Box>
                    </Box>
                  )}
                  <FormControlLabel
                    value="handwork"
                    control={<Radio color="primary" />}
                    label={t("managerTest.choncauhoithucongtunhungdethidachon")}
                  />
                </RadioGroup>
              </FormControl>
              {valueRadio === "handwork" && (
                <Box mt={1}>
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    style={{width: "100%", margin: "10px 0"}}
                  >
                    {t("managerTest.danhsachcauhoitudethicu").toUpperCase()}
                  </Typography>

                  {/* <Box className={classes.question}>BÀI TEST KIỂM TRA CHẤT LƯỢNG ĐÀN HEO MÙA 1</Box> */}

                  {listQuestionHandwork?.map((item, index) => (
                    <Box className={classes.question} key={index}>
                      <Box alignItems="center" mb={1}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Typography variant="subtitle2" className={classes.question__content}>
                            {t("managerTest.cau")} {index + 1}
                          </Typography>

                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            width="20%"
                          >
                            <Box width="70px">
                              <TextField
                                autoComplete="off"
                                label={t("managerTest.diem")}
                                variant="outlined"
                                size="small"
                                disabled={arrQuestion?.every((x) => x.id !== item.id)}
                                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                  handleChangePoint(event, item.id)
                                }
                              />
                            </Box>
                            <img
                              src={
                                arrQuestion?.filter((x) => x.id === item.id)[0]?.shuffle
                                  ? ICON_SHUFFLE
                                  : ICON_SHUFFLE_NOCHECKED
                              }
                              alt="ICON_SHUFFLE"
                              style={{
                                width: 30,
                                height: 30,
                                cursor: "pointer",
                              }}
                              onClick={() => handleShuffle(item.id)}
                            />

                            <Checkbox
                              color="primary"
                              inputProps={{"aria-label": "secondary checkbox"}}
                              onClick={() =>
                                setArrQuestion((prevState: any) => {
                                  const foundIdx = arrQuestion?.findIndex(
                                    (x: any) => x?.id === item.id
                                  );
                                  if (foundIdx > -1) {
                                    return prevState.splice(0, foundIdx);
                                  }
                                  return [
                                    ...prevState,
                                    {
                                      shuffle: false,
                                      point: "",
                                      id: item.id,
                                      questionContent: item.content,
                                      answers: item.answers.map((x) => ({
                                        answerContent: x.content,
                                      })),
                                    },
                                  ];
                                })
                              }
                            />
                          </Box>
                        </Box>
                        <Typography variant="subtitle2">{item?.content}</Typography>
                      </Box>

                      {item?.answers?.map((answer, index) => (
                        <Box key={index}>
                          {type === "CHOICES" ? (
                            <FormControlLabel
                              control={<Radio />}
                              label={<Typography variant="subtitle2">{answer?.content}</Typography>}
                            />
                          ) : (
                            <Typography variant="subtitle2">{answer?.content}</Typography>
                          )}
                        </Box>
                      ))}
                    </Box>
                  ))}
                </Box>
              )}
            </Box>

            <Box textAlign="right" mt={2}>
              <Button className={classes.btn_goBack} onClick={() => setShowCreateExam(false)}>
                {t("button.goback")}
              </Button>
              <Button className={classes.btn_next} type="submit">
                {t("button.next")}
              </Button>
            </Box>
          </form>
        </>
      )}
    </Fragment>
  );
}
