import {
  AttendByArea,
  AttendByYear,
  AttendInFarm,
  AttendProvinceByArea,
  ItemList,
  LessonByYear,
  ListParamsChart,
  MonthList,
  ProvinceForStatistic,
  ReponseGetAllProvinceForStatistic,
  ResponseAttendAreaByYear,
  ResponseAttendByArea,
  ResponseAttendFarmByProvince,
  ResponseAttendProvinceByArea,
  ResponseAttendProvincesByYear,
  ResponseExamAreaByYear,
  ResponseLessonByYear,
  ResponseExam3AreaByMonth,
  ResponseExamResultFarmByProvince,
  farmList
} from "models/managerChart";
import axiosClient from "./axiosClient";

const managerChartApi = {
  getLessonByYear(year: number): Promise<ResponseLessonByYear<LessonByYear>> {
    const url = `/statistic/statisticLessionsByYear?year=${year}`;
    return axiosClient.get(url);
  },

  getStatisticAttendByArea(params: {
    month: number | "";
    year: number;
  }): Promise<ResponseAttendByArea<AttendByArea>> {
    const url = "/statistic/attendanceByArea";
    return axiosClient.get(url, {params});
  },

  getStatisticAttendProvinceByArea(params: {
    month: number | "";
    year: number;
  }): Promise<ResponseAttendProvinceByArea<AttendProvinceByArea>> {
    const url = "/statistic/attendanceByAreaAndProvinces";
    return axiosClient.get(url, {params});
  },

  getStatisticAttendAreaByYear(year: number): Promise<ResponseAttendAreaByYear<AttendByYear>> {
    const url = `/statistic/attendanceByAreaAndYear?year=${year}`;
    return axiosClient.get(url);
  },
  getStatisticAttendProvincesByYear(
    params: ListParamsChart
  ): Promise<ResponseAttendProvincesByYear<AttendByYear>> {
    const url = "/statistic/attendanceByNewProvinceIdAndYear";
    return axiosClient.get(url, {params});
  },

  getStatisticAttendFarmByProvince(
    params: ListParamsChart
  ): Promise<ResponseAttendFarmByProvince<AttendInFarm>> {
    const url = "/statistic/attendanceInFarmByNewProvinceIdAndYear";
    return axiosClient.get(url, {params});
  },
  // get all province for statistic
  getAllProvinceForStatistic(): Promise<ReponseGetAllProvinceForStatistic<ProvinceForStatistic>> {
    const url = "/getAllNewProvincesForStatictics";
    return axiosClient.get(url);
  },
  getStatisticExamResultAreaByYear(params:ListParamsChart):Promise<ResponseExamAreaByYear<MonthList>>{
    const url = "statistic/statisticExamResultAreaByYear";
    return axiosClient.get(url,{params});
  },
  getStatisticExamResult3AreaByMonth(params:ListParamsChart):Promise<ResponseExam3AreaByMonth<ItemList>>{
    const url = "statistic/statisticExamResult3AreasByMonth";
    return axiosClient.get(url,{params});
  },
  getStatisticExamResultProvinceByYear(params:ListParamsChart):Promise<ResponseExamAreaByYear<MonthList>>{
    const url = "statistic/statisticExamResultProvinceByYear";
    return axiosClient.get(url,{params});
  },
  getStatisticExamResultFarmByProvince(params:ListParamsChart):Promise<ResponseExamResultFarmByProvince<farmList>>{
    const url = "statistic/statisticExamResultFramByProvince";
    return axiosClient.get(url,{params});
  }
};

export default managerChartApi;
