import {PayloadAction} from "@reduxjs/toolkit";
import managerTestApi from "api/managerTest";
import {ListParams} from "models/common";
import {ListExam, ListExamResponse} from "models/managerTest";
import {call, put, takeLatest} from "redux-saga/effects";
import {fetchListExam, fetchListExamSuccess} from "./managerExamListSlice";

function* handleFetchDataListExam(action: PayloadAction<ListParams>) {
  try {
    const res: ListExamResponse<ListExam> = yield call(managerTestApi.getListExam, action.payload);
    if (res.responseCode === "00") {
      yield put(fetchListExamSuccess(res));
    }
  } catch (error) {}
}

export default function* managerTestListSaga() {
  yield takeLatest(fetchListExam, handleFetchDataListExam);
}
