import {
  Box,
  Button,
  CircularProgress,
  Container,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import managerTestApi from "api/managerTest";
import {useAppSelector} from "app/hooks";
import BACKGROUND from "assets/img/bg_waiting_test.png";
import CLOCK from "assets/img/clock.png";
import {selectInfoLogin} from "features/Auth/authSlice";
import {ListExam} from "models/managerTest";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Navigate, useLocation, useNavigate, useParams} from "react-router-dom";

export interface TakeATestProps {}

export default function TakeATest(props: TakeATestProps) {
  const useStyles = makeStyles((theme: Theme) => ({
    background: {
      width: "100%",
      objectFit: "cover",
    },
    container: {
      backgroundColor: "#FFFFFF",
      boxShadow: "0px 4px 4px rgba(168, 168, 168, 0.25), 0px 2px 4px rgba(168, 168, 168, 0.3)",
    },
    wrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "10px 24px",
      [theme.breakpoints?.down("xs")]: {
        padding: "10px 8px",
        display: "block",
      },
    },
    content__right: {
      marginLeft: 30,
      [theme.breakpoints?.down("xs")]: {
        marginLeft: 0,
        textAlign: "center",
        marginTop: 15,
      },
      "& >img": {
        width: 200,
        height: 200,
        [theme.breakpoints?.down("md")]: {
          width: 150,
          height: 150,
        },
        [theme.breakpoints?.down("xs")]: {
          width: 100,
          height: 100,
        },
      },
      "& > h6": {
        textAlign: "center",
        fontSize: 30,
        color: "#DB2139",
      },
    },
    content__title: {
      backgroundColor: "#F2F2F2",
      fontSize: 20,
      fontWeight: 600,
      borderRadius: 8,
      padding: "10px 50px",
      [theme.breakpoints?.down("md")]: {
        padding: "10px 30px",
      },
      [theme.breakpoints?.down("xs")]: {
        padding: "10px",
        fontSize: 14,
      },
      "&>span": {
        color: "#157BBC",
        padding: "0",
      },
    },
    btn_start: {
      background: "linear-gradient(0deg, #FD5468 0%, rgba(255, 193, 200, 0.9) 169.32%)",
      borderRadius: 20,
      color: "#fff",
      padding: "8px 20px",
      [theme.breakpoints?.down("md")]: {
        padding: "5px 10px",
        borderRadius: 10,
      },
    },
    loading: {
      zIndex: 99,
      backgroundColor: "rgba(255,255,255,0.8)",
      width: "100%",
      height: "100%",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      "& >div": {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        "& .MuiCircularProgress-root": {
          display: "flex",
          margin: "0 auto",
        },
      },
    },
  }));
  const classes = useStyles();
  const {t} = useTranslation();
  const location: any = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints?.down("xs"));
  const [detailTest, setDetailTest] = useState<ListExam>();
  const navigate = useNavigate();
  const infoUser = useAppSelector(selectInfoLogin);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    (async () => {
      const res: ListExam = await managerTestApi.getDetailExam(Number(location?.state?.id), true);
      setDetailTest(res);
    })();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentDay = new Date().getTime();
      const timeOfTest = new Date(detailTest?.exam?.startDate).getTime();
      const difference = moment.duration(moment(timeOfTest).diff(moment(currentDay)));
      if (difference.minutes() > 0) {
        setMinutes(difference.minutes());
      } else if (difference.minutes() === 0) {
        setMinutes(0);
      }
      if (difference.seconds() > 0) {
        setSeconds(difference.seconds());
      } else if (difference.seconds() === 0) {
        setSeconds(0);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [detailTest]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  if (location?.state === null) {
    return <Navigate to="/" />;
  }
  const showButton = minutes === 0 && seconds === 0;
  return (
    <Box px={isMobile ? 1 : 3}>
      <img src={BACKGROUND} alt="BACKGROUND" className={classes.background} />
      <Box className={classes.container}>
        <Box className={classes.wrapper}>
          <Box flex={1}>
            <Typography variant="subtitle2" className={classes.content__title}>
              {t("auth.fullName")} :{" "}
              <Typography variant="subtitle2" component="span" className={classes.content__title}>
                {infoUser?.name}
              </Typography>
            </Typography>
            {/* <Typography
              variant="subtitle2"
              className={classes.content__title}
              style={{margin: isMobile ? "15px 0 " : "20px 0"}}
            >
              {t("auth.department")} :{" "}
              <Typography variant="subtitle2" className={classes.content__title} component="span">
                {detailTest?.exam?.departments?.length > 1
                  ? `${t("tatca")}`
                  : detailTest?.exam?.departments[0]?.title}
              </Typography>
            </Typography> */}
            <Typography variant="subtitle2" className={classes.content__title}>
              {t("listexam.tieudebaithi")} :{" "}
              <Typography variant="subtitle2" className={classes.content__title} component="span">
                {detailTest?.exam?.examTitle}
              </Typography>
            </Typography>
            {detailTest?.exam?.prize?.name && (
              <Typography variant="subtitle2" className={classes.content__title}>
                {t("listexam.phanthuong")} :{" "}
                <Typography variant="subtitle2" className={classes.content__title} component="span">
                  {detailTest?.exam?.prize?.name}
                </Typography>
              </Typography>
            )}
          </Box>

          {
            <Box className={classes.content__right}>
              <img src={CLOCK} alt="CLOCK" />
              <Typography variant="subtitle2">
                {loading ? (
                  <Box>
                    <CircularProgress style={{width: 30, height: 30}} />
                  </Box>
                ) : showButton ? (
                  <Button
                    className={classes.btn_start}
                    onClick={() =>
                      navigate("/exam/take-exam", {
                        state: {
                          id: location?.state?.id,
                          startTime: new Date(),
                        },
                      })
                    }
                  >
                    {t("button.start")}
                  </Button>
                ) : (
                  <>
                    {minutes < 10 ? "0" + minutes : minutes}:
                    {seconds < 10 ? "0" + seconds : seconds}
                  </>
                )}
              </Typography>
            </Box>
          }
        </Box>
      </Box>
    </Box>
  );
}
