import {Box, Grid, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {useAppSelector} from "app/hooks";
import {selectLessonListByUser} from "features/Home/pages/ListLesson/listLessonSlice";
import {LessonByUser} from "models/managerLesson";
import VECTOR_CATEGORY from "assets/img/vector_category.png";
import React, {Fragment, useEffect} from "react";
import ICON_WATCHED from "assets/img/icon_watched.png";
import ICON_NO_WATCHED from "assets/img/icon_noWatched.png";
import queryString from "query-string";
import {useLocation, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

export default function ListVideoRecommandMobile() {
  const useStyles = makeStyles((theme: Theme) => ({
    container: {
      padding: 15,
      paddingTop: 0,
      "& >h6": {
        fontSize: 16,
        fontWeight: 700,
        paddingBottom: 10,
      },
    },
    wrapper: {
      backgroundColor: "#FFF",
      boxShadow: " 0px 4px 4px rgb(126 126 126 / 25%)",
      padding: 5,
      paddingLeft: 10,
      "& > h6": {
        fontSize: 15,
        fontWeight: 700,
        maxHeight: "1.4rem",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        cursor: "pointer",
        [theme.breakpoints?.down("sm")]: {
          fontSize: 13,
          paddingTop: 5,
        },
      },
    },
    image: {
      width: "100%",
      height: 200,
      cursor: "pointer",
      objectFit: "cover",
    },
    content__category: {
      fontWeight: 700,
      fontSize: 15,
      marginRight: 5,
      [theme.breakpoints?.down("sm")]: {
        fontSize: 12,
      },
    },
    content__date: {
      color: "#4F4F4F",
      [theme.breakpoints?.down("sm")]: {
        fontSize: 13,
      },
    },
    content__lesson__status__icon: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginLeft: 20,
      "& >img": {
        width: 15,
        height: 15,
        marginRight: 5,
      },
    },
  }));
  const listLesson = useAppSelector(selectLessonListByUser);
  const classes = useStyles();
  const location: any = useLocation();
  const querySearchDate = queryString.parse(location.search);
  const navigate = useNavigate();
  const {t} = useTranslation();

  return (
    <Box className={classes.container}>
      <Typography variant="subtitle2"> {t("home.detaillesson.danhsachbaihockhac")}</Typography>
      <Grid container spacing={3}>
        {listLesson?.content?.map((lesson: LessonByUser) => (
          <Grid item xs={12} sm={6} md={4} key={lesson?.lessionUsers?.id}>
            <img
              src={lesson?.lession?.attachment?.urlThumbnail}
              alt="thumbnailUrl"
              className={classes.image}
              onClick={() =>
                navigate({
                  pathname: `/lesson/${lesson.lessionId}`,
                  search: `year=${querySearchDate.year}&month=${querySearchDate.month}&attachmentID=${lesson.lession.attachmentID}`,
                })
              }
            />
            <Box className={classes.wrapper}>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <Typography variant="subtitle2" className={classes.content__category}>
                  {lesson?.lession?.lessionCategory?.name}
                </Typography>

                <img src={VECTOR_CATEGORY} alt="background" width="30px" height="15px" />
              </Box>
              <Typography
                variant="subtitle2"
                onClick={() =>
                  navigate({
                    pathname: `/lesson/${lesson.lessionId}`,
                    search: `year=${querySearchDate.year}&month=${querySearchDate.month}&attachmentID=${lesson.lession.attachmentID}`,
                  })
                }
              >
                {lesson?.lession?.lessionName}
              </Typography>
              <Box pt={1} display="flex" justifyContent="space-between">
                <Typography variant="body1" className={classes.content__date}>
                  {String(lesson?.lession?.publishDate).slice(-2)}/
                  {String(lesson?.lession?.publishDate).slice(4, 6)}/
                  {String(lesson?.lession?.publishDate).slice(0, 4)}
                </Typography>
                <Box className={classes.content__lesson__status__icon}>
                  {lesson?.watched ? (
                    <Fragment>
                      <img src={ICON_WATCHED} alt="ICON_WATCHED" />
                      <Typography variant="subtitle2"> {t("home.detaillesson.daxem")}</Typography>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <img src={ICON_NO_WATCHED} alt="ICON_NO_WATCHED" />
                      <Typography variant="subtitle2"> {t("home.detaillesson.chuaxem")}</Typography>
                    </Fragment>
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
