import authApi from "api/authApi";
import {InfoUser} from "models/auth";
import {call, put, takeLatest} from "redux-saga/effects";
import {fetchInfoLogin, fetchInfoLoginSuccess} from "./authSlice";
import {createBrowserHistory} from "history";
import {TOKEN, TOKEN_ADMIN} from "constants/config";

const history = createBrowserHistory();

function* handleFetchInfoLogin() {
  try {
    const res: InfoUser = yield call(authApi.getInfo);

    if (res.responseCode === "00") {
      yield put(fetchInfoLoginSuccess(res));
    } else {
      history.push("/login");
      localStorage.removeItem(TOKEN);
      localStorage.removeItem(TOKEN_ADMIN);
      throw new Error("Failed to fetch info");
    }
  } catch (error) {}
}

export default function* authSaga() {
  yield takeLatest(fetchInfoLogin, handleFetchInfoLogin);
}
