import {makeStyles, Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    visibility: "hidden",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: "100%",
    background: "rgba(0,0,0,0.4)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  button: {
    margin: theme.spacing(1),
  },
  controlIcons: {
    color: "#777",
    fontSize: 50,
    transform: "scale(0.9)",
    "&:hover": {
      color: "#fff",
      transform: "scale(1)",
    },
  },

  icon_play: {
    padding: 5,
    backgroundColor: "#fff",
    width: 50,
    height: 50,
    borderRadius: "50%",
    color: "#f9ab00",
    transition: "ease-in 0.5s",
    border: "8px solid #999",
    "&:hover": {
      backgroundColor: "#f9ab00",
      color: "#fff",
    },
  },
  wrapper__control: {
    padding: 16,
    paddingTop: 0,
    [theme.breakpoints?.down("xs")]: {
      padding: "10px",
    },
    "& .MuiSlider-root": {
      padding: "8px 0",
    },
  },
  iconButton: {
    color: "#fff",
    zIndex: 9,
    padding: 10,
    [theme.breakpoints?.down("xs")]: {
      padding: "5px",
      fontSize: 12,
      "& .MuiSvgIcon-fontSizeLarge": {
        fontSize: 18,
      },
    },
  },
}));

export default useStyles;
