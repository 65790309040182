import {
  Box,
  Button,
  createStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import {Pagination} from "@material-ui/lab";
import {makeStyles, withStyles} from "@material-ui/styles";
import managerLessonApi from "api/managerLesson";
import {useAppDispatch, useAppSelector} from "app/hooks";
import BACKGROUND from "assets/img/bg_manager_lesson.png";
import LOADING from "assets/img/loading.gif";
import clsx from "clsx";
import {useConfirm} from "material-ui-confirm";
import {ResponseStatus} from "models/common";
import {Lesson} from "models/managerLesson";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {
  selectFilterLessonListHidden,
  selectLessonList,
  setFilterLessonListHidden,
} from "../../managerLessonSlice";
import EmptyComponent from "components/Empty";
export interface ListVideoHiddenProps {}

export default function ListVideoHidden(props: ListVideoHiddenProps) {
  const useStyles = makeStyles((theme: Theme) => ({
    background: {
      backgroundImage: `url(${BACKGROUND})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      height: 100,
      width: "100%",
      [theme.breakpoints?.up("xl")]: {
        width: "90%",
        height: 150,
      },
    },

    thumbnail_video: {
      width: 40,
      height: 40,
      objectFit: "cover",
    },

    percentHide: {
      width: "100%",
      position: "relative",
      backgroundColor: "#EFEFEF",
      height: 8,
      borderRadius: 8,
      marginTop: 5,
    },
    percent: {
      height: 8,
      borderRadius: 8,
      position: "absolute",
      top: 0,
      left: 0,
    },
    percentView: {
      backgroundColor: "#F1A34D",
    },
    percentLike: {
      backgroundColor: "  #0499C8",
    },

    btn_delete: {
      borderRadius: 10,
      fontSize: 12,
      color: "#fff",
      padding: "4px 10px",
      background: "linear-gradient(0deg, #FD5468 0%, rgba(255, 193, 200, 0.9) 169.32%)",
    },
    btn_detail: {
      background: "linear-gradient(360deg, #019ABB 0%, rgba(167, 255, 255, 0.9) 168.18%)",
      borderRadius: 10,
      fontSize: 12,
      color: "#fff",
      padding: "4px 10px",
      margin: "0 10px",
    },
    btn_add: {
      background: "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)",
      borderRadius: 10,
      fontSize: 12,
      color: "#fff",
      padding: "4px 10px",
    },
    btn_list_missing: {
      background: "linear-gradient(0deg, #10BCA7 0%, rgba(255, 239, 156, 0.9) 165.52%)",
      borderRadius: 10,
      fontSize: 12,
      color: "#fff",
      padding: "4px 10px",
    },
    root_modal: {
      width: "100%",
      height: "60%",
      display: "flex",
      justifyContent: "center",
    },
    modal__container: {
      width: "100%",
      maxWidth: 560,
      borderRadius: 12,
      backgroundColor: "#fff",
      zIndex: 9,
      display: "flex",
      flexDirection: "column",
      margin: "30px 0",
    },
    modal__header: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      padding: "0 24px",
      minHeight: 20,
      borderBottom: "1px solid rgb(235, 235, 235)",
      "& >h6": {
        fontWeight: 700,
        fontSize: 18,
        marginRight: 50,
      },
    },
    icon_play: {
      padding: 5,
      backgroundColor: "#fff",
      width: 50,
      height: 50,
      borderRadius: "50%",
      color: "#f9ab00",
      transition: "ease-in 0.5s",
      border: "8px solid #999",
      "&:hover": {
        backgroundColor: "#f9ab00",
        color: "#fff",
      },
    },
    container: {
      maxHeight: 500,
    },
  }));
  const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
      head: {
        padding: "16px 5px",
        // backgroundColor: "#F3F3F3CC",
        color: "#1B1B1B",
        fontSize: 11,
        fontWeight: 700,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        borderBottom: "1px solid #777777",
        [theme.breakpoints?.up("xl")]: {
          fontSize: 13,
        },
      },
      body: {
        padding: "16px 5px",
        fontSize: 11,
        borderRight: "1px solid rgba(224, 224, 224, 0.8)",
        [theme.breakpoints?.up("xl")]: {
          fontSize: 13,
        },
      },
    })
  )(TableCell);

  const {t} = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const listLesson = useAppSelector(selectLessonList);
  const filter = useAppSelector(selectFilterLessonListHidden);
  const handleChangePage = (page: number) => {
    dispatch(
      setFilterLessonListHidden({
        ...filter,
        page: page - 1,
      })
    );
  };
  const handleClick = (id: number, name: string, decription: string, videoUrl: string) => {
    navigate(`/admin/manager-lesson/reup/${id}`, {
      state: {
        id: id,
        name: name,
        decription: decription,
        videoUrl: videoUrl,
        back: "/admin/manager-lesson",
      },
    });
  };

  return (
    <>
      {" "}
      {listLesson?.totalPages > 0 ? (
        <TableContainer component={Paper} className={classes.container}>
          <Table
            stickyHeader
            // style={{minWidth: "1600px"}}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell align="center" width="70px">
                  {t("managerLesson.video")}
                </StyledTableCell>
                <StyledTableCell align="center" width="250px">
                  {t("managerLesson.tenbaihoc")}
                </StyledTableCell>
                <StyledTableCell align="center" width="100px">
                  {t("managerLesson.ngaydang")}
                </StyledTableCell>
                <StyledTableCell align="center" width="100px">
                  {t("managerLesson.giodang")}
                </StyledTableCell>
                <StyledTableCell align="center" width="100px">
                  {t("managerLesson.ngayan")}
                </StyledTableCell>
                <StyledTableCell align="center" width="100px">
                  {t("managerLesson.gioan")}
                </StyledTableCell>
                {/* <StyledTableCell align="center" width="100px">
                  {t("managerLesson.khuvuc")}
                </StyledTableCell>
                <StyledTableCell align="center" width="100px">
                  {t("managerLesson.bophan")}
                </StyledTableCell>
                <StyledTableCell align="center" width="100px">
                  {t("managerLesson.phongban")}
                </StyledTableCell> */}
                <StyledTableCell align="center" width="150px">
                  {t("thaotac")}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listLesson?.content.map((lesson) => (
                <TableRow key={lesson.id}>
                  <StyledTableCell align="center">
                    {lesson.attachment ? (
                      <img
                        src={lesson.attachment?.urlThumbnail}
                        alt="thumbnal_video"
                        className={classes.thumbnail_video}
                      />
                    ) : lesson.powerPointFileList ? (
                      <img
                        src={lesson.powerPointFileList[0].powerPointFile.urlThumbnail}
                        alt="thumbnal_video"
                        className={classes.thumbnail_video}
                      />
                    ) : (
                      <img src={LOADING} alt="thumbnal_video" className={classes.thumbnail_video} />
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center">{lesson.lessionName}</StyledTableCell>
                  <StyledTableCell align="center">
                    {String(lesson?.publishDate).slice(-2)}/
                    {String(lesson?.publishDate).slice(4, 6)}/
                    {String(lesson?.publishDate).slice(0, 4)}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {String(lesson?.publishDateTimeAsString?.slice(0, 5))}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {String(lesson?.expiryDateTimeAsString?.slice(8))}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {String(lesson?.expiryDateTimeAsString?.slice(0, 5))}
                  </StyledTableCell>
                  {/* <StyledTableCell align="center">{lesson?.area?.name}</StyledTableCell>
                  <StyledTableCell align="center">{lesson?.department?.title}</StyledTableCell>
                  <StyledTableCell align="center">{lesson?.workType?.name}</StyledTableCell> */}
                  <StyledTableCell align="center">
                    <Button
                      onClick={() =>
                        handleClick(
                          lesson?.id,
                          lesson?.lessionName,
                          lesson?.lessionDescription,
                          lesson?.attachment?.url
                        )
                      }
                      className={classes.btn_add}
                      size="small"
                    >
                      {t("managerLesson.taobaihoc")}
                    </Button>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box p={2}>
          {" "}
          <EmptyComponent isBorder={false} />
        </Box>
      )}
      {listLesson?.totalPages > 1 && (
        <Box mt={3} display="flex" justifyContent="space-between">
          <Typography variant="subtitle2" style={{fontWeight: 600}}>
            {t("managerLesson.tongbaihoc")}: {listLesson.totalElements}
          </Typography>
          <Pagination
            count={Math.ceil(listLesson?.totalElements / listLesson?.size)}
            page={listLesson?.number + 1}
            variant="outlined"
            color="primary"
            shape="rounded"
            onChange={(e, page) => handleChangePage(page)}
          />
        </Box>
      )}
    </>
  );
}
