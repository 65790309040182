import {PayloadAction} from "@reduxjs/toolkit";
import managerPrizeApi from "api/managerPrize";
import {ListParams} from "models/common";
import {ListRespoinsePrizes, Prize} from "models/managerPrize";
import {takeLatest, call, put, debounce} from "redux-saga/effects";
import {
  fetchPrizeList,
  fetchPrizeListFailed,
  fetchPrizeListSuccess,
  setFilterPrizeList,
  setFilterSearchDebounce,
} from "./managerPrizeSlice";

function* fetchDataPrizeList(action: PayloadAction<ListParams>) {
  try {
    const res: ListRespoinsePrizes<Prize> = yield call(managerPrizeApi.getPrizes, action.payload);
    if (res.responseCode === "00") {
      yield put(fetchPrizeListSuccess(res));
    } else {
      yield put(fetchPrizeListFailed());
    }
  } catch (error) {}
}

function* handleSearchDebounce(action: PayloadAction<ListParams>) {
  yield put(setFilterPrizeList(action.payload));
}

export default function* managerPrizeSaga() {
  yield takeLatest(fetchPrizeList, fetchDataPrizeList);

  yield debounce(500, setFilterSearchDebounce, handleSearchDebounce);
}
