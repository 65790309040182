import {yupResolver} from "@hookform/resolvers/yup";
import {Box, Button, Theme, Typography} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import {makeStyles} from "@material-ui/styles";
import managerClassApi from "api/managerClass";
import {useAppDispatch, useAppSelector} from "app/hooks";
import InputField from "components/FormFields/InputField";
import {SelectField} from "components/FormFields/SelectFiled";
import {
  fetchDataOptions,
  fetchListWorkTypes,
  selectListDepartment,
  selectListWorkTypes,
} from "features/Auth/options/optionsSlice";
import {ResponseStatus} from "models/common";
import {CreateClass} from "models/managerClass";
import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {fetchClassList, selectFilterClassList} from "../../managerClassSlice";
import * as yup from "yup";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

export default function AddClass() {
  const useStyles = makeStyles((theme: Theme) => ({
    title: {
      color: "#004673",
      fontSize: 20,
      fontWeight: 700,
      paddingBottom: 5,
      paddingTop: 5,
    },
    btn_add: {
      background: "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)",
      color: "#fff",
      borderRadius: 10,
      padding: "10px 15px",
      alignSelf: "center",
      fontSize: 13,
    },
  }));
  const {t} = useTranslation();
  const schema = yup.object().shape({
    name: yup.string().required("*Tên lớp học không được để trống"),
  });

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const filterClassList = useSelector(selectFilterClassList);
  const initialValues: CreateClass = {
    name: "",
    description: "",
  };
  const {handleSubmit, control, reset} = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    dispatch(fetchDataOptions());
  }, []);
  const handleSubmitForm = async (value: CreateClass) => {
    try {
      const res: ResponseStatus = await managerClassApi.createClass(value);
      console.log(res);
      if (res.responseCode === "00") {
        toast.success(`${t("managerClass.themclassthanhcong")}`);
        dispatch(fetchClassList(filterClassList));
        reset(initialValues);
      } else {
        if (res.responseCode === "30") {
          toast.error(`${t("managerClass.classdatontai")}`);
          throw new Error("Create class is failed");
        }
      }
    } catch (error) {}
  };
  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2" className={classes.title}>
            {t("managerClass.themclass")}
          </Typography>

          <Button
            startIcon={<AddIcon style={{fontSize: 20}} />}
            className={classes.btn_add}
            type="submit"
          >
            {t("button.addnew")}
          </Button>
        </Box>

        <Box display="flex">
          <Box ml={4} width="50%">
            <InputField control={control} label={t("managerClass.tenclass")} name="name" />
          </Box>
          <Box ml={4} width="50%">
            <InputField
              control={control}
              label={t("managerClass.mota")}
              name="description"
              multiline
              rows={3}
            />
          </Box>
        </Box>
      </Box>
    </form>
  );
}
