import {createTheme, responsiveFontSizes} from "@material-ui/core/styles";

const TOKEN = "TOKEN_STUDENT_GPET_EDU";
const TOKEN_ADMIN = "TOKEN_ADMIN_GPET_EDU";
const BASE_URL = "https://api-edu.gpet.vn/api/v1";

let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 769,
      lg: 1025,
      xl: 1450,
    },
  },
  typography: {
    fontFamily: "Be Vietnam Pro",
  },
  overrides: {
    MuiTableCell: {
      footer: {
        left: 0,
        bottom: 0,
        zIndex: 10,
        position: "sticky",
        backgroundColor: "#fff",
      },
    },
  },
});
theme = responsiveFontSizes(theme);
export {TOKEN, TOKEN_ADMIN, BASE_URL, theme};
