import {ListParams, ResponseStatus} from "models/common";
import {CreatePrize, ListRespoinsePrizes, Prize} from "models/managerPrize";
import axiosClient from "./axiosClient";

const managerPrizeApi = {
  getPrizes(params: ListParams): Promise<ListRespoinsePrizes<Prize>> {
    const url = "/prizes";
    return axiosClient.get(url, {params});
  },
  addPrize(value: CreatePrize): Promise<ResponseStatus> {
    const url = "/create-prize";
    return axiosClient.post(url, value);
  },
  getDetailPrize(id: string): Promise<Prize> {
    const url = `/prize/${id}`;
    return axiosClient.get(url);
  },
  updatePrize(value: CreatePrize, id: string): Promise<ResponseStatus> {
    const url = `/update-prize/${id}`;
    return axiosClient.post(url, value);
  },
  deletePrize(id: string): Promise<ResponseStatus> { 
    const url = `/prize/${id}`;
    return axiosClient.delete(url);
  },
};

export default managerPrizeApi;
