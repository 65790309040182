import {makeStyles, Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    backgroundColor: "#E58A25CC",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,

    "& .MuiTableCell-root": {
      borderRight: "1px solid #C6C6C6",
    },
  },
  header__title: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    color: "#fff",
    [theme.breakpoints?.down("xs")]: {
      fontSize: 11,
    },
  },
  header__filterMonth: {
    backgroundColor: "#fff",
    borderRadius: 30,
    width: "70%",
    margin: "0 auto",
    display: "flex",
    cursor: "pointer",
    padding: 5,
    [theme.breakpoints?.down("xs")]: {
      width: "100%",
    },
    "& >h6": {
      color: "#000",
      textAlign: "center",
      width: "90%",
    },
  },
  formControl: {
    width: "70%",
    margin: "0 auto",
    "& .MuiSelect-outlined": {
      padding: 5,
    },
  },
  table_container: {
    maxHeight: 500,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  table: {
    minWidth: 600,
  },
  status: {
    display: "grid",
    gridTemplateColumns: "0.5fr 1.5fr",
    textAlign: "left",
  },
  status_checkIcon: {
    color: "#0ab330",
  },
  status_clearIcon: {
    color: "#dc2138",
  },
  menu_listMonth: {
    height: 300,
    "& .MuiPaper-root": {
      borderRadius: "8px",
      backgroundColor: "#fff",
      boxShadow: "0px 6px 20px rgb(0 0 0 / 20%)",
      overflowX: "hidden",
      overflowY: "auto",
      padding: 10,
      marginTop: 10,
      width: "45%",
      [theme.breakpoints?.down("xs")]: {
        width: 150,
      },
    },
    "& .MuiList-root ": {
      width: "100%",
    },
    "& .MuiMenuItem-root": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
}));

export default useStyles;
