import managerGroupApi from "api/managerGroupApi";
import {GroupList, ListOptions, ListResponseHiarachy} from "models/managerGroup";
import {call, put, takeLatest} from "redux-saga/effects";
import {fetchGroupList, fetchGroupListFailed, fetchGroupListSuccess} from "./managerGroupSlice";

function* fetchDataGroupList() {
  try {
    const res: ListResponseHiarachy<GroupList> = yield call(managerGroupApi.getAll);
    if (res.responseCode === "00") {
      yield put(fetchGroupListSuccess(res));
    } else {
      yield put(fetchGroupListFailed());
      throw new Error("Fetch data group list is failed");
    }
  } catch (error) {}
}

export default function* managerGroupSaga() {
  yield takeLatest(fetchGroupList, fetchDataGroupList);
}
