import { ResponseStatus, ListParams } from "models/common";
import { IClass, CreateClass, ListResponseClass, ListResponseDetailClass } from "models/managerClass";
import axiosClient from "./axiosClient";

const managerClassApi = {
  getClasses(params?: ListParams): Promise<ListResponseClass<IClass>> {
    const url = "/class";
    return axiosClient.get(url, { params });
  },
  getDetailClass(id: string): Promise<ListResponseDetailClass> {
    const url = `/class/${id}`;
    return axiosClient.get(url)
  },
  createClass(value: CreateClass): Promise<ResponseStatus> {
    const url = "/class";
    return axiosClient.post(url, value);
  },
  updateClass(value: IClass, id: number): Promise<ResponseStatus> {
    const url = `/class`;
    return axiosClient.put(url, { ...value, classID: id });
  },

  deleteClass(id: number): Promise<ResponseStatus> {
    const url = `/class/${id}`;
    return axiosClient.delete(url);
  },
};

export default managerClassApi;
