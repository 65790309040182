import {yupResolver} from "@hookform/resolvers/yup";
import {Box, Button, Theme, Typography} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import {makeStyles} from "@material-ui/styles";
import managerCategoryApi from "api/managerCategory";
import {useAppDispatch, useAppSelector} from "app/hooks";
import InputField from "components/FormFields/InputField";
import {SelectField} from "components/FormFields/SelectFiled";
import {
  fetchDataOptions,
  fetchListWorkTypes,
  selectListDepartment,
  selectListWorkTypes,
} from "features/Auth/options/optionsSlice";
import {ResponseStatus} from "models/common";
import {CreateCategory} from "models/managerCategory";
import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {fetchCategoryList} from "../../managerCategorySlice";
import * as yup from "yup";
import {useTranslation} from "react-i18next";

export default function AddCategory() {
  const useStyles = makeStyles((theme: Theme) => ({
    title: {
      color: "#004673",
      fontSize: 20,
      fontWeight: 700,
      paddingBottom: 5,
      paddingTop: 5,
    },
    btn_add: {
      background: "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)",
      color: "#fff",
      borderRadius: 10,
      padding: "10px 15px",
      alignSelf: "center",
      fontSize: 13,
    },
  }));
  const {t} = useTranslation();
  const schema = yup.object().shape({
    lessionCategoryName: yup.string().required("*Tên chủ đề không được để trống"),
  });

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const listDepartment = useAppSelector(selectListDepartment);
  const initialValues: CreateCategory = {
    lessionCategoryName: "",
    workTypeId: "",
  };
  const {handleSubmit, control, reset} = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    dispatch(fetchDataOptions());
  }, []);
  const handleSubmitForm = async (value: CreateCategory) => {
    try {
      const res: ResponseStatus = await managerCategoryApi.createCategory(value);
      if (res.responseCode === "00") {
        toast.success(`${t("managerCategory.themchudethanhcong")}`);
        dispatch(fetchCategoryList());
        reset(initialValues);
      } else {
        if (res.responseCode === "30") {
          toast.error(`${t("managerCategory.chudedatontai")}`);
          throw new Error("Create category is failed");
        }
      }
    } catch (error) {}
  };
  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2" className={classes.title}>
            {t("managerCategory.themchude")}
          </Typography>

          <Button
            startIcon={<AddIcon style={{fontSize: 20}} />}
            className={classes.btn_add}
            type="submit"
          >
            {t("button.addnew")}
          </Button>
        </Box>

        <Box display="flex" alignItems="center">
          {/* <SelectField
            name="workTypeId"
            label={t("managerCategory.bophan")}
            control={control}
            options={listDepartment.map((department) => ({
              id: department.id,
              name: department.title,
            }))}
          /> */}

          <Box ml={4} width="100%">
            <InputField
              control={control}
              label={t("managerCategory.tenchude")}
              name="lessionCategoryName"
            />
          </Box>
        </Box>
      </Box>
    </form>
  );
}
