import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "app/store";
import {ListResponseCommunityPost, CommnunityPostList} from "models/managerCommunityPost";
import {ListParams, NewListParams} from "models/common";
export interface ManagerCommunityPostListState {
  communityPostList: CommnunityPostList[];
  loading: Boolean;
  params: NewListParams;
}
const initialState: ManagerCommunityPostListState = {
  communityPostList: [],
  loading: false,
  params: {
    status_type: "Published",
    sort_type: 1,
    page_index: 0,
    page_size: 10,
    category_id_list: "",
    content: "",
    owner_id: "",
  },
};
const managerCommunityPost = createSlice({
  initialState,
  name: "manager-community-post-slice",
  reducers: {
    fetchCommunityPostList(state, action: PayloadAction<any>) {
      if (action.payload.loading) {
        state.loading = false;
      } else {
        state.loading = true;
      }
    },
    fetchCommunityPostListSuccess(
      state,
      action: PayloadAction<ListResponseCommunityPost<CommnunityPostList>>
    ) {
      state.communityPostList = action.payload.postList;
      state.loading = false;
    },
    fetchCommunityPostListFailed(state) {
      state.loading = false;
    },
    handleFilterParams(state, action: PayloadAction<NewListParams>) {
      state.params = action.payload;
    },
  },
});
export const {
  fetchCommunityPostList,
  fetchCommunityPostListFailed,
  fetchCommunityPostListSuccess,
  handleFilterParams,
} = managerCommunityPost.actions;
export const selectCommunityPostList = (state: RootState) =>
  state.managerCommunityPostReducer.communityPostList;
export const loadingComunnityPost = (state: RootState) => state.managerCommunityPostReducer.loading;
export const selectParamsCommunityPostList = (state: RootState) =>
  state.managerCommunityPostReducer.params;

const managerCommunityPostReducer = managerCommunityPost.reducer;
export default managerCommunityPostReducer;
