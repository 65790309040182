import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "app/store";
import { CommunityCommentList,ListResponseCommunityPost } from "models/managerCommunityPost";
import {ListParams} from "models/common";

export interface ManagerCommunityCommentListState{
    communityCommentList:CommunityCommentList[];
    loading:Boolean;
}
const initialState:ManagerCommunityCommentListState={
    communityCommentList:[],
    loading:false,
}
const managerCommunityComment=createSlice({
    initialState,
    name:"manager-community-Comment-slice",
    reducers:{
        fetchCommunityCommentList(state, action: PayloadAction<ListParams>) {
            state.loading = true;
        },
        fetchCommunityCommentListSuccess(state, action: PayloadAction<ListResponseCommunityPost<CommunityCommentList>>) {   
            state.communityCommentList = action.payload.communityCommentList;
            state.loading = false;
        },
        fetchCommunityCommentListFailed(state) {
            state.loading = false;
        },
    }
})
export const {fetchCommunityCommentList,  fetchCommunityCommentListFailed,  fetchCommunityCommentListSuccess} =managerCommunityComment.actions;
export const selectCommunityCommentList = (state: RootState) => state.managerCommunityCommentReducer.communityCommentList;
const managerCommunityCommentReducer = managerCommunityComment.reducer;
export default managerCommunityCommentReducer;
