import {
  Checkbox,
  FormHelperText,
  Input,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import * as React from "react";
import {Control, useController} from "react-hook-form";

export interface SelectOption {
  id: number | string;
  title?: string;
  name?: string;
  [key: string]: any;
}

export interface SelectFieldProps {
  name: any;
  control: Control<any>;
  label?: string;
  disabled?: boolean;
  options: SelectOption[];
  borderRadius?: string;
  startIcon?: any;
  handleClick?: any;
  workType?: string;
  department?: string;
  required?: boolean;
  multiple?: boolean;
}

export function SelectField({
  name,
  control,
  label,
  disabled,
  options,
  startIcon,
  borderRadius,
  handleClick,
  department,
  required,
  multiple,
}: SelectFieldProps) {
  const useStyles = makeStyles((theme: Theme) => ({
    root_multiple: {
      padding: 6,
      backgroundColor: disabled ? "#f8f4f4" : "#fff",
      borderRadius: borderRadius,
      border: "1px solid #d2bcbc",
      [theme.breakpoints?.down("xs")]: {
        padding: 0,
      },
      "& .MuiSelect-select.MuiSelect-select": {
        margin: "5px",
      },
      "&:before": {
        display: "none",
      },
      "&:hover": {
        border: "1px solid #d2bcbc",
        borderRadius: "4px",
      },
    },
    root: {
      padding: 6,
      backgroundColor: disabled ? "#f8f4f4" : "#fff",
      borderRadius: borderRadius,
      border: "1px solid #d2bcbc",
      [theme.breakpoints?.down("xs")]: {
        padding: 0,
      },
    },
    label_multiple: {
      padding: 5,
      color: "#999999",
      fontSize: "0.9rem",
      [theme.breakpoints?.down("md")]: {
        fontSize: 12,
      },
      [theme.breakpoints?.down("xs")]: {
        fontSize: 9,
      },
    },
    label: {
      padding: "8px 5px",
      color: "#999999",
      fontSize: "0.9rem",
      [theme.breakpoints?.down("md")]: {
        fontSize: 12,
      },
      [theme.breakpoints?.down("xs")]: {
        fontSize: 9,
      },
    },
    error: {
      fontSize: 10,
      padding: 0,
    },
    options: {
      fontSize: 14,
      [theme.breakpoints?.down("md")]: {
        fontSize: 12,
        margin: 0,
      },
    },
  }));
  const classes = useStyles();
  const {
    field: {value, onChange, onBlur},
    fieldState: {invalid, error},
  } = useController({
    name,
    control,
  });

  if (multiple) {
    return (
      <FormControl
        fullWidth
        variant="outlined"
        margin="dense"
        size="small"
        disabled={disabled}
        error={invalid}
        required={required}
      >
        <InputLabel id={`${name}_label`} className={classes.label_multiple} style={{marginTop: 20}}>
          {label}
        </InputLabel>
        <Select
          labelId={`${name}_label`}
          defaultValue={[]}
          onChange={onChange}
          onBlur={onBlur}
          input={<Input />}
          value={value}
          renderValue={(selected) => (selected as string[]).join(", ")}
          label={label}
          className={classes.root_multiple}
          style={{borderColor: "rgba(0, 0, 0, 0.23)"}}
          multiple={multiple}
          startAdornment={startIcon}
          inputProps={{
            name: `${name}`,
            id: `${name}_label`,
          }}
        >
          {options.map((item: any) => (
            <MenuItem
              key={item.id}
              value={item.name}
              onClick={() => {
                handleClick && handleClick(item);
              }}
            >
              <Checkbox checked={value.indexOf(item.name) > -1} />
              <ListItemText primary={item.name} />

              {item.class && <ListItemText style={{textAlign: "right"}} primary={item.class} />}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText className={classes.error}>{error?.message}</FormHelperText>
      </FormControl>
    );
  }

  return (
    <FormControl
      fullWidth
      variant="outlined"
      margin="dense"
      size="small"
      disabled={disabled}
      error={invalid}
      required={required}
    >
      <InputLabel id={`${name}_label`} className={classes.label}>
        {label}
      </InputLabel>
      <Select
        labelId={`${name}_label`}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        label={label}
        className={classes.root}
        multiple={multiple}
        startAdornment={startIcon}
        inputProps={{
          name: `${name}`,
          id: `${name}_label`,
        }}
      >
        {options?.map((option) => (
          <MenuItem
            key={option.id}
            value={option?.id}
            onClick={() => {
              handleClick && handleClick(option);
            }}
          >
            {department === "department" ? (
              <span className={classes.options}>{option?.title}</span>
            ) : (
              <span className={classes.options}>{option?.name}</span>
            )}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText className={classes.error}>{error?.message}</FormHelperText>
    </FormControl>
  );
}
