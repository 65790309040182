import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { InfoUser } from "models/auth";
import { ListParams } from "models/common";
import { ListResponseUser } from "models/managerUser";

export interface ManagerAdminState {
  userList: {
    content: InfoUser[];
    [key: string]: any;
  };
  loading: Boolean;
  filter: ListParams;
  filterInreview: ListParams;
}
const initialState: ManagerAdminState = {
  userList: {
    content: [],
  },
  loading: false,
  filter: {
    page: 0,
    size: 10,
    statusName: 'Published',
    name: "",
    sortType: 2
  },
  filterInreview: {
    page: 0,
    size: 10,
    newProvinceId: "",
    workTypeId: "",
    areaId: "",
    name: "",
    statusName: "InReview",
    departmentId: ""
  },
};

const managerAdminSlice = createSlice({
  initialState,
  name: "manager-admin-slice",
  reducers: {
    fetchUserList(state, action: PayloadAction<ListParams>) {
      state.loading = true;
    },
    fetchUserListSuccess(state, action: PayloadAction<ListResponseUser<InfoUser>>) {
      state.userList = action.payload.users;
      state.loading = false;
    },
    fetchUserListFailed(state) {
      state.loading = false;
    },

    setFilterUserList(state, action: PayloadAction<ListParams>) {
      state.filter = action.payload;
    },
    setFilterInreviewUserList(state, action: PayloadAction<ListParams>) {
      state.filterInreview = action.payload;
    },
    setFilterSearchDebounce(state, action: PayloadAction<ListParams>) { },
  },
});

export const {
  fetchUserList,
  fetchUserListSuccess,
  fetchUserListFailed,
  setFilterUserList,
  setFilterInreviewUserList,
  setFilterSearchDebounce,
} = managerAdminSlice.actions;
export const selectUserList = (state: RootState) => state.managerAdminReducer.userList;
export const selectFilterUserList = (state: RootState) => state.managerAdminReducer.filter;
export const selectFilterInreviewUserList = (state: RootState) => state.managerAdminReducer.filterInreview;
const managerAdminReducer = managerAdminSlice.reducer;
export default managerAdminReducer;
