import {makeStyles, Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "50px 25px",
    position: "relative",
    [theme.breakpoints?.down("md")]: {
      padding: 0,
    },
  },
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  rootMobile: {
    [theme.breakpoints?.down("xs")]: {
      display: "block",
    },
  },
  icon: {
    width: 25,
    height: 25,
    objectFit: "cover",
    marginRight: 8,
    cursor: "pointer",
  },
  icon_checked: {
    color: "#137bbd",
  },
  content__status: {
    minWidth: 250,
    [theme.breakpoints?.down("xs")]: {
      padding: "10px 0",
    },
  },
  number_status: {
    fontWeight: 500,
    fontSize: 15,
    paddingTop: 5,
  },
  lesson__category: {
    fontWeight: 700,
    fontSize: 26,
    marginTop: 15,
    color: "#CE6C00",
    [theme.breakpoints?.down("sm")]: {
      fontSize: 18,
      marginTop: 5,
    },
  },
  lesson__title: {
    fontWeight: 700,
    fontSize: 26,
    marginTop: 10,
    [theme.breakpoints?.down("sm")]: {
      fontSize: 18,
      marginTop: 5,
    },
  },
  lesson__date: {
    color: "#4F4F4F",
    fontSize: 15,
    [theme.breakpoints?.down("sm")]: {
      fontSize: 13,
    },
  },
  lesson__description: {
    padding: "5px 0",
    fontSize: 17,
    fontWeight: 700,
    "&>span": {
      fontSize: 15,
    },
    [theme.breakpoints?.down("sm")]: {
      fontSize: 13,
    },
  },

  content: {
    padding: 15,
    paddingBottom: 10,
    [theme.breakpoints?.down("sm")]: {
      paddingTop: 10,
    },
  },

  player_wrapper: {
    position: "relative",
    paddingTop: " 56.25% ",
  },
  react_player: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  lesson__isWatched: {
    display: "flex",
    alignItems: "center",
    "& >img": {
      width: 20,
      height: 20,
      marginRight: 10,
    },
    "& >h6": {
      fontWeight: 500,
      fontSize: 16,
    },
  },

  showMore: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 0px 4px rgba(168, 168, 168, 0.4)",
    marginTop: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 8,
    cursor: "pointer",
    "& >h6": {
      color: "#4F4F4F",
      fontSize: 15,
    },
  },

  icon_play: {
    padding: 5,
    backgroundColor: "#fff",
    width: 50,
    height: 50,
    borderRadius: "50%",
    color: "#f9ab00",
    transition: "ease-in 0.5s",
    border: "8px solid #999",
    zIndex: 9,
    "&:hover": {
      backgroundColor: "#f9ab00",
      color: "#fff",
    },
  },
  bg_lesson: {
    padding: 15,
    paddingRight: 0,
    "& >img": {
      width: "100%",
      height: "100%",
    },
  },
  loading: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    "& >img": {
      width: 100,
      height: 100,
    },
  },
  thumbnail_video: {
    width: 200,
    height: 120,
    objectFit: "cover",
  },
}));

export default useStyles;
