import {Box, Grid, makeStyles, Theme, Typography} from "@material-ui/core";
import ICON_BOOK from "assets/img/icon_book.png";
import React, {useEffect, useState} from "react";
import ICON_TASK_CALENDAR from "assets/img/task_calendar.png";
import ICON_TASK_DONE from "assets/img/task_done.png";
import ICON_TASK_MISSED from "assets/img/task_missed.png";
import {StatisticHomePage} from "models/auth";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {dateTimeToFormatHHMM} from "utils/dateTimeFormat";

export interface StatisticsProps {
  statistic: StatisticHomePage | undefined;
}

export default function Statistics({statistic}: StatisticsProps) {
  const useStyles = makeStyles((theme: Theme) => ({
    content: {
      borderRadius: 10,
      paddingBottom: 0,
      height: "100%",
      [theme.breakpoints?.down("xs")]: {
        marginTop: 10,
        marginBottom: 10,
      },
    },
    wrapper: {
      backgroundColor: "#ffffff",
      boxShadow: "0px 0px 6px rgba(168, 168, 170, 0.3), 0px 2px 4px rgba(168, 168, 168, 0.3)",
      borderRadius: 10,

      height: 150,
      // marginRight: 8,
      [theme.breakpoints?.up("sm")]: {
        // height: "100%",
      },
      "& >h6": {
        textAlign: "center",
        color: "#494949",
        padding: "10px 5px",
        fontSize: "0.6em",
        fontWeight: 700,
      },
    },
    content__totalLesson: {
      textAlign: "center",
      borderTop: "1px solid #DB2139",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "50%",
      position: "relative",
      paddingTop: 5,
      [theme.breakpoints?.up("xl")]: {
        height: "70%",
      },
      "&>p": {
        fontSize: 10,
        color: "#DB2139",
      },
      "& > span": {
        color: "#DB2139",
        fontSize: 25,
        marginRight: 5,
        marginLeft: 5,
        [theme.breakpoints?.up("xl")]: {
          fontSize: 30,
        },
      },
      "&> i": {
        fontSize: 13,
        color: "#707070",
        [theme.breakpoints?.up("xl")]: {
          fontSize: 15,
        },
        "& >p": {
          color: "#dc2138",
          marginBottom: 0,
          [theme.breakpoints?.up("xl")]: {
            marginBottom: 10,
          },
        },
        "& >span": {
          fontSize: 30,
          color: "#DB2139",
        },
      },
    },
    icon_statistic: {
      position: "absolute",
      right: 10,
      bottom: 0,
      width: 15,
      height: 15,
      objectFit: "cover",
      [theme.breakpoints?.up("xl")]: {
        right: 20,
        bottom: 15,
        width: 20,
        height: 20,
      },
    },
  }));
  const classes = useStyles();
  const [difference, setDifference] = useState<any>();
  const {t} = useTranslation();
  useEffect(() => {
    const currentDay = new Date().getTime();
    const examDay = statistic && new Date(statistic.nextExam).getTime();
    const difference = moment.duration(moment(examDay).diff(moment(currentDay)));
    setDifference(difference);
  }, [statistic]);

  const handleDateChange = () => {
    if (difference?.days() > 0) {
      return (
        <>
          {t("home.statistic.conlai")}{" "}
          <Typography variant="subtitle2" component="span">
            {difference?.days()}
          </Typography>{" "}
          <Typography variant="body2" component="i">
            {" "}
            {t("home.statistic.ngay")}
          </Typography>
        </>
      );
    } else if (difference?.days() === 0 && difference?.hours() > 0) {
      return (
        <>
          {t("home.statistic.conlai")}{" "}
          <Typography variant="subtitle2" component="span">
            {difference?.hours()}
          </Typography>{" "}
          <Typography variant="body2" component="i">
            {" "}
            {t("home.statistic.gio")}
          </Typography>
        </>
      );
    } else if (difference?.days() === 0 && difference?.hours() === 0 && difference?.minutes() > 0) {
      return (
        <>
          {t("home.statistic.conlai")}{" "}
          <Typography variant="subtitle2" component="span">
            {difference?.minutes()}
          </Typography>{" "}
          <Typography variant="body2" component="i">
            {" "}
            {t("home.statistic.phut")}
          </Typography>
        </>
      );
    } else {
      return (
        <>
          <Typography variant="subtitle2" component="span">
            0{" "}
          </Typography>{" "}
          <Typography variant="body2" component="i">
            {" "}
            {t("home.statistic.bai")}
          </Typography>
        </>
      );
    }
  };
  return (
    <Box className={classes.content}>
      <Grid container>
        <Grid item xs={6}>
          <Box className={classes.wrapper} style={{marginRight: 5}}>
            <Typography variant="subtitle2">{t("home.statistic.baihoctrongthang")}</Typography>
            <Box className={classes.content__totalLesson}>
              <Typography variant="body2" component="span">
                {statistic?.noLessionInMonth}
              </Typography>
              <Typography variant="body2" component="i">
                {t("home.statistic.bai")}
              </Typography>
              <img src={ICON_BOOK} alt="icon_book" className={classes.icon_statistic} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box className={classes.wrapper}>
            <Typography variant="subtitle2">{t("home.statistic.sobaidathi")}</Typography>
            <Box className={classes.content__totalLesson}>
              <Typography variant="body2" component="span">
                {statistic?.noExams}
              </Typography>
              <Typography variant="body2" component="i">
                {t("home.statistic.bai")}
              </Typography>

              <img src={ICON_TASK_DONE} alt="icon_task_done" className={classes.icon_statistic} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box className={classes.wrapper} style={{marginRight: 5, marginTop: 10}}>
            <Typography variant="subtitle2">{t("home.statistic.sobaidabolo")}</Typography>

            <Box className={classes.content__totalLesson}>
              <Typography variant="body2" component="span">
                {statistic?.noLessionMissed}
              </Typography>
              <Typography variant="body2" component="i">
                {t("home.statistic.bai")}
              </Typography>

              <img
                src={ICON_TASK_MISSED}
                alt="icon_task_missed"
                className={classes.icon_statistic}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box className={classes.wrapper} style={{marginTop: 10}}>
            <Typography variant="subtitle2">{t("home.statistic.lichkiemtrasaptoi")}</Typography>
            <Box className={classes.content__totalLesson}>
              {handleDateChange()}
              <Typography variant="body2" component="i">
                {/* {handleDateChange()} */}
              </Typography>
              <img
                src={ICON_TASK_CALENDAR}
                alt="icon_task_calendar"
                className={classes.icon_statistic}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
