import {PayloadAction} from "@reduxjs/toolkit";
import managerMagazineApi from "api/managerMagazine";
import {NewListParams} from "models/common";
import {MagazineList, ResponseMagazine} from "models/managerMagazine";
import {call, debounce, put, takeLatest} from "redux-saga/effects";
import {
  fetchListMagazine,
  fetchListMagazineFailed,
  fetchListMagazineSuccess,
  fetchListTrendingMagazine,
  fetchListTrendingMagazineFailed,
  fetchListTrendingMagazineSuccess,
  setFilterMagazineNews,
  setFilterSearchDebounce,
} from "./managerMagazineSlice";

function* handleFetchDataListMagazine(action: PayloadAction<NewListParams>) {
  try {
    const res: ResponseMagazine<MagazineList> = yield call(
      managerMagazineApi.getMagazineList,
      action.payload
    );
    if (res.responseCode === "00") {
      yield put(fetchListMagazineSuccess(res));
    } else {
      yield put(fetchListMagazineFailed());
    }
  } catch (error) {}
}

function* handleFetchDataListTrendingMagazine(action: PayloadAction<NewListParams>) {
  try {
    const res: ResponseMagazine<MagazineList> = yield call(
      managerMagazineApi.getMagazineList,
      action.payload
    );
    if (res.responseCode === "00") {
      yield put(fetchListTrendingMagazineSuccess(res));
    } else {
      yield put(fetchListTrendingMagazineFailed());
    }
  } catch (error) {}
}

function* handleSearchDebounce(action: PayloadAction<NewListParams>) {
  yield put(setFilterMagazineNews(action.payload));
}
export default function* managerMagazineSaga() {
  yield takeLatest(fetchListMagazine, handleFetchDataListMagazine);
  yield takeLatest(fetchListTrendingMagazine, handleFetchDataListTrendingMagazine);

  yield debounce(500, setFilterSearchDebounce, handleSearchDebounce);
}
