import managerCategoryApi from "api/managerCategory";
import {Category, ListResponseCategory} from "models/managerCategory";
import {call, put, takeLatest} from "redux-saga/effects";
import {
  fetchCategoryList,
  fetchCategoryListFailed,
  fetchCategoryListSuccess,
} from "./managerCategorySlice";

function* fetchDataCategoryList() {
  try {
    const res: ListResponseCategory<Category> = yield call(managerCategoryApi.getCategories);
    if (res.responseCode === "00") {
      yield put(fetchCategoryListSuccess(res));
    } else {
      yield put(fetchCategoryListFailed());
    }
  } catch (error) {}
}

export default function* managerCategorySaga() {
  yield takeLatest(fetchCategoryList, fetchDataCategoryList);
}
