import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "app/store";
import {ListParams} from "models/common";
import {
  ExamNoQuestion,
  ListExamNoQuestionResponse,
  ListTest,
  ListTestByCodeExamResponse,
} from "models/managerTest";

export interface ListExamStudent {
  listExamStudent: {
    basicExamResponses: {
      content: ExamNoQuestion[];
      [key: string]: any;
    };
  };

  listTestOldStudent: {
    submissionDetails: {
      content: ListTest[];
      [key: string]: any;
    };
  };
  loading: Boolean;
  filter: ListParams;
}

const initialState: ListExamStudent = {
  listExamStudent: {
    basicExamResponses: {
      content: [],
    },
  },
  listTestOldStudent: {
    submissionDetails: {
      content: [],
    },
  },
  loading: false,
  filter: {
    page: 0,
    size: 10,
  },
};

const listExamStudentSlice = createSlice({
  initialState,
  name: "list-exam-student-slice",
  reducers: {
    fetchListExamStudent(state, action: PayloadAction<ListParams>) {
      state.loading = true;
    },
    fetchListExamStudentSuccess(
      state,
      action: PayloadAction<ListExamNoQuestionResponse<ExamNoQuestion>>
    ) {
      state.loading = false;
      state.listExamStudent = action.payload;
    },
    fetchListExamStudentFailed(state) {
      state.loading = false;
    },

    // fetch list test old
    fetchListTestOldStudent(state, action: PayloadAction<ListParams>) {
      state.loading = true;
    },
    fetchListTestOldStudentSuccess(
      state,
      action: PayloadAction<ListTestByCodeExamResponse<ListTest>>
    ) {
      state.loading = false;
      state.listTestOldStudent = action.payload;
    },
    fetchListTestOldStudentFailed(state) {
      state.loading = false;
    },

    setFilterListExam(state, action: PayloadAction<ListParams>) {
      state.filter = action.payload;
    },
  },
});

export const {
  fetchListExamStudent,
  fetchListExamStudentSuccess,
  fetchListExamStudentFailed,
  setFilterListExam,
  fetchListTestOldStudent,
  fetchListTestOldStudentSuccess,
  fetchListTestOldStudentFailed,
} = listExamStudentSlice.actions;

export const SelectListExamStudent = (state: RootState) =>
  state.listExamStudentNoQuestionReducer.listExamStudent;
export const SelectFilterListExamStudent = (state: RootState) =>
  state.listExamStudentNoQuestionReducer.filter;
export const selectLoadingListExamStudent = (state: RootState) =>
  state.listExamStudentNoQuestionReducer.loading;

export const SelectListTestOldStudent = (state: RootState) =>
  state.listExamStudentNoQuestionReducer.listTestOldStudent;
const listExamStudentNoQuestionReducer = listExamStudentSlice.reducer;
export default listExamStudentNoQuestionReducer;
