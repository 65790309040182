import {makeStyles, Theme} from "@material-ui/core";

type Props = {
  background: string;
};

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  background: {
    backgroundImage: (props: Props) => `url(${props.background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    minHeight: "100vh",
    width: "100%",
  },
  logo: {
    textAlign: "center",
    "& >img": {
      height: "auto",
      zIndex: 9,
      objectFit: "cover",
    },
  },
  container: {
    width: "75%",
    border: "1px solid #DB2139",
    margin: "0 auto",
    height: "100%",
    borderRadius: 25,
    [theme.breakpoints?.down("md")]: {
      width: "85%",
    },
    [theme.breakpoints?.down("xs")]: {
      width: "94%",
    },
  },
  content: {
    width: "95%",
    margin: "30px auto",
    backgroundColor: "rgba(255,255,255,0.5)",
    boxShadow: "0px 5px 15px 0px #A8A8A8",
    borderRadius: 25,
    height: "100%",
    [theme.breakpoints?.down("md")]: {
      width: "90%",
    },
    [theme.breakpoints?.down("xs")]: {
      borderRadius: 15,
      margin: "15px auto",
    },
  },
  form: {
    padding: "30px 50px",
    [theme.breakpoints?.down("md")]: {
      padding: "20px",
    },
    [theme.breakpoints?.down("xs")]: {
      padding: "0 10px",
      paddingTop: 10,
    },
  },
  form__title: {
    [theme.breakpoints?.down("xs")]: {
      fontSize: 14,
    },
    "& > span": {
      color: "#157BBC",
      cursor: "pointer",
      [theme.breakpoints?.down("xs")]: {
        fontSize: 14,
      },
    },
  },
  form__content: {
    marginTop: 10,
    "& .MuiGrid-item": {
      padding: "0px 20px",
      alignSelf: "center",
      [theme.breakpoints?.down("xs")]: {
        padding: "0 3px",
      },
    },
  },
  btn_submit: {
    backgroundColor: "#DB2139",
    borderRadius: 40,
    padding: "10px 50px",
    fontSize: 20,
    marginTop: 25,
    "&:hover": {
      backgroundColor: "#157BBC",
    },
    [theme.breakpoints?.down("md")]: {
      fontSize: 15,
    },
    [theme.breakpoints?.down("xs")]: {
      fontSize: 12,
      margin: "10px 0",
    },
  },
  root: {
    padding: 6,
    backgroundColor: "#fff",
    borderRadius: 10,
    [theme.breakpoints?.down("xs")]: {
      padding: 0,
      borderRadius: 5,
    },
  },
  error: {
    fontSize: 10,
    padding: 0,
  },
  label: {
    padding: 5,
    color: "#999999",
    [theme.breakpoints?.down("md")]: {
      fontSize: 12,
    },
    [theme.breakpoints?.down("xs")]: {
      fontSize: 9,
    },
  },
  options: {
    fontSize: 14,
    [theme.breakpoints?.down("md")]: {
      fontSize: 12,
      margin: 0,
    },
  },
}));

export default useStyles;
