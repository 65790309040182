import {yupResolver} from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  makeStyles,
  Menu,
  MenuItem,
  Select,
  Theme,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import managerFilesApi from "api/managerFiles";
import managerGroupApi from "api/managerGroupApi";
import managerLessonApi from "api/managerLesson";
import {useAppDispatch, useAppSelector} from "app/hooks";
import EMOJI from "assets/img/emoji.png";
import ICON_VIDEO from "assets/img/icon_video.png";
import axios, {AxiosResponse} from "axios";
import CheckboxField from "components/FormFields/CheckboxField";
import InputField from "components/FormFields/InputField";
import {SelectField} from "components/FormFields/SelectFiled";
import {BASE_URL, TOKEN} from "constants/config";
import Picker, {SKIN_TONE_MEDIUM_DARK} from "emoji-picker-react";
import {
  fetchCategoryList,
  selectCategoryList,
} from "features/Admin/pages/ManagerCategory/managerCategorySlice";
import {
  fetchDataOptions,
  fetchListWorkTypes,
  selectListArea,
  selectListDepartment,
  selectListWorkTypes,
} from "features/Auth/options/optionsSlice";
import {ListOptions, ListResponseOptions} from "models/managerGroup";
import {CreateLesson} from "models/managerLesson";
import moment from "moment";
import React, {Fragment, useEffect, useState} from "react";
import Dropzone from "react-dropzone";
import {useController, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import ReactPlayer from "react-player";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import * as yup from "yup";

const schemaDraft = yup.object().shape({});

export default function AddLesson() {
  const useStyles = makeStyles((theme: any) => ({
    loading: {
      zIndex: 99,
      backgroundColor: "rgba(255,255,255,0.8)",
      width: "100%",
      height: "100%",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      "& >div": {
        position: "absolute",
        top: "60%",
        left: "50%",
        transform: "translate(-60%,-50%)",
        "& .MuiCircularProgress-root": {
          display: "flex",
          margin: "0 auto",
        },
      },
    },
    percentage: {
      color: "#137bbd",
      fontSize: 18,
      marginBottom: 15,
    },
    root: {
      display: "flex",
      alignItems: "center",
      marginBottom: 5,
      width: "100%",
    },
    header: {
      color: "#004673",
      fontSize: 25,
      fontWeight: 700,
      marginBottom: 10,
    },
    title: {
      fontSize: 15,
      fontWeight: 600,
      width: "14%",
      [theme.breakpoints?.up("xl")]: {
        // fontSize: 15,
        width: "10%",
      },
    },
    selectField: {
      padding: 6,
      backgroundColor: disableArea ? "#f8f4f4" : "#fff",
    },
    label: {
      padding: 5,
      color: "#999999",
      fontSize: "0.9rem",
      [theme.breakpoints?.down("md")]: {
        fontSize: 12,
      },
      [theme.breakpoints?.down("xs")]: {
        fontSize: 9,
      },
    },

    btn_create: {
      background: "linear-gradient(0deg, #10BCA7 0%, rgba(255, 239, 156, 0.9) 165.52%)",
      color: "#fff",
      borderRadius: 10,
      padding: "10px 15px",
      marginLeft: 10,
    },
    btn_draft: {
      background: "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)",
      color: "#fff",
      borderRadius: 10,
      padding: "10px 15px",
    },
    btn_cancel: {
      background: "linear-gradient(0deg, #FD5468 0%, rgba(255, 193, 200, 0.9) 169.32%)",
      color: "#fff",
      borderRadius: 10,
      padding: "5px 15px",
      marginTop: 30,
    },
    icon_emoji: {
      position: "absolute",
      bottom: 10,
      right: 10,
      "& >span >img": {
        width: 25,
        height: 25,
      },
    },
    form__video__wrapper: {
      backgroundColor: "#fff",
      width: "100%",
      height: 400,
      boxShadow: "0px 0px 4px rgba(168, 168, 168, 0.25)",
      borderRadius: 8,
      "&:hover": {
        backgroundColor: "#ECECEC",
        transition: "linear 0.3s all",
      },
      "& >input ": {
        display: "none",
      },
    },
    form__video__iconClose: {
      position: "absolute",
      right: 10,
      top: 10,
      zIndex: 99,
      backgroundColor: "#FFF",
      borderRadius: "50%",
      width: 25,
      height: 25,
      cursor: "pointer",
    },
    form__video__content: {
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      height: "100%",
      "& > span": {
        color: "#AAAAAA",
        fontSize: 13,
      },
    },
  }));

  const {t} = useTranslation();
  const schema = yup.object().shape({
    publishDate: yup.string().when("postNow", {
      is: false,
      then: yup.string().required(`${t("managerLesson.vuilongchonngaydang")}`),
    }),
    publishTime: yup.string().when("postNow", {
      is: false,
      then: yup.string().required(`${t("managerLesson.vuilongchongiodang")}`),
    }),
    lessionCategoryId: yup.string().required(`${t("managerLesson.vuilongchonchude")}`),
    lessionDescription: yup.string().required(`${t("managerLesson.vuilongchonmota")}`),
    lessionTitle: yup.string().required(`${t("managerLesson.vuilongnhaptenbaihoc")}`),
  });
  const listArea = useAppSelector(selectListArea);
  const listCategory = useAppSelector(selectCategoryList);
  const listWorkTypes = useAppSelector(selectListWorkTypes);
  const [isPostNow, setIsPostNow] = useState(false);
  const [isAll, setIsAll] = useState(true);
  const [disableArea, setDisableArea] = useState(false);
  const [disableDate, setDisableDate] = useState(false);
  const [disableTime, setDisableTime] = useState(false);
  const [disableWorkType, setDisableWorkType] = useState(false);

  const [draft, setDraft] = useState(false);
  const [errorVideo, setErrorVideo] = useState("");
  const [video, setVideo] = useState("");
  const [fileVideo, setFileVideo] = useState("");
  const [filePPTX, setFilePPTX] = useState<any>("");
  const [idFilePTTX, setIdFilePPTX] = useState<any>("");
  const navigate = useNavigate();
  const [openEmoji, setOpenEmoji] = useState<null | HTMLElement>(null);
  const [loading, setLoading] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const listDepartment = useAppSelector(selectListDepartment);
  const [idDepartment, setIdDepartment] = useState({
    id: 0,
    name: "",
  });

  // const cancelFileUpload = useRef(null);

  const handleOpenEmoji = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenEmoji(event.currentTarget);
  };

  const initialValues: CreateLesson = {
    postNow: false,
    isAll: false,
    publishDate: "",
    publishTime: "",
    hiddenDate: "",
    hiddenTime: "",
    lessionCategoryId: "",
    lessionDescription: "",
    lessionTitle: "",
    areaId: "",
    workTypeId: "",
    isDraft: "N",
    departmentId: "",
  };
  const {control, handleSubmit, setValue, getValues} = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(draft ? schemaDraft : schema),
  });
  const {
    field: {value, onChange, onBlur},
    fieldState: {invalid, error},
  } = useController({
    name: "areaId",
    control,
  });

  const onEmojiClick = (event: any, emojiObject: any) => {
    setValue("lessionDescription", getValues().lessionDescription + emojiObject.emoji);
  };
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchDataOptions());
    dispatch(fetchCategoryList());
  }, []);

  useEffect(() => {
    if (isAll) {
      setValue("areaId", "");
      setValue("workTypeId", "");
      setValue("departmentId", "");
      setDisableArea(true);
      setDisableWorkType(true);
    } else {
      setDisableArea(false);
      setDisableWorkType(false);
    }
    if (isPostNow) {
      setValue("publishDate", "");
      setValue("publishTime", "");
      setDisableTime(true);
      setDisableDate(true);
    } else {
      setDisableTime(false);
      setDisableDate(false);
    }
  }, [isAll, isPostNow]);
  const classes = useStyles();
  const token = localStorage.getItem(TOKEN);

  const handleGetListWorkType = async (option: ListOptions) => {
    try {
      const res: ListResponseOptions<ListOptions> = await managerGroupApi.getWorkTypeByArea(
        option?.id
      );

      if (res.responseCode === "00") {
        dispatch(fetchListWorkTypes(res));
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (video !== "") {
      setErrorVideo("");
    }
  }, [video]);

  const handleSubmitForm = async (value: CreateLesson) => {
    if (video === "" && !draft && filePPTX === "") {
      setErrorVideo(`${t("managerLesson.vuilongchonvideobaihoc")}`);
      return;
    }
    const newValue = {
      ...value,
      isDraft: draft ? "Y" : "N",
      isAll: value.isAll ? "Y" : "N",
      publishDate: value.postNow
        ? moment(new Date()).format("YYYY/MM/DD").split("/").join("")
        : value.publishDate.split("-").join(""),
      publishTime: value.postNow
        ? moment(new Date()).format("HH/mm").split("/").join("")
        : value.publishTime.split(":").join(""),
      hiddenDate: value.hiddenDate?.split("-").join(""),
      hiddenTime: value.hiddenTime?.split(":").join(""),
    };

    const formData = new FormData();
    formData.append("publishDate", newValue.publishDate);
    formData.append("publishTime", newValue.publishTime);
    formData.append("expiryDate", newValue.hiddenDate);
    formData.append("expiryTime", newValue.hiddenTime);
    formData.append("isAll", newValue.isAll);
    formData.append("isDraft", newValue.isDraft);
    formData.append("lessionCategoryId", newValue.lessionCategoryId);
    formData.append("lessionDescription", newValue.lessionDescription);
    formData.append("areaId", String(newValue.areaId));
    formData.append("workTypeId", String(newValue.workTypeId));
    formData.append("departmentId", String(newValue.departmentId));

    fileVideo && formData.append("video", fileVideo);
    idFilePTTX.length > 0 && formData.append("powerPointFileIDList", idFilePTTX);

    formData.append("lessionTitle", newValue.lessionTitle);
    setLoading(true);
    try {
      const res: AxiosResponse = await axios({
        method: "POST",
        url: `${BASE_URL}/lession/createLession`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
        onUploadProgress: (progressEvent) => {
          let percent = Math.round((progressEvent.loaded * 100) / progressEvent?.total);
          if (percent < 100) {
            setUploadPercentage(percent);
          }
        },
        // cancelToken: new CancelToken((cancel: any) => (cancelFileUpload.current = cancel)),
      });
      if (res.data.responseCode === "00") {
        setUploadPercentage(100);
        setTimeout(() => {
          setUploadPercentage(0);
        }, 1000);
        toast.success(`${t("managerLesson.taomoibaihocthanhcong")}`);
        navigate("/admin/manager-lesson");
        setLoading(false);
      } else {
        setUploadPercentage(0);
        toast.error(`${t("managerLesson.taobaihocthatbai")}`);
        setLoading(false);
        throw new Error("Create lesson is failed");
      }
    } catch (error) {}
  };
  const handleChangeFile = async (acceptedFiles: any) => {
    if (acceptedFiles[0].type !== "video/mp4" && !acceptedFiles[0].path.includes(".pptx")) {
      setErrorVideo(`${t("managerLesson.tepkhongdungdinhdang")}`);
      return;
    }
    if (acceptedFiles[0].size > 500e7) {
      setErrorVideo(`${t("managerLesson.dungluongtoida")}`);
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(acceptedFiles[0]);
    const formData = new FormData();
    if (acceptedFiles[0].type === "video/mp4") {
      setFileVideo(acceptedFiles[0]);
      reader.onload = (e: any) => {
        setVideo(URL.createObjectURL(acceptedFiles[0]));
      };
    } else {
      try {
        acceptedFiles.forEach((acceptedFile: any) => {
          formData.append("attachments", acceptedFile);
        });

        const data = await managerFilesApi.uploadFiles(formData);
        setFilePPTX(data.body.attachmentList[0]);
        setIdFilePPTX(data.body.attachmentList[0].id);
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <Box style={{position: "relative"}}>
      {loading && (
        <Box className={classes.loading}>
          <Box>
            <Typography variant="subtitle2" className={classes.percentage}>
              {t("managerLesson.dangtai")}
              {uploadPercentage}%
            </Typography>
            {/* <img src={LOADING} alt="LOADING" /> */}
            <CircularProgress style={{width: 50, height: 50}} />
            {/* <Box textAlign="center">
              <Button className={classes.btn_cancel}>Hủy bỏ</Button>
            </Box> */}
          </Box>
        </Box>
      )}
      <Typography variant="subtitle2" className={classes.header}>
        {t("button.addnew").toUpperCase()}
      </Typography>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Box className={classes.root}>
          <Box className={classes.root} width="100%">
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerLesson.lichdang")}
            </Typography>
            <Box className={classes.root}>
              <Box width="30%">
                <InputField
                  control={control}
                  type="date"
                  label=""
                  name="publishDate"
                  disabled={disableDate}
                />
              </Box>
              <Box ml={2} mr={4} width="30%">
                <InputField
                  control={control}
                  type="time"
                  label=""
                  name="publishTime"
                  disabled={disableTime}
                />
              </Box>

              <CheckboxField
                name="postNow"
                label={t("managerLesson.dangngay")}
                control={control}
                handleChange={setIsPostNow}
              />
            </Box>
          </Box>
        </Box>
        <Box className={classes.root}>
          <Box className={classes.root} width="100%">
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerLesson.lichan")}
            </Typography>
            <Box className={classes.root}>
              <Box width="30%">
                <InputField control={control} type="date" label="" name="hiddenDate" />
              </Box>
              <Box ml={2} mr={4} width="30%">
                <InputField control={control} type="time" label="" name="hiddenTime" />
              </Box>
            </Box>
          </Box>
        </Box>
        {/* <Box className={classes.root}>
          <Box className={classes.root}>
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerLesson.phancap")}
            </Typography>
            <Box className={classes.root}>
              <Box width="25%">
                <FormControl
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  size="small"
                  error={invalid}
                >
                  <InputLabel id="areaId" className={classes.label}>
                    {t("auth.area")}
                  </InputLabel>
                  <Select
                    labelId="areaId"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    className={classes.selectField}
                    variant="outlined"
                    fullWidth
                    disabled={disableArea}
                    label={t("auth.area")}
                    inputProps={{
                      name: "areaId",
                      id: "areaId_label",
                    }}
                  >
                    {listArea?.map((option) => (
                      <MenuItem
                        key={option.id}
                        value={option.id}
                        onClick={() => handleGetListWorkType(option)}
                      >
                        <span>{option.name}</span>
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{error?.message}</FormHelperText>
                </FormControl>
              </Box>
              <Box ml={2} width="25%">
                <SelectField
                  name="departmentId"
                  label={t("auth.department")}
                  options={listDepartment.map(({id, title}) => ({
                    id,
                    name: title,
                  }))}
                  disabled={disableWorkType}
                  control={control}
                  handleClick={(option: any) => {
                    if (option.name !== "Sản Xuất") {
                      setValue("workTypeId", "");
                    }
                    setIdDepartment({
                      id: option.id,
                      name: option.title,
                    });
                  }}
                />
              </Box>
              <Box ml={2} mr={4} width="25%">
                <SelectField
                  name="workTypeId"
                  label={t("auth.workType")}
                  options={listWorkTypes.map(({workType}) => ({
                    id: workType.id,
                    name: workType.name,
                  }))}
                  disabled={
                    listDepartment?.filter((department) => department.title === "Sản Xuất")[0]
                      ?.id === idDepartment.id
                      ? false
                      : true
                  }
                  control={control}
                />
              </Box>

              <CheckboxField
                name="isAll"
                label="Tất cả"
                control={control}
                handleChange={setIsAll}
              />
            </Box>
          </Box>
        </Box> */}
        <Box className={classes.root}>
          <Box className={classes.root}>
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerLesson.chude")}
            </Typography>
            <Box className={classes.root}>
              <SelectField
                name="lessionCategoryId"
                label={t("managerLesson.chude")}
                options={listCategory}
                control={control}
              />
            </Box>
          </Box>
        </Box>

        <Box className={classes.root}>
          <Box className={classes.root}>
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerLesson.tenbaihoc")}
            </Typography>
            <Box className={classes.root}>
              <InputField
                name="lessionTitle"
                label={t("managerLesson.tenbaihoc") + "..."}
                control={control}
              />
            </Box>
          </Box>
        </Box>

        <Box className={classes.root}>
          <Box className={classes.root}>
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerLesson.mota")}
            </Typography>
            <Box className={classes.root}>
              <Box position="relative" width="100%">
                <InputField
                  name="lessionDescription"
                  label={t("managerLesson.mota")}
                  control={control}
                  rows={3}
                  multiline
                />
                <IconButton onClick={handleOpenEmoji} className={classes.icon_emoji}>
                  <img src={EMOJI} alt="EMOJI" />
                </IconButton>
                {openEmoji && (
                  <Menu
                    id="simple-menu"
                    anchorEl={openEmoji}
                    keepMounted
                    open={Boolean(openEmoji)}
                    onClose={() => setOpenEmoji(null)}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    //  className={classes.menu}
                  >
                    <Picker
                      onEmojiClick={onEmojiClick}
                      disableAutoFocus={true}
                      skinTone={SKIN_TONE_MEDIUM_DARK}
                      groupNames={{smileys_people: "PEOPLE"}}
                      native
                    />
                  </Menu>
                )}
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className={classes.root}>
          <Box className={classes.root}>
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerLesson.themvideo")}
            </Typography>
            <Dropzone onDrop={handleChangeFile}>
              {({getRootProps, getInputProps}) => (
                <Box className={classes.root} position="relative">
                  {video !== "" && (
                    <Box className={classes.form__video__iconClose} onClick={() => setVideo("")}>
                      <CloseIcon />
                    </Box>
                  )}
                  {filePPTX !== "" && (
                    <Box className={classes.form__video__iconClose} onClick={() => setFilePPTX("")}>
                      <CloseIcon />
                    </Box>
                  )}
                  {video === "" && filePPTX === "" && (
                    <Box
                      {...getRootProps({
                        className: classes.form__video__wrapper,
                      })}
                    >
                      <input {...getInputProps()} accept="video/* ,.pptx" multiple={false} />

                      <Box className={classes.form__video__content}>
                        <img src={ICON_VIDEO} alt="ICON_VIDEO" height="90px" />
                        <Typography variant="subtitle1">{t("managerLesson.themvideo")}</Typography>
                        <Typography variant="subtitle1" component="span">
                          {t("managerLesson.hoackeotha")}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  {video !== "" && (
                    <ReactPlayer
                      url={video}
                      controls={true}
                      width="100%"
                      height="100%"
                      config={{file: {attributes: {controlsList: "nodownload"}}}}
                      // Disable right click
                      onContextMenu={(e: any) => e.preventDefault()}
                    />
                  )}

                  {filePPTX !== "" && (
                    <iframe
                      src={`https://view.officeapps.live.com/op/embed.aspx?src=${filePPTX.url}`}
                      width="100%"
                      height="600px"
                    ></iframe>
                  )}
                </Box>
              )}
            </Dropzone>
          </Box>
        </Box>
        {errorVideo !== "" && (
          <Typography variant="subtitle2" color="secondary" style={{marginLeft: 100}}>
            {errorVideo}
          </Typography>
        )}
        <Box textAlign="right" mt={3}>
          <Button type="submit" className={classes.btn_draft} onClick={() => setDraft(true)}>
            {t("managerLesson.luunhap")}
          </Button>
          <Button type="submit" className={classes.btn_create} onClick={() => setDraft(false)}>
            {t("managerLesson.dangtin")}
          </Button>
        </Box>
      </form>
    </Box>
  );
}
