import DateFnsUtils from "@date-io/date-fns";
import {Box, Button, Menu, MenuItem, Paper, Typography} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import BACKGROUND_DATEPICKER from "assets/img/bg_register1.jpg";
import ICON_PIG_NEXT from "assets/img/icon_pig_next.png";
import ICON_PIG_CHECKED from "assets/img/icon_pig_pink.png";
import ICON_PIG_NOCHECKED from "assets/img/icon_pig_pink_nobg.png";
import ICON_PIG_PREV from "assets/img/icon_pig_prev.png";
import {Category} from "models/managerCategory";
import moment from "moment";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import useStyles from "./style";

export interface ContentRightProps {
  listCategory: Category[];
  handleClickChecked: (e: any) => void;
  listChecked: number[];
  listCurrentDate: string[];
}

export default function ContentRight({
  handleClickChecked,
  listCategory,
  listChecked,
  listCurrentDate,
}: ContentRightProps) {
  const location = useLocation();
  const classes = useStyles({
    background: BACKGROUND_DATEPICKER,
    pathname: location.pathname.includes("/admin") ? "admin" : "student",
  });
  const [openMenuCategory, setOpenMenuCategory] = useState<HTMLButtonElement | null>(null);
  const {t} = useTranslation();
  const [selectedDate, setSelectedDate] = useState<any>(new Date());
  const handleShowMenuCategory = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenMenuCategory(openMenuCategory ? null : event.currentTarget);
  };
  const handleCloseMenuCategory = () => {
    setOpenMenuCategory(null);
  };

  function getDayElement(day: any, selectedDate: any, isInCurrentMonth: any, dayComponent: any) {
    const today = new Date();
    const isCurrentDateMagazine = listCurrentDate.includes(moment(day).format("DD/MM/YYYY"));
    const isSelected = day.getDate() === selectedDate.getDate();
    const isToday = day.getDate() === today.getDate() && day.getMonth() === today.getMonth();

    let dateTile;
    if (isInCurrentMonth) {
      if (isCurrentDateMagazine) {
        dateTile = (
          <Paper
            className={classes.normalDayPaper}
            // className={
            //   isSelected
            //     ? classes.selectedDayPaper
            //     : isToday
            //     ? classes.todayPaper
            //     : classes.normalDayPaper
            // }
          >
            <Box className={classes.currentDate__content}>
              <img src={ICON_PIG_CHECKED} alt="ICON_PIG_CHECKED" style={{height: 20, width: 20}} />
              <Typography variant="subtitle2">{day.getDate()}</Typography>
            </Box>
          </Paper>
        );
      } else {
        dateTile = (
          <Paper className={isToday ? classes.todayPaper : classes.normalDayPaper}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="subtitle2">{day.getDate()}</Typography>
            </Box>
          </Paper>
        );
      }
    } else {
      dateTile = (
        <Paper className={classes.notInThisMonthDayPaper}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="subtitle2">{day.getDate()}</Typography>
          </Box>
        </Paper>
      );
    }
    return dateTile;
  }
  return (
    <Box mx={1} flexGrow={1}>
      <Box>
        <Button
          aria-describedby="simple-popover"
          className={classes.btn_menu_category}
          onClick={handleShowMenuCategory}
          endIcon={<ArrowDropDownIcon />}
          disableRipple
        >
          {t("managerMagazine.chude")}
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={openMenuCategory}
          keepMounted
          open={Boolean(openMenuCategory)}
          onClose={handleCloseMenuCategory}
          className={classes.menu__category__list}
        >
          <Box style={{height: 250, overflowY: "auto"}}>
            {listCategory.map((item, index) => (
              <MenuItem
                disableGutters
                key={item.id + index}
                className={classes.menu__category__item}
                onClick={() => {
                  handleClickChecked(item.id);
                }}
              >
                <Typography variant="subtitle2">{item.name}</Typography>
                <img
                  src={listChecked.includes(item.id) ? ICON_PIG_CHECKED : ICON_PIG_NOCHECKED}
                  alt="ICON_PIG_NOCHECKED"
                />
              </MenuItem>
            ))}
          </Box>
        </Menu>
      </Box>

      <Box className={classes.datePicker}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            value={selectedDate}
            onChange={setSelectedDate}
            variant="static"
            disableToolbar
            leftArrowIcon={<img src={ICON_PIG_PREV} className={classes.icon_pig_calendar} />}
            rightArrowIcon={<img src={ICON_PIG_NEXT} className={classes.icon_pig_calendar} />}
            renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) =>
              getDayElement(day, selectedDate, isInCurrentMonth, dayComponent)
            }
          />
        </MuiPickersUtilsProvider>
      </Box>
    </Box>
  );
}
