import {
  Box,
  Button,
  createStyles,
  FormControl,
  IconButton,
  InputAdornment,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import managerTestApi from "api/managerTest";
import managerUserApi from "api/managerUser";
import EmptyComponent from "components/Empty";
import _ from "lodash";
import { useConfirm } from "material-ui-confirm";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

export interface ListUserByExamrops {}

export default function ListUserByExam(props: ListUserByExamrops) {
  const useStyles = makeStyles(() => ({
    background: {
      // backgroundImage: `url(${BACKGROUND})`,
      // backgroundSize: "cover",
      // backgroundPositionX: "right",
      // backgroundRepeat: "no-repeat",
      height: 70,
      width: "100%",
      position: "relative",
      "& >h6": {
        position: "absolute",
        bottom: 10,
        left: 0,
        color: "#004673",
        fontSize: 20,
        fontWeight: 700,
      },
    },
    title: {
      color: "#004673",
      fontSize: 20,
      fontWeight: 700,
      padding: "12px 0px",
    },
    btn_goBack: {
      position: "absolute",
      top: -15,
      left: -20,
      zIndex: 99,
    },
    input_search: {
      "& .MuiOutlinedInput-root": {
        backgroundColor: "#FFFFFF",
        borderRadius: 12,
      },
    },
    container: {
      maxHeight: 350,
      overflow: "auto",
    },
    btn_delete: {
      background: "linear-gradient(0deg, #FD5468 0%, rgba(255, 193, 200, 0.9) 169.32%)",
      borderRadius: 10,
      fontSize: 12,
      color: "#fff",
      padding: "4px 10px",
      margin: "0 10px",
    },
    btn_edit: {
      background: "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)",
      borderRadius: 10,
      fontSize: 11,
      color: "#fff",
      padding: "5px 12px",
      margin: "0 7px",
    },
  }));
  const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
      head: {
        padding: "16px 5px",
        color: "#1B1B1B",
        fontSize: 11,
        fontWeight: 700,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        borderBottom: "1px solid #777777",
        zIndex: 99,
        [theme.breakpoints?.up("xl")]: {
          fontSize: 13,
        },
      },
      body: {
        padding: "16px 5px",
        fontSize: 11,
        borderRight: "1px solid rgba(224, 224, 224, 0.8)",

        [theme.breakpoints?.up("xl")]: {
          fontSize: 13,
        },
      },
    })
  )(TableCell);
  const {id} = useParams();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const [listUsersByExam, setListUsersByExamId] = useState([]);
  const [listUserByExamInnit, setListUsersByExamInit] = useState([]);
  const [listUser, setListUser] = useState([]);
  const [listUserInit, setListUserInit] = useState([]);
  const confirm = useConfirm();
  const [userListNotExistInExam, setUserListNotExistInExam] = useState<any>([]);
  const {state} = useLocation();

  const [filter, setFilter] = useState({
    page: 0,
    size: 99999,
    statusName: "Published",
    name: "",
    sortType: 2,
    role: "STUDENT",
  });
  useEffect(() => {
    if (id) {
      getListUserByExamId(id);
    }
  }, [id]);

  useEffect(() => {
    const isEqual = (obj1: any, obj2: any) => {
      return obj1.id === obj2.id && obj1.name === obj2.name;
    };
    // Use _.differenceWith() to filter out objects from array1 that are the same as objects in array2
    const data:any = _.differenceWith(listUser, listUsersByExam, isEqual);
    setUserListNotExistInExam(data);
  }, [listUser, ListUserByExam]);

  const getListUserByExamId = async (id: any) => {
    try {
      const res: any = await managerTestApi.getUsersByExamID(id);
      setListUsersByExamId(res.userList);
      setListUsersByExamInit(res.userList);
    } catch (error) {}
  };

  useEffect(() => {
    (async () => {
      try {
        const res: any = await managerUserApi.getUsers(filter);
        setListUser(res.users.content);
        setListUserInit(res.users.content);
      } catch (error) {}
    })();
  }, []);

  const debounceSearchListUserByExam = (valueSearch: string)=>{
    if (valueSearch !== "") {
      const filtered = listUserByExamInnit.filter(
       (user: any) =>
         user.name.toLowerCase().includes(valueSearch) ||
         user?.staffId.toLowerCase().includes(valueSearch) ||
         user?.class?.name.toLowerCase().includes(valueSearch)
     );
     setListUsersByExamId(filtered);
   } else {
     setListUsersByExamId(listUserByExamInnit);
   }
  }
  const debounceSearchListUser = (valueSearch: string)=>{
    if (valueSearch === "") {
      setListUser(listUserInit);
    } else {
      const filtered = listUserInit.filter(
        (user: any) =>
          user.name.toLowerCase().includes(valueSearch) ||
          user?.staffId.toLowerCase().includes(valueSearch) ||
          user?.class?.name.toLowerCase().includes(valueSearch)
      );
      setListUser(filtered);
    }
  }

  const debouncedSearchListUserByExam = _.debounce(debounceSearchListUserByExam, 300);
  const debouncedSearchListUser = _.debounce(debounceSearchListUser, 300);

  const handleInputChangeListUserByExam = (event: any) => {
    const valueSearch = event.target.value.toLowerCase().trim();
    debouncedSearchListUserByExam(valueSearch);
  };
  const handleInputChangeListUser = (event: any) => {
    const valueSearch = event.target.value.toLowerCase().trim();
    debouncedSearchListUser(valueSearch);
   
  };


  const handleDeleteUser = async (user: any) => {
    confirm({
      title: (
        <Typography variant="h5" color="secondary">
          {t("delete.chuy")}
        </Typography>
      ),
      description: (
        <Typography variant="subtitle2">
          {t("delete.khiban")}{" "}
          <Typography variant="subtitle2" component="span" color="secondary">
            {t("delete.dongy")}
          </Typography>{" "}
          {t("delete.xoa")} học viên
          <Typography
            variant="subtitle2"
            component="span"
            style={{color: "#004673", fontWeight: 700}}
          >
            {" "}
            {user.name}
          </Typography>{" "}
          {t("delete.sexoatatcadulieu")}
        </Typography>
      ),
      confirmationText: (
        <Button className={classes.btn_delete} style={{fontSize: 14, margin: 0}}>
          {t("delete.dongy")}
        </Button>
      ),
      cancellationText: (
        <Button className={classes.btn_edit} style={{fontSize: 14, margin: 0}}>
          {t("delete.huybo")}
        </Button>
      ),
    }).then(async () => {
      try {
        const value = {
          examID: id,
          userIdList: [String(user.id)],
          staffIdList: [String(user.staffId)],
        };
        const res: any = await managerTestApi.deleteUserFromExam(value);
        if (res.responseCode === "00") {
          toast.success(`Xóa học viên khỏi bài thi thành công`);
          getListUserByExamId(id);
        }
      } catch (error) {}
    });
  };

  const handleAddUser = async (user: any) => {
    confirm({
      title: (
        <Typography variant="h5" color="primary">
          Thêm học viên
        </Typography>
      ),
      description: (
        <Typography variant="subtitle2">
          Bấm{" "}
          <Typography variant="subtitle2" component="span" color="primary">
            {t("delete.dongy")}
          </Typography>{" "}
          để thêm học viên
          <Typography
            variant="subtitle2"
            component="span"
            style={{color: "#004673", fontWeight: 700}}
          >
            {" "}
            {user.name}
          </Typography>{" "}
          vào bài thi
        </Typography>
      ),
      confirmationText: (
        <Button className={classes.btn_delete} style={{fontSize: 14, margin: 0}}>
          {t("delete.dongy")}
        </Button>
      ),
      cancellationText: (
        <Button className={classes.btn_edit} style={{fontSize: 14, margin: 0}}>
          {t("delete.huybo")}
        </Button>
      ),
    }).then(async () => {
      try {
        const value = {
          examID: id,
          userIdList: [user.id],
          staffIdList: [user.staffId],
        };
        const res: any = await managerTestApi.addUserToExam(value);
        if (res.responseCode === "00") {
          toast.success(`Thêm học viên vào bài thi thành công`);
          getListUserByExamId(id);
        }
      } catch (error) {}
    });
  };

  return (
    <Box position="relative">
      <Box className={classes.btn_goBack}>
        <Tooltip title={t("button.goback") || ""}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIosIcon style={{marginLeft: 7}} />
          </IconButton>
        </Tooltip>
      </Box>
      <Box mb={2}>
        <Box className={classes.background}>
          <Typography variant="subtitle2">Danh sách học viên đã có trong bài thi </Typography>
        </Box>
        <Box my={2}>
          <Box className={classes.input_search}>
            <FormControl fullWidth variant="outlined" size="small">
              <TextField
                name="search_existed"
                autoComplete="off"
                label={"Tìm kiếm theo tên hoặc mã học viên"}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <Search style={{marginLeft: 5}} />
                    </InputAdornment>
                  ),
                }}
                onChange={handleInputChangeListUserByExam}
              />
            </FormControl>
          </Box>
        </Box>
        <Box>
          <TableContainer component={Paper} className={classes.container}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center" width="70px">
                    {t("stt")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width="160px">
                    {t("auth.fullName")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width="100px">
                    {t("auth.code")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width="120px">
                    {t("auth.email")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width="120px">
                    {t("auth.class")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width="150px">
                    {t("thaotac")}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listUsersByExam.length > 0 ? (
                  listUsersByExam?.map((user: any, index) => (
                    <TableRow key={user.id}>
                      <StyledTableCell align="center" component="th" scope="exam">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell align="center">{user.name}</StyledTableCell>
                      <StyledTableCell align="center">{user.staffId}</StyledTableCell>
                      <StyledTableCell align="center">{user.email}</StyledTableCell>
                      <StyledTableCell align="center">{user?.class?.name}</StyledTableCell>
                      <StyledTableCell align="center">
                        <Button
                          className={classes.btn_delete}
                          onClick={() => handleDeleteUser(user)}
                          disabled={state.disabled}
                        >
                          Xóa học viên
                        </Button>
                      </StyledTableCell>
                    </TableRow>
                  ))
                ) : (
                  <>
                    <StyledTableCell align="center" style={{border: "none"}}></StyledTableCell>
                    <StyledTableCell align="center" style={{border: "none"}}></StyledTableCell>
                    <StyledTableCell align="center" style={{border: "none"}}></StyledTableCell>
                    <Box p={2}>
                      <EmptyComponent isBorder={false} />
                    </Box>
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Box mt={2}>
        <Box borderTop={"1px solid #edd1d1"} marginTop="15px">
          <Typography className={classes.title} variant="subtitle2">
            Danh sách học viên chưa có trong bài thi{" "}
          </Typography>
        </Box>
        <Box my={2}>
          <Box className={classes.input_search}>
            <FormControl fullWidth variant="outlined" size="small">
              <TextField
                name="search_user"
                autoComplete="off"
                label={"Tìm kiếm theo tên hoặc mã học viên"}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <Search style={{marginLeft: 5}} />
                    </InputAdornment>
                  ),
                }}
                onChange={handleInputChangeListUser}
              />
            </FormControl>
          </Box>
        </Box>
        <Box>
          <TableContainer component={Paper} className={classes.container}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center" width="70px">
                    {t("stt")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width="160px">
                    {t("auth.fullName")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width="100px">
                    {t("auth.code")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width="120px">
                    {t("auth.email")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width="120px">
                    {t("auth.class")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width="150px">
                    {t("thaotac")}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userListNotExistInExam.length > 0 ? (
                  userListNotExistInExam?.map((user: any, index: number) => (
                    <TableRow key={user.id}>
                      <StyledTableCell align="center" component="th" scope="exam">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell align="center">{user.name}</StyledTableCell>
                      <StyledTableCell align="center">{user.staffId}</StyledTableCell>
                      <StyledTableCell align="center">{user?.email}</StyledTableCell>
                      <StyledTableCell align="center">{user?.class?.name}</StyledTableCell>
                      <StyledTableCell align="center">
                        <Button
                          disabled={state.disabled}
                          className={classes.btn_edit}
                          onClick={() => handleAddUser(user)}
                        >
                          Thêm học viên
                        </Button>
                      </StyledTableCell>
                    </TableRow>
                  ))
                ) : (
                  <>
                    <StyledTableCell align="center" style={{border: "none"}}></StyledTableCell>
                    <StyledTableCell align="center" style={{border: "none"}}></StyledTableCell>
                    <StyledTableCell align="center" style={{border: "none"}}></StyledTableCell>
                    <Box p={2}>
                      <EmptyComponent isBorder={false} />
                    </Box>
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
}
