import {Box, Button, FormControlLabel, makeStyles, Radio, Typography} from "@material-ui/core";
import managerTestApi from "api/managerTest";
import {useAppSelector} from "app/hooks";
import BACKGROUND from "assets/img/bg_create_test.png";
import {selectListDepartment, selectListWorkTypes} from "features/Auth/options/optionsSlice";
import {ResponseStatus} from "models/common";
import {AnswerContent, ListQuestion, Question} from "models/managerTest";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {timeConvert} from "utils/timeConvert";

export interface DetailExamProps {
  valueForm: Question | undefined;
  listQuestion: ListQuestion | any;
  setShowDetailExam: (e: any) => void;
  valueInputShuffle?:any;
}

export default function DetailExam({listQuestion, valueForm, setShowDetailExam, valueInputShuffle}: DetailExamProps) {
  const useStyles = makeStyles(() => ({
    background: {
      width: "60%",
      height: "50%",
      objectFit: "cover",
    },
    header: {
      backgroundColor: "#fff",
      boxShadow: "0px 2px 4px rgba(168, 168, 168, 0.25), 0px 0px 4px rgba(168, 168, 168, 0.25)",
      borderRadius: 6,
      display: "flex",
      justifyContent: "space-between",
      padding: "20px 40px",
    },
    title: {
      fontWeight: 700,
      paddingBottom: 10,
      "& > span": {
        fontWeight: 500,
      },
    },
    question: {
      backgroundColor: "#fff",
      boxShadow: "0px 2px 4px rgba(168, 168, 168, 0.25), 0px 0px 4px rgba(168, 168, 168, 0.25)",
      borderRadius: 6,
      padding: "20px 40px",
    },
    question__content: {
      color: "#0065A6",
      fontSize: 15,
      paddingRight: valueForm?.category === "essay" ? 5 : 0,
    },
    btn_submit: {
      background: "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)",
      color: "#fff",
      borderRadius: 10,
      "&:hover": {
        backgroundColor: "#E38825",
      },
    },
    btn_goBack: {
      borderRadius: 10,
      color: "#fff",
      marginRight: 15,
      background: "linear-gradient(0deg, #FD5468 0%, rgba(255, 193, 200, 0.9) 169.32%)",
    },
  }));

  const {t} = useTranslation();

  const classes = useStyles();
  const [disabledBtnSend, setDisableBtnSend] = React.useState(false);
  const navigate = useNavigate();
  const handleSendForm = async () => {
    setDisableBtnSend(true);
    const value = {
      title: valueForm?.title,
      questionList: listQuestion.map((question: ListQuestion) => ({
        answers: question.answers,
        point: question.point,
        questionContent: question.questionContent,
        imageFileIDList: question.imageFileIDList.map((x: any) => x.id),
      })),
      examTime: valueForm?.examTime,
      prizeId: valueForm?.prizeId,
      startDate: valueForm?.startDate,
      totalPoint: valueForm?.totalPoint,
      type: valueForm?.type,
      departmentIdList: valueForm?.departmentId,
      publishAnswerDate: valueForm?.publishAnswerDate,
      classIdList: valueForm?.classId,
      userIdList: valueForm?.userIdList,
      numberOfDisplayedQuestion: valueInputShuffle || null,
    };
    try {
      const res: ResponseStatus = await managerTestApi.createExam(value);
      if (res.responseCode === "00") {
        toast.success(`${t("managerTest.taodethithanhcong")}`);
        navigate("/admin/manager-test/list");
      } else {
        toast.error(`${t("managerTest.taodethithatbai")}`);
        navigate("/admin/manager-test/list");
      }
      setDisableBtnSend(false);
    } catch (error) {
      setDisableBtnSend(false);
    }
  };
  return (
    <Box>
      <Box textAlign="right">
        <img src={BACKGROUND} alt="BACKGROUND" className={classes.background} />
      </Box>
      <Box className={classes.header}>
        <Box>
          <Typography variant="subtitle2" className={classes.title}>
            {valueForm?.title}
          </Typography>
          {/* <Typography variant="subtitle2" className={classes.title}>
            {t("managerTest.nhomthi")}:{" "}
            <Typography variant="subtitle2" component="span" color="secondary">
              {departments.length === valueForm?.departmentId.length ? (
                `${t("tatca")}`
              ) : (
                <>
                  {departments.map((type) => {
                    if (type.id === Number(valueForm?.departmentId.join(""))) {
                      return <React.Fragment key={type.id}>{type.title}</React.Fragment>;
                    }
                  })}
                </>
              )}
            </Typography>
          </Typography> */}
          <Typography variant="subtitle2" className={classes.title}>
            {t("managerTest.diemtongbaithi")} :{" "}
            <Typography variant="subtitle2" component="span" color="secondary">
              {valueForm?.totalPoint}{" "}
              <Typography variant="subtitle2" component="span" style={{color: "#000"}}>
                {t("managerTest.diem").toLowerCase()}
              </Typography>
            </Typography>
          </Typography>
        </Box>

        <Box textAlign="right">
          <Typography variant="subtitle2" className={classes.title}>
            <Typography variant="subtitle2" component="span">
              {valueForm?.startDate?.slice(0, 10)}
            </Typography>
          </Typography>
          <Typography variant="subtitle2" className={classes.title}>
            {t("managerTest.giothi")}:{" "}
            <Typography variant="subtitle2" component="span">
              {valueForm?.time}
            </Typography>
          </Typography>
          <Typography variant="subtitle2" className={classes.title}>
            {t("managerTest.thoigianthi")} :{" "}
            <Typography variant="subtitle2" component="span" color="secondary">
              {timeConvert(Number(valueForm?.examTime))}
            </Typography>
          </Typography>
        </Box>
      </Box>
      {listQuestion?.map((item: ListQuestion, index: number) => (
        <Box mt={2} className={classes.question} key={index}>
          <Box display={valueForm?.essay ? "flex" : "block"} alignItems="center" mb={1}>
            <Typography variant="subtitle2" className={classes.question__content}>
              {t("managerTest.cau")} {index + 1} {valueForm?.essay && ":"}
            </Typography>{" "}
            <Typography variant="subtitle2" style={{fontSize: valueForm?.essay && 15}}>
              {"  "}
              {item?.questionContent}
            </Typography>
          </Box>
          {item.imageFileIDList.length > 0 && (
            <Box
              display="grid"
              gridTemplateColumns={item?.imageFileIDList?.length === 1 ? "1fr" : "1fr 1fr"}
              gridGap={10}
              my="10px"
            >
              {item.imageFileIDList.map((x: any) => {
                return (
                  <img
                    key={x.id}
                    style={{
                      width: "100%",
                      height: "fit-content",

                      objectFit: "contain",
                      maxHeight: "450px",
                    }}
                    src={x.url}
                    alt={x}
                  />
                );
              })}
            </Box>
          )}
          {item?.answers?.map((answer: AnswerContent, index: number) => (
            <Box key={index}>
              {valueForm?.category === "essay" ? (
                <Typography variant="subtitle2">{answer.answerContent}</Typography>
              ) : (
                <FormControlLabel
                  control={
                    <Radio checked={answer?.isRightAnswer} disabled={!answer?.isRightAnswer} />
                  }
                  label={
                    <Typography
                      variant="subtitle2"
                      style={{fontWeight: answer?.isRightAnswer ? 700 : 500}}
                    >
                      {answer?.answerContent}
                    </Typography>
                  }
                />
              )}
            </Box>
          ))}
        </Box>
      ))}

      <Box textAlign="right" mt={3}>
        <Button onClick={() => setShowDetailExam(false)} className={classes.btn_goBack}>
          {" "}
          {t("button.goback")}
        </Button>
        <Button className={classes.btn_submit} onClick={handleSendForm} disabled={disabledBtnSend}>
          {t("button.accept")}
        </Button>
      </Box>
    </Box>
  );
}
