import {
  Box,
  Button,
  Card,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  MenuList,
  Modal,
  Popover,
  Slide,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import clsx from "clsx";
import useStyles from "./style";
import {useTranslation} from "react-i18next";
import EMOJI from "assets/img/emoji.png";
import Picker, {SKIN_TONE_MEDIUM_DARK} from "emoji-picker-react";
import NO_AVATAR from "assets/img/no_avatar.png";
import {useState} from "react";
export interface InputComment {
  parent: any;
  infoLogin: any;
  enterKeyPressed: any;
}
export default function InputComment({infoLogin, parent, enterKeyPressed}: InputComment) {
  const classes = useStyles();
  const {t} = useTranslation();
  const [value, setValue] = useState("");
  const [openEmoji, setOpenEmoji] = useState<null | HTMLElement>(null);
  const handleOpenEmoji = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenEmoji(event.currentTarget);
  };
  const onEmojiCommentClick = (event: any, emojiObject: any) => {
    setValue(value + emojiObject.emoji);
  };
  return (
    <Box display="flex" alignItems="center" mt={2} mb={2}>
      <Box mr={1}>
        {infoLogin.profileImageUrl ? (
          <img src={infoLogin.profileImageUrl} alt="avatar" className={classes.avatar} />
        ) : (
          <img src={NO_AVATAR} alt="avatar" className={classes.avatar} />
        )}
      </Box>
      <Box className={clsx(classes.form__wrapper, classes.form__wrapper__comment)}>
        <TextField
          id="commentInput"
          name={`comment-${parent.id}`}
          value={value}
          placeholder={t("community.binhluan...")}
          fullWidth
          className={classes.input__comment}
          autoComplete="off"
          onChange={(e) => setValue(e.target.value)}
          onKeyPress={(e) => {
            enterKeyPressed(e, parent.id, value, setValue);
          }}
        />
        <IconButton onClick={handleOpenEmoji}>
          <img src={EMOJI} alt="EMOJI" className={classes.icon_emoji} />
        </IconButton>
        {openEmoji && (
          <Menu
            id="simple"
            anchorEl={openEmoji}
            keepMounted
            open={Boolean(openEmoji)}
            onClose={() => setOpenEmoji(null)}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Picker
              onEmojiClick={onEmojiCommentClick}
              disableAutoFocus={true}
              skinTone={SKIN_TONE_MEDIUM_DARK}
              groupNames={{smileys_people: "PEOPLE"}}
              native
            />
          </Menu>
        )}
      </Box>
    </Box>
  );
}
