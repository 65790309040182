import {Datum} from "@ant-design/charts";
import {Line} from "@ant-design/plots";
import {Box} from "@material-ui/core";
import {RankInYear} from "models/auth";
import React from "react";
import {useTranslation} from "react-i18next";

interface ChartRankingProps {
  ranking: any;
}

const ChartRanking = ({ranking}: ChartRankingProps) => {
  const {t} = useTranslation();
  const config: any = {
    data:
      (ranking &&
        ranking.map((rank: RankInYear) => ({
          ...rank,
          month: `T${rank.month}/${rank.year}`,
        }))) ||
      [],
    xField: "month",
    yField: "ranking",
    label: {},
    point: {
      size: 5,
      shape: "diamond",
      style: {
        fill: "white",
        stroke: "#5B8FF9",
        lineWidth: 2,
      },
    },
    tooltip: {
      showMarkers: false,
      formatter: (datum: Datum) => {
        return {name: `${t("home.chart.hang")}`, value: datum.ranking};
      },
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: "#000",
          fill: "red",
        },
      },
    },
    interactions: [
      {
        type: "marker-active",
      },
    ],
  };
  return (
    <Box height="250px">
      <Line {...config} />
    </Box>
  );
};

export default ChartRanking;
