import managerCommnunityApi from "api/managerCommunity";
import {PayloadAction} from "@reduxjs/toolkit";
import {ListParams} from "models/common";
import {ListResponseCommunityPost,CommunityCommentList} from "models/managerCommunityPost";
import {call, put, takeLatest} from "redux-saga/effects";
import{
    fetchCommunityCommentList,
    fetchCommunityCommentListSuccess,
    fetchCommunityCommentListFailed,
} from "./communityCommentSlice";
function* fetchDataCommunityCommentList(action: PayloadAction<ListParams>) {
    try {
      const res: ListResponseCommunityPost<CommunityCommentList> = yield call(managerCommnunityApi.getListComment,action.payload); 
      if (res.responseCode === "00") {
        yield put(fetchCommunityCommentListSuccess(res));
      } else {
        yield put(fetchCommunityCommentListFailed());
      }
    } catch (error) {}
  }
  
  export default function* managerCommunityCommentSaga() { 
    yield takeLatest(fetchCommunityCommentList, fetchDataCommunityCommentList);
  }
  
