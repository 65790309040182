import {ListParams, NewListParams, ResponseStatus} from "models/common";
import {
  Comment,
  DetailUserSkip,
  Lesson,
  LessonByUser,
  ListResponseComments,
  ListResponseLesson,
  ListSlideMonth,
  PostComment,
  PostLikeStatus,
  PostWatchedStatus,
  ResponseListSkip,
  SlideMonth,
} from "models/managerLesson";
import axiosClient from "./axiosClient";

const managerLessonApi = {
  getLessons(params: ListParams): Promise<ListResponseLesson<Lesson>> {
    const url = "/lession/getLessions";
    return axiosClient.get(url, {params});
  },
  createLesson(value: any): Promise<ResponseStatus> {
    const url = "/lession/createLession";
    return axiosClient.post(url, value);
  },
  updateLesson(value: any): Promise<ResponseStatus> {
    const url = "/lession/updateLession";
    return axiosClient.put(url, value);
  },
  deleteLesson(lessionId: number | undefined): Promise<ResponseStatus> {
    const url = `/lession/deleteLession?lessionId=${lessionId}`;
    return axiosClient.delete(url);
  },

  trackLessonLastSeen(value: any) {
    const url = "/lession/lessonUsers";
    return axiosClient.put(url, value);
  },

  getSlideMonth(year: number): Promise<ListSlideMonth<SlideMonth>> {
    const url = `/lession/overview?year=${year}`;
    return axiosClient.get(url);
  },

  getLessonByUser(params: ListParams): Promise<ListResponseLesson<LessonByUser>> {
    const url = "/lession/getLessionsByUser";
    return axiosClient.get(url, {params});
  },
  getDetailLessonByUser(lessonId: number | undefined): Promise<LessonByUser> {
    const url = `/lession/getLessionDetails?lessionId=${lessonId}`;
    return axiosClient.get(url);
  },
  getComments(params: ListParams): Promise<ListResponseComments<Comment>> {
    const url = "/comment/getComments";
    return axiosClient.get(url, {params});
  },
  postComment(value: PostComment): Promise<ResponseStatus> {
    const url = "/comment/postComment";
    return axiosClient.post(url, value);
  },
  postLikeStatus(value: PostLikeStatus): Promise<ResponseStatus> {
    const url = "/lession/setLessionLikeStatus";
    return axiosClient.put(url, value);
  },
  postWatchedStatus(value: PostWatchedStatus): Promise<ResponseStatus> {
    const url = "/lession/setLessionWatched";
    return axiosClient.put(url, value);
  },

  listSkip(params: NewListParams): Promise<ResponseListSkip<DetailUserSkip>> {
    const url = "/statistic/statisticDropLearning";
    return axiosClient.get(url, {params});
  },
  deleteComment(comment_id: number | undefined): Promise<ResponseStatus> {
    const url = "/comment/deleteLessonComment";
    return axiosClient.post(url, {comment_id});
  },
  updateComment(data: any): Promise<ResponseStatus> {
    const url = `/comment/updateLessonComment?comment_id=${data.comment_id}&content=${data.content}`;
    return axiosClient.post(url);
  },
};

export default managerLessonApi;
