import * as React from "react";
import {Empty} from "antd";
import {useTranslation} from "react-i18next";

export interface EmptyProps {
  isBorder?: boolean;
  description?: string;
}

export default function EmptyComponent({isBorder = true, description}: EmptyProps) {
  const {t} = useTranslation();
  return (
    <Empty
      description={description || t("nodata")}
      style={{border: isBorder ? "1px solid #e9dede" : "none", padding: 10}}
    />
  );
}
