import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "app/store";
import {NewListParams} from "models/common";
import {MagazineList, ResponseMagazine} from "models/managerMagazine";

export interface ManagerMagazineState {
  data: {
    magazineList: MagazineList[];
    [key: string]: any;
  };
  dataTrending: {
    magazineList: MagazineList[];
    [key: string]: any;
  };
  loading: Boolean;
  filterTable: NewListParams;
  loadingTrending: Boolean;
  filterMagazineNews: NewListParams;
}

const initialState: ManagerMagazineState = {
  data: {
    magazineList: [],
  },
  dataTrending: {
    magazineList: [],
  },
  loading: false,
  loadingTrending: false,
  filterTable: {
    page_index: 0,
    page_size: 10,
    sort_type: 0,
  },
  filterMagazineNews: {
    until_published_timestamp_utc: 0,
    page_size: 12,
    is_hidden: false,
    page_index: 0,
    sort_type: 0,
    title: "",
  },
};

const managerMagazineSlice = createSlice({
  initialState,
  name: "manager-list-exam-slice",
  reducers: {
    fetchListMagazine(state, action: PayloadAction<NewListParams>) {
      state.loading = true;
    },
    fetchListMagazineSuccess(state, action: PayloadAction<ResponseMagazine<MagazineList>>) {
      state.loading = false;
      state.data = action.payload;
    },
    fetchListMagazineFailed(state) {
      state.loading = false;
    },
    fetchListTrendingMagazine(state, action: PayloadAction<NewListParams>) {
      state.loadingTrending = true;
    },
    fetchListTrendingMagazineSuccess(state, action: PayloadAction<ResponseMagazine<MagazineList>>) {
      state.loadingTrending = false;
      state.dataTrending = action.payload;
    },
    fetchListTrendingMagazineFailed(state) {
      state.loadingTrending = false;
    },
    setFilterListTableMagazine(state, action: PayloadAction<NewListParams>) {
      state.filterTable = action.payload;
    },

    setFilterMagazineNews(state, action: PayloadAction<NewListParams>) {
      state.filterMagazineNews = action.payload;
    },

    setFilterSearchDebounce(state, action: PayloadAction<NewListParams>) {},
  },
});

export const {
  fetchListMagazine,
  fetchListMagazineSuccess,
  fetchListMagazineFailed,
  fetchListTrendingMagazine,
  fetchListTrendingMagazineSuccess,
  fetchListTrendingMagazineFailed,
  setFilterListTableMagazine,
  setFilterMagazineNews,
  setFilterSearchDebounce,
} = managerMagazineSlice.actions;

export const SelectListMagazine = (state: RootState) => state.managerMagazineReducer.data;
export const SelectFilterListMagazine = (state: RootState) =>
  state.managerMagazineReducer.filterTable;
export const selectLoadingListMagazine = (state: RootState) => state.managerMagazineReducer.loading;

export const SelectListTrendingMagazine = (state: RootState) =>
  state.managerMagazineReducer.dataTrending;
export const SelectFilterListTrendingMagazine = (state: RootState) =>
  state.managerMagazineReducer.filterTable;
export const selectLoadingListTrendingMagazine = (state: RootState) =>
  state.managerMagazineReducer.loadingTrending;

export const selectFilterListMagazineNews = (state: RootState) =>
  state.managerMagazineReducer.filterMagazineNews;

const managerMagazineReducer = managerMagazineSlice.reducer;
export default managerMagazineReducer;
