import {makeStyles, Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    color: "#0072BB",
    fontSize: 25,
    fontWeight: 700,
    minWidth: 250,
    [theme.breakpoints?.down("md")]: {
      fontSize: 18,
    },
    [theme.breakpoints?.down("sm")]: {
      fontSize: 12,
    },
  },
  container_table: {
    maxHeight: "50vh",
  },
  formControl: {
    width: 150,
    backgroundColor: "#FFF",
    position: "absolute",
    right: 30,
    bottom: 45,
    [theme.breakpoints?.down("md")]: {
      right: 10,
      bottom: 10,
      width: 80,
    },
    [theme.breakpoints?.down("sm")]: {
      right: 0,
      bottom: 0,
      width: 65,
    },
    "& .MuiSelect-root": {
      padding: 10,
      [theme.breakpoints?.down("md")]: {
        padding: "7px 5px",
        fontSize: 12,
      },
      [theme.breakpoints?.down("sm")]: {
        padding: "5px",
      },
    },
  },
  banner: {
    flexGrow: 1,
    "&>img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  stickyFooter: {
    bottom: 0,
    position: "fixed",
    backgroundColor: "#FFF",
  },
}));

export default useStyles;
