import {ListParams, NewListParams, ResponseStatus} from "models/common";
import {CommnunityCategoryList, ListResponseCommunityCategory} from "models/managerCommunity";
import {
  CommnunityPostList,
  CommunityCommentList,
  CreatePostInterface,
  ListPost,
  ListResponseCommunityPost,
  ListResponseReact,
  ListReviewPost,
  ReactAction,
  ResponseHandleComment,
  ReviewPost,
} from "models/managerCommunityPost";
import axiosClient from "./axiosClient";

const managerCommnunityApi = {
  getListCategory(): Promise<ListResponseCommunityCategory<CommnunityCategoryList>> {
    const url = "/community/getCategoryList";
    return axiosClient.get(url);
  },
  getListComment(params: any): Promise<ListResponseCommunityPost<CommunityCommentList>> {
    const url = "/community/getCommentList";
    return axiosClient.get(url, {params});
  },
  createCategory(name: string): Promise<ListResponseCommunityCategory<CommnunityCategoryList>> {
    const url = "/community/createCategory";
    return axiosClient.post(url, {name});
  },
  createPost(value: CreatePostInterface): Promise<ListPost> {
    const url = "/community/createPost";
    return axiosClient.post(url, value);
  },
  reviewPost(value: ReviewPost): Promise<ListReviewPost<ReviewPost>> {
    const url = "/community/reviewPostList";
    return axiosClient.post(url, value);
  },
  deleteCategory(category_id: number): Promise<ResponseStatus> {
    const url = "/community/deleteCategory";
    return axiosClient.post(url, {category_id});
  },
  deletePost(post_id: string): Promise<ListResponseCommunityPost<CommnunityPostList>> {
    const url = "/community/deletePost";
    return axiosClient.post(url, {post_id});
  },
  getListPost(params: NewListParams): Promise<ListResponseCommunityPost<CommnunityPostList>> {
    const url = "/community/getPostList";
    return axiosClient.get(url, {params});
  },
  ReactPost(params: ReactAction): Promise<ListResponseReact> {
    const url = "/community/reactPost";
    return axiosClient.post(url, params);
  },
  ReactComment(params: ReactAction): Promise<ListResponseReact> {
    const url = "/community/reactComment";
    return axiosClient.post(url, params);
  },
  deleteComment(comment_id: string | undefined): Promise<ResponseHandleComment> {
    const url = "/community/deleteCommunityComment";
    return axiosClient.post(url, {comment_id});
  },
  updateComment(data: any): Promise<ResponseHandleComment> {
    const url = "/community/updateCommunityComment";
    return axiosClient.post(url, data);
  },
  createComment(data: any): Promise<ResponseHandleComment> {
    const url = "/community/createCommunityComment";
    return axiosClient.post(url, data);
  },
};
export default managerCommnunityApi;
