import Profile from "components/Profile";
import ForgotPassword from "features/Auth/pages/ForgotPassWord";
import Login from "features/Auth/pages/Login";
import Register from "features/Auth/pages/Register";
import HomePage from "features/Home";
import DetailLesson from "features/Home/pages/DetailLesson";
import ListExamStudent from "features/Home/pages/ListExamStudent";
import ListLesson from "features/Home/pages/ListLesson";
import SlideMonth from "features/Home/pages/CarouselMonth";
import TakeATest from "features/Home/pages/TakeATest";
import BeginExam from "features/Home/pages/TakeATest/pages/BeginExam";
import ResultExam from "features/Home/pages/TakeATest/pages/ResultExam";
import React, {useEffect} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import AdminTemPlate from "template/AdminTemplate";
import HomeTemplate from "template/HomeTemPlate";
import "./App.css";
import ListExamHistory from "features/Home/pages/ListExamStudent/pages/ListExamHistory";
import DetailResultTest from "features/Home/pages/TakeATest/pages/DetailResultExam";
import AcademicRecord from "features/Home/pages/AcademicRecord";
import Community from "features/Home/pages/Community";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "app/hooks";
import {selectLanguage} from "features/Auth/authSlice";
import Magazine from "features/Home/pages/Magazine";

function App() {
  const {i18n} = useTranslation();
  const language = localStorage.getItem("language");
  const languageState = useAppSelector(selectLanguage);
  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    } else {
      i18n.changeLanguage(languageState);
    }
  }, [language, languageState]);

  return (
    <Routes>
      <Route
        path="/home"
        element={
          <HomeTemplate>
            <HomePage />
          </HomeTemplate>
        }
      />

      <Route
        path="/profile"
        element={
          <HomeTemplate>
            <Profile />
          </HomeTemplate>
        }
      />
      {/* Community */}

      <Route
        path="/community"
        element={
          <HomeTemplate>
            <Community />
          </HomeTemplate>
        }
      />

      {/* Magazine */}
      <Route
        path="/magazine"
        element={
          <HomeTemplate>
            <Magazine />
          </HomeTemplate>
        }
      />
      {/* Road Lesson Month */}
      <Route
        path="/lesson-month"
        element={
          <HomeTemplate>
            <SlideMonth />
          </HomeTemplate>
        }
      />

      {/* Lesson user */}
      <Route
        path="/list-lesson"
        element={
          <HomeTemplate>
            <ListLesson />
          </HomeTemplate>
        }
      />
      <Route
        path="/lesson/:id"
        element={
          <HomeTemplate>
            <DetailLesson />
          </HomeTemplate>
        }
      />

      {/* Academic Record */}

      <Route
        path="/academic-record"
        element={
          <HomeTemplate>
            <AcademicRecord />
          </HomeTemplate>
        }
      />
      {/* Exam User */}

      <Route
        path="/exam-upcoming"
        element={
          <HomeTemplate>
            <ListExamStudent />
          </HomeTemplate>
        }
      />

      <Route
        path="/test-history"
        element={
          <HomeTemplate>
            <ListExamHistory />
          </HomeTemplate>
        }
      />

      <Route
        path="/exam"
        element={
          <HomeTemplate>
            <TakeATest />
          </HomeTemplate>
        }
      />
      <Route
        path="/exam/take-exam"
        element={
          <HomeTemplate>
            <BeginExam />
          </HomeTemplate>
        }
      />

      <Route
        path="/exam/result-exam/:id"
        element={
          <HomeTemplate>
            <ResultExam />
          </HomeTemplate>
        }
      />

      <Route
        path="/test/detail/:id"
        element={
          <HomeTemplate>
            <DetailResultTest />
          </HomeTemplate>
        }
      />
      {/* Login */}
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />

      {/* Admin */}
      <Route path="admin/*" element={<AdminTemPlate />} />

      <Route path="/" element={<Navigate to="/login" />} />
    </Routes>
  );
}

export default App;
