import React, {Fragment} from "react";
import _ from "lodash";

export interface RadialSeparatorsProps {
  count: number;
  style: {
    background: String;
    width: String;
    height: String;
  };
}

export default function RadialSeparators({count, style}: RadialSeparatorsProps) {
  const Separator = (props: any) => {
    return (
      <div
        style={{
          position: "absolute",
          height: "100%",
          transform: `rotate(${props.turns}turn)`,
        }}
      >
        <div style={props.style} />
      </div>
    );
  };

  const turns = 1 / count;
  return (
    <>
      {_.range(count).map((index) => (
        <Fragment key={index}>
          <Separator turns={index * turns} style={style} />
        </Fragment>
      ))}
    </>
  );
}
