import {yupResolver} from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import {useTheme} from "@material-ui/core/styles";
import authApi from "api/authApi";
import managerGroupApi from "api/managerGroupApi";
import {useAppDispatch, useAppSelector} from "app/hooks";
import BACK_GROUND from "assets/img/bg_register1.jpg";
import LOGO from "assets/img/logo.png";
import InputField from "components/FormFields/InputField";
import {SelectField} from "components/FormFields/SelectFiled";
import {
  fetchDataOptions,
  fetchListFarm,
  fetchListProvince,
  fetchListWorkTypes,
  selectListArea,
  selectListClass,
  selectListDepartment,
  selectListFarm,
  selectListProvince,
  selectListWorkTypes,
} from "features/Auth/options/optionsSlice";
import {UserRegister} from "models/auth";
import {ResponseStatus} from "models/common";
import {ListOptions, ListResponseOptions} from "models/managerGroup";
import React, {useEffect, useState} from "react";
import {useController, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import * as yup from "yup";
import useStyles from "./style";
import moment from "moment";
import {IClass} from "models/managerClass";

const schema = yup.object().shape({
  email: yup.string().email("*Vui lòng nhập đúng định dạng email"),
  staffId: yup.string().required("*Mã nhân viên bắt buộc nhập ."),
  password: yup.string().required("*Mật khẩu bắt buộc nhập."),
  confirmPassword: yup
    .string()
    .required("*Xác nhận mật khẩu bắt buộc nhập.")
    .oneOf([yup.ref("password"), null], "*Mật khẩu phải trùng khớp với nhau."),
  name: yup.string().required("*Họ và tên bắt buộc nhập."),
  classId: yup.string().required("*Lớp học bắt buộc chọn"),
  // areaId: yup.string().required("*Khu vực bắt buộc chọn."),
  // phone: yup.string().required("*Số điện thoại bắt buộc nhập."),
  // dateOfJoining: yup.string().required("*Ngày gia nhập công ty bắt buộc nhập."),
  // departmentId: yup.string().required("*Phòng ban bắt buộc chọn."),
  // position: yup.string().required("*Chức vụ bắt buộc chọn."),
});

export default function Register() {
  const {t} = useTranslation();

  const [disabledCity, setDisabledCity] = useState(true);
  const [disableWorkType, setDisableWorkType] = useState(true);
  const [disableFarm, setDisableFarm] = useState(true);
  const navigate = useNavigate();
  // const [disableDepartment, setDisableDepartment] = useState(true);
  // const listArea = useAppSelector(selectListArea);
  // const listWorkTypes = useAppSelector(selectListWorkTypes);
  // const listProvince = useAppSelector(selectListProvince);
  // const listFarm = useAppSelector(selectListFarm);
  // const listDepartment = useAppSelector(selectListDepartment);
  const listClass = useAppSelector(selectListClass);
  const [areaId, setAreaId] = useState<number | undefined>();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints?.down("xs"));
  useEffect(() => {
    dispatch(fetchDataOptions());
  }, [dispatch]);

  const initialValues: UserRegister = {
    email: "",
    staffId: "",
    password: "",
    confirmPassword: "",
    name: "",
    areaId: "",
    phone: "",
    provinceId: "",
    dateOfJoining: "",
    workTypeId: "",
    position: "",
    farmId: "",
    departmentId: "",
    newProvinceId: "",
    classId: "",
  };

  const {control, handleSubmit, setValue, getValues} = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });

  const handleSubmitForm = async (infoUser: UserRegister) => {
    try {
      const res: ResponseStatus = await authApi.register({
        ...infoUser,
        areaId: 3,
        departmentId: 2,
        newProvinceId: 45,
        position: "Nhân viên",
        dateOfJoining: moment(new Date()).format("YYYY-MM-DD"),
        classId: Number(infoUser.classId),
      });
      if (res.responseCode === "00") {
        navigate("/login");
        toast.success(t("auth.registerSuccess"));
      } else if (res.responseCode === "02") {
        toast.error(t("auth.emalOrStaffIdExisted"));
      } else if (res.responseCode === "01") {
        toast.error(t("auth.missingField"));
      } else {
        toast.error(res.responseDescription);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const classes = useStyles({background: BACK_GROUND});

  // useEffect(() => {
  //   if (disableFarm) {
  //     setValue("farmId", "");
  //   }
  //   if (disableWorkType) {
  //     setValue("workTypeId", "");
  //   }
  //   if (disabledCity) {
  //     setValue("provinceId", "");
  //   }
  // }, [disableFarm, disableWorkType, disabledCity]);

  // const handleGetListWorkType = async (id: number | undefined) => {
  //   try {
  //     const res: ListResponseOptions<ListOptions> = await managerGroupApi.getWorkTypeByArea(id);
  //     if (res.responseCode === "00") {
  //       dispatch(fetchListWorkTypes(res));
  //     }
  //   } catch (error) {}
  // };
  // const handleClickListArea = async (option: ListOptions) => {
  //   setDisableDepartment(false);
  //   setAreaId(option.id);
  //   await handleGetListProvinceByAreaId(option);
  // };

  // const handleGetListProvinceByAreaId = async (option: ListOptions) => {
  //   setDisabledCity(false);
  //   try {
  //     const res: ListResponseOptions<ListOptions> = await managerGroupApi.getProvincesByAreaId(
  //       option?.id
  //     );
  //     if (res.responseCode === "00") {
  //       dispatch(fetchListProvince(res));
  //     }
  //   } catch (error) {}
  // };

  // const handleGetListFarm = async (option: ListOptions) => {
  //   setDisableFarm(false);
  //   try {
  //     const res: ListResponseOptions<ListOptions> = await managerGroupApi.getFarms(
  //       option?.id,
  //       getValues().workTypeId
  //     );
  //     if (res.responseCode === "00") {
  //       dispatch(fetchListFarm(res));
  //     }
  //   } catch (error) {}
  // };
  // const handleTurnOnDisable = () => {
  //   setDisableWorkType(true);
  //   setDisableFarm(true);
  // };

  // const handleTurnOffDisable = () => {
  //   handleGetListWorkType(areaId);
  //   setDisableWorkType(false);
  //   if (getValues("workTypeId") === "") {
  //     setDisableFarm(true);
  //   } else {
  //     setDisableFarm(false);
  //   }
  // };
  return (
    <Box className={classes.background}>
      <Box className={classes.logo}>
        <img src={LOGO} alt="logo" />
      </Box>
      <Box className={classes.container}>
        <Box className={classes.content}>
          <Box className={classes.form}>
            <Typography variant="h6" align="center" className={classes.form__title}>
              {t("auth.haveAccount")}{" "}
              <Typography variant="h6" component="span" onClick={() => navigate("/login")}>
                {t("auth.login")}
              </Typography>
            </Typography>
            <form onSubmit={handleSubmit(handleSubmitForm)}>
              <Grid container className={classes.form__content}>
                <Grid item xs={6}>
                  <InputField
                    name="staffId"
                    label={t("auth.code") + "*"}
                    control={control}
                    borderRadius={isMobile ? "5px" : "10px"}
                    required={true}
                    autocomplete={true}
                  />
                </Grid>

                <Grid item xs={6}>
                  <InputField
                    name="email"
                    label={t("auth.email") + "*"}
                    control={control}
                    borderRadius={isMobile ? "5px" : "10px"}
                    required={true}
                    autocomplete={true}
                  />
                </Grid>

                <Grid item xs={6}>
                  <InputField
                    name="password"
                    label={t("auth.password") + "*"}
                    control={control}
                    borderRadius={isMobile ? "5px" : "10px"}
                    inputType="password"
                    required={true}
                  />
                </Grid>

                <Grid item xs={6}>
                  <InputField
                    name="confirmPassword"
                    inputType="password"
                    label={t("auth.confirm.password") + "*"}
                    control={control}
                    borderRadius={isMobile ? "5px" : "10px"}
                    required={true}
                  />
                </Grid>

                <Grid item xs={6}>
                  <InputField
                    name="name"
                    label={t("auth.fullName") + "*"}
                    control={control}
                    borderRadius={isMobile ? "5px" : "10px"}
                    required={true}
                    autocomplete={true}
                  />
                </Grid>

                <Grid item xs={6}>
                  <InputField
                    name="phone"
                    label={t("auth.phone")}
                    control={control}
                    borderRadius={isMobile ? "5px" : "10px"}
                    type="number"
                    // required={true}
                    autocomplete={true}
                  />
                </Grid>

                <Grid item xs={6}>
                  <SelectField
                    name="classId"
                    label={t("auth.class")}
                    options={listClass.map((item: IClass) => ({
                      id: item.id,
                      name: item.name,
                    }))}
                    control={control}
                    borderRadius={isMobile ? "5px" : "10px"}
                    required
                  />
                </Grid>
                {/* <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    size="small"
                    error={invalid}
                    // required
                  >
                    <InputLabel id="areaId" className={classes.label}>
                      {t("auth.area")}
                    </InputLabel>
                    <Select
                      labelId="areaId"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      className={classes.root}
                      label={t("auth.area")}
                      inputProps={{
                        name: "areaId",
                        id: "areaId_label",
                      }}
                    >
                      {listArea?.map((option) => (
                        <MenuItem
                          key={option.id}
                          value={option.id}
                          onClick={() => handleClickListArea(option)}
                        >
                          <span className={classes.options}>{option.name}</span>
                        </MenuItem>
                      ))}
                    </Select>

                    <FormHelperText className={classes.error}>{error?.message}</FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <SelectField
                    name="departmentId"
                    label={t("auth.department")}
                    options={listDepartment.map((department) => ({
                      id: department.id,
                      name: department.title,
                    }))}
                    disabled={disableDepartment}
                    control={control}
                    borderRadius={isMobile ? "5px" : "10px"}
                    // required={true}
                    handleClick={(option: any) =>
                      option.name === "Sản Xuất" ? handleTurnOffDisable() : handleTurnOnDisable()
                    }
                  />
                </Grid>

                <Grid item xs={6}>
                  <SelectField
                    name="workTypeId"
                    label={t("auth.workType")}
                    // options={listWorkTypes}
                    options={listWorkTypes.map((workType) => ({
                      id: workType.workType.id,
                      name: workType.workType.name,
                    }))}
                    disabled={disableWorkType}
                    control={control}
                    borderRadius={isMobile ? "5px" : "10px"}
                    workType="workType"
                  />
                </Grid>

                <Grid item xs={6}>
                  <SelectField
                    name="newProvinceId"
                    label={t("auth.city")}
                    options={listProvince}
                    control={control}
                    disabled={listProvince.length === 0 ? true : disabledCity}
                    // required={true}
                    borderRadius={isMobile ? "5px" : "10px"}
                    handleClick={handleGetListFarm}
                  />
                </Grid>

                <Grid item xs={6}>
                  <SelectField
                    name="farmId"
                    label={t("auth.farm")}
                    options={listFarm}
                    control={control}
                    disabled={listFarm.length === 0 ? true : disableFarm}
                    borderRadius={isMobile ? "5px" : "10px"}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SelectField
                    name="position"
                    label={t("auth.position")}
                    // required={true}
                    options={[
                      {
                        id: "Tổng quản lý",
                        name: "Tổng quản lý",
                      },
                      {
                        id: "Quản lý",
                        name: "Quản lý",
                      },
                      {
                        id: "Giám sát",
                        name: "Giám sát",
                      },
                      {
                        id: "Trưởng trại",
                        name: "Trưởng trại",
                      },
                      {
                        id: "Kỹ thuật trại",
                        name: "Kỹ thuật trại",
                      },
                      {
                        id: "Công nhân",
                        name: "Công nhân",
                      },
                      {
                        id: "Nhân viên",
                        name: "Nhân viên",
                      },
                      {
                        id: "Khác",
                        name: "Khác",
                      },
                    ]}
                    control={control}
                    borderRadius={isMobile ? "5px" : "10px"}
                  />
                </Grid>

                <Grid item xs={6}>
                  <InputField
                    type="date"
                    label={t("auth.date.join")}
                    control={control}
                    name="dateOfJoining"
                    borderRadius={isMobile ? "5px" : "10px"}
                    inputType="date"
                    // required={true}
                  />
                </Grid> */}
              </Grid>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.btn_submit}
                >
                  {t("auth.register")}
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
