import {TOKEN} from "constants/config";
import React from "react";
import {Navigate, RouteProps} from "react-router-dom";
import Header from "./pages/Header";

const HomeTemplate: React.FC<RouteProps> = ({children}) => {
  const token = localStorage.getItem(TOKEN);
  const role = localStorage.getItem("ROLE");
  return token && role === "STUDENT" ? (
    <>
      <Header />
      {children}
    </>
  ) : (
    <Navigate to="/" />
  );
};
export default HomeTemplate;
