import { useCallback } from "react";
import { debounce } from "lodash";

const useDebouncedSetter = (updater: any, delay = 300) => {
  // Memoized debounce function
  const debouncedUpdater = useCallback(
    debounce((newState) => {
      updater(newState);
    }, delay),
    [updater, delay]
  );

  // Function to update state with debounce
  const setDebouncedState = useCallback(
    (newState) => {
      debouncedUpdater(newState);
    },
    [debouncedUpdater]
  );

  return setDebouncedState;
};

export default useDebouncedSetter;
