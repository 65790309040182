import {
  Box,
  Button,
  createStyles,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import FileDownload from "js-file-download";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {Pagination} from "@material-ui/lab";
import {withStyles} from "@material-ui/styles";
import managerTestApi from "api/managerTest";
import BACKGROUND from "assets/img/bg_listTest_bycode.png";
import axios, {AxiosResponse} from "axios";
import {BASE_URL, TOKEN} from "constants/config";
import {ListExam, ListTest, ListTestByCodeExamResponse} from "models/managerTest";
import React, {useCallback, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {dateTimeToFormatHHMM, dateTimeToFormatYYYY} from "utils/dateTimeFormat";
import {secondsToHms} from "utils/timeConvert";

export default function ListTestByCodeExam() {
  const useStyles = makeStyles((theme: Theme) => ({
    background: {
      backgroundImage: `url(${BACKGROUND})`,
      backgroundSize: "cover",
      backgroundPositionX: "right",
      backgroundRepeat: "no-repeat",
      height: 150,
      width: "100%",
      position: "relative",
      "& >h6": {
        position: "absolute",
        bottom: 30,
        left: 0,
        color: "#004673",
        fontSize: 20,
        fontWeight: 700,
      },
    },
    btn_export_all: {
      position: "absolute",
      bottom: 30,
      left: 210,
      background: "linear-gradient(360deg, #019ABB 0%, rgba(167, 255, 255, 0.9) 168.18%)",
      borderRadius: 10,
      fontSize: 11,
      color: "#fff",
      marginRight: 10,
      marginLeft: 10,
      padding: "5px 8px",
      [theme.breakpoints?.up("xl")]: {
        fontSize: 13,
        padding: "5px 12px",
      },
    },
    header: {
      backgroundColor: "#fff",
      width: "100%",
      padding: "15px 20px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      boxShadow: "0px 2px 4px rgba(168, 168, 168, 0.25)",
      borderRadius: "6px 5px 0px 0px",
      "& > h6": {
        fontWeight: 600,
        fontSize: 15,
        [theme.breakpoints?.up("xl")]: {
          fontSize: 18,
        },
      },
      "& > p": {
        color: "#DC2138",
        fontSize: 15,
        [theme.breakpoints?.up("xl")]: {
          fontSize: 18,
        },
        "& >span": {
          color: "#1B1B1B",
          fontSize: 15,
          marginLeft: 15,
          [theme.breakpoints?.up("xl")]: {
            fontSize: 18,
          },
        },
      },
    },
    btn_goBack: {
      position: "absolute",
      top: -15,
      left: -20,
      zIndex: 99,
    },
    btn_detail: {
      background: "linear-gradient(0deg, #10BCA7 0%, rgba(255, 239, 156, 0.9) 165.52%)",
      borderRadius: 10,
      fontSize: 11,
      color: "#fff",
      padding: "5px 8px",
      [theme.breakpoints?.up("xl")]: {
        fontSize: 13,
        padding: "5px 12px",
      },
    },
    container: {
      maxHeight: 500,
    },
    btn_export: {
      background: "linear-gradient(360deg, #019ABB 0%, rgba(167, 255, 255, 0.9) 168.18%)",
      borderRadius: 10,
      fontSize: 11,
      color: "#fff",
      marginRight: 10,
      marginLeft: 10,
      padding: "5px 8px",
      [theme.breakpoints?.up("xl")]: {
        fontSize: 13,
        padding: "5px 12px",
      },
    },
    btn_import: {
      background: "linear-gradient(0deg, #FD5468 0%, rgba(255, 193, 200, 0.9) 169.32%)",
      borderRadius: 10,
      fontSize: 11,
      color: "#fff",
      marginRight: 10,
      padding: "5px 8px",
      [theme.breakpoints?.up("xl")]: {
        fontSize: 13,
        padding: "5px 12px",
      },
    },
  }));
  const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
      head: {
        padding: "16px 5px",
        // backgroundColor: "#F3F3F3CC",
        color: "#1B1B1B",
        fontSize: 11,
        fontWeight: 700,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        borderBottom: "1px solid #777777",
        [theme.breakpoints?.up("xl")]: {
          fontSize: 13,
        },
      },
      body: {
        padding: "16px 5px",
        fontSize: 11,
        borderRight: "1px solid rgba(224, 224, 224, 0.8)",
        [theme.breakpoints?.up("xl")]: {
          fontSize: 13,
        },
      },
    })
  )(TableCell);
  const token = localStorage.getItem(TOKEN);
  const {t} = useTranslation();
  const classes = useStyles();
  const params = useParams();
  const navigate = useNavigate();
  const [detailExam, setDetailExam] = useState<ListExam>();
  const [listTest, setListTest] = useState<ListTestByCodeExamResponse<ListTest>>();
  const [filter, setFilter] = useState({
    page: 0,
    size: 10,
  });

  useEffect(() => {
    (async () => {
      const res: ListExam = await managerTestApi.getDetailExam(Number(params.id));
      setDetailExam(res);
    })();
  }, []);
  const handleFetchListTest = useCallback(async () => {
    try {
      const res: ListTestByCodeExamResponse<ListTest> = await managerTestApi.getListTest(
        Number(params.id),
        filter
      );
      if (res.responseCode === "00") {
        setListTest(res);
      }
    } catch (error) {
      console.log(error);
    }
  }, [filter]);

  useEffect(() => {
    handleFetchListTest();
  }, [filter]);

  const handleChangePage = (page: number) => {
    setFilter({...filter, page: page - 1});
  };

  const handleImport = async (event: any) => {
    const fileExcel = event?.target?.files[0];
    const formData = new FormData();
    formData.append("file", fileExcel);
    try {
      const res: AxiosResponse = await axios({
        method: "POST",
        url: `${BASE_URL}/submissions/upload`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });
      if (res.data.responseCode === "00") {
        toast.success(`${t("managerTest.nhapdulieuthanhcong")}`);
        handleFetchListTest();
      } else {
        toast.error(`${t("managerTest.nhapdulieuthatbai")}`);
      }
    } catch (error) {}
  };

  const handleExportExam = async (linkApi: any, name?: string) => {
    if (!linkApi) return;
    const token = localStorage.getItem(TOKEN);
    try {
      const res: AxiosResponse = await axios({
        method: "GET",
        url: linkApi,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "blob",
        },
        responseType: "arraybuffer",
      });
      if (res.data.responseDescription) {
        toast.error(t("file.xuatthatbai"));
        return;
      }
      const blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      FileDownload(blob, `${name}.xlsx`);
      toast.success(t("file.xuatthanhcong"));
    } catch (error) {}
  };

  return (
    <Box style={{position: "relative"}}>
      <Box className={classes.btn_goBack}>
        <Tooltip title={t("button.goback") || ""}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIosIcon style={{marginLeft: 7}} />
          </IconButton>
        </Tooltip>
      </Box>
      <Box className={classes.background}>
        <Typography variant="subtitle2">{t("managerTest.danhsachbaithi").toUpperCase()}</Typography>
        <Button
          className={classes.btn_export_all}
          onClick={() =>
            handleExportExam(
              `${BASE_URL}/submissions/download/${params.id}`,
              `${t("managerTest.danhsachbaithi")}-${params.id}`
            )
          }
        >
          {t("managerTest.xuatfile")}
        </Button>
      </Box>

      <Box className={classes.header}>
        <Typography variant="subtitle2">{detailExam?.exam?.examTitle}</Typography>

        <Typography variant="body2">
          {dateTimeToFormatHHMM(detailExam?.exam?.startDate)}
          <Typography variant="body2" component="span">
            {dateTimeToFormatYYYY(detailExam?.exam?.startDate)}
          </Typography>
        </Typography>

        <Typography variant="subtitle2">
          {detailExam?.exam?.type === "TYPING"
            ? `${t("managerTest.tuluan")}`
            : `${t("managerTest.tracnghiem")}`}
        </Typography>
      </Box>
      <Box>
        {listTest && listTest?.submissionDetails?.content?.length > 0 ? (
          <>
            <TableContainer component={Paper} className={classes.container}>
              <Table
                stickyHeader
                // style={{minWidth: 1400}}
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center" width="70px">
                      {t("stt")}
                    </StyledTableCell>
                    <StyledTableCell align="center" width="150px">
                      {t("managerTest.tenthisinh")}
                    </StyledTableCell>
                    <StyledTableCell align="center" width="120px">
                      {t("managerTest.manhanvien")}
                    </StyledTableCell>
                    {/* <StyledTableCell align="center" width="150px">
                      {t("managerTest.khuvuc")}
                    </StyledTableCell>
                    <StyledTableCell align="center" width="200px">
                      {t("managerTest.tinh")}
                    </StyledTableCell>
                    <StyledTableCell align="center" width="150px">
                      {t("managerTest.bophan")}
                    </StyledTableCell>
                    <StyledTableCell align="center" width="150px">
                      {t("managerTest.phongban")}
                    </StyledTableCell>
                    <StyledTableCell align="center" width="150px">
                      {t("managerTest.trai")}
                    </StyledTableCell> */}
                    <StyledTableCell align="center" width="100px">
                      {t("managerTest.diem")}
                    </StyledTableCell>
                    <StyledTableCell align="center" width="100px">
                      {t("managerTest.thoigian")}
                    </StyledTableCell>

                    <StyledTableCell
                      align="center"
                      width={detailExam?.exam?.type === "TYPING" ? "420px" : "100px"}
                    >
                      {detailExam?.exam?.type === "TYPING" ? (
                        <Box>
                          <Button
                            className={classes.btn_export}
                            onClick={() =>
                              handleExportExam(
                                `${BASE_URL}/submissions/download/${params.id}`,
                                `${t("managerTest.danhsachbaithi")}-${params.id}`
                              )
                            }
                          >
                            {t("managerTest.xuattatca")}
                          </Button>
                          <label
                            htmlFor="contained-button-file"
                            className={classes.btn_import}
                            style={{
                              padding: 8,
                              cursor: "pointer",
                              textTransform: "uppercase",
                              fontWeight: 500,
                            }}
                          >
                            {t("managerTest.nhaptatca")}
                          </label>
                          <input
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8ent.spreadsheetml.sheet"
                            id="contained-button-file"
                            multiple
                            type="file"
                            name="image"
                            onChange={handleImport}
                            style={{display: "none"}}
                          />
                        </Box>
                      ) : (
                        `${t("thaotac")}`
                      )}
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listTest?.submissionDetails?.content.map((test, index) => (
                    <TableRow key={test?.id}>
                      <StyledTableCell align="center" component="th" scope="test">
                        {listTest?.submissionDetails?.size * listTest?.submissionDetails?.number +
                          index +
                          1}
                      </StyledTableCell>
                      <StyledTableCell align="center">{test?.student?.name}</StyledTableCell>
                      <StyledTableCell align="center">{test?.student?.staffId}</StyledTableCell>
                      {/* <StyledTableCell align="center">{test?.student?.area?.name}</StyledTableCell>
                      <StyledTableCell align="center">
                        {test?.student?.newProvince?.name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {test?.student?.department?.title}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {test?.student?.workType?.name}
                      </StyledTableCell>
                      <StyledTableCell align="center">{test?.student?.farm?.name}</StyledTableCell> */}
                      <StyledTableCell align="center">{test?.point}</StyledTableCell>
                      {/* <StyledTableCell align="center"></StyledTableCell> */}
                      <StyledTableCell align="center">
                        {secondsToHms(test?.duration)}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        <Button
                          className={classes.btn_detail}
                          onClick={() =>
                            navigate(`/admin/manager-test/exam/list-test/detail/${test.id}`, {
                              state: {
                                examId: test?.examId,
                              },
                            })
                          }
                        >
                          {t("button.detail")}
                        </Button>
                        {detailExam?.exam?.type === "TYPING" && (
                          <>
                            <Button
                              className={classes.btn_export}
                              onClick={() =>
                                handleExportExam(
                                  `${BASE_URL}/submission/download/${test.id}`,
                                  `FILE-${test?.student?.name}`
                                )
                              }
                            >
                              {t("managerTest.xuatfile")}
                            </Button>
                            <Button
                              className={classes.btn_import}
                              onClick={() =>
                                navigate(`/admin/manager-test/exam/list-test/detail/${test.id}`, {
                                  state: {
                                    examId: test?.examId,
                                    editPoint: true,
                                  },
                                })
                              }
                            >
                              {t("managerTest.suadiem")}
                            </Button>
                          </>
                        )}
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {listTest.submissionDetails.totalPages > 1 && (
              <Box mt={3} display="flex" justifyContent="space-between">
                <Typography variant="subtitle2" style={{fontWeight: 600}}>
                  {t("managerTest.tongbaithi")}: {listTest.submissionDetails.totalElements}
                </Typography>
                <Pagination
                  count={Math.ceil(
                    listTest?.submissionDetails?.totalElements / listTest?.submissionDetails?.size
                  )}
                  page={listTest?.submissionDetails?.number + 1}
                  variant="outlined"
                  color="primary"
                  shape="rounded"
                  onChange={(e, page) => handleChangePage(page)}
                />
              </Box>
            )}
          </>
        ) : (
          <Box mt={3}>
            <Typography variant="subtitle2">{t("managerTest.hienchuacobaithi")}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
