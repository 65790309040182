import managerCommnunityApi from "api/managerCommunity";
import {PayloadAction} from "@reduxjs/toolkit";
import {NewListParams} from "models/common";
import {ListResponseCommunityPost, CommnunityPostList} from "models/managerCommunityPost";
import {call, put, takeLatest} from "redux-saga/effects";
import {
  fetchCommunityPostList,
  fetchCommunityPostListSuccess,
  fetchCommunityPostListFailed,
} from "./communityPostSlice";
import _ from "lodash";

function* fetchDataCommunityPostList(action: PayloadAction<NewListParams>) {
  try {
    const res: ListResponseCommunityPost<CommnunityPostList> = yield call(
      managerCommnunityApi.getListPost,
      _.pick(
        action.payload,
        "status_type",
        "sort_type",
        "page_index",
        "page_size",
        "category_id_list",
        "content",
        "owner_id"
      )
    );
    if (res.responseCode === "00") {
      yield put(fetchCommunityPostListSuccess(res));
    } else {
      yield put(fetchCommunityPostListFailed());
    }
  } catch (error) {}
}

export default function* managerCommunityPostSaga() {
  yield takeLatest(fetchCommunityPostList, fetchDataCommunityPostList);
}
