import {
  Box,
  Button,
  createStyles,
  IconButton,
  Modal,
  Slide,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CloseIcon from "@material-ui/icons/Close";
import managerClassApi from "api/managerClass";
import {useAppDispatch, useAppSelector} from "app/hooks";
import {useConfirm} from "material-ui-confirm";
import {ResponseStatus} from "models/common";
import {IClass} from "models/managerClass";
import React, {Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {
  fetchClassList,
  selectClassList,
  selectFilterClassList,
  selectPageInfoClass,
  setFilterClass,
} from "../../managerClassSlice";
import EditClass from "../EditClass";
import useStyles from "./style";
import EmptyComponent from "components/Empty";
import {useSelector} from "react-redux";
import {Pagination} from "@material-ui/lab";
import {useNavigate} from "react-router-dom";

export default function TableClass() {
  const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
      head: {
        padding: "16px 5px",
        // backgroundColor: "#F3F3F3CC",
        color: "#1B1B1B",
        fontSize: 11,
        fontWeight: 700,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        borderBottom: "1px solid #777777",
        [theme.breakpoints?.up("xl")]: {
          fontSize: 13,
        },
      },
      body: {
        padding: "16px 5px",
        fontSize: 11,
        borderRight: "1px solid rgba(224, 224, 224, 0.8)",
        [theme.breakpoints?.up("xl")]: {
          fontSize: 13,
        },
      },
    })
  )(TableCell);
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const classList = useAppSelector(selectClassList);
  const filterClassList = useSelector(selectFilterClassList);
  const pageInfo = useSelector(selectPageInfoClass);
  const classes = useStyles();
  const confirm = useConfirm();
  const navigate = useNavigate();

  const [valueClass, setValueClass] = useState<IClass>({
    name: "",
    description: "",
    id: 0,
  });
  useEffect(() => {
    dispatch(fetchClassList(filterClassList));
  }, [dispatch, filterClassList]);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleDeleteClass = (item: IClass) => {
    confirm({
      title: (
        <Typography variant="h5" color="secondary">
          {t("delete.chuy")}
        </Typography>
      ),
      description: (
        <Typography variant="subtitle2">
          {t("delete.khiban")}{" "}
          <Typography variant="subtitle2" component="span" color="secondary">
            {t("delete.dongy")}
          </Typography>{" "}
          {t("delete.xoa")}
          <Typography variant="subtitle2" component="span" style={{color: "#004673"}}>
            {" "}
            {item.name}
          </Typography>{" "}
          {t("delete.sexoatatcadulieu")}
        </Typography>
      ),
      confirmationText: <Button className={classes.btn_delete}> {t("delete.dongy")}</Button>,
      cancellationText: (
        <Button className={classes.btn_edit} style={{marginRight: 0}}>
          {t("delete.huybo")}
        </Button>
      ),
    })
      .then(async () => {
        const res: ResponseStatus = await managerClassApi.deleteClass(item.id);
        if (res.responseCode === "00") {
          toast.success(`${t("managerClass.xoaclassthanhcong")}`);
          dispatch(fetchClassList(filterClassList));
        } else {
          if (res.responseCode === "30") {
            toast.error(`${t("managerClass.xoaclassthatbai")}`);
            throw new Error("Delete class is failed");
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEditClass = (value: IClass) => {
    setOpenModal(true);
    setValueClass(value);
  };

  const handleChangePage = (page: number) => {
    dispatch(
      setFilterClass({
        ...filterClassList,
        page: page - 1,
      })
    );
  };
  return (
    <Fragment>
      <Box>
        {classList.length > 0 ? (
          <>
            <TableContainer component={Paper} className={classes.container}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center" width="130px">
                      {t("stt")}
                    </StyledTableCell>
                    <StyledTableCell align="center">{t("managerClass.tenclass")}</StyledTableCell>
                    <StyledTableCell align="center">{t("managerClass.mota")}</StyledTableCell>
                    <StyledTableCell align="center" width="170px">
                      {t("managerClass.soluong")}
                    </StyledTableCell>
                    <StyledTableCell align="center" width="300px">
                      {t("thaotac")}
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {classList.map((item: IClass, index: number) => (
                    <TableRow key={item.id}>
                      <StyledTableCell align="center" component="th" scope="row">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell align="center">{item.name}</StyledTableCell>
                      <StyledTableCell align="center">{item.description}</StyledTableCell>
                      <StyledTableCell align="center">{item.numberStudents}</StyledTableCell>
                      <StyledTableCell align="center">
                        <Button
                          className={classes.btn_delete}
                          onClick={() => handleDeleteClass(item)}
                        >
                          {t("button.delete")}
                        </Button>
                        <Button className={classes.btn_edit} onClick={() => handleEditClass(item)}>
                          {t("button.edit")}
                        </Button>

                        <Button
                          className={classes.btn_detail}
                          onClick={() => navigate(`/admin/manager-class/detail/${item?.id}`)}
                        >
                          {t("button.detail")}
                        </Button>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {pageInfo?.totalPages > 1 && (
              <Box mt={3} display="flex" justifyContent="space-between">
                <Typography variant="subtitle2" style={{fontWeight: 600}}>
                  {"Tổng lớp học"}: {pageInfo.totalElements}
                </Typography>
                <Pagination
                  count={Math.ceil(pageInfo?.totalElements / pageInfo?.pageSize)}
                  page={pageInfo?.pageIndex + 1}
                  variant="outlined"
                  color="primary"
                  shape="rounded"
                  onChange={(e, page) => handleChangePage(page)}
                />
              </Box>
            )}
          </>
        ) : (
          <EmptyComponent />
        )}
        <Box mt={3} display="flex" justifyContent="flex-end"></Box>
      </Box>

      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        closeAfterTransition
        className={classes.root_modal}
      >
        <Slide direction="up" in={openModal}>
          <Box className={classes.modal__container}>
            <Box className={classes.modal__header}>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
              <Typography variant="subtitle2">{t("managerClass.capnhatclass")}</Typography>
              <div></div>
            </Box>
            <EditClass valueClass={valueClass} setOpenModal={setOpenModal} />
          </Box>
        </Slide>
      </Modal>
    </Fragment>
  );
}
