import {makeStyles, Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: "#f0f0f0",
    padding: "10px",
    [theme.breakpoints?.up("sm")]: {
      padding: "20px",
    },
  },

  wrapper: {
    backgroundColor: "#E5E5E5",
    padding: 25,
    borderRadius: 10,
    width: "95%",
    margin: "0 auto",
    height: "100%",
  },
  content_info: {
    background: " #FFFFFF",
    boxShadow: "0px 0px 6px rgba(168, 168, 170, 0.3), 0px 2px 4px rgba(168, 168, 168, 0.3)",
    borderRadius: 10,
    padding: " 10px",
    [theme.breakpoints?.up("sm")]: {
      padding: "0px 15px",
      height: "100%",
    },
  },
  content__info__left: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  content__info__left__title: {
    color: "#E9A051",
    fontSize: "0.7rem",
    textAlign: "center",
    padding: "15px 0",
    [theme.breakpoints?.up("sm")]: {
      fontSize: "0.8rem",
    },
    [theme.breakpoints?.up("lg")]: {
      fontSize: "1rem",
    },
  },

  content__info__avatar: {
    flexGrow: 1,
    height: "95%",
    [theme.breakpoints?.down("md")]: {
      height: "65%",
    },
    [theme.breakpoints?.down("sm")]: {
      height: "95%",
    },
    "& >img": {
      maxWidth: "100%",
      height: "100%",
      display: "block",
      margin: "auto",
      objectFit: "cover",
      borderRadius: 10,
    },
  },
  content__info__no__avatar: {
    flexGrow: 1,
    border: "1px solid #E9A051",
    width: "100%",
    borderRadius: 10,
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints?.down("md")]: {
      marginBottom: 10,
    },
    "& > div > img": {
      maxWidth: "90%",
      height: "auto",
      display: "block",
      margin: "auto",
      objectFit: "cover",
      borderRadius: 10,
    },
  },
  popup__message: {
    position: "relative",
    padding: 20,
    "&>div>img": {
      width: "90%",
      height: 40,
      position: "absolute",
      bottom: 0,
    },
  },
  hello_text: {
    position: "relative",
    "& >h5": {
      position: "absolute",
      bottom: 4,
      left: 7,
      width: "100%",
      color: "#fff",
      fontSize: 11,
      lineHeight: "25px",
      zIndex: 99,
      [theme.breakpoints?.up("sm")]: {
        fontSize: 13,
        bottom: 7,
        left: 13,
      },
      [theme.breakpoints?.up("xl")]: {
        left: 15,
        bottom: 10,
        fontSize: 16,
      },
    },
    "& > img": {
      width: 100,
      [theme.breakpoints?.up("sm")]: {
        width: 130,
      },
      [theme.breakpoints?.up("xl")]: {
        width: 150,
      },
    },
  },
  medal: {
    width: 50,
    height: 50,
    [theme.breakpoints?.up("sm")]: {
      width: 90,
      height: 90,
    },
    [theme.breakpoints?.up("xl")]: {
      width: 120,
      height: 120,
    },
  },

  content__info__right: {
    padding: "10px 5px 0px 10px",
    borderTop: "1px solid #E9A051",
    [theme.breakpoints?.up("sm")]: {
      padding: "15px 5px 0px 15px",
    },
    "& >h5": {
      fontWeight: 700,
      textTransform: "uppercase",
      fontSize: "1rem",
      [theme.breakpoints?.up("xl")]: {
        fontSize: "1.2rem",
      },
    },
    "& >h6": {
      color: "#ACACAC",
      fontSize: "0.6rem",
      paddingTop: 5,
      [theme.breakpoints?.up("sm")]: {
        fontSize: "0.8rem",
        paddingTop: 0,
      },
      [theme.breakpoints?.up("xl")]: {
        fontSize: "1rem",
      },
    },
  },
  content__info__right__name: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& >h5": {
      fontSize: "0.6rem",
      fontWeight: 600,
      [theme.breakpoints?.up("sm")]: {
        fontSize: "1rem",
      },
      [theme.breakpoints?.up("xl")]: {
        fontSize: "1.3rem",
      },
    },
  },
  content__info__right__button: {
    background: "linear-gradient(360deg, #E9A051 0%, rgba(233, 160, 81, 0.6) 111.11%)",
    color: "#ffffff",
    borderRadius: 8,
    padding: "3px",
    fontSize: 10,
    "& .MuiButton-endIcon": {
      marginLeft: 5,
    },
    [theme.breakpoints?.up("sm")]: {
      padding: "5px 8px",
      fontSize: 12,
    },
  },
  content__lessonOfMonth: {
    background: " #FFFFFF",
    boxShadow: "0px 0px 6px rgba(168, 168, 170, 0.3), 0px 2px 4px rgba(168, 168, 168, 0.3)",
    borderRadius: 10,
    paddingBottom: 0,
    [theme.breakpoints?.up("sm")]: {
      height: "100%",
    },
    "& > h5": {
      borderBottom: "1px solid  #157BBC",
      textAlign: "center",
      fontWeight: 700,
      padding: "10px 0px",
      color: "#157BBC",
      fontSize: "0.8rem",
      [theme.breakpoints?.up("xl")]: {
        fontSize: "1rem",
      },
    },
  },
  content__lessonOfMonth__wrapper: {
    padding: "10px ",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    [theme.breakpoints?.up("sm")]: {
      padding: "10px 20px",
    },
    [theme.breakpoints?.up("xl")]: {
      padding: "10px 50px",
    },
  },
  content__lessonOfMonth__point: {
    color: "#DB2139",
    fontSize: "2.5rem",
    marginLeft: 10,
    "& > span": {
      color: "#707070",
      fontSize: "2rem",
      marginLeft: 5,
    },
  },
  content__lessonOfMonth__button: {
    background: "linear-gradient(360deg, #399FD9 0%, #83CFFF 172.67%)",
    color: "#ffffff",
    borderRadius: 10,
    padding: "5px 8px",
    marginTop: 8,
    fontSize: 12,
    [theme.breakpoints?.up("xl")]: {
      marginTop: 15,
    },
  },
  content__lessonOfMonth__text: {
    fontSize: "0.7rem",
    textAlign: "center",
    padding: "5px 10px",
    [theme.breakpoints?.up("xl")]: {
      fontSize: "0.9rem",
      padding: "10px",
    },
  },

  ranking: {
    padding: "5px 10px",
    background: "#FFFFFF",
    boxShadow: "0px 0px 6px rgb(168 168 170 / 30%), 0px 2px 4px rgb(168 168 168 / 30%)",
    borderRadius: 10,
    [theme.breakpoints?.up("sm")]: {
      height: "100%",
    },
    [theme.breakpoints?.up("xl")]: {
      padding: "10px 15px",
    },
  },
  ranking__content: {
    padding: "5px 10px",
    [theme.breakpoints?.up("sm")]: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    [theme.breakpoints?.up("xl")]: {
      padding: "10px 15px",
    },
    "& >h5": {
      color: "#157BBC",
      textAlign: "center",
      fontWeight: 700,
      fontSize: "0.8rem",
      [theme.breakpoints?.up("xl")]: {
        fontSize: "1.3rem",
      },
    },
  },
  ranking__content__detail: {
    textAlign: "center",
    [theme.breakpoints?.up("sm")]: {},
    "& >h5": {
      color: "#DB2139",
      fontSize: 35,
      [theme.breakpoints?.up("xl")]: {
        fontSize: 50,
      },
      "& > span": {
        color: "#707070",
        fontSize: 25,
        [theme.breakpoints?.up("xl")]: {
          fontSize: 35,
        },
      },
    },
    "& > p": {
      color: "#157BBC",
      fontSize: 12,
      fontWeight: 700,
      marginTop: 10,
    },
  },
  ranking__content__detail__increase: {
    color: "#79D66A",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    "& >svg": {
      fontSize: 17,
    },
    "& >span": {
      color: "#000",
      marginLeft: 5,
      fontSize: 12,
    },
  },
  liquid: {
    "& > div ": {
      fontSize: 10,
    },
  },
}));

export default useStyles;
