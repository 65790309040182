import {Box, Button, Grid, IconButton, Modal, Slide, Tooltip, Typography} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import managerCommnunityApi from "api/managerCommunity";
import ICON_PDF from "assets/img/icon_pdf.png";
import NO_AVATAR from "assets/img/no_avatar.png";
import {CommnunityPostList, ListResponseCommunityPost} from "models/managerCommunityPost";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import ReactPlayer from "react-player";
import {toast} from "react-toastify";
import useStyles from "./style";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import ReactImageVideoLightbox from "react-image-video-lightbox";

export interface CardPostsProps {
  content: any;
  showBtnDeleted: boolean;
  handleApprove?: (e: any) => void;
  handleReject?: (e: any) => void;
  info?: any;
  allPost?: any;
  setAllPost?: any;
}

export default function CardPosts({
  showBtnDeleted,
  handleReject,
  handleApprove,
  content,
  info,
  allPost,
  setAllPost,
}: CardPostsProps) {
  const {t} = useTranslation();
  const classes = useStyles();
  const [itemMagazine, setItemMagazine] = useState({
    url: "",
  });
  const listAttachments = content.attachmentList;
  const [openModalPDF, setOpenModalPDF] = useState(false);
  const timeCreatedPost = (timeStamp: number) => {
    let currentTime = new Date().getTime();
    let createTime = timeStamp;
    let diff = (currentTime - createTime) / 1000; // seconds
    if (diff < 180) {
      return `${t("community.vuadang")}`;
    } else if (diff < 3600 && diff > 180) {
      diff = diff / 60;
      diff = Math.round(diff);
      return `${diff} ${t("community.phut")}`;
    } else if (diff < 86400 && diff > 3600) {
      diff = diff / 3600;
      diff = Math.round(diff);
      return `${diff} ${t("community.gio")}`;
    } else if (diff < 2592000 && diff > 86400) {
      diff = diff / 86400;
      diff = Math.round(diff);
      return `${diff} ${t("community.ngay")}`;
    } else if (diff < 28512000 && diff > 2592000) {
      diff = diff / 2592000;
      diff = Math.round(diff);
      return `${diff} ${t("community.thang")}`;
    } else if (diff > 28512000) {
      diff = diff / 2592000;
      diff = Math.round(diff);
      return `${diff} ${t("community.nam")}`;
    }
  };
  const handleClickShowModalPDF = (pdf: any) => {
    setItemMagazine({
      url: pdf.url,
    });
    setOpenModalPDF(true);
  };
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index: number) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  const onDelete = async (id: string) => {
    try {
      const res: ListResponseCommunityPost<CommnunityPostList> =
        await managerCommnunityApi.deletePost(id);
      if (res.responseCode === "00") {
        const tempAllPost = [...allPost];
        tempAllPost.forEach((item, index) => {
          if (item.id === id) {
            tempAllPost.splice(index, 1);
          }
        });
        setAllPost(tempAllPost);
        toast.success(`${t("community.xoabaivietthanhcong")}`);
      }
    } catch (error) {}
  };

  const listPdf = listAttachments.filter((x: any) => x.url.slice(-3) === "pdf");
  const handleShowVideoOrImage = (item: any, oneItem?: boolean) => {
    if (item.url.includes(".mp4")) {
      return (
        <Box width="100%" height="100%">
          <ReactPlayer
            // className={classes.react_player}
            url={item.url}
            controls={true}
            light={item.urlThumbnail}
            playIcon={<PlayArrowIcon className={classes.icon_play} />}
            width="100%"
            height="100%"
            config={{file: {attributes: {controlsList: "nodownload"}}}}
            onContextMenu={(e: any) => e.preventDefault()}
          />
        </Box>
      );
    } else {
      return (
        <img
          src={item.url}
          alt="image_post"
          className={classes.image}
          style={{width: oneItem ? "auto" : "100%"}}
        />
      );
    }
  };
  return (
    <>
      <Box className={classes.card__content}>
        <Box className={classes.card__content__info}>
          {!content.ownerPost.avatarUrl ? (
            <img src={content.ownerPost.avatarUrl} alt="avatar" className={classes.avatar} />
          ) : (
            <img src={NO_AVATAR} alt="avatar" className={classes.avatar} />
          )}

          <Box>
            <Typography variant="subtitle2" className={classes.card__username}>
              {content.ownerPost.name}
            </Typography>
            <Typography variant="subtitle2" className={classes.card__category}>
              <div style={{alignItems: "center", display: "flex"}}>
                {content.categoryList.slice(0, 5).map((x: any, index: number) => (
                  <p key={x.id} style={{margin: "0px 10px 0px 0px", fontSize: "14px"}}>
                    {`${x.name}`}
                    {index === 4 && content.categoryList.length > 5 ? "..." : ","}
                  </p>
                ))}
                <Typography variant="subtitle2">
                  {timeCreatedPost(content.createdTimestampUTC)}
                </Typography>
              </div>
            </Typography>
          </Box>
        </Box>

        <Box>
          {showBtnDeleted ? (
            info.role === "ADMIN" ||
            info.role === "SUPER_ADMIN" ||
            info.id === content.ownerPost.id ? (
              <div>
                <IconButton>
                  <Tooltip title={t("community.xoabaiviet") || ""}>
                    <div onClick={() => onDelete(content.id)}>
                      <DeleteOutlineIcon className={classes.btn_delete} />
                    </div>
                  </Tooltip>
                </IconButton>
              </div>
            ) : null
          ) : (
            <>
              <Button className={classes.btn_approve} onClick={handleApprove}>
                {t("community.pheduyet")}
              </Button>
              <Button className={classes.btn_reject} onClick={handleReject}>
                {t("community.tuchoi")}
              </Button>
            </>
          )}
        </Box>
      </Box>

      <Box px={1}>
        <Typography variant="subtitle2" className={classes.card__posted}>
          {content.content}
        </Typography>
        {listPdf.length > 0 ? (
          listPdf.map((pdf: any, index: number) => (
            <Box
              position="relative"
              style={{cursor: "pointer", margin: "5px", maxWidth: 400}}
              onClick={() => handleClickShowModalPDF(pdf)}
              key={index}
            >
              <div className={classes.bg_pdf} style={{backgroundImage: `url(${pdf.urlThumbnail})`}}>
                <div className={classes.overlay}>
                  <Box className={classes.content_overlay}>
                    <Box
                      color="#fff"
                      fontSize="20px"
                      fontWeight="700"
                      display="flex"
                      alignItems="center"
                    >
                      <img className={classes.icon_pdf} src={ICON_PDF} alt="" />
                      <p>{t("community.openfile")}</p>
                    </Box>
                  </Box>
                </div>
              </div>
            </Box>
          ))
        ) : (
          <>
            {content.attachmentList?.length === 1 ? (
              <Box
                onClick={() => openLightbox(0)}
                padding="15px 0"
                height={content.attachmentList[0].url.includes(".mp4") ? "450px" : "auto"}
              >
                {handleShowVideoOrImage(content.attachmentList[0], true)}
              </Box>
            ) : content.attachmentList?.length === 3 ? (
              <Box maxWidth="700px" height="450px" display="flex" padding="15px 0">
                <Box
                  margin="3px"
                  width="50%"
                  maxHeight="500px"
                  onClick={() => openLightbox(0)}
                  style={{padding: "2px"}}
                >
                  {handleShowVideoOrImage(content.attachmentList[0])}
                </Box>
                <Box margin="3px" width="50%" maxHeight="500px">
                  <Box height="50%" style={{paddingBottom: 2}} onClick={() => openLightbox(1)}>
                    {handleShowVideoOrImage(content.attachmentList[1])}
                  </Box>
                  <Box height="50%" onClick={() => openLightbox(2)}>
                    {handleShowVideoOrImage(content.attachmentList[2])}
                  </Box>
                </Box>
              </Box>
            ) : (
              <div className={classes.content_image}>
                <Box
                  display="flex"
                  width="100%"
                  maxHeight={content.attachmentList?.length > 2 ? "300px" : "600px"}
                >
                  {content.attachmentList.slice(0, 2).map((x: any, index: number) => (
                    <Box
                      width="calc(100% /2 )"
                      margin="3px"
                      onClick={() => openLightbox(index)}
                      key={index}
                    >
                      {handleShowVideoOrImage(x)}
                    </Box>
                  ))}
                </Box>
                {content.attachmentList?.length > 2 && (
                  <Box display="flex" maxHeight="300px">
                    {content.attachmentList?.slice(2, 5).map((item: any, index: number) => (
                      <Box
                        key={index}
                        width={
                          content.attachmentList.length > 4 ? "calc(100% /3 )" : "calc(100% /2 )"
                        }
                        margin="3px"
                        onClick={() => openLightbox(index + 2)}
                        position={
                          content.attachmentList.length > 5 && index === 2 ? "relative" : "initial"
                        }
                      >
                        {handleShowVideoOrImage(item)}
                        {content.attachmentList?.length > 5 && index === 2 && (
                          <div className={classes.overlay}>
                            <Box className={classes.content_overlay}>
                              <Box textAlign="center" color="#fff" fontSize="2rem" fontWeight="700">
                                + {content.attachmentList.length - 5}
                              </Box>
                            </Box>
                          </div>
                        )}
                      </Box>
                    ))}
                  </Box>
                )}
              </div>
            )}
          </>
        )}

        <Modal
          open={openModalPDF}
          onClose={() => setOpenModalPDF(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          closeAfterTransition
          className={classes.root_modal}
        >
          <Slide direction="up" in={openModalPDF}>
            <Box className={classes.modal__container}>
              <Box className={classes.modal__header}>
                <div></div>
                <IconButton onClick={() => setOpenModalPDF(false)}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <embed src={itemMagazine.url} width="100%" height="100%" />
            </Box>
          </Slide>
        </Modal>
      </Box>
      {viewerIsOpen && (
        <div className={classes.lightBox}>
          <ReactImageVideoLightbox
            data={listAttachments.map((x: any, index: number) => {
              return {
                url: x.url,
                type: x.url.includes(".mp4") ? "video" : "photo",
                index,
              };
            })}
            startIndex={currentImage}
            showResourceCount={true}
            onCloseCallback={closeLightbox}
          />
        </div>
      )}
    </>
  );
}
