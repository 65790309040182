export const timeConvert = (n: number) => {
  if (n / 60 < 10) {
    return "0" + Math.floor(n / 60) + ":" + (n % 60 < 10 ? "0" + (n % 60) : n % 60) + ":" + "00";
  }
  return Math.floor(n / 60) + ":" + (n % 60 < 10 ? "0" + (n % 60) : n % 60) + ":" + "00";
};

export const secondsToHms = (duration: any) => {
  var h = Math.floor(duration / 3600);
  var m = Math.floor((duration % 3600) / 60);
  var s = Math.floor((duration % 3600) % 60);
  return (h < 10 ? `0${h}` : h) + ":" + (m < 10 ? `0${m}` : m) + ":" + (s < 10 ? `0${s}` : s);
};

export const isShowVideoNonExpired = (expiredTime: number) => {
  const currentTime = new Date().getTime();
  if (currentTime - expiredTime >= 0) {
    return true;
  } else {
    return false;
  }
};
