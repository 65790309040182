import {Box, Container, makeStyles, Theme, Typography} from "@material-ui/core";
import {useAppDispatch} from "app/hooks";
import BACKGROUND from "assets/img/bg_manager_category.png";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import AddCategory from "./components/AddCategory";
import TableCategory from "./components/TableCategory";
import {fetchCategoryList} from "./managerCategorySlice";

export interface ManagerCategoryProps {}

export default function ManagerCategory(props: ManagerCategoryProps) {
  const useStyles = makeStyles((theme: Theme) => ({
    background: {
      backgroundImage: `url(${BACKGROUND})`,
      backgroundSize: "cover",
      backgroundPositionX: "right",
      backgroundRepeat: "no-repeat",
      height: 150,
      width: "100%",
      position: "relative",
      "& >h6": {
        position: "absolute",
        top: 50,
        left: 0,
        color: "#004673",
        fontSize: 20,
        fontWeight: 700,
      },
    },
  }));
  const {t} = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchCategoryList());
  }, [dispatch]);

  return (
    <Box>
      {/* Add new */}
      <AddCategory />

      <Box className={classes.background}>
        <Typography variant="subtitle2">{t("managerCategory.danhsachchude")}</Typography>
      </Box>

      {/* Table */}
      <TableCategory />
    </Box>
  );
}
