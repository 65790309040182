import {Box, Typography} from "@material-ui/core";
import {Pagination} from "@material-ui/lab";
import managerUserApi from "api/managerUser";
import NO_AVATAR from "assets/img/no_avatar.png";
import {ActivityHistory, ResponseActivityHistory} from "models/managerUser";
import moment from "moment";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {dateTimeToFormatHHMM} from "utils/dateTimeFormat";
import useActivityHistory from "utils/useActivityHistory";
import useStyles from "./style";

export interface HistoryProps {}

export default function History(props: HistoryProps) {
  const classes = useStyles();
  const {t} = useTranslation();
  const {BASE_ACTIVITY} = useActivityHistory();
  const [filter, setFilter] = useState({
    page_index: 0,
    page_size: 10,
  });
  const [listActivity, setListActivity] = useState<ResponseActivityHistory<ActivityHistory>>();
  useEffect(() => {
    (async () => {
      try {
        const res: ResponseActivityHistory<ActivityHistory> =
          await managerUserApi.getActivityHistory(filter);
        setListActivity({
          ...res,
          activityHistoryList: res.activityHistoryList.map((x) => ({
            ...x,
            info: JSON.parse(x.info),
          })),
        });
      } catch (error) {}
    })();
  }, [filter]);
  const handleChangePage = (page: number) => {
    setFilter({
      ...filter,
      page_index: page - 1,
    });
  };
  return (
    <Box>
      <Typography className={classes.title} variant="subtitle2">
        {t("managerAdmin.nhatkyhoatdong")}
      </Typography>

      {listActivity?.activityHistoryList.map((item: ActivityHistory) => (
        <Box className={classes.content} key={item.id}>
          <img src={item.user.profileImageUrl || NO_AVATAR} alt="NO_AVATAR" />
          <Box>
            <Typography variant="subtitle2">
              <Typography variant="subtitle2" component="span" style={{color: "rgb(184, 15, 15)"}}>
                {item.user.name}{" "}
              </Typography>
              {BASE_ACTIVITY.map((x: any) =>
                x.id === item.action.id ? x.description.toLowerCase() : " "
              )}{" "}
              {item.action.id === 2 ? (
                item.info.userList.map((x: any, idx: number) => (
                  <Typography variant="subtitle2" component="span" style={{color: "#0d8ddf"}}>
                    {idx !== item.info.userList.length - 1 ? x.userName + ", " : x.userName}
                  </Typography>
                ))
              ) : item.action.id === 5 ? (
                <Typography variant="subtitle2" component="span" style={{color: "#0d8ddf"}}>
                  {item.info.lession.name}
                </Typography>
              ) : item.action.id === 6 ? (
                <Typography variant="subtitle2" component="span" style={{color: "#0d8ddf"}}>
                  {item.info.exam.name}
                </Typography>
              ) : item.action.id === 9 || item.action.id === 10 ? (
                item.info.postList.map((x: any, idx: number) => (
                  <Typography variant="subtitle2" component="span" style={{color: "#0d8ddf"}}>
                    {idx !== item.info.postList.length - 1
                      ? x.owner.userName + ", "
                      : x.owner.userName}
                  </Typography>
                ))
              ) : (
                <>
                  <Typography variant="subtitle2" component="span" style={{color: "#0d8ddf"}}>
                    {item.info.user.userName}
                  </Typography>
                  {item.action.id === 1 && <> {t("activityHistory.thanhtaikhoanadmin")}</>}
                </>
              )}
            </Typography>
            <Typography variant="subtitle2" className={classes.content_date}>
              {t("activityHistory.vaoluc")} {dateTimeToFormatHHMM(new Date(item.createdTimestamp))},{" "}
              {moment(new Date(item.createdTimestamp)).format("ll")}
            </Typography>
          </Box>
        </Box>
      ))}

      {/* Pagination */}

      {listActivity && listActivity.pageInfo && listActivity?.pageInfo.totalPages > 1 && (
        <Box mt={3} display="flex" justifyContent="flex-end">
          <Pagination
            count={Math.ceil(listActivity.pageInfo.totalElements / listActivity.pageInfo.pageSize)}
            page={listActivity.pageInfo.pageIndex + 1}
            variant="outlined"
            color="primary"
            shape="rounded"
            onChange={(e, page) => handleChangePage(page)}
          />
        </Box>
      )}
    </Box>
  );
}
