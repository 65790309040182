import {makeStyles, Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxHeight: 500,
  },
  btn_add: {
    background: "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)",
    borderRadius: 10,
    fontSize: 13,
    color: "#fff",
    padding: "5px 12px",
    marginRight: 10,
  },
  btn_delete: {
    borderRadius: 10,
    fontSize: 13,
    color: "#fff",
    padding: "5px 12px",
    background: "linear-gradient(0deg, #FD5468 0%, rgba(255, 193, 200, 0.9) 169.32%)",
  },
  root_modal: {
    width: "100%",
    height: "60%",
    display: "flex",
    justifyContent: "center",
  },
  modal__container: {
    width: "100%",
    maxWidth: 560,
    borderRadius: 12,
    backgroundColor: "#fff",
    zIndex: 9,
    display: "flex",
    flexDirection: "column",
    margin: "30px 0",
  },
  modal__header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 24px",
    minHeight: 65,
    borderBottom: "1px solid rgb(235, 235, 235)",
    "& >h6": {
      fontWeight: 700,
      fontSize: 18,
      marginRight: 50,
    },
  },
}));

export default useStyles;
