import {makeStyles, Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    color: "#004673",
    fontSize: 25,
    fontWeight: 700,
    paddingTop: 20,
    paddingBottom: 20,
  },
  content: {
    backgroundColor: "#fff",
    borderRadius: 10,
    boxShadow: "0px 2px 4px rgba(168, 168, 168, 0.25), 0px 2px 4px rgba(168, 168, 168, 0.25)",
    padding: "20px 30px",
    display: "flex",
    alignItems: "center",
    marginBottom: 15,
    "& img": {
      width: 50,
      height: 50,
      borderRadius: "50%",
      objectFit: "cover",
      marginRight: 20,
    },
  },
  content_date: {
    fontSize: 12,
    color: "#1B1B1B",
  },
}));

export default useStyles;
