import {ResponseStatus} from "models/common";
import {Category, CreateCategory, ListResponseCategory} from "models/managerCategory";
import axiosClient from "./axiosClient";

const managerCategoryApi = {
  getCategories(): Promise<ListResponseCategory<Category>> {
    const url = "/lession/getLessionCategory";
    return axiosClient.get(url);
  },

  createCategory(value: CreateCategory): Promise<ResponseStatus> {
    const url = "/lession/createLessionCategory";
    return axiosClient.post(url, value);
  },
  updateCategory(name: string, id: number | undefined): Promise<ResponseStatus> {
    const url = `/lession/updateLessionCategory?lessionCategoryId=${id}`;
    return axiosClient.put(url, name);
  },

  deleteCategory(id: number): Promise<ResponseStatus> {
    const url = `/lession/deleteLessionCategory?lessionCategoryId=${id}`;
    return axiosClient.delete(url);
  },
};

export default managerCategoryApi;
