import {makeStyles, Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    padding: 20,
  },
  btn_submit: {
    background: "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)",
    color: "#fff",
    marginTop: 15,
    borderRadius: 10,

    width: "40%",
  },
}));

export default useStyles;
