import {
  Box,
  createStyles,
  Menu,
  MenuItem,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {withStyles} from "@material-ui/styles";
import {useAppDispatch, useAppSelector} from "app/hooks";
import {
  fetchListLessonByUser,
  selectFilterLessonListByUser,
  selectLessonListByUser,
} from "features/Home/pages/ListLesson/listLessonSlice";
import React, {useEffect, useState} from "react";
import useStyles from "./style";
import {useTranslation} from "react-i18next";

export interface ITableCalendarProps {}

export default function TableCalendar(props: ITableCalendarProps) {
  const {t} = useTranslation();
  const classes = useStyles();
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints?.down("xs"));
  const arrayMonth = [
    {
      name: `${t("home.table.thang1")}`,
      value: 1,
    },
    {
      name: `${t("home.table.thang2")}`,
      value: 2,
    },
    {
      name: `${t("home.table.thang3")}`,
      value: 3,
    },
    {
      name: `${t("home.table.thang4")}`,
      value: 4,
    },
    {
      name: `${t("home.table.thang5")}`,
      value: 5,
    },
    {
      name: `${t("home.table.thang6")}`,
      value: 6,
    },
    {
      name: `${t("home.table.thang7")}`,
      value: 7,
    },
    {
      name: `${t("home.table.thang8")}`,
      value: 8,
    },
    {
      name: `${t("home.table.thang9")}`,
      value: 9,
    },
    {
      name: `${t("home.table.thang10")}`,
      value: 10,
    },
    {
      name: `${t("home.table.thang11")}`,
      value: 11,
    },
    {
      name: `${t("home.table.thang12")}`,
      value: 12,
    },
  ];
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useAppDispatch();

  const filter = useAppSelector(selectFilterLessonListByUser);
  const listLesson = useAppSelector(selectLessonListByUser);
  useEffect(() => {
    dispatch(
      fetchListLessonByUser({...filter, month: currentMonth, year: new Date().getFullYear()})
    );
  }, [dispatch, filter, currentMonth]);
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
      head: {
        backgroundColor: "#f3f3f3",
        color: "#1B1B1B",
        fontSize: 13,
        fontWeight: 700,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
      },
      body: {
        fontSize: 14,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
      },
    })
  )(TableCell);

  const handleChangeMonth = (month: number) => {
    setAnchorEl(null);
    setCurrentMonth(month);
  };
  return (
    <Box>
      <Table className={classes.header}>
        <TableHead>
          <TableRow>
            <TableCell align="center" width={isMobile ? "60px" : "150px"}>
              <Typography variant="subtitle2" className={classes.header__title}>
                {t("home.table.lichhoc")}
              </Typography>
            </TableCell>
            <TableCell align="center" style={{flexGrow: 1}}>
              <Box
                className={classes.header__filterMonth}
                aria-controls={anchorEl ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleOpenMenu}
              >
                <Typography variant="subtitle2">{t(`home.table.thang${currentMonth}`)}</Typography>
                <ExpandMoreIcon />
              </Box>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                className={classes.menu_listMonth}
              >
                {arrayMonth.map((month) => (
                  <MenuItem key={month.value} onClick={() => handleChangeMonth(month.value)}>
                    {month.name}
                  </MenuItem>
                ))}
              </Menu>
            </TableCell>
            <TableCell align="center" width={isMobile ? "60px" : "150px"}>
              <Typography variant="subtitle2" className={classes.header__title}>
                {t("home.table.tatca")}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>

      <TableContainer component={Paper} className={classes.table_container}>
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              <StyledTableCell align="center" width="150px">
                {t("home.table.ngay/thang/nam")}
              </StyledTableCell>
              <StyledTableCell align="center">{t("home.table.tenbaihoc")}</StyledTableCell>
              <StyledTableCell align="center" width="150px">
                {t("home.table.trangthai")}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listLesson?.content?.length > 0 ? (
              <>
                {listLesson?.content?.map((lesson, index) => (
                  <TableRow key={index}>
                    <StyledTableCell align="center" component="th" scope="row">
                      {String(lesson?.lession?.publishDate).slice(-2)}/
                      {String(lesson?.lession?.publishDate).slice(4, 6)}/
                      {String(lesson?.lession?.publishDate).slice(0, 4)}
                    </StyledTableCell>
                    <StyledTableCell align="center">{lesson?.lession?.lessionName}</StyledTableCell>
                    <StyledTableCell align="center">
                      {lesson?.watched ? (
                        <Box className={classes.status}>
                          <CheckIcon className={classes.status_checkIcon} />
                          {t("home.table.dathamgia")}
                        </Box>
                      ) : (
                        <Box className={classes.status}>
                          <ClearIcon className={classes.status_clearIcon} />
                          {t("home.table.chuathamgia")}
                        </Box>
                      )}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              <TableRow>
                <StyledTableCell align="center"></StyledTableCell>
                <StyledTableCell align="center">
                  {" "}
                  <Box p={3}>{t("home.table.hientaichuacobaihoc")}</Box>
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
