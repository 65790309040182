import {yupResolver} from "@hookform/resolvers/yup";
import {Box, Button, Grid, IconButton, Menu, Tooltip, Typography} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import managerLessonApi from "api/managerLesson";
import {useAppDispatch, useAppSelector} from "app/hooks";
import BG_LESSON_STUDENT from "assets/img/bg_detail_lesson_student.png";
import EMOJI from "assets/img/emoji.png";
import ICON_DISLIKE from "assets/img/icon_dislike.png";
import ICON_LIKE from "assets/img/icon_like.png";
import LOADING from "assets/img/loading.gif";
import clsx from "clsx";
import InputField from "components/FormFields/InputField";
import {BASE_URL} from "constants/config";
import Picker, {SKIN_TONE_MEDIUM_DARK} from "emoji-picker-react";
import LOGO from "assets/img/logo.png";
import {
  fetchLessonList,
  selectFilterLessonListPosted,
  selectLoadingLessonList,
} from "features/Admin/pages/ManagerLesson/managerLessonSlice";
import {useConfirm} from "material-ui-confirm";
import {ResponseStatus} from "models/common";
import {Comment, LessonByUser, ListResponseComments} from "models/managerLesson";
import React, {ChangeEvent, Fragment, useCallback, useEffect, useRef, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import ReactPlayer from "react-player";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import * as yup from "yup";
import ListComment from "./components/ListComment";
import ListVideoRecommend from "./components/ListVideoRecommend";
import useStyles from "./style";

export default function DetailLesson() {
  const [openDrawer, setOpenDrawer] = useState(true);
  const {t} = useTranslation();
  const schema = yup.object().shape({
    comment: yup.string().required(`${t("home.detaillesson.vuilongnhapbinhluan")}`),
  });
  const [comment, setComment] = useState("");
  const [openEmoji, setOpenEmoji] = useState<null | HTMLElement>(null);
  const [detailLesson, setDetailLesson] = useState<LessonByUser | undefined>();
  const params = useParams();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const dispatch = useAppDispatch();
  const filterListPosted = useAppSelector(selectFilterLessonListPosted);
  const loading = useAppSelector(selectLoadingLessonList);
  const [openButton, setOpenButton] = useState(false);
  const [listComment, setListComment] = useState<ListResponseComments<Comment> | undefined>();
  const [filter, setFilter] = useState({
    page: 0,
    size: 3,
  });
  const videoRef = useRef<any>(null);
  const handleFetchListComment = useCallback(async () => {
    try {
      const res: ListResponseComments<Comment> = await managerLessonApi.getComments({
        page: 0,
        size: 3,
        lessionId: Number(params.id),
      });

      setListComment(res);
    } catch (error) {}
  }, [params]);

  const handleFetchDetailLesson = useCallback(async () => {
    try {
      const res: LessonByUser = await managerLessonApi.getDetailLessonByUser(Number(params.id));
      setDetailLesson(res);
    } catch (error) {}
  }, [params]);

  useEffect(() => {
    handleFetchDetailLesson();
    handleFetchListComment();
    setFilter({page: 0, size: 3});
  }, [params]);

  useEffect(() => {
    dispatch(fetchLessonList(filterListPosted));
  }, [dispatch]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const initialValues = {
    comment: "",
  };
  const {control, handleSubmit, setValue, getValues, reset} = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });
  const handleOpenEmoji = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenEmoji(event.currentTarget);
  };

  const onEmojiClick = (event: any, emojiObject: any) => {
    setValue("comment", getValues().comment + emojiObject.emoji);
    setComment(emojiObject.emoji);
  };

  const classes = useStyles({
    comment,
  });

  const handleShowMore = async () => {
    setFilter({...filter, page: filter.page + 1});
    try {
      const res: ListResponseComments<Comment> = await managerLessonApi.getComments({
        ...filter,
        lessionId: Number(params.id),
        page: filter.page + 1,
      });
      const dataListCommentNext = res.comments.content;
      const currentListComment = {...res};
      const newListComment = listComment?.comments?.content?.concat(dataListCommentNext);
      currentListComment.comments.content = newListComment;
      setListComment(currentListComment);
    } catch (error) {}
  };
  const handleSubmitComment = async (value: any) => {
    const valueComment = {
      comment: value.comment,
      lessionId: Number(params.id),
    };
    try {
      const res: ResponseStatus = await managerLessonApi.postComment(valueComment);
      if (res.responseCode === "00") {
        handleFetchDetailLesson();
        handleFetchListComment();
        setOpenButton(false);
        toast.success(`${t("home.detaillesson.binhluanthanhcong")}`);
        reset();
      } else {
        toast.error(`${t("home.detaillesson.binhluanthatbai")}`);
        throw new Error("Comment is failed");
      }
    } catch (error) {}
  };

  // const handleWatchedVideo = async (detail: LessonByUser | undefined) => {
  //   if (detail?.lessionUsers?.watched === true) {
  //     return;
  //   }
  //   try {
  //     const res: ResponseStatus = await managerLessonApi.postWatchedStatus({
  //       lessionId: detail?.lessionUsers?.lessionId,
  //       isWatched: true,
  //     });
  //     if (res.responseCode === "00") {
  //       handleFetchDetailLesson();
  //     } else {
  //       throw new Error(res.responseDescription);
  //     }
  //   } catch (error) {}
  // };

  const handlePostLikeStatus = async (detail: LessonByUser | undefined) => {
    try {
      const res: ResponseStatus = await managerLessonApi.postLikeStatus({
        lessionId: detail?.lessionUsers?.lessionId,
        likeStatus: detail?.lessionUsers?.likeStatus === "LIKED" ? "NONE" : "LIKED",
      });
      if (res.responseCode === "00") {
        handleFetchDetailLesson();
      } else {
        throw new Error("Like video is failed");
      }
    } catch (error) {}
  };

  const handlePostDislikeStatus = async (detail: LessonByUser | undefined) => {
    try {
      const res: ResponseStatus = await managerLessonApi.postLikeStatus({
        lessionId: detail?.lessionUsers?.lessionId,
        likeStatus: detail?.lessionUsers?.likeStatus === "DISLIKE" ? "NONE" : "DISLIKE",
      });
      if (res.responseCode === "00") {
        handleFetchDetailLesson();
      } else {
        throw new Error("Like video is failed");
      }
    } catch (error) {}
  };
  const handleDeleteLesson = (lesson: LessonByUser | undefined) => {
    confirm({
      title: (
        <Typography variant="h5" color="secondary">
          {t("delete.chuy")}
        </Typography>
      ),
      description: (
        <Typography variant="subtitle2">
          {t("delete.khiban")}{" "}
          <Typography variant="subtitle2" component="span" color="secondary">
            {t("delete.dongy")}
          </Typography>{" "}
          {t("delete.xoa")}
          <Typography variant="subtitle2" component="span" style={{color: "#004673"}}>
            {" "}
            {lesson?.lessionUsers?.lession?.lessionName}
          </Typography>{" "}
          {t("delete.sexoatatcadulieu")}
        </Typography>
      ),
      confirmationText: <Button className={classes.btn_delete}> {t("delete.dongy")}</Button>,
      cancellationText: <Button className={classes.btn_add}> {t("delete.huybo")}</Button>,
    })
      .then(async () => {
        const res: ResponseStatus = await managerLessonApi.deleteLesson(
          lesson?.lessionUsers?.lessionId
        );
        if (res.responseCode === "00") {
          toast.success(`${t("managerLesson.xoabaihocthanhcong")}`);
          navigate(-1);
        } else {
          toast.error(`${t("managerLesson.xoabaihocthatbai")}`);
          throw new Error(res.responseDescription);
        }
      })
      .catch((error) => console.log(error));
  };

  return loading ? (
    <Box className={classes.loading}>
      <img src={LOADING} alt="LOADING" />
    </Box>
  ) : (
    <Box className={classes.container}>
      <Box className={classes.btn_goBack}>
        <Tooltip title="Quay lại">
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIosIcon style={{marginLeft: 7}} />
          </IconButton>
        </Tooltip>
      </Box>
      <Grid container style={{paddingTop: 25}}>
        <Grid item xs={openDrawer ? 8 : 10}>
          {detailLesson?.lessionUsers?.lession?.attachment ? (
            <div className={classes.player_wrapper}>
              <ReactPlayer
                className={classes.react_player}
                url={detailLesson?.lessionUsers?.lession?.attachment?.url}
                controls={true}
                width="100%"
                playing
                height="100%"
                light={detailLesson?.lessionUsers?.lession?.attachment?.urlThumbnail}
                playIcon={<PlayArrowIcon className={classes.icon_play} />}
                ref={videoRef}
                config={{file: {attributes: {controlsList: "nodownload"}}}}
                // Disable right click
                onContextMenu={(e: any) => e.preventDefault()}
                onStart={() => {
                  const timer = detailLesson?.lessionUsers?.lastSeenTime;
                  if (timer) {
                    videoRef.current.seekTo(timer, "seconds");
                  }
                }}
              />
            </div>
          ) : (
            <iframe
              src={`https://view.officeapps.live.com/op/embed.aspx?src=${detailLesson?.lessionUsers?.lession?.powerPointFileList[0]?.powerPointFile?.url}`}
              width="100%"
              height="400px"
            ></iframe>
          )}
          <Box className={classes.root}>
            <Typography variant="subtitle2" className={classes.lesson__category}>
              {detailLesson?.lessionUsers?.lession?.lessionCategory?.name}
            </Typography>
            <Button className={classes.btn_delete} onClick={() => handleDeleteLesson(detailLesson)}>
              {t("button.delete")}
            </Button>
          </Box>
          <Box className={classes.root}>
            <Typography variant="subtitle2" className={classes.lesson__title}>
              {detailLesson?.lessionUsers?.lession?.lessionName}
            </Typography>

            <Box className={classes.root} style={{minWidth: 220}}>
              <Tooltip
                title={
                  detailLesson?.lessionUsers?.likeStatus === "LIKED"
                    ? `${t("home.detaillesson.bothich")}`
                    : `${t("home.detaillesson.khongthich")}`
                }
                style={{cursor: "pointer"}}
                onClick={() => handlePostLikeStatus(detailLesson)}
              >
                <Box display="flex" alignItems="center">
                  {detailLesson?.lessionUsers?.likeStatus === "LIKED" ? (
                    <ThumbUpIcon className={clsx(classes.icon, classes.icon_checked)} />
                  ) : (
                    <img src={ICON_LIKE} alt="ICON_LIKE" className={classes.icon} />
                  )}
                  <Typography
                    variant="subtitle2"
                    component="span"
                    className={classes.number_status}
                  >
                    {detailLesson?.lessionUsers?.lession?.noUserLiked} {t("home.detaillesson.like")}
                  </Typography>
                </Box>
              </Tooltip>
              <Tooltip
                title={t("home.detaillesson.khongthich") || ""}
                style={{cursor: "pointer"}}
                onClick={() => handlePostDislikeStatus(detailLesson)}
              >
                <Box display="flex" alignItems="center">
                  {detailLesson?.lessionUsers?.likeStatus === "DISLIKE" ? (
                    <ThumbDownIcon className={clsx(classes.icon, classes.icon_checked)} />
                  ) : (
                    <img src={ICON_DISLIKE} alt="ICON_DISLIKE" className={classes.icon} />
                  )}
                  <Typography
                    variant="subtitle2"
                    component="span"
                    className={classes.number_status}
                  >
                    {detailLesson?.lessionUsers?.lession?.noUserDislike}{" "}
                    {t("home.detaillesson.unlike")}
                  </Typography>
                </Box>
              </Tooltip>
            </Box>
          </Box>
          <Typography variant="subtitle2" className={classes.lesson__date}>
            {String(detailLesson?.lessionUsers?.lession?.publishDate).slice(-2)}/
            {String(detailLesson?.lessionUsers?.lession?.publishDate).slice(4, 6)}/
            {String(detailLesson?.lessionUsers?.lession?.publishDate).slice(0, 4)}
          </Typography>

          <Typography variant="subtitle2" className={classes.lesson__description}>
            {t("home.detaillesson.mota")} :{" "}
            <Typography variant="subtitle2" component="span">
              {detailLesson?.lessionUsers?.lession?.lessionDescription}
            </Typography>
          </Typography>

          {/*  Comment */}
          <form onSubmit={handleSubmit(handleSubmitComment)}>
            <Box mt={3}>
              <Typography variant="h5" style={{fontSize: 18}}>
                {listComment?.comments.totalElements} {t("home.detaillesson.binhluan")}
              </Typography>

              <Box mt={3} className={classes.root}>
                <Box>
                  <img
                    src={detailLesson?.lessionUsers?.lession?.user?.profileImageUrl || LOGO}
                    alt="avatar"
                    className={classes.avatar}
                  />
                </Box>
                <Box position="relative" width="100%">
                  <InputField
                    name="comment"
                    label={t("home.detaillesson.vietbinhluan")}
                    onClick={() => setOpenButton(true)}
                    control={control}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setComment(event?.target.value)
                    }
                  />
                  <IconButton onClick={handleOpenEmoji} className={classes.icon_emoji}>
                    <img src={EMOJI} alt="EMOJI" />
                  </IconButton>
                  {openEmoji && (
                    <Menu
                      id="simple-menu"
                      anchorEl={openEmoji}
                      keepMounted
                      open={Boolean(openEmoji)}
                      onClose={() => setOpenEmoji(null)}
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      //  className={classes.menu}
                    >
                      <Picker
                        onEmojiClick={onEmojiClick}
                        disableAutoFocus={true}
                        skinTone={SKIN_TONE_MEDIUM_DARK}
                        groupNames={{smileys_people: "PEOPLE"}}
                        native
                      />
                    </Menu>
                  )}
                </Box>
              </Box>
              {openButton && (
                <Box display="flex" justifyContent="flex-end" mt={3}>
                  <Button
                    onClick={() => {
                      setOpenButton(false);
                      reset();
                    }}
                  >
                    {t("button.cancel")}
                  </Button>
                  <Button className={classes.btn__comment} type="submit" disabled={comment === ""}>
                    {t("home.detaillesson.binhluan")}
                  </Button>
                </Box>
              )}
            </Box>
          </form>

          {/* List comment */}

          {listComment &&
            listComment.comments.content?.map((comment: Comment, index: number) => (
              <Fragment key={index}>
                <ListComment
                  userComment={comment}
                  handleFetchListComment={handleFetchListComment}
                />
              </Fragment>
            ))}
          {listComment?.comments?.number + 1 !== listComment?.comments?.totalPages &&
          listComment?.comments?.content &&
          listComment?.comments?.content?.length > 0 ? (
            <Box className={classes.showMore} onClick={handleShowMore} mt={3}>
              <ArrowDropDownIcon />
              <Typography variant="subtitle2">
                {t("home.detaillesson.hienthithembinhluan")}
              </Typography>
            </Box>
          ) : null}
        </Grid>
        {/* List video recommend */}

        <Grid item xs={openDrawer ? 4 : 2}>
          <ListVideoRecommend openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />

          <Box className={classes.bg_lesson}>
            <img src={BG_LESSON_STUDENT} alt="BG_LESSON_STUDENT" />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
