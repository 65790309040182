import {Box, makeStyles, Theme, Tooltip, Typography} from "@material-ui/core";
import ICON_LIKE from "assets/img/icon_like_fb.svg";

import managerCommnunityApi from "api/managerCommunity";
import {useAppSelector} from "app/hooks";
import NO_AVATAR from "assets/img/no_avatar.png";
import {selectInfoLogin} from "features/Auth/authSlice";
import {ListResponseReact} from "models/managerCommunityPost";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
export interface ReplyProps {
  reply: any;
  handleTimeCreatedPost: any;
  handleGetChildComment: any;
}
export default function Reply(props: ReplyProps) {
  const {reply, handleTimeCreatedPost, handleGetChildComment} = props;
  const infoLogin = useAppSelector(selectInfoLogin);

  const useStyles = makeStyles((theme: Theme) => ({
    comments: {
      paddingTop: 16,
      display: "flex",
    },
    reply: {
      paddingLeft: 72,
      fontSize: 10,
      cursor: "pointer",
    },
    reply_avatar: {
      width: 30,
      height: 30,
      objectFit: "cover",
      marginRight: 10,
      borderRadius: "50%",
    },
    comments__avatar: {
      width: 50,
      height: 50,
      objectFit: "cover",
      marginRight: 10,
      borderRadius: "50%",
    },

    comments__content: {
      backgroundColor: reply.ownerComment.id === infoLogin.id ? "#eaf2ff" : "#F2F2F2",
      borderRadius: 10,
      padding: 10,
      position: "relative",
    },
    comments__content__username: {
      fontWeight: 600,
      fontSize: 15,
    },
    comments__content__totalLike: {
      position: "absolute",
      right: 10,
      bottom: -10,
      "& > img": {
        width: 20,
        height: 20,
      },
    },
    comment__content__bgtooltip: {
      padding: 5,
      display: "flex",
      "& > img": {
        width: 15,
        height: 15,
        marginRight: 10,
      },
    },
    comments__actions: {
      display: "flex",
      maxWidth: 220,
      padding: "5px 10px",
      paddingBottom: 0,
      "& >h6": {
        fontSize: 13,
        color: "#828282",
        cursor: "pointer",
      },
    },
    icon_emoji: {
      height: 20,
      width: 20,
    },
    form__wrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: "#F3F3F3",
      borderRadius: 50,
      cursor: "pointer",
      zIndex: 99,
      width: "100%",
      position: "relative",
      padding: "0px 15px",
    },
    input__comment: {
      "& .MuiInputBase-root": {
        "&::after": {
          display: "none",
        },
        "&::before": {
          display: "none",
        },
      },
    },
  }));
  const classes = useStyles();
  const {t} = useTranslation();

  const handleLikeComment = async () => {
    try {
      let value = {
        comment_id: reply.id,
        action: reply.isLikedByRequester ? "Unlike" : "Like",
      };
      const res: ListResponseReact = await managerCommnunityApi.ReactComment(value);
      if (res.responseCode === "00") {
        handleGetChildComment(reply.parentCommentID);
      }
    } catch (error) {
      toast.error("Failed");
      throw new Error("Failed");
    }
  };

  return (
    <Box>
      <Box className={classes.comments}>
        <Box>
          {reply?.ownerComment.avatarUrl ? (
            <img
              src={reply?.ownerComment.avatarUrl}
              alt="avatar"
              className={classes.reply_avatar}
            />
          ) : (
            <img src={NO_AVATAR} alt="avatar" className={classes.reply_avatar} />
          )}
        </Box>
        <Box>
          <Box className={classes.comments__content}>
            <Typography variant="subtitle2" className={classes.comments__content__username}>
              {reply?.ownerComment.name}
            </Typography>
            <Typography variant="body2">{reply?.content}</Typography>
            {reply.totalLikeCount > 0 && (
              <Box className={classes.comments__content__totalLike}>
                <Tooltip
                  title={
                    <div className={classes.comment__content__bgtooltip}>
                      <img src={ICON_LIKE} alt="LIKE" />
                      <Typography variant="body2">{reply.totalLikeCount}</Typography>
                    </div>
                  }
                >
                  <img src={ICON_LIKE} alt="LIKE" />
                </Tooltip>
              </Box>
            )}
          </Box>

          <Box className={classes.comments__actions}>
            <Typography
              variant="subtitle2"
              style={{color: reply.isLikedByRequester ? "#2078f4" : "initial"}}
              onClick={handleLikeComment}
            >
              {t("community.thich")}
            </Typography>

            <Typography variant="subtitle2" style={{marginLeft: 10}}>
              {handleTimeCreatedPost(reply.postedTimestampUTC)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
