import {yupResolver} from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  createStyles,
} from "@material-ui/core";
import {makeStyles, withStyles} from "@material-ui/styles";
import {useAppDispatch, useAppSelector} from "app/hooks";
import BACKGROUND from "assets/img/bg_create_test.png";
import CheckboxField from "components/FormFields/CheckboxField";
import InputField from "components/FormFields/InputField";
import {SelectField} from "components/FormFields/SelectFiled";
import {
  fetchDataOptions,
  selectListDepartment,
  selectListClass,
} from "features/Auth/options/optionsSlice";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";

import CreateQuestion from "./components/CreateQuestion";

import {Pagination} from "@material-ui/lab";
import EmptyComponent from "components/Empty";
import {RadioGroupField} from "components/FormFields/RadioGroupFiled";
import {
  fetchPrizeList,
  selectFilterPrizeList,
} from "features/Admin/pages/ManagerPrize/managerPrizeSlice";
import {ListExam, Question} from "models/managerTest";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {dateTimeToFormatYYYYHHMM} from "utils/dateTimeFormat";
import * as yup from "yup";
import {
  SelectFilterListExam,
  SelectListExam,
  fetchListExam,
  setFilterListExam,
} from "../../managerExamListSlice";
import PickOldExamComponent from "./components/PickOldExam";
import {IClass} from "models/managerClass";
import managerClassApi from "api/managerClass";

export default function AddExam() {
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      display: "flex",
      alignItems: "center",
      marginBottom: 15,
      width: "100%",
      "& .MuiFormGroup-root": {
        flexDirection: "row",
      },
    },
    background: {
      backgroundImage: `url(${BACKGROUND})`,
      backgroundSize: "cover",
      backgroundPositionX: "right",
      backgroundRepeat: "no-repeat",
      height: 130,
      width: "100%",
      position: "relative",
      "& >h6": {
        position: "absolute",
        top: 50,
        left: 0,
        color: "#004673",
        fontSize: 25,
        fontWeight: 700,
      },
    },
    title: {
      fontSize: 15,
      fontWeight: 600,
      width: "20%",
      [theme.breakpoints?.up("xl")]: {
        fontSize: 18,
        width: "17%",
      },
    },

    btn_create: {
      background: "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)",
      color: "#fff",
      borderRadius: 20,
      padding: "10px 15px",
    },
    container: {
      maxHeight: 500,
      "& .MuiButton-root.Mui-disabled": {
        color: "#fff",
      },
    },
    checkbox: {
      color: "#DADADA",
      borderRadius: 5,
    },
  }));
  const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
      head: {
        padding: "16px 5px",
        background: "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)",
        color: "#fff",
        fontSize: 11,
        fontWeight: 700,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        borderBottom: "1px solid #777777",
        zIndex: 99,
        [theme.breakpoints?.up("xl")]: {
          fontSize: 13,
        },
      },
      body: {
        padding: "16px 5px",
        fontSize: 11,
        borderRight: "1px solid rgba(224, 224, 224, 0.8)",

        [theme.breakpoints?.up("xl")]: {
          fontSize: 13,
        },
      },
    })
  )(TableCell);
  const {t} = useTranslation();

  const schema = yup.object().shape({
    startDate: yup.string().required(`${t("managerTest.vuilongchonngaykiemtra")}`),
    time: yup.string().required(`${t("managerTest.vuilongchongiokiemtra")}`),
    examTime: yup.string().required(`${t("managerTest.vuilongnhapthoigianthi")}`),
    totalPoint: yup.string().required(`${t("managerTest.vuilongnhapthoigianthi")}`),
    departmentId: yup.string().when("allDepartment", {
      is: false,
      then: yup.string().required(`${t("managerTest.vuilongchonbophan")}`),
    }),
    timePublishAnswerDate: yup.string().when("publishAnswerDate", {
      is: (publishAnswerDate: Date | undefined | any) => !!publishAnswerDate,
      then: yup.string().required("Vui lòng chọn giờ hiện đáp án"),
    }),
    // classId: yup.string().when("allClass", {
    //   is: false,
    //   then: yup.array().min(1).required(`*Vui lòng chọn lớp học`),
    // }),
    classId: yup.array().min(1, "*Vui lòng chọn lớp học"),
    category: yup.string().required("*Vui lòng chọn hình thức kiểm tra"),
  });
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const department = useAppSelector(selectListDepartment);
  const classList = useAppSelector(selectListClass);
  const filter = useAppSelector(selectFilterPrizeList);
  const [valueForm, setValueForm] = useState<Question>();
  const [createQuestion, setCreateQuestion] = useState(false);
  const [isPickOldExam, setIsPickOldExam] = useState(false);
  const [isShowMainExam, setIsShowMainExam] = useState(true);
  const [disableClass, setDisabledClass] = useState(false);
  const [disableListUser, setDisableListUser] = useState(false);
  const [userListByClass, setUserListByClass] = useState([]);
  useEffect(() => {
    dispatch(fetchDataOptions());
    dispatch(fetchPrizeList(filter));
  }, [dispatch]);

  const initialValue: Question = {
    departmentId: "",
    type: "",
    startDate: "",
    examTime: "",
    time: "",
    prizeId: "",
    totalPoint: "",
    allDepartment: true,
    category: "",
    timePublishAnswerDate: "",
    publishAnswerDate: "",
    allClass: false,
    classId: [],
    userId: [],
  };
  const {control, handleSubmit, setValue, watch} = useForm({
    defaultValues: initialValue,
    resolver: yupResolver(schema),
  });
  const watchIsAllClass = watch("allClass");
  const watchListClass = watch("classId");

  useEffect(() => {
    if (watchIsAllClass) {
      setDisableListUser(true);
      setValue(
        "classId",
        classList.map((item) => item.name)
      );
      setValue("userId", []);
    } else {
      setValue("classId", []);
    }
  }, [watchIsAllClass]);

  useEffect(() => {
    if (watchListClass.length > 0 && !watchIsAllClass) {
      setDisableListUser(false);
      const classIdList = watchListClass
        .map((x: string) => classList.find((y: any) => x === y.name))
        .map((z: IClass) => z.id);

      (async () => {
        try {
          const res: any = await Promise.all(
            classIdList.map((x: string) => managerClassApi.getDetailClass(x))
          );
          const data = res.map((res: any) =>
            res.class.userList.map((x: any) => ({
              id: x.id,
              name: x.name,
              class: x.class.name,
            }))
          );
          setUserListByClass(data.flat());
        } catch (error) {}
      })();
    } else {
      setDisableListUser(true);
    }
  }, [watchListClass]);

  const handleSubmitForm = (value: Question) => {
    const currentHours = new Date().getHours();
    const currentMinutes = new Date().getMinutes();
    if (value.time) {
      const currentDay = moment(new Date()).format("YYYY-MM-DD");
      const currentTimeStamp = currentHours * 3600 + currentMinutes * 60;
      const timeClicked = Number(value.time.slice(0, 2)) * 3600 + Number(value.time.slice(-2)) * 60;
      if (currentTimeStamp > timeClicked && currentDay === value.startDate) {
        toast.error(`${t("managerTest.vuilongchongiokiemtralonhonhoacbanggiohientai")}`);
        return;
      }
    }
    const convertExamTime =
      (Math.floor(Number(value?.examTime) / 60) < 10
        ? "0" + Math.floor(Number(value?.examTime) / 60)
        : Math.floor(Number(value?.examTime) / 60)) +
      ":" +
      (Number(value?.examTime) % 60 > 0
        ? Number(value?.examTime) % 60
        : "0" + (Number(value?.examTime) % 60));

    const endDate =
      Number(value?.time?.split(":")[1]) + Number(convertExamTime?.split(":")[1]) > 59
        ? Number(value?.time?.split(":")[0]) +
          Number(convertExamTime?.split(":")[0]) +
          1 +
          ":" +
          (Number(value?.time?.split(":")[1]) + Number(convertExamTime?.split(":")[1]) - 60 < 10
            ? "0" +
              (Number(value?.time?.split(":")[1]) + Number(convertExamTime?.split(":")[1]) - 60)
            : Number(value?.time?.split(":")[1]) + Number(convertExamTime?.split(":")[1]) - 60)
        : Number(value?.time?.split(":")[0]) +
          Number(convertExamTime?.split(":")[0]) +
          ":" +
          (Number(value?.time?.split(":")[1]) + Number(convertExamTime?.split(":")[1]));
    const classIdList = value.classId
      .map((x: string) => classList.find((y) => x === y.name))
      .map((z: IClass) => z.id);

    const userIdList = value.userId
      .map((x: string) => userListByClass.find((y: any) => x === y.name))
      .map((z: IClass) => z.id);

    setValueForm({
      ...value,
      startDate: value.startDate + "T" + value.time,
      publishAnswerDate: value.publishAnswerDate
        ? value.publishAnswerDate + "T" + value.timePublishAnswerDate
        : null,
      prizeId: Number(value.prizeId) || "",
      totalPoint: Number(value.totalPoint) || "",
      type: value.category === "multipleChoice" ? "CHOICES" : "TYPING",
      classId: value.allClass ? classList.map((x) => x.id) : classIdList,
      userIdList: userIdList.length > 0 ? userIdList : null,
      // departmentId: value.allDepartment
      //   ? department.map((x) => x.id)
      //   : [Number(value.departmentId)],
      departmentId: department.map((x) => x.id),
      endDate: value.startDate + "T" + endDate,
    });
    listExamOldAdded.length > 0 && isOldExam === "true"
      ? setIsPickOldExam(true)
      : setCreateQuestion(true);
    setIsShowMainExam(false);
  };

  // Table old exam
  const [isOldExam, setIsOldExam] = useState("false");
  const typeExam = watch("category");
  const filterListExam = useAppSelector(SelectFilterListExam);
  const listExam = useAppSelector(SelectListExam);
  const [listExamOldAdded, setListOldExamAdded] = useState<ListExam[]>([]);
  const [listQuestionsAdded, setListQuestionsAdded] = useState([]);

  useEffect(() => {
    if (isOldExam === "true") {
      if (typeExam === "multipleChoice") {
        dispatch(fetchListExam({...filterListExam, type: "CHOICES"}));
      } else {
        dispatch(fetchListExam({...filterListExam, type: "TYPING"}));
      }
    } else {
      dispatch(fetchListExam(filterListExam));
    }
  }, [dispatch, filterListExam, isOldExam, typeExam]);

  const handleChangePage = (page: number) => {
    dispatch(
      setFilterListExam({
        ...filterListExam,
        page: page - 1,
      })
    );
  };

  const handleAddExam = (exam: any) => {
    const cloneListAdded = [...listExamOldAdded];
    const findItem = cloneListAdded?.findIndex((x) => x.id === exam.id);
    if (findItem < 0) {
      cloneListAdded.push({...exam});
      setListOldExamAdded(cloneListAdded);
    } else {
      const newListAdded = cloneListAdded.filter((item) => item.id !== exam.id);
      setListOldExamAdded(newListAdded);
    }
  };

  return (
    <Box>
      {isPickOldExam ? (
        <PickOldExamComponent
          setListQuestionsAdded={setListQuestionsAdded}
          listQuestionsAdded={listQuestionsAdded}
          data={listExamOldAdded}
          setCreateQuestion={setCreateQuestion}
          setIsPickOldExam={setIsPickOldExam}
          setIsShowMainExam={setIsShowMainExam}
        />
      ) : createQuestion ? (
        <CreateQuestion
          valueForm={valueForm}
          setValueForm={setValueForm}
          setCreateQuestion={setCreateQuestion}
          listQuestionsAdded={listQuestionsAdded}
          setIsPickOldExam={setIsPickOldExam}
        />
      ) : isShowMainExam ? (
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <Box className={classes.background}>
            <Typography variant="subtitle2">{t("managerTest.themmoi").toUpperCase()}</Typography>
          </Box>
          <Box className={classes.root}>
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerTest.class")}
            </Typography>
            <Box width="50%" mr={5}>
              <SelectField
                options={classList}
                name="classId"
                control={control}
                multiple
                label={t("managerTest.chonclass")}
                disabled={disableClass}
                borderRadius="4px"
              />
            </Box>
            <CheckboxField
              name="allClass"
              label={t("tatca")}
              control={control}
              handleChange={(value) => {
                setDisabledClass(value);
                if (!value && watchListClass.length > 0) {
                  setDisableListUser(false);
                }
              }}
            />
          </Box>
          <Box className={classes.root}>
            <Typography variant="subtitle2" className={classes.title}>
              Học viên
            </Typography>
            <Box width="50%" mr={5}>
              <SelectField
                options={userListByClass}
                name="userId"
                multiple
                control={control}
                label={"Chọn học viên"}
                disabled={disableListUser}
                borderRadius="4px"
              />
            </Box>
          </Box>

          <Box className={classes.root}>
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerTest.ngaygiokiemtra")}
            </Typography>
            <Box display="flex" width="80%">
              <InputField
                control={control}
                type="date"
                label=""
                name="startDate"
                min={moment(new Date()).format("YYYY-MM-DD")}
              />
              <Box ml={2} width="100%">
                <InputField control={control} type="time" label="" name="time" />
              </Box>
            </Box>
          </Box>

          <Box className={classes.root}>
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerTest.thoigianhienthidapan")}
            </Typography>
            <Box display="flex" width="80%">
              <InputField
                control={control}
                type="date"
                label=""
                name="publishAnswerDate"
                min={moment(new Date()).format("YYYY-MM-DD")}
              />
              <Box ml={2} width="100%">
                <InputField control={control} type="time" label="" name="timePublishAnswerDate" />
              </Box>
            </Box>
          </Box>

          <Box className={classes.root}>
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerTest.thoigianlambai")}
            </Typography>
            <Box width="30%">
              <InputField
                control={control}
                name="examTime"
                label={t("managerTest.nhapthoigianthi")}
              />
              <Box ml={3}>
                <Typography variant="subtitle2" component="i" style={{color: "#999999"}}>
                  ({t("managerTest.vidu")}: 100 = 01:40:00)
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box className={classes.root}>
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerTest.hinhthuckiemtra")}
            </Typography>

            <RadioGroupField
              name="category"
              label=""
              control={control}
              options={[
                {label: `${t("managerTest.tracnghiem")}`, value: "multipleChoice"},
                {label: `${t("managerTest.tuluan")}`, value: "essay"},
              ]}
            />
          </Box>

          <Box className={classes.root}>
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerTest.tongdiem")}
            </Typography>
            <Box width="30%">
              <InputField
                control={control}
                name="totalPoint"
                label={t("managerTest.nhaptongdiem")}
              />
            </Box>
          </Box>

          <Box className={classes.root}>
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerTest.dungdethicu")}
            </Typography>

            <RadioGroup
              name="oldExam"
              onChange={(event) => setIsOldExam(event.target.value)}
              value={isOldExam}
            >
              <FormControlLabel
                value="true"
                control={<Radio color="primary" size="small" />}
                label={t("managerTest.co")}
                disabled={typeExam === "" ? true : false}
              />
              <FormControlLabel
                value="false"
                control={<Radio color="primary" size="small" />}
                label={t("managerTest.khong")}
                disabled={typeExam === "" ? true : false}
              />
            </RadioGroup>
          </Box>
          {isOldExam === "true" && (
            <Box m={2}>
              {listExam?.totalPages > 0 ? (
                <TableContainer component={Paper} className={classes.container}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell width="100px" align="center">
                          {t("luachon")}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {t("managerTest.tieudebaithi")}
                        </StyledTableCell>
                        <StyledTableCell align="center" width="100px">
                          {t("managerTest.socauhoi")}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {t("managerTest.thoigian")}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {t("managerTest.tongdiem")}
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listExam?.content.map((exam, index) => (
                        <TableRow key={exam.id}>
                          <StyledTableCell align="center" component="th" scope="exam">
                            <Checkbox
                              color="primary"
                              className={classes.checkbox}
                              checked={listExamOldAdded.some((x) => x.id === exam.id)}
                              onChange={(event) => handleAddExam(exam)}
                            />
                          </StyledTableCell>
                          <StyledTableCell align="center">{exam?.examTitle}</StyledTableCell>
                          <StyledTableCell align="center">
                            {exam?.examQuestions?.length}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {dateTimeToFormatYYYYHHMM(exam?.startDate)}
                          </StyledTableCell>
                          <StyledTableCell align="center">{exam?.totalPoint}</StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <EmptyComponent isBorder={false} />
              )}
              {listExam?.totalPages > 1 && (
                <Box mt={3} display="flex" justifyContent="space-between">
                  <Typography variant="subtitle2" style={{fontWeight: 600}}>
                    {t("managerTest.tongdethi")}: {listExam.totalElements}
                  </Typography>
                  <Pagination
                    count={Math.ceil(listExam?.totalElements / listExam?.size)}
                    page={listExam?.number + 1}
                    variant="outlined"
                    color="primary"
                    shape="rounded"
                    onChange={(e, page) => handleChangePage(page)}
                  />
                </Box>
              )}
            </Box>
          )}
          {/* <Box className={classes.root}>
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerTest.phanthuong")}
            </Typography>

            <Box width="80%">
              <SelectField
                options={prizeList?.content}
                name="prizeId"
                control={control}
                label={t("managerTest.chonphanthuong")}
              />
            </Box>
          </Box> */}
          <Box textAlign="right">
            <Button
              type="submit"
              className={classes.btn_create}
              disabled={isOldExam === "true" && listExamOldAdded.length === 0}
            >
              {t("managerTest.batdautaodethi")}
            </Button>
          </Box>
        </form>
      ) : (
        <></>
      )}
    </Box>
  );
}
